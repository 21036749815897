import React, { useEffect, useCallback, useMemo, forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import Button from "components/shared/Button";
import { TextInput, Select, AdvancedSelect, Radio, Checkbox } from "components/form";
import { InputDecorator } from "components/form/InputDecorator";
import notSelectedDropDownIcon from "images/icon-not-selected-dropdown.svg";
import { ProfessionalDetails as ProfessionalDetailsModel } from "models/auth/ProfessionalDetails";
import { ProfessionType } from "models/enums/ProfessionType";
import { RegistrarCommencementMonth } from "models/enums/RegistrarCommencementMonth";
import { RegistrarClassification } from "models/enums/RegistrarClassification";
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";
import { composeValidators, max, maxLength, min, minLength, required } from "validate";

import { FieldSelectNotSelectedDropDownIcon } from "./styles/SignUp";
import { Title, TitleLabel } from "./styles/VerifyMobile";
import "./styles/ProfessionalDetails.scss";

interface IProps {
    professionType: string;
    professionalDetails?: ProfessionalDetailsModel;
    isLoading?: boolean;
    onBack?: () => void;
    onNext?: (professionalDetails: ProfessionalDetailsModel) => Promise<void>;
    hideTitle?: boolean;
    canEdit: boolean;
    showButtons?: boolean;
    userEmail?: string;
    isDeletedUser?: boolean;
    hideTermsAndConditions?: boolean;
}

export type ProfessionalDetailsFormRef = {
    submit(): Promise<void>;
    reset(): void;
}

type Props = IProps &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const states = ["NSW", "VIC", "QLD", "WA", "SA", "TAS"];

const ProfessionalDetails = forwardRef((
    {
        professionType,
        userEmail,
        isDeletedUser,
        practices,
        fetchPractices,
        professionalDetails,
        deletedEmployee,
        fetchDeletedEmployee,
        canEdit,
        onNext,
        hideTitle,
        hideTermsAndConditions,
        showButtons,
        isLoading,
        onBack
    }: Props, ref: React.Ref<ProfessionalDetailsFormRef>) => {

    const {
        register,
        handleSubmit: innerSubmit,
        formState: { errors },
        watch,
        control,
        reset
    } = useForm<ProfessionalDetailsModel & { "termsAccepted": boolean }>({ defaultValues: professionalDetails });


    useImperativeHandle(ref, () => {
        return {
            submit: onNext && innerSubmit(onNext),
            reset
        }
    }, [innerSubmit, onNext, reset]);

    useEffect(() => {
        if (isDeletedUser && userEmail) {
            fetchDeletedEmployee(userEmail);
        }
    }, [fetchDeletedEmployee, isDeletedUser, userEmail]);

    const refreshData = useCallback(async () => {
        await fetchPractices();
    }, [fetchPractices]);

    const practiceOptions: { name: string, id: number }[] = useMemo(() => {
        return [
            { name: "Other", id: -1 },
            ...practices,
        ]
    }, [practices]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    useEffect(() => {
        reset(professionalDetails);
    }, [professionalDetails, reset]);

    return (
        <React.Fragment>
            {!hideTitle && (
                <>
                    <Title>Professional details</Title>
                    <TitleLabel>
                        All fields are required unless marked optional
                    </TitleLabel>
                </>
            )}
            {canEdit && (
                <form
                    className="mt-3 flex-grow-1 flex flex-col"
                    onSubmit={onNext && innerSubmit(onNext)}
                >
                    <div className="content flex-grow-1">
                        <Row className="justify-content-center">
                            {professionType !== ProfessionType.Registrar && (
                                <Col>
                                    <TextInput
                                        label="Year of fellowship completion
                                        (optional)"
                                        placeholder="Enter the Year of Completion"
                                        hint="In the format of YYYY"
                                        type="number"
                                        id="fellowshipCompletionYear"
                                        {...register("fellowshipCompletionYear", { validate: composeValidators(min(1900, "Enter a valid year"), max(2100, "Enter a valid year")) })}
                                        fieldError={errors.fellowshipCompletionYear}
                                    />
                                </Col>
                            )}
                            {professionType === ProfessionType.Registrar && (
                                <Col>
                                    <FieldSelectNotSelectedDropDownIcon
                                        src={notSelectedDropDownIcon}
                                    />
                                    <Select
                                        label="Registrar classification"
                                        id="registrarClassification"
                                        placeholder="Select the Registrar Classification"
                                        {...register("registrarClassification", { validate: required() })}
                                        fieldError={errors.registrarClassification}
                                    >
                                        {Object.keys(RegistrarClassification)
                                            .filter((v) => isNaN(Number(v)))
                                            .map((p) => (
                                                <option value={p}>
                                                    {p}
                                                </option>
                                            ))}
                                    </Select>
                                </Col>
                            )}
                            <Col />
                        </Row>
                        {professionType === ProfessionType.Registrar && (
                            <Row className="justify-content-center">
                                <Col>
                                    <TextInput
                                        type="number"
                                        id="registrarCommencementYear"
                                        label="Year commenced as registrar"
                                        placeholder="Enter the Year Commenced"
                                        hint="In the format of YYYY"
                                        {...register("registrarCommencementYear", { validate: composeValidators(required(), min(1900, "Enter a valid year"), max(2100, "Enter a valid year")) })}
                                        fieldError={errors.registrarClassification}
                                    />
                                </Col>
                                <Col>
                                    <InputDecorator
                                        label="Commencement Month"
                                        fieldError={errors.registrarCommencementMonth}
                                    >
                                        {Object.keys(RegistrarCommencementMonth)
                                            .filter((v) => isNaN(Number(v)))
                                            .map(v => (<Radio
                                                key={v}
                                                {...register("registrarCommencementMonth", { validate: required() })}
                                                value={v}
                                                label={v}
                                                id={`registrarCommencementMonth.${v}`}
                                                invalid={!!errors.registrarCommencementMonth}
                                            />))}
                                    </InputDecorator>
                                </Col>
                            </Row>
                        )}

                        <Row className="justify-content-center">
                            <Col>
                                <TextInput
                                    label="Racgp number"
                                    id="racgpNumber"
                                    placeholder="Enter your RACPG Number"
                                    hint="This is your 6 digit RACGP membership
                                        number"
                                    {...register("racgpNumber", { validate: composeValidators(required(), minLength(6), maxLength(6)) })}
                                    fieldError={errors.racgpNumber}
                                />
                            </Col>
                            <Col>
                                <TextInput
                                    label="Ahpra number"
                                    id="ahpraNumber"
                                    placeholder="Enter your AHPRA Number"
                                    hint={<>
                                        Enter your Australian Health
                                        Practitioner Regulation Agency Number.
                                        You can look up your AHPRA
                                        {" "}
                                        <a
                                            href="https://www.ahpra.gov.au/Registration/Registers-of-Practitioners.aspx"
                                            target="_blank" rel="noreferrer"
                                        >
                                            here
                                        </a>
                                        .
                                    </>}
                                    {...register("ahpraNumber", { validate: composeValidators(required(), minLength(1), maxLength(50)) })}
                                    fieldError={errors.ahpraNumber}
                                />
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col>
                                <AdvancedSelect
                                    label="Primary practice location"
                                    menuPosition="fixed"
                                    name="primaryPracticeLocationId"
                                    id="primaryPracticeLocationId"
                                    rules={{ validate: required() }}
                                    isSearchable={true}
                                    getOptionLabel={function (p) {
                                        return p.id > 0 ? `Myhealth ${p.name}` : p.name;
                                    }}
                                    getOptionValue={(p) => p?.id}
                                    options={practiceOptions}
                                    placeholder="Select a primary practice location"
                                    hint="If your centre isn’t listed, please select ’Other’"
                                    control={control}
                                />
                            </Col>
                            <Col>
                                {watch("primaryPracticeLocationId") > -1 && (
                                    <TextInput
                                        label="State"
                                        type="text"
                                        value={
                                            `${practices.find(
                                                (p) => p.id === watch("primaryPracticeLocationId")
                                            )?.state}`
                                        }
                                        disabled
                                    />
                                )}

                                {watch("primaryPracticeLocationId") === -1 && (
                                    <Select
                                        id="otherPracticeLocationState"
                                        label="State"
                                        placeholder="Select a state"
                                        {...register("otherPracticeLocationState", { validate: required() })}
                                        fieldError={errors.otherPracticeLocationState}
                                    >
                                        {states?.map((s) => (
                                            <option key={s} value={s}>
                                                {s}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col>
                                {watch("primaryPracticeLocationId") === -1 && (
                                    <TextInput
                                        label="Other practice location"
                                        id="otherPracticeLocation"
                                        {...register("otherPracticeLocation", { validate: required() })}
                                        fieldError={errors.otherPracticeLocation}
                                    />
                                )}
                            </Col>
                        </Row>
                        {!hideTermsAndConditions &&
                            <Row className="justify-content-center">
                                <Col>
                                    <Checkbox
                                        {...register("termsAccepted", { validate: required() })}
                                        id="termsAccepted"
                                        fieldError={errors.termsAccepted}
                                        value="true"
                                        label={<>
                                            I have read and accept the <a href="/privacy-policy" target="_blank">Terms & Conditions</a>
                                        </>}
                                    />
                                </Col>
                            </Row>
                        }
                    </div>

                    {showButtons && (
                        <div className="mb-3">
                            <div className="row justify-content-center text-center mt-4 mx-0">
                                {onBack && (
                                    <span style={{ marginRight: "30px" }}>
                                        <Button
                                            className="col"
                                            variant="primary"
                                            disabled={isLoading}
                                            onClick={onBack}
                                            type="button"
                                        >
                                            Back
                                        </Button>
                                    </span>
                                )}
                                <Button
                                    className="col"
                                    variant="primary"
                                    disabled={isLoading}
                                    type="submit"
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    )}
                </form>
            )}

            {!canEdit && (
                <>
                    {professionType !== ProfessionType.Registrar && (
                        <Row>
                            <Col>
                                <dt>Year of fellowship completion</dt>
                                <dd>{professionalDetails.fellowshipCompletionYear || "\u00A0"}</dd>
                            </Col>
                            {professionType === ProfessionType.Registrar && (
                                <Col>
                                    <dt>Registrar classification</dt>
                                    <dd>
                                        {professionalDetails.registrarClassification || "\u00A0"}
                                    </dd>
                                </Col>
                            )}
                        </Row>
                    )}
                    {professionType === ProfessionType.Registrar && (
                        <Row>
                            <Col>
                                <dt>Year commenced as registrar</dt>
                                <dd>{professionalDetails.registrarCommencementYear}</dd>
                            </Col>
                            <Col>
                                <dt>Commencement month</dt>
                                <dd>
                                    {professionalDetails.registrarCommencementMonth || "\u00A0"}
                                </dd>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col>
                            <dt>Racgp number</dt>
                            <dd>{professionalDetails.racgpNumber || "\u00A0"}</dd>
                        </Col>
                        <Col>
                            <dt>Ahpra number</dt>
                            <dd>{professionalDetails.ahpraNumber || "\u00A0"}</dd>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <dt>Primary practice location</dt>
                            <dd>
                                {professionalDetails.primaryPracticeLocationId === -1
                                    ? "Other"
                                    : practices?.find(
                                        (p) =>
                                            p.id === professionalDetails.primaryPracticeLocationId
                                    )?.name || "\u00A0"}
                            </dd>
                        </Col>
                        <Col>
                            <dt>State</dt>
                            <dd>
                                {professionalDetails.primaryPracticeLocationId === -1
                                    ? professionalDetails.otherPracticeLocationState
                                    : practices?.find(
                                        (p) =>
                                            p.id === professionalDetails.primaryPracticeLocationId
                                    )?.state || "\u00A0"}
                            </dd>
                        </Col>
                    </Row>

                    {professionalDetails.primaryPracticeLocationId === -1 && (
                        <Row>
                            <Col>
                                <dt>Other practice location</dt>
                                <dd>{professionalDetails.otherPracticeLocation || "\u00A0"}</dd>
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </React.Fragment>
    );
});
export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }, null,
    { forwardRef: true }
)(ProfessionalDetails as any) as React.ForwardRefExoticComponent<IProps & { ref?: React.Ref<ProfessionalDetailsFormRef> }>;

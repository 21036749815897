import iconPdf from "../../images/icon-file-pdf.svg";
import iconDoc from "../../images/icon-file-doc.svg";
import iconImage from "../../images/icon-file-image.svg";
import iconOther from "../../images/icon-file-other.svg";

export const getDocumentIcon = (name: string) => {
  const type = name.slice(-3).toLowerCase();

  if (type === "pdf") {
    return iconPdf;
  } else if (type === "doc") {
    return iconDoc;
  } else if (type === "png" || type === "jpg" || type === "jpeg") {
    return iconImage;
  } else {
    return iconOther;
  }
};

export const maxFileSize = 5242880;

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export enum DocumentSortOptions {
  NAME = "Name",
  TYPE = "Type",
  DATE_UPLOADED = "Date Uploaded",
}

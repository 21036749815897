import React, { useCallback } from "react";
import { Col, Modal, Row } from "reactstrap";
import { connect } from "react-redux";

import { RoleType } from "models/admin/UserRoles";
import { Employee } from "models/Employee";
import { InviteEmployeePayload } from "models/InviteEmployeePayload";
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";

import InviteUserForm from "./forms/InviteUserForm";

interface InviteUsersProps {
    closeClicked: () => void;
    user: Employee;
    isModalOpen: boolean;
    toggleModalOpen: () => void;
}


const initialValues: Partial<InviteEmployeePayload> = { role: RoleType.USER };

type Props = InviteUsersProps &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const InviteUsers: React.FC<Props> = (props: Props) => {
    const { closeClicked, inviteEmployee } = props;

    const handleInvite = useCallback((values: InviteEmployeePayload) => {
        const inviteEmployeePayload = {
            lastName: values.lastName,
            firstName: values.firstName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            role: values.role,
        };

        inviteEmployee(inviteEmployeePayload);
        closeClicked();

    }, [closeClicked, inviteEmployee]);

    return (
        <Modal
            className="wide-modal"
            size="lg"
            isOpen={props.isModalOpen}
            toggle={props.toggleModalOpen}
        >
            <Row>
                <Col
                    lg={3}
                    className="description-container border-end p-4 pt-2"
                >
                    <div className="description-title-label">
                        <img
                            className="modal-header-icon mb-2"
                            src={
                                require("images/modal-user-plus.svg")
                                    .default
                            }
                            alt=""
                        />
                        <br />
                        Invite User
                    </div>
                    {!props.user && (
                        <div className="description-label">
                            Enter the details of the user you would like to
                            invite to the portal. We’ll send them an email for
                            their account.
                        </div>
                    )}
                </Col>

                <Col lg={9} className="ps-lg-0">
                    <InviteUserForm
                        handleSubmit={handleInvite}
                        initialValues={initialValues}
                        handleCancel={closeClicked}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(InviteUsers as any);

import React from "react";
import Doctors from "./Doctors";
import { RouteComponentProps } from "react-router-dom";
import { ProfessionType } from "../../models/enums/ProfessionType";

const allowedProfessionTypes = Object.keys(ProfessionType).map(x => ProfessionType[x]);

export const DoctorsScreen: React.FC<RouteComponentProps<{ id?: string }>> = (props: RouteComponentProps<{ id?: string }>) => {
    return (
        <Doctors routePath="/doctors" allowedProfessionTypes={allowedProfessionTypes} title="All Doctors" subTitle="View, edit or upload submission form for doctors" hideInviteUser hidePendingInvite></Doctors>
    );
};

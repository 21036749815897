import React from 'react';
import { FormGroup, FormText, Label } from 'reactstrap';

export type InputDecoratorProps = {
    id?: string;
    className?: string;
    label?: React.ReactChild;
    error?: boolean;
    errorMessage?: React.ReactChild;
    hint?: React.ReactChild;
    fieldError?: {
        message?: string;
    }
}

export const InputDecorator: React.FC<InputDecoratorProps> = ({ className, children, id, label, errorMessage, fieldError, hint }) => {
    return <FormGroup className={className}>
        {label && <Label htmlFor={id} className='text-uppercase'><small>{label}</small></Label>}
        {children}
        {(errorMessage || fieldError?.message)
            && <><FormText color="danger">{errorMessage || fieldError?.message}</FormText><br /></>}
        {hint && <FormText>{hint}</FormText>}
    </FormGroup>
}
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "reactstrap";

import { StyledSVG } from "components/dashboard/styles/Common";
import iconHeart from "images/icon-heart.svg";
import { CourseDetails } from "models/CourseDetails";
import { Employee } from "models/Employee";
import { EvaluationFormData } from "models/EvaluationFormData";
import { UserCourseCompletionStatus } from "models/enums/UserCourseCompletionStatus";
import { UserCourseSubmissionStatus } from "models/enums/UserCourseSubmissionStatus";
import { EvaluationForm } from "pages/evaluation-form/EvaluationForm";
import { EmployeeService } from "services/EmployeeService";

import {
    ResponseMissingMessageContainer,
} from "../styles/LearningMaterials";
import "./styles/LearningMaterialDetails.scss";

interface LearningMaterialEvaluationProps {
    course: CourseDetails;
    currentEmployee: Employee;
    refreshCourseState(): unknown;
}

const LearningMaterialEvaluation: React.FC<
    LearningMaterialEvaluationProps
> = (props) => {
    const {
        course,
        currentEmployee,
        refreshCourseState
    } = props;

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [evaluationFormData, setEvaluationFormData] = useState<
        EvaluationFormData | undefined
    >();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        refreshCourseState();
    }, [refreshCourseState]);

    const fetchFormData = useCallback(async () => {
        if (!course || !currentEmployee) {
            return;
        }

        var result = await EmployeeService.FetchEvaluationFormData(
            currentEmployee.email,
            course.id,
            true
        );
        if (!result.hasErrors) {
            setEvaluationFormData(result.value);
            setIsDataLoaded(true);
        } else {
            if (!result.friendlyError) {
            } else {
                setErrorMessage(result.friendlyError);
            }
        }

        return undefined;
    }, [course, currentEmployee]);

    const saveEvaluationFormData = useCallback(async (formContent) => {
        if (!course || !currentEmployee) {
            return;
        }

        var result = await EmployeeService.SaveEvaluationFormData(
            currentEmployee.email,
            course.id,
            formContent
        );
        if (!result.hasErrors) {
            setShowSuccessMessage(true);
        }
    }, [course, currentEmployee]);

    useEffect(() => {
        fetchFormData();
    }, [fetchFormData]);

    if (!course || !currentEmployee || !evaluationFormData || !isDataLoaded) {
        return <></>;
    }

    if (errorMessage) {
        <Container className="evaluation-form-container mt-5">
            <h3 className="text-center">
                {errorMessage}
            </h3>
        </Container>
    }

    if (course?.completionStatus !== UserCourseCompletionStatus.Completed) {
        return <div className="flex flex-col align-items-center pt-5">
            <div className="pt-5 mb-4">
                <StyledSVG src={iconHeart} size="40px" />
            </div>
            <ResponseMissingMessageContainer>
                Once you have submitted all responses and completed the course, you will be re-directed to complete the evaluation form for your responses to be reviewed.
            </ResponseMissingMessageContainer>
        </div>
    }

    if (showSuccessMessage || course?.submissionStatus === UserCourseSubmissionStatus.PendingApproval) {
        return <Container className="evaluation-form-container mt-5">
            <h3 className="text-center">
                You have sent the form successfully!
            </h3>
        </Container>
    }

    if (course?.submissionStatus) {
        return <Container className="evaluation-form-container mt-5">
            <h3 className="text-center">
                This form has already been submitted!
            </h3>
        </Container>
    }

    return (
        <Container className="evaluation-form-container my-5">
            <EvaluationForm
                employee={currentEmployee}
                course={course}
                evaluationForm={evaluationFormData}
                onSubmit={saveEvaluationFormData}
            />
        </Container>
    );
};

export default LearningMaterialEvaluation;

import React, { useCallback, useMemo } from "react";
import * as dashboard from "../../store/dashboardStore";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { RouteComponentProps } from "react-router";
import { Row, Col } from "reactstrap";
import { ProfileContainer } from "./styles/UserProfile";
import UserCard from "./UserCard";
import EditUserProfile from "./forms/EditUserProfile";
import { UserPayload } from "models/UserPayload";

export interface UserProfileProps {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
}

type Props = RouteComponentProps<{}> &
    dashboard.DashboardState &
    typeof dashboard.actionCreators &
    UserProfileProps;

function LabelValueCell({ label, value }: { label: string, value: string }) {
    return <p>
        <sub className="text-muted text-uppercase">{label}</sub><br />
        {value}
    </p>
}

const UserProfile: React.FC<Props> = (props: Props) => {
    const { employeeById, updateUser, isEditing, setIsEditing } = props;
    const employeeCardProps = {
        employee: employeeById,
        onEditUser: () => {
            setIsEditing(true);
        },
    };

    const initialValues: Partial<UserPayload> = useMemo(() => ({
        firstName: employeeById?.firstName || "",
        lastName: employeeById?.lastName || "",
        email: employeeById?.email || "",
        contactNumber: employeeById?.phoneNumber || "",
        role: employeeById?.userRoles.role,
        id: employeeById?.id
    }), [employeeById])

    const submitForm = useCallback(async (values: UserPayload) => {
        await updateUser({
            ...values,
            id: initialValues.id,
            role: initialValues.role
        });
        setIsEditing(false);
    }, [initialValues, setIsEditing, updateUser]);

    return (
        <ProfileContainer className="user-profile-details-container">
            <UserCard {...employeeCardProps} />
            <hr />
            {!isEditing && employeeById && (
                <div>
                    <div className="container">
                        <h6>Personal Details</h6>
                        <Row>
                            <Col>
                                <LabelValueCell
                                    label="First Name"
                                    value={employeeById.firstName}
                                />
                            </Col>
                            <Col>
                                <LabelValueCell
                                    label="Last Name"
                                    value={employeeById.lastName}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <LabelValueCell
                                    label="Email"
                                    value={employeeById.email}
                                />
                            </Col>
                            <Col>
                                <LabelValueCell
                                    label="Contact Number"
                                    value={employeeById.mobile}
                                />
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="container">
                        <h6>
                            Permission Access Level
                        </h6>
                        <Row>
                            <Col>
                                <LabelValueCell
                                    label="Role"
                                    value={employeeById.userRoles.role}
                                />
                            </Col>
                            <Col>
                                <LabelValueCell
                                    label="Status"
                                    value={employeeById.isInvitePending
                                        ? "Pending Invite"
                                        : employeeById.isActive
                                            ? "Active"
                                            : "Inactive"}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
            {isEditing && <EditUserProfile
                handleSubmit={submitForm}
                handleCancel={() => setIsEditing(false)}
                isLoading={props.isLoading}
                initialValues={initialValues}
            />}
        </ProfileContainer>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(UserProfile as any) as React.FC<UserProfileProps>;

import React from "react";
import Doctors from "./Doctors";
import { RouteComponentProps } from "react-router-dom";

const allowedProfessionType = ['Nurse'];

export const NursesScreen: React.FC<RouteComponentProps<{ id?: string }>> = (props: RouteComponentProps<{ id?: string }>) => {
    return (
        <Doctors routePath="/nurses" allowedProfessionTypes={allowedProfessionType} title="All Nurses" subTitle="" hideProfessionTypeFilter hideUploadSubmission></Doctors>
    );
};
import React from "react";
import MyAccount from "../components/dashboard/MyAccount";
import * as dashboard from "../store/dashboardStore";
import { connect } from "react-redux";
import { IApplicationState } from "../store";

type Props = dashboard.DashboardState;

const DoctorAccount: React.FC<Props> = (props: Props) => {
    const { currentEmployee } = props;
    return (
        <>
            {currentEmployee && <MyAccount />}
        </>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(DoctorAccount as any);

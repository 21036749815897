import React from "react";
import { TabItem } from "./styles/NavTabContainer";

interface NavTabItemProps {
    isSelected: boolean;
    onClick?: (e: any) => void;
}

type Props = React.PropsWithChildren<NavTabItemProps>;
const NavTabItem = (props: Props) => {
    return (
        <React.Fragment>
            <TabItem isSelected={props.isSelected} onClick={props.onClick}>
                {props.children}
            </TabItem>
        </React.Fragment>
    );
};

export default NavTabItem;

import "./styles/SignupProgress.scss";
import * as React from "react";
import { Col, Row } from "reactstrap";
import { SignupProgressItem } from "./SignupProgressItem";
import { SignupStatus } from "./Signup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

interface ISignupProgressProps {
  signupStatus: SignupStatus
}

export const SignupProgress = (props: React.PropsWithChildren<ISignupProgressProps>) => {
  return (
    <div className="leftHalf signup">
      <div className="academy-logo"></div>

      <div className="progress-container">
        <Row>
          <Col>
            <SignupProgressItem title="Personal details" subTitle="Please provide your name and email" isActive={props.signupStatus == SignupStatus.PersonalDetails} isReady={props.signupStatus > SignupStatus.PersonalDetails} first></SignupProgressItem>
            <SignupProgressItem title="Verify" subTitle="Please confirm your identity" isActive={props.signupStatus == SignupStatus.Verify} isReady={props.signupStatus > SignupStatus.Verify}></SignupProgressItem>
            <SignupProgressItem title="Professional details" subTitle="Please provide your RACGP and AHPRA Number" isActive={props.signupStatus == SignupStatus.ProfessionalDetails} isReady={props.signupStatus > SignupStatus.ProfessionalDetails} last></SignupProgressItem>
          </Col>
        </Row>
      </div>

      <span className="copyright">© Myhealth 2024</span>
      <div className="email">
        <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
        <a href="mailto:CPD@myhealth.net.au">
          CPD@myhealth.net.au
        </a>
      </div>
    </div>
  );
};



import moment from "moment";

export const FirstMondayOfYear = (year: number) => {
  let dateTime = new Date(`${year}/1/1`);
  while (dateTime.getDay() != 1) {
    dateTime.setDate(dateTime.getDate() + 1);
  }
  return dateTime;
};

export const GetTwoWeeksPeriodStart = (date: Date) => {
  let startDay = moment(FirstMondayOfYear(date.getFullYear()));
  let endDay = startDay.clone().add(13, "days");

  while (endDay < moment(date)) {
    startDay.add(14, "days");
    endDay = startDay.clone().add(13, "days");
  }
  return startDay;
};

export const TwoWeeksBefore = (date: Date) => {
  return moment(date).add(-14, "days").toDate();
};

export const TwoWeeksAfter = (date: Date) => {
  return moment(date).add(14, "days").toDate();
};

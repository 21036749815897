import React, { useState } from "react";
import { AuthService } from "../../services/AuthService";
import "react-toastify/dist/ReactToastify.css";
import { VerifyMobileBox } from "./VerifyMobileBox";
import { showDefault, showErrors } from "../../utils";

type Props = {
    phoneNumber: string;
    onBack: () => void;
    onNext: (code: string) => void;
};

export const VerifyMobileForm: React.FC<Props> = (props: Props) => {
    const [showAlert, setShowAlert] = useState(false);
    const [isValidCode, setIsValidCode] = useState(true);

    const validateCode = async (code: string) => {
        const result = await AuthService.VerifyMobileNumber(
            props.phoneNumber,
            code
        );

        if (result.hasErrors || !result.value) {
            setIsValidCode(false);
            return;
        }

        setIsValidCode(true);
        props.onNext(code);
    };

    const resendValidationCode = async () => {
        const result = await AuthService.RequestCode(props.phoneNumber);

        if (result.hasErrors) {
            if (
                result.errors
                    .toLocaleString()
                    .toString()
                    .toLowerCase()
                    .includes("retry")
            ) {
                setShowAlert(true);
            } else {
                showErrors(...result.errors);
            }
        } else {
            showDefault("A new code has been sent to your number!");
        }
    };

    return (
        <VerifyMobileBox
            onBack={props.onBack}
            onResend={resendValidationCode}
            onNext={validateCode}
            isValidCode={isValidCode}
            showAlert={showAlert}
            onCloseAlertModel={() => setShowAlert(false)}
        />
    );
};

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { Row, Col, FormGroup } from "reactstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
// import { createHash } from "crypto";

import Button from 'components/shared/Button';
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";
import { isAdmin } from "models/admin/UserRoles";
import { ProfessionType } from "models/enums/ProfessionType";
import { RegistrarClassification } from "models/enums/RegistrarClassification";
import { RegistrarCommencementMonth } from "models/enums/RegistrarCommencementMonth";
import { composeValidators, email, max, min, required } from "validate";
import { TextInput, Select, AdvancedSelect, Radio } from "components/form";
import iconEdit from "images/icon-edit-red.svg";

import { ChangeMobileModals } from "./ChangeMobileModals";
import "./styles/MyAccount.scss";
import { InputDecorator } from "components/form/InputDecorator";
// import {
//     DefaultImage,
//     DefaultNameOverlay,
// } from "../dashboard/styles/Common";
// import PhotoUploadMenu from "./PhotoUploadMenu";

type Props = RouteComponentProps<{}> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

type MyAccountForm = {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    gender: string;
    racgpNumber: string;
    ahpraNumber: string;
    professionType: string;
    fellowshipCompletionYear: string | number;
    primaryPracticeLocationId: number;
    otherPracticeLocation: string;
    otherPracticeLocationState: string;
    registrarClassification: RegistrarClassification;
    registrarCommencementMonth: RegistrarCommencementMonth;
    registrarCommencementYear: string | number;
}

const states = ["NSW", "VIC", "QLD", "WA", "SA", "TAS"];

const MyAccount: React.FC<Props> = (props: Props) => {
    const {
        currentEmployee,
        updateCurrentEmployee,
        requestPhoneNumberChangeValidation,
        phoneChangeVerificationState,
        setPhoneChangeVerificationState,
        validatePhoneNumberChange,
        setPhoneChangeCodeVerificationState,
        phoneChangeCodeVerificationState,
        practices,
        fetchPractices,
    } = props;

    // const [showPhotoUploadMenu, setShowPhotoUploadMenu] = useState(false);
    const [showMobileChangeModal, setShowMobileChangeModal] = useState(false);

    const practiceOptions: { name: string, id: number }[] = useMemo(() => {
        return [
            { name: "Other", id: -1 },
            ...practices,
        ]
    }, [practices]);

    const isAdminUser =
        currentEmployee != null &&
        currentEmployee.userRoles != null &&
        isAdmin(currentEmployee.userRoles.role);

    const refreshData = useCallback(async () => {
        await fetchPractices();
    }, [fetchPractices]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const saveChanges = useCallback(async (values: MyAccountForm) => {
        // if (employee.avatarImage) {
        //     employee.avatarImageHash = encodeURIComponent(
        //         createHash("sha256")
        //             .update(employee.avatarImage)
        //             .digest("base64")
        //     );
        // }

        await updateCurrentEmployee({
            ...values,
            fellowshipCompletionYear: Number(values.fellowshipCompletionYear),
            registrarCommencementYear: Number(values.registrarCommencementYear),
            phoneNumber: values.mobile,
            isActive: currentEmployee.isActive,
            avatarImage: currentEmployee.avatarImage,
            courses: currentEmployee.courses,
        });

        toast.success("Profile updated!");
    }, [currentEmployee.avatarImage, currentEmployee.courses, currentEmployee.isActive, updateCurrentEmployee]);

    const initialValues: Partial<MyAccountForm> = useMemo(() => {
        return currentEmployee;
    }, [currentEmployee]);

    const {
        register,
        handleSubmit: innerSubmit,
        formState: { errors, isDirty },
        watch,
        setValue,
        control
    } = useForm<MyAccountForm>({ defaultValues: initialValues });

    const changeMobileNumber = useCallback((newNumber: string) => {
        setValue("mobile", newNumber);
    }, [setValue])

    if (!currentEmployee) {
        return null;
    }

    return (
        <form className="container py-4" onSubmit={innerSubmit(saveChanges)}>
            {/*
                <MyAccountTitleRow>
                    <div
                        onClick={() =>
                            setShowPhotoUploadMenu(!showPhotoUploadMenu)
                        }
                    >
                        {employee.avatarImage || employee.avatarImageHash ? (
                            <MyAccountImage
                                src={
                                    employee.avatarImage
                                        ? `data:image/jpeg;base64,${employee.avatarImage}`
                                        : `/api/${Consts.API_VERSION}/employees/${employee.id}/avatar/${employee.avatarImageHash}`
                                }
                            />
                        ) : (
                            <DefaultImage
                                isLive={false}
                                size={6.944}
                                clickable={true}
                            >
                                <DefaultNameOverlay
                                    fontSize={"25pt"}
                                >{`${currentEmployee?.firstName[0].toUpperCase()}${currentEmployee?.lastName[0].toUpperCase()}`}</DefaultNameOverlay>
                            </DefaultImage>
                        )}
                    </div>
                    <Col>
                        <MyAccountTitleLabel>{`${currentEmployee.firstName} ${currentEmployee.lastName}`}</MyAccountTitleLabel>
                        <MyAccountTitleUserRight>
                            {roleString(userRoles.role)}
                        </MyAccountTitleUserRight>
                    </Col>
                    {showPhotoUploadMenu && (
                        <PhotoUploadMenu
                            onUploadClick={(value) => {
                                setShowPhotoUploadMenu(!showPhotoUploadMenu);

                                let imageContent = "";
                                if (
                                    value.split("data:image/png;base64,")
                                        .length > 1
                                ) {
                                    imageContent = value.split(
                                        "data:image/png;base64,"
                                    )[1];
                                }

                                if (
                                    value.split("data:image/jpg;base64,")
                                        .length > 1
                                ) {
                                    imageContent = value.split(
                                        "data:image/jpg;base64,"
                                    )[1];
                                }

                                if (
                                    value.split("data:image/jpeg;base64,")
                                        .length > 1
                                ) {
                                    imageContent = value.split(
                                        "data:image/jpeg;base64,"
                                    )[1];
                                }

                                value &&
                                    setEmployee({
                                        ...employee,
                                        avatarImage: imageContent,
                                    });
                            }}
                            onDeleteClick={() => {
                                setEmployee({
                                    ...employee,
                                    avatarImage: null,
                                    avatarImageHash: null,
                                });
                                setShowPhotoUploadMenu(!showPhotoUploadMenu);
                            }}
                        />
                    )}
                </MyAccountTitleRow>
            */}

            <Row>
                <Col>
                    <h3>My Account</h3>
                </Col>
                <Col></Col>
                <Col className="col-auto">
                    <Button disabled={!isDirty} type="submit">
                        Save changes
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h6>Personal info</h6>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <TextInput
                        name="firstName"
                        label="First Name"
                        id="firstName"
                        {...register("firstName", { validate: required() })}
                        fieldError={errors.firstName}
                    />
                </Col>
                <Col lg={6}>
                    <TextInput
                        name="lastName"
                        label="Last Name"
                        id="lastName"
                        {...register("lastName", { validate: required() })}
                        fieldError={errors.lastName}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <TextInput
                        label="Email"
                        name="email"
                        id="email"
                        type="email"
                        {...register("email", { validate: composeValidators(required(), email()) })}
                        fieldError={errors.email}
                    />
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <div className="position-relative">
                            <TextInput
                                disabled={true}
                                label="Mobile number"
                                {...register("mobile")}
                            />
                            <img
                                className="position-absolute bottom-0 end-0 m-2 cursor-pointer"
                                src={iconEdit}
                                onClick={() => {
                                    setShowMobileChangeModal(true);
                                }}
                                alt=""
                                role='button'
                            />
                            <ChangeMobileModals
                                show={showMobileChangeModal}
                                setShow={setShowMobileChangeModal}
                                employeeId={currentEmployee.id}
                                onChange={changeMobileNumber}
                                requestPhoneNumberChangeValidation={requestPhoneNumberChangeValidation}
                                validatePhoneNumberChange={validatePhoneNumberChange}
                                phoneChangeVerificationState={phoneChangeVerificationState}
                                setPhoneChangeVerificationState={setPhoneChangeVerificationState}
                                setPhoneChangeCodeVerificationState={setPhoneChangeCodeVerificationState}
                                phoneChangeCodeVerificationState={phoneChangeCodeVerificationState}
                            />
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Select
                        label="Role"
                        disabled
                    >
                        <option selected value={currentEmployee.userRoles?.role}>
                            {currentEmployee.userRoles?.role}
                        </option>
                    </Select>
                </Col>
            </Row>

            <Row>
                <Col>
                    <hr />
                </Col>
            </Row>

            {!isAdminUser && (
                <>
                    <Row>
                        <Col xs={12}>
                            <h6>Professional info</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Select
                                label="Profession type"
                                placeholder="Select a profession type"
                                {...register("professionType")}
                                fieldError={errors.professionType}
                            >
                                {(watch("professionType") === "Nurse"
                                    ? [ProfessionType.Nurse]
                                    : [
                                        ProfessionType.GeneralPractitioner,
                                        ProfessionType.Registrar,
                                    ]
                                ).map((p) => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </Select>
                        </Col>
                        {watch("professionType") !==
                            ProfessionType.Registrar && (
                                <Col lg={6}>
                                    <TextInput
                                        label="Year of fellowship completion"
                                        type="number"
                                        id="fellowshipCompletionYear"
                                        {...register("fellowshipCompletionYear", { validate: composeValidators(min(1900, "Enter valid year"), max(2100, "Enter valid year")) })}
                                        fieldError={errors.fellowshipCompletionYear}
                                        placeholder="Enter the Year of Completion"
                                    />
                                </Col>
                            )}
                        {watch("professionType") === ProfessionType.Registrar && (
                            <Col lg={6}>
                                <Select
                                    label="Registrar classification"
                                    id="registrarClassification"
                                    placeholder="Select the Registrar Classification"
                                    {...register("registrarClassification", { validate: required() })}
                                    fieldError={errors.registrarClassification}
                                >
                                    {Object.keys(RegistrarClassification)
                                        .filter((v) => isNaN(Number(v)))
                                        .map((p) => (
                                            <option key={p} value={p}>{p}</option>
                                        ))}
                                </Select>
                            </Col>
                        )}
                    </Row>
                    {watch("professionType") === ProfessionType.Registrar && (
                        <Row>
                            <Col lg={6}>
                                <TextInput
                                    label="Year commenced as registrar"
                                    type="number"
                                    id="registrarCommencementYear"
                                    {...register("registrarCommencementYear", { validate: composeValidators(required(), min(1900, "Enter valid year"), max(2100, "Enter valid year")) })}
                                    placeholder="Enter the Year Commenced"
                                    fieldError={errors.registrarCommencementYear}
                                />
                            </Col>
                            <Col lg={6}>
                                <InputDecorator
                                    label="Commencement Month"
                                    fieldError={errors.registrarCommencementMonth}
                                >
                                    {Object.keys(RegistrarCommencementMonth)
                                        .filter((v) => isNaN(Number(v)))
                                        .map(v => (<Radio
                                            key={v}
                                            {...register("registrarCommencementMonth", { validate: required() })}
                                            value={v}
                                            label={v}
                                            id={`registrarCommencementMonth.${v}`}
                                            invalid={!!errors.registrarCommencementMonth}
                                        />))}
                                </InputDecorator>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col lg={6}>
                            <TextInput
                                label="Racgp number"
                                id="racgpNumber"
                                {...register("racgpNumber", { validate: required() })}
                                fieldError={errors.racgpNumber}
                                hint="This is your 6 digit RACGP membership number."
                            />
                        </Col>
                        <Col lg={6}>
                            <TextInput
                                label="Ahpra number"
                                id="ahpraNumber"
                                {...register("ahpraNumber", { validate: required() })}
                                fieldError={errors.ahpraNumber}
                                hint={<>
                                    Enter your Australian Health Practitioner
                                    Regulation Agency Number. You can look up
                                    your AHPRA
                                    <a
                                        href="https://www.ahpra.gov.au/Registration/Registers-of-Practitioners.aspx"
                                        target="_blank" rel="noreferrer"
                                    >
                                        {" "}
                                        here
                                    </a>
                                    .
                                </>}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <AdvancedSelect
                                label="Primary practice location"
                                menuPosition="fixed"
                                name="primaryPracticeLocationId"
                                id="primaryPracticeLocationId"
                                rules={{ validate: required() }}
                                isSearchable={true}
                                getOptionLabel={function (p) {
                                    return p.id > 0 ? `Myhealth ${p.name}` : p.name;
                                }}
                                getOptionValue={(p) => p?.id}
                                options={practiceOptions}
                                placeholder="Select a primary practice location"
                                hint="If your centre isn’t listed, please select ’Other’"
                                control={control}
                            />
                        </Col>
                        <Col lg={6}>
                            {watch("primaryPracticeLocationId") === -1 && (
                                <Select
                                    id="otherPracticeLocationState"
                                    label="State"
                                    placeholder="Select a state"
                                    {...register("otherPracticeLocationState", { validate: required() })}
                                    fieldError={errors.otherPracticeLocationState}
                                >
                                    {states?.map((s) => (
                                        <option key={s} value={s}>
                                            {s}
                                        </option>
                                    ))}
                                </Select>
                            )}
                            {watch("primaryPracticeLocationId") > -1 && (
                                <TextInput
                                    label="State"
                                    type="text"
                                    value={
                                        `${practices.find(
                                            (p) => p.id === watch("primaryPracticeLocationId")
                                        )?.state}`
                                    }
                                    disabled
                                />
                            )}
                        </Col>
                    </Row>

                    {watch("primaryPracticeLocationId") === -1 && (
                        <Row>
                            <Col lg={6}>
                                <TextInput
                                    label="Other practice location"
                                    id="otherPracticeLocation"
                                    {...register("otherPracticeLocation", { validate: required() })}
                                    fieldError={errors.otherPracticeLocation}
                                />
                            </Col>
                            <Col lg={6} />
                        </Row>
                    )}
                </>
            )}
        </form>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(MyAccount as any);

import React, { useState, useCallback } from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { Container } from "components/reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { NavLink, Redirect } from "react-router-dom";

import { IApplicationState } from "store";
import * as auth from "store/authStore";
import { AuthService } from "services/AuthService";
import { HelpText } from "components/shared/HelpText";
import iconAlert from "images/alert-circle.svg";
import academyLogoImage from "images/academy-logo.svg";

import { SigninAlert } from "./styles/Signin";
import { LoginLogo, MobileLoginLogo } from "./styles/SignUp";
import SignInForm from "./forms/SignInForm";

enum SigninStatus {
    Ready,
    Failed,
    Successful,
}
type Props = RouteComponentProps<{}> &
    auth.AuthState & {} & typeof auth.actionCreators;

export type SigninForm = {
    email: string;
    lastName: string;
}

const Signin: React.FC<Props> = (props: Props) => {
    const { setUser } = props;
    const [loginStatus, setSigninStatus] = useState(SigninStatus.Ready);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const onSubmit = useCallback(async ({ email, lastName }: SigninForm) => {
        setLoading(true);
        const loginResult = await AuthService.Signin(
            email,
            lastName
        );
        setLoading(false);
        const newStatus = loginResult.hasErrors
            ? SigninStatus.Failed
            : SigninStatus.Successful;
        if (newStatus === SigninStatus.Failed) {
            setErrors(loginResult.errors);
            setUser({
                email: "",
                lastName: "",
            });
        } else {
            setUser({ email, lastName });
        }
        setSigninStatus(newStatus);
    }, [setUser]);

    if (loginStatus === SigninStatus.Successful) {
        return (
            <Redirect
                to={{
                    pathname: "/auth/verify",
                    state: { from: (props.location?.state as any)?.from },
                }}
            />
        );
    }

    const renderLoginContent = () => {
        return (
            <>
                <div>
                    <div className="text-center">
                        <h2>Welcome back</h2>
                        <span className="text-gray-500">Welcome back! Please enter your details.</span>
                    </div>
                    {loginStatus === SigninStatus.Failed && (
                        <FormGroup>
                            <SigninAlert className="mt-5 flex">
                                <div className="alert-icon me-3">
                                    <img src={iconAlert} alt="" />
                                </div>
                                <div className="alert-message">
                                    <div className="mb-2">
                                        <strong>Sign in Error</strong>
                                    </div>
                                    {errors.map((e, i) => (
                                        <div key={i}>{e}</div>
                                    ))}
                                </div>
                            </SigninAlert>
                        </FormGroup>
                    )}

                    <SignInForm
                        handleSubmit={onSubmit}
                        isLoading={isLoading}
                    />
                </div>
            </>
        );
    };

    return (
        <div className="signin">
            <div className="block md:hidden">
                <MobileLoginLogo src={academyLogoImage} />
            </div>
            <div className="hidden lg:block">
                <LoginLogo src={academyLogoImage} />
            </div>
            <Container className="vh-100">
                <Row
                    style={{ height: "calc(100vh - 40px)" }}
                    className="justify-content-center m-0"
                >
                    <Col
                        xs={12}
                        md={6}
                        className="align-self-center justify-self-center"
                        style={{ maxWidth: "375px" }}
                    >
                        <Row>
                            <Col>
                                <div>{renderLoginContent()}</div>
                            </Col>
                        </Row>
                        <Row className="pt-4">
                            <Col>
                                <div className="text-gray-500 text-center">
                                    Don’t have an account? &nbsp;
                                    <NavLink to="/auth/signup">Sign up</NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-0">
                    <Col>
                        <HelpText />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.auth,
    }),
    { ...auth.actionCreators }
)(Signin as any);

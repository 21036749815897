import React, { MutableRefObject } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { AdvancedSelect, Select } from 'components/form';
import Button from 'components/shared/Button';
import { CourseSessionLink } from 'models/CourseSessionLink';
import { ODataPageResult } from 'common/ODataPageResult';
import { required } from 'validate';
import { UsersPageListItem } from 'models/UsersPageListItem';

type Props = {
    formRef?: MutableRefObject<HTMLFormElement>;
    allUsersPage: ODataPageResult<UsersPageListItem>;
    searchUsers(name: string): unknown;
    handleSubmit(values: ExportCsvFormValues);
    courseSessionLinks: CourseSessionLink[];
    onCancel?();
};

export type ExportCsvFormValues = {
    reportType: string;
    sessionLinkId?: number;
    employeeId?: number;
}

const reportTypes = [
    {
        name: "User Session Attendance Report",
        type: "UserSessionAttendanceReport",
    },
];

export const ExportCsvForm = ({
    formRef,
    handleSubmit,
    courseSessionLinks,
    allUsersPage,
    searchUsers,
    onCancel
}: Props) => {

    const {
        register,
        control,
        formState: { errors },
        handleSubmit: innerSubmit
    } = useForm<ExportCsvFormValues>();

    return <form
        ref={formRef}
        onSubmit={innerSubmit(handleSubmit)}
    >
        <div className="p-3">
            <Row>
                <Col>
                    <Select
                        label="Report type"
                        id="reportType"
                        placeholder='Select a report'
                        {...register("reportType", { validate: required(), value: null })}
                        fieldError={errors.reportType}
                    >
                        {reportTypes.map(
                            (s) => (
                                <option
                                    key={s.type}
                                    value={
                                        s.type
                                    }
                                >
                                    {s.name}
                                </option>
                            )
                        )}
                    </Select>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Select
                        label="Session"
                        id="sessionLinkId"
                        {...register("sessionLinkId", { value: null, valueAsNumber: true })}
                        fieldError={errors.sessionLinkId}
                    >
                        {[
                            {
                                id: null,
                                sessionLinkName:
                                    "All Sessions",
                            },
                            ...courseSessionLinks,
                        ].map((s) => (
                            <option
                                key={s.id}
                                value={s.id}
                            >
                                {s.sessionLinkName}
                            </option>
                        ))}
                    </Select>
                </Col>
            </Row>

            <Row>
                <Col>
                    <AdvancedSelect
                        label="Filter"
                        control={control}
                        menuPosition="fixed"
                        name="employeeId"
                        id="employeeId"
                        isSearchable={true}
                        getOptionLabel={(p) => `${p.firstName} ${p.lastName}`}
                        getOptionValue={(p) => p?.id}
                        options={[
                            {
                                employeeId:
                                    null,
                                firstName:
                                    "All Users",
                                lastName: "",
                            },
                            ...allUsersPage.value
                        ]
                            .map((u) => ({
                                id: u.employeeId,
                                firstName:
                                    u.firstName,
                                lastName:
                                    u.lastName,
                            }))}
                        onInputChange={searchUsers}
                        fieldError={errors.employeeId}
                    />
                </Col>
            </Row>

            <Row className="mt-4">
                {onCancel &&
                    <Col>
                        <Button
                            variant="secondary"
                            onClick={onCancel}
                            className='w-full'
                        >
                            Cancel
                        </Button>
                    </Col>
                }
                <Col>
                    <Button
                        variant="primary"
                        type="submit"
                        className='w-full'
                    >
                        Export CSV
                    </Button>
                </Col>
            </Row>
        </div>
    </form>
}
import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { UserCourseSubmission } from "../models/UserCourseSubmission";
import { UserCourseSubmissionStatus } from "../models/enums/UserCourseSubmissionStatus";
import { UserCourseSubmissionSort } from "../models/enums/UserCourseSubmissionSort";
import { UserCourseSubmissionDismissReason } from "../models/enums/UserCourseSubmissionDismissReason";

class UserCourseSubmissionServiceClass extends ServiceBase {
    private static _evaluationFormService: UserCourseSubmissionServiceClass;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): UserCourseSubmissionServiceClass {
        return (
            this._evaluationFormService ||
            (this._evaluationFormService = new this("evaluationforms"))
        );
    }

    public async FetchAllSubmissions(filter: string, courseId: number | null, status: UserCourseSubmissionStatus | null, sort: UserCourseSubmissionSort, isAscending: boolean): Promise<Result<UserCourseSubmission[]>> {
        const result = await super.requestJson<UserCourseSubmission[]>({
            method: "GET",
            url: `list-submissions?filter=${filter}&courseId=${courseId == null ? "" : courseId}&status=${status == null ? "" : status}&sort=${sort}&isAscending=${isAscending}`,
            callType: ApiCall.FetchUserCourseSubmissions,
        });

        return result;
    }

    public async Approve(id: number, isReapprove?: boolean): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "POST",
            url: `${id}/approve?isReapprove=${isReapprove ? "true" : "false"}`,
            callType: ApiCall.ApproveEvaluationForm
        });

        return result;
    }

    public async Dismiss(id: number, reason: UserCourseSubmissionDismissReason, comment: string): Promise<Result<{}>> {        
        const formData = new FormData();
        formData.append("comment", comment);
        const result = await super.sendFormData<{}>({
            method: "POST",
            url: `${id}/dismiss?reason=${reason}`,
            callType: ApiCall.DismissEvaluationForm,
            data: formData
        });

        return result;
    }

    public async DownloadSubmission(id: number): Promise<Result<Blob>> {
        const result = await super.requestJson<Blob>({
            method: "GET",
            url: `download/${id}`,
            callType: ApiCall.GetEvaluationFormSubmissionDocument,
            isBlob: true,
        });

        return result;
    }

    public async UploadEvaluationFormSubmission(userCourseId: number, document: FormData): Promise<Result<{}>> {
        const result = await super.sendFormData<{}>({
            method: "POST",
            url: `upload/${userCourseId}`,
            callType: ApiCall.UploadEvaluationFormSubmission,
            data: document,
        });

        return result;
    }
}

export const UserCourseSubmissionService = UserCourseSubmissionServiceClass.Instance;

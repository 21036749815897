import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as IconClose } from "images/icon-close.svg";
import classNames from 'classnames';

interface Props {
    open: boolean;
    setOpen(open: boolean);
    direction?: "left" | "right";
    hideClosePanel?: boolean;
}

export const SidePanel: React.FC<Props> = ({ children, open, setOpen, direction = "right", hideClosePanel }) => {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed z-40 inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-50 inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className={classNames("pointer-events-none fixed inset-y-0 flex max-w-full", { "right-0": direction === 'right', "left-0": direction === "left" })}>
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-300 sm:duration-500"
                                enterFrom={direction === 'right' ? "translate-x-full" : "-translate-x-full"}
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-300 sm:duration-500"
                                leaveFrom="translate-x-0"
                                leaveTo={direction === 'right' ? "translate-x-full" : "-translate-x-full"}
                            >
                                <Dialog.Panel className={classNames("pointer-events-auto w-screen", { "max-w-md": !hideClosePanel, "max-w-sm": hideClosePanel })}>
                                    <div className="flex h-full flex-row bg-white shadow-xl">
                                        <div className='flex-1 overflow-hidden'>
                                            {children}
                                        </div>
                                        {!hideClosePanel &&
                                            <div className="w-16 py-2 px-1 sm:px-6 bg-gray-900 flex flex-col items-center space-y-4">
                                                <button
                                                    type="button"
                                                    className="p-2 relative rounded-md text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close menu</span>
                                                    <IconClose className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
import "./styles/GenerateLinkButtonAndModal.scss";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as dashboard from "../../store/dashboardStore";
import { Button, Col, Modal, ModalHeader, Row } from "reactstrap";
import { CourseService } from "../../services/CourseService";
import { showDefault, showErrors } from "../../utils";

interface Props {
    courseId: number;
    sessionId: number;
    sessionLinkId: number;
    sessionName: string;
    hideButton?: boolean;
    isModalOpened?: boolean;
    onClose?: () => void;
}

type PropsType = Props & dashboard.DashboardState & typeof dashboard.actionCreators;

const DeactivateLinkButtonAndModal: React.FC<PropsType> = (props: PropsType) => {
    const {
        isModalOpened: isModalOpenedFromProps,
        hideButton,
        courseId,
        sessionId,
        sessionLinkId,
        sessionName,
        fetchCourseSessionLinks,
        onClose
    } = props;

    const [isDeactivateModalOpened, setIsDeactivateModalOpened] = useState(false);

    useEffect(() => {
        if (isModalOpenedFromProps === true || isModalOpenedFromProps === false) {
            setIsDeactivateModalOpened(isModalOpenedFromProps);
        }
    }, [isModalOpenedFromProps]);

    const deactivateLink = async () => {
        const response = await CourseService.SetSessionLinkStatus(courseId, sessionId, sessionLinkId, 'Cancelled');

        if (response && !response.hasErrors) {
            setIsDeactivateModalOpened(false);
            fetchCourseSessionLinks(courseId);
            showDefault('Link is deactivated successfully.');
        }
        else {
            showErrors('Failed to deactivating session link.');
        }
    }

    return (
        <>
            {
                isDeactivateModalOpened &&
                <Modal className="course-modal" isOpen={isDeactivateModalOpened} centered size="md">
                    <div className="modal-header-icon-container">
                        <img
                            src={
                                require("../../images/modal-circles.svg")
                                    .default
                            }
                        />
                        <img
                            className="modal-header-icon"
                            src={
                                require("../../images/modal-link-broken.svg")
                                    .default
                            }
                        />
                    </div>

                    <ModalHeader toggle={() => setIsDeactivateModalOpened(false)}>
                    </ModalHeader>

                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>Deactivate Link</h5>
                                <p className="description">
                                    Are you sure you want to deactivate this session? Deactivating this link will prevent users from accessing the attendance form.
                                    <br /><br />
                                    {sessionName}
                                </p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-3">
                                <Row className="m-0 button-container">
                                    <Col>
                                        <Button color="secondary" onClick={() => setIsDeactivateModalOpened(false)}>Cancel</Button>
                                    </Col>
                                    <Col>
                                        <Button color="danger" onClick={deactivateLink}>Deactivate</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }
            {
                !hideButton &&
                <span className="me-4 text-gray-600 font-weight-600" role="button" onClick={_ => { setIsDeactivateModalOpened(true) }}>Deactivate</span>
            }
        </>
    );
}

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(DeactivateLinkButtonAndModal as any) as React.FC<Props>;

import "./styles/FaceToFaceEvents.scss";
import * as dashboard from "../../store/dashboardStore";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { RouteComponentProps, useHistory } from "react-router";
import LearningMaterialCard from "../learning-materials/LearningMaterialCard";
import { CourseOrEvent } from "../../models/enums/CourseOrEvent";
import { Card } from "reactstrap";
import {
    CourseEventDetailsSponsorLogo,
    CourseEventSpeakerContainer,
    EventSpeakerImageContainer,
    EventSpeakerName,
    EventSpeakerTextContainer,
    EventSpeakerTopic,
    EventSpeakersContainer,
    FaceToFaceEventsBackLink,
} from "./styles/FaceToFaceEvents";
import { ReactComponent as BackIcon } from "../../images/icon-back-ios.svg";
import { CourseEvent } from "../../models/CourseEvent";
import { CourseEventSpeaker } from "../../models/CourseEventSpeaker";
import { isAdmin } from "../../models/admin/UserRoles";
import { Container } from "components/reactstrap";

interface OwnRouteProps {
    id?: string;
}

type Props = RouteComponentProps<OwnRouteProps> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const FaceToFaceEventDetails: React.FC<Props> = (props: Props) => {
    const history = useHistory();
    const courseEventId = parseInt(props.match.params.id);

    const { fetchCourseEvent, selectedCourseEvent, currentEmployee } = props;

    useEffect(() => {
        fetchCourseEvent(courseEventId);
    }, [courseEventId, fetchCourseEvent]);

    const racgpDescriptionSplitted =
        selectedCourseEvent?.racpgDescription?.split("\n") ?? [];

    const racgpDescriptionTitle =
        racgpDescriptionSplitted.length > 0 ? racgpDescriptionSplitted[0] : "";
    const racgpDescriptionItems =
        racgpDescriptionSplitted.length > 1
            ? racgpDescriptionSplitted.slice(1)
            : [];

    const renderCourseEventSpeaker = (
        courseEventSpeaker: CourseEventSpeaker
    ) => {
        return (
            <CourseEventSpeakerContainer key={courseEventSpeaker.id}>
                <EventSpeakerImageContainer>
                    {courseEventSpeaker.thumbnailBase64 &&
                        <img
                            alt=""
                            className="object-cover w-20 h-20 rounded-full"
                            src={`data:image/png;base64,${courseEventSpeaker.thumbnailBase64}`}
                        />
                    }
                </EventSpeakerImageContainer>
                <EventSpeakerTextContainer>
                    <EventSpeakerName>
                        {courseEventSpeaker.name} {courseEventSpeaker.titles && `(${courseEventSpeaker.titles})`}
                    </EventSpeakerName>
                    <EventSpeakerTopic>
                        Topic: {courseEventSpeaker.topic}
                    </EventSpeakerTopic>
                </EventSpeakerTextContainer>
            </CourseEventSpeakerContainer>
        );
    };

    const renderCourseEventSpeakers = (courseEvent: CourseEvent) => {
        if (
            selectedCourseEvent.courseEventSpeakers == null ||
            selectedCourseEvent.courseEventSpeakers.length === 0
        ) {
            return null;
        }

        return (
            <div>
                <h6>Speakers</h6>
                <EventSpeakersContainer>
                    {selectedCourseEvent.courseEventSpeakers.map(
                        renderCourseEventSpeaker
                    )}
                </EventSpeakersContainer>
            </div>
        );
    };

    const isAdminUser =
        currentEmployee?.userRoles?.role &&
        isAdmin(currentEmployee.userRoles.role);

    return (
        <div className="w-full h-full flex flex-col">
            <div className="w-full course-title-container py-3 flex align-items-center align-content-center">
                <div className="flex w-full h-100">
                    <FaceToFaceEventsBackLink
                        role="button"
                        className="flex align-items-center w-full"
                        onClick={() => history.push("/face-to-face-events")}
                    >
                        <BackIcon />
                        {selectedCourseEvent != null && (
                            <h1 className="h-100 course-title ms-1">
                                {selectedCourseEvent.title}
                            </h1>
                        )}
                    </FaceToFaceEventsBackLink>
                </div>
            </div>
            <Container className="flex flex-col-reverse md:flex-row justify-content-center pb-4 gap-4">
                <div className="md:w-72 flex-shrink-0">
                    {selectedCourseEvent != null && (
                        <LearningMaterialCard
                            isExpanded={true}
                            type={CourseOrEvent.Event}
                            event={selectedCourseEvent}
                            startCourse={() => { }}
                            employee={null}
                            markCourseAsSubmitted={() => { }}
                            courseCompletionQrCode={""}
                            fetchCourseCompletionFormQrCode={() => { }}
                            hideCompletitionDate={true}
                            isAdminUser={isAdminUser}
                        />
                    )}
                </div>
                {selectedCourseEvent != null && (
                    <Card className="flex-grow-1 p-3 gap-4 text-sm">
                        <div>
                            <h6>
                                Summary
                            </h6>
                            {selectedCourseEvent.summary
                                .split("\n")
                                .map((paragraph, index) => (
                                    <p key={index}>
                                        {paragraph}
                                    </p>
                                ))}
                        </div>
                        <div>
                            <h6>
                                CPD hours
                            </h6>
                            <ul className="mb-1">
                                <li className="mb-1">
                                    {`Educational Activities: ${selectedCourseEvent.course
                                        .educationalActivitiesCpd} hours`}
                                </li>
                                <li className="mb-1">
                                    {`Measuring Outcomes: ${selectedCourseEvent.course
                                        .measuringOutcomesCpd} hours`}
                                </li>
                                <li className="mb-1">
                                    {`Reviewing Performance: ${selectedCourseEvent.course
                                        .reviewingPerformanceCpd} hours`}
                                </li>
                            </ul>
                            {selectedCourseEvent.cpdHoursInfo &&
                                <p className="text-xs">
                                    ({selectedCourseEvent.cpdHoursInfo})
                                </p>
                            }
                            <p>
                                {racgpDescriptionTitle}
                            </p>
                            {racgpDescriptionItems.length > 0 && (
                                <ul className="list-disc">
                                    {racgpDescriptionItems.map(
                                        (descriptionItem, index) => (
                                            <li key={index}>
                                                {descriptionItem}
                                            </li>
                                        )
                                    )}
                                </ul>
                            )}
                        </div>
                        {selectedCourseEvent.courseEventPricingItems.length >
                            0 && (
                                <div>
                                    <h6>
                                        Pricing
                                    </h6>
                                    <p>
                                        {selectedCourseEvent.courseEventPricingItems.map(
                                            (pricingItem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {pricingItem.title}: $
                                                        {pricingItem.price}{" "}
                                                        {pricingItem.priceSubText}
                                                        {" / "}
                                                        {
                                                            pricingItem.alternativePriceInformation
                                                        }
                                                        {index <
                                                            selectedCourseEvent
                                                                .courseEventPricingItems
                                                                .length -
                                                            1 && <br />}
                                                    </React.Fragment>
                                                );
                                            }
                                        )}
                                    </p>
                                </div>
                            )}
                        {selectedCourseEvent.courseEventSchedules.length >
                            0 && (
                                <div>
                                    <h6>
                                        Schedule
                                    </h6>
                                    {selectedCourseEvent.courseEventSchedules.map(
                                        (scheduleItem, index) => (
                                            <React.Fragment key={index}>
                                                <p className="font-semibold">
                                                    {scheduleItem.title}
                                                </p>
                                                {scheduleItem.description && (
                                                    <p>
                                                        {scheduleItem.description
                                                            .split("\n")
                                                            .map(
                                                                (
                                                                    paragraph,
                                                                    paragraphIndex
                                                                ) => (
                                                                    <React.Fragment
                                                                        key={
                                                                            paragraphIndex
                                                                        }
                                                                    >
                                                                        {paragraph}
                                                                        {paragraphIndex <
                                                                            scheduleItem.description.split(
                                                                                "\n"
                                                                            )
                                                                                .length -
                                                                            1 && (
                                                                                <br />
                                                                            )}
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                    </p>
                                                )}
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                            )}
                        {renderCourseEventSpeakers(selectedCourseEvent)}
                        {selectedCourseEvent.sponsorLogoBase64 != null && (
                            <div>
                                <h6>
                                    Sponsor
                                </h6>
                                <CourseEventDetailsSponsorLogo
                                    src={`data:image/png;base64,${selectedCourseEvent.sponsorLogoBase64}`}
                                    alt="Sponsor logo"
                                />
                            </div>
                        )}
                    </Card>
                )}
            </Container>
        </div>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(FaceToFaceEventDetails as any);

import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";

class ContactUsServiceClass extends ServiceBase {
    private static _documentService: ContactUsServiceClass;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): ContactUsServiceClass {
        return (
            this._documentService || (this._documentService = new this("contactus"))
        );
    }

    public async SendContactUsRequest(contactUsContent: FormData): Promise<Result<{}>> {
        const result = await super.sendFormData<{}>({
            method: "POST",
            url: ``,
            callType: ApiCall.SendContactUsRequest,
            data: contactUsContent,
        });

        return result;
    }
}

export const ContactUsService = ContactUsServiceClass.Instance;

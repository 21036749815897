import styled from "styled-components";
import { device } from "../../../styles/sizes/device";
import { Link } from "react-router-dom";

export const FooterSection = styled.section`
    background: var(--white, #fff);
    padding: 64px 0px 48px 0px;
    @media ${device.xs} {
        padding: 32px 16px;
    }
`;

export const FooterSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
`;

export const FooterHeaderContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 50px;
    align-self: stretch;
    @media ${device.xs} {
    }
`;

export const FooterHeaderContainerContent = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    @media ${device.xs} {
    }
`;

export const FooterHeaderContainerInnerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    @media ${device.xs} {
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
    }
`;

export const FooterLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
`;

export const FooterLogoText = styled.p`
    color: var(--gray-500, #667085);
    /* Text lg/Medium */

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    margin-bottom: 0px;
`;

export const FooterLogo = styled.img`
    height: 32px;
`;

export const FooterLinksContainerContent = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 32px;
    @media ${device.xs} {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
`;

export const FooterLinksContainerContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const FooterLinksContainerContentInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
`;

export const FooterLink = styled(Link)`
    color: var(--gray-600, #475467);

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */

    @media ${device.xs} {
        font-size: 16px;
        line-height: 24px; /* 150% */
    }
`;

export const FooterLinkA = styled.a`
    color: var(--gray-600, #475467);

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    @media ${device.xs} {
        font-size: 16px;
        line-height: 24px; /* 150% */
    }
`;

export const FooterHr = styled.hr`
    color: #eaecf0;
`;

export const FooterBottomContainer = styled.div`
    display: flex;
    padding-top: 32px;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    border-top: 1px solid var(--gray-200, #eaecf0);

    @media ${device.xs} {
        flex-direction: column;
    }
`;

export const FooterBottomTextContainer = styled.div`
    flex: 1 0 0;
    width: 100%;

    @media ${device.xs} {
        text-align: center;
    }
`;

export const FooterCopyrightText = styled.p`
    color: var(--gray-500, #667085);
    /* Text lg/Regular */

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    margin-bottom: 0;
`;

export const FooterSocialContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    @media ${device.xs} {
        flex-direction: column;
    }
`;

export const FooterSocialIconsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

export const FooterSocialIconLink = styled.a``;

export const FooterSocialIcon = styled.img``;

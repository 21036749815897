import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, ModalHeader, Row } from "reactstrap";

import { PageFilter } from "common/PageFilter";
import { UserSortColumn } from "common/UserSortColumn";
import { PAGE_SIZE } from "consts";
import { useDebounce } from "hooks";
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";

import { ExportCsvForm, ExportCsvFormValues } from "./forms/ExportCsvFom";
import "./styles/GenerateLinkButtonAndModal.scss";

interface Props {
    courseId: number;
    sessionLinkId?: number;
    hideButton?: boolean;
    isModalOpened?: boolean;
    onClose?: () => void;
}

type PropsType = Props &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const ExportCsv: React.FC<PropsType> = (props: PropsType) => {
    const {
        isModalOpened: isModalOpenedFromProps,
        courseId,
        hideButton,
        courseSessionLinks,
        onClose,
        downloadSessionAttendancesCsv,
        fetchAllUsersPage,
        allUsersPage,
    } = props;

    const formRef = useRef<any>();

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [userPage, setUserPage] = useState(0);
    const [searchInputValue, setSearchInputValue] = useState("");

    const debouncedSearch = useDebounce((e: string) => {
        setSearchInputValue(e);
        setUserPage(0);
    }, 500, []);

    useEffect(() => {
        const filter: PageFilter[] = [];

        if (searchInputValue) {
            const childLevelFilters: PageFilter[] = [];
            childLevelFilters.push({
                filterName: "firstName",
                filterValue: searchInputValue,
                isContains: true,
            });
            childLevelFilters.push({
                filterName: "lastName",
                filterValue: searchInputValue,
                isContains: true,
            });
            filter.push({
                filterName: "",
                filterValue: "",
                isContains: false,
                childLevelFilters: childLevelFilters,
            });
        }

        fetchAllUsersPage(PAGE_SIZE, userPage, filter, UserSortColumn.Name);
    }, [userPage, searchInputValue, fetchAllUsersPage]);


    useEffect(() => {
        if (
            isModalOpenedFromProps === true ||
            isModalOpenedFromProps === false
        ) {
            setIsModalOpened(isModalOpenedFromProps);
        }
    }, [isModalOpenedFromProps]);

    const close = () => {
        setIsModalOpened(false);
        onClose && onClose();
    };

    const exportCsv = async ({ reportType, sessionLinkId, employeeId }: ExportCsvFormValues) => {
        await downloadSessionAttendancesCsv(
            courseId,
            sessionLinkId,
            employeeId
        );
        close();
    };

    return (
        <>
            {isModalOpened && (
                <Modal className="course-modal" isOpen={isModalOpened} centered size="md">
                    <div className="modal-header-icon-container">
                        <img
                            src={
                                require("images/modal-circles.svg")
                                    .default
                            }
                            alt=""
                        />
                        <img
                            className="modal-header-icon"
                            src={
                                require("images/modal-download.svg")
                                    .default
                            }
                            alt=""
                        />
                    </div>
                    <ModalHeader
                        toggle={close}
                    ></ModalHeader>
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>Export CSV</h5>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-0">
                                <ExportCsvForm
                                    formRef={formRef}
                                    allUsersPage={allUsersPage}
                                    searchUsers={debouncedSearch}
                                    handleSubmit={exportCsv}
                                    onCancel={close}
                                    courseSessionLinks={courseSessionLinks}
                                />
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}
            {!hideButton && (
                <Button
                    color="outline-primary"
                    className="me-1"
                    onClick={() => setIsModalOpened(true)}
                >
                    Export
                </Button>
            )}
        </>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(ExportCsv as any) as React.FC<Props>;

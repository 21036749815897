import React from "react";
import { Modal, AddButton, CancelButton } from "./styles/Common";
import {
  TitleLabel,
  DetailLabel,
  DialogueContainer,
} from "./styles/DeleteUserDialogue";
import { Row, Col } from "reactstrap";
import { Employee } from "../../models/Employee";

interface DeleteUserDialogueProps {
  onCancel: () => void;
  onConfirm: () => void;
  selectedEmployee: Employee;
}

const DeleteUserDialogue = (props: DeleteUserDialogueProps) => {
  const { selectedEmployee } = props;

  return (
    <Modal>
      <DialogueContainer>
        <TitleLabel>Delete user</TitleLabel>
        <DetailLabel>
          {`Are you sure you want to delete ${selectedEmployee.firstName} ${selectedEmployee.lastName}?`}
        </DetailLabel>
        <Row style={{ padding: 0, margin: 0, marginTop: 20 }}>
          <Col xs={6} style={{ padding: 0, margin: 0 }}>
            <CancelButton
              width={"175px"}
              marginRight={"0px"}
              onClick={() => {
                props.onCancel();
              }}
            >
              CANCEL
            </CancelButton>
          </Col>
          <Col xs={6} style={{ padding: 0, margin: 0 }}>
            <AddButton
              width={"175px"}
              onClick={() => props.onConfirm()}
              backgroundColor="#CB2424"
              height="40px"
              borderRadius="2px"
              fontSize="16px"
              lineHeight="24px"
              fontWeight="600"
            >
              CONFIRM
            </AddButton>
          </Col>
        </Row>
      </DialogueContainer>
    </Modal>
  );
};

export default DeleteUserDialogue;

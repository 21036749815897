import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

interface ScrolledProp {
    scrolled: boolean;
}

export const TopBannerContainer = styled.div<ScrolledProp>`
    position: sticky;
    width: 100%;
    background: var(--primary-25, #fff8f4);
    transition: opacity 0.3s, top 0.3s;
    z-index: 100;
    top: 0px;
    opacity: ${({ scrolled }) => (scrolled ? 0 : 1)};

    @media ${device.xs} {
        width: 100%;
        top: 0px;
    }
`;

export const TopBannerInnerContainer = styled.div`
    display: flex;
    padding: 12px 0px;
    align-items: center;
    align-self: stretch;
    width: 100%;
    @media ${device.xs} {
        padding: 16px;
    }
`;

export const TopBannerContentContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    width: 100%;
    @media ${device.xs} {
        flex-direction: column;
    }
`;

export const TopBannerIconAndTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    width: 100%;
    @media ${device.xs} {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const TopBannerIconContainer = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 8px solid var(--primary-50);
    background: var(--primary-100);
    @media ${device.xs} {
        width: 40px;
        height: 40px;
        padding: 10px;
        border: 6px solid var(--primary-50);
    }
`;

export const TopBannerTextContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 6px;
    flex: 1 0 0;
`;

export const TopBannerPrimaryTextContainer = styled.div`
    color: var(--primary-700, #f46e15);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    @media ${device.xs} {
    }
`;

export const TopBannerSecondaryTextContainer = styled.span`
    color: var(--primary-600, #f47721);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

export const TopBannerActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    @media ${device.xs} {
        width: 100%;
    }
`;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SearchBar } from "../../components/dashboard/styles/Common";
import { FilterDropDown } from "../../components/shared/FilterDropDown";
import * as dashboard from "../../store/dashboardStore";
import { IApplicationState } from "../../store";

interface IProps {
    courseId: number;
    sessionLinkId: number;
}

type Props = IProps &
    dashboard.DashboardState & typeof dashboard.actionCreators;

const LearningMaterialSessionAttendanceTable: React.FC<Props> = (props: Props) => {
    const dateFilterItems = () => [
        "Date Added",
        ...courseSessionAttendances.map(l => l?.date?.format('MM/DD/YYYY')).filter((value, index, array) => array.indexOf(value) === index)
    ];

    const {
        courseId,
        sessionLinkId,
        fetchCourseSessionAttendances,
        courseSessionAttendances,
        downloadSessionAttendancePdf
    } = props;

    const [searchText, setSearchText] = useState("");
    const [selectedFilter, setSelectedFilter] = useState(dateFilterItems()[0]);

    useEffect(() => {
        fetchCourseSessionAttendances(courseId, sessionLinkId);
    }, [sessionLinkId]);

    const attendanceFilter = (attendance) => {
        if (!attendance) {
            return false;
        }

        return (
            (
                !searchText ||
                attendance?.firstName?.toLowerCase()?.indexOf(searchText) >= 0 ||
                attendance?.lastName?.toLowerCase()?.indexOf(searchText) >= 0 ||
                attendance?.racgpNumber?.toLowerCase()?.indexOf(searchText) >= 0 ||
                attendance?.date?.format('DD/MM/YYYY hh:mm a')?.toLowerCase()?.indexOf(searchText) >= 0
            )
        );
    }

    return (
        <React.Fragment>
            <div className="p-3 flex justify-between">
                <SearchBar className="mx-0" value={searchText} placeholder="Search" onChange={(e) => setSearchText(e?.target?.value?.trim()?.toLowerCase())} style={{ backgroundPosition: "10px center", width: "250px", paddingLeft: "40px", marginLeft: 0, marginTop: "1px", marginBottom: "1px" }} />
                <FilterDropDown options={dateFilterItems()} onSelected={setSelectedFilter} selectedValue={selectedFilter} labelSelector={o => o} keySelector={o => o} />
            </div>
            <div className="flex-grow-1 overflow-auto">
                <table className="table table-hover doctors-table w-full mb-0">
                    <thead>
                        <tr className="p-2">
                            <th className="table-cell">Name</th>
                            <th className="table-cell">RACGP ID</th>
                            <th className="table-cell">Date and Time</th>
                            <th className="table-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            courseSessionAttendances.filter(attendanceFilter)?.map((attendance) => {
                                return (
                                    <tr key={attendance.employeeId} className="p-2">
                                        <td className="approval-table-cell">
                                            {attendance.firstName} {attendance.lastName}
                                        </td>
                                        <td className="approval-table-cell">
                                            {attendance.racgpNumber}
                                        </td>
                                        <td className="approval-table-cell">
                                            {attendance.date?.format('DD/MM/YYYY hh:mm a')}
                                        </td>
                                        <td className="approval-table-cell">
                                            <span className="text-link" role="button" onClick={_ => downloadSessionAttendancePdf(courseId, sessionLinkId, attendance.employeeId)}>View</span>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(LearningMaterialSessionAttendanceTable as any) as React.FC<IProps>;
import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as dashboard from "../../store/dashboardStore";
import { HelpText } from "../../components/shared/HelpText";
import { LoginLogo, MobileLoginLogo } from "./styles/SignUp";
import academyLogoImage from "../../images/academy-logo.svg";
import Button from "components/shared/Button";

type Props = dashboard.DashboardState & {} & typeof dashboard.actionCreators;
const SignupComplete: React.FC<Props> = (props: Props) => {
    const { clearDeletedEmployee } = props;

    useEffect(() => {
        clearDeletedEmployee();
    });
    const renderContent = () => {
        return (
            <div className="signup m-auto" style={{ maxWidth: "360px" }}>
                <div className="text-md-center pt-2">
                    <h2>Registration complete</h2>
                    <p
                        className="pb-2"
                        style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: "var(--darkGrey)",
                        }}
                    >
                        Your account has been successfully created.
                        <br />
                        Click below to log in.
                    </p>
                </div>
                <div className="d-grid gap-2 justify-content-center">
                    <Button to="/auth/signin">
                        Back to login
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="block md:hidden">
                <MobileLoginLogo src={academyLogoImage} />
            </div>
            <div className="hidden lg:block">
                <LoginLogo src={academyLogoImage} />
            </div>
            <Container className="vh-100">
                <Row className="justify-content-center m-0" style={{ height: "calc(100vh - 40px)" }}>
                    <Col className="align-self-center justify-self-center hidden lg:block" xs={12} md={6}>
                        {renderContent()}
                    </Col>
                    <Col xs={12} md={6} className="md:hidden block mt-5">
                        <div className="mt-5">
                            {renderContent()}
                        </div>
                    </Col>
                </Row>
                <Row className="m-0">
                    <Col>
                        <HelpText />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(SignupComplete as any) as React.FC<Props>;

import React from 'react'
import {
  AboutCardContainer,
  AboutCardsContainer,
  AboutSection,
  CardDescriptionContainer,
  CardImageContainer,
  CardTextContainer,
  CardTitleContainer,
  TitleContainer,
  TitleContainerContent,
  TitleContainerContentHeadingAndSub,
  TitleContainerContentHeadingContainer,
  TitleContainerContentHeadingText,
  TitleContainerContentSubHeadingText,
} from './styles/About'
import DoctorIcon from '../../images/landing-page/doctor.svg'
import RegistrarIcon from '../../images/landing-page/registrar.svg'
import NurseIcon from '../../images/landing-page/nurse.svg'

const About = () => {
  return (
    <>
      <div className="container">
        <AboutSection>
          <TitleContainer>
            <TitleContainerContent>
              <TitleContainerContentHeadingAndSub>
                <TitleContainerContentHeadingAndSub>
                  <TitleContainerContentHeadingContainer>
                    <TitleContainerContentHeadingText>
                      Drawing on our experience as a doctor-led, doctor-owned
                      and doctor-run community
                    </TitleContainerContentHeadingText>
                  </TitleContainerContentHeadingContainer>
                  <TitleContainerContentSubHeadingText>
                    Myhealth Academy builds on the collective expertise of our
                    GPs and our experience in running medical centres to offer a
                    range of CPD and other training for GPs and those who work
                    in medical centres.
                  </TitleContainerContentSubHeadingText>
                </TitleContainerContentHeadingAndSub>
              </TitleContainerContentHeadingAndSub>
            </TitleContainerContent>
          </TitleContainer>
          <AboutCardsContainer>
            <AboutCardContainer>
              <CardImageContainer>
                <img src={DoctorIcon} style={{ height: 20, width: 20 }} />
              </CardImageContainer>
              <CardTextContainer>
                <CardTitleContainer>For GPs</CardTitleContainer>
                <CardDescriptionContainer>
                  Our RACGP-accredited CPD courses and activities are offered at
                  no cost to all GPs, and are specially curated by GPs for GPs.
                  Registration gives you access to a range of courses and
                  activities, which are constantly being added to, to build a
                  best-in-class CPD library.
                </CardDescriptionContainer>
              </CardTextContainer>
            </AboutCardContainer>
            <AboutCardContainer>
              <CardImageContainer>
                <img src={RegistrarIcon} style={{ height: 20, width: 20 }} />
              </CardImageContainer>
              <CardTextContainer>
                <CardTitleContainer>For GP Registrars</CardTitleContainer>
                <CardDescriptionContainer>
                  A portal for all your registrar training, from access to
                  weekly webinars to useful resources. Enjoy complimentary
                  access to RACGP-accredited CPD activities aligned to your
                  scope of practice and interests.
                </CardDescriptionContainer>
              </CardTextContainer>
            </AboutCardContainer>
            <AboutCardContainer>
              <CardImageContainer>
                <img src={NurseIcon} style={{ height: 20, width: 20 }} />
              </CardImageContainer>
              <CardTextContainer>
                <CardTitleContainer>For Nurses</CardTitleContainer>
                <CardDescriptionContainer>
                  Access to a library of CPD activities across a broad range of
                  topics, interests and specialties, supported by templates and
                  documentation so you can quickly and easily log your CPD.
                </CardDescriptionContainer>
              </CardTextContainer>
            </AboutCardContainer>
          </AboutCardsContainer>
        </AboutSection>
      </div>
    </>
  )
}

export default About

import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'

import { Card } from 'components/reactstrap'
import * as Consts from 'consts'
import { ReactComponent as IconLogout } from 'images/icon-logout.svg'
import { IApplicationState } from 'store'
import * as dashboard from 'store/dashboardStore'
import classNames from 'classnames'

type Props = RouteComponentProps<{}> &
  dashboard.DashboardState & {} & typeof dashboard.actionCreators

const avatarClass = 'bg-gray-100 w-10 h-10 rounded-full flex-shrink-0';
const clickableAvatarClass = 'ring ring-gray-200/0 hover:ring-gray-200 transition-ring duration-200 ease-in-out'

const UserMenu: React.FC<Props> = (props: Props) => {
  const {
    currentEmployee,
    logoutUser,
  } = props

  const renderAccountImage = (isClickable?: boolean) => {
    return !currentEmployee?.avatarImageHash
      ? <div
        className={classNames(
          avatarClass,
          'flex items-center justify-center select-none',
          { [clickableAvatarClass]: isClickable })}
      >
        {currentEmployee ? `${currentEmployee?.firstName[0].toUpperCase()}${currentEmployee?.lastName[0].toUpperCase()}` : '...'}
      </div>
      : <img
        className={classNames(
          avatarClass,
          'object-cover',
          { [clickableAvatarClass]: isClickable })}
        src={`/api/${Consts.API_VERSION}/employees/${currentEmployee.id}/avatar/${currentEmployee.avatarImageHash}`} alt={currentEmployee.fullName} />
  }

  return (
    <Popover className="relative">
      <Popover.Button
        className="rounded-full"
      >
        {renderAccountImage(true)}
      </Popover.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-3 w-60 origin-top-right focus:outline-none">
          <Card.Shell>
            <Link
              className="flex flex-row space-x-4 px-4 py-3 text-sm text-gray-700 hover:bg-gray-50 hover:text-gray-900"
              to="/myaccount"
            >
              {renderAccountImage()}
              <div className='break-words overflow-hidden'>
                <span className='font-semibold block overflow-hidden whitespace-nowrap text-ellipsis'>{currentEmployee ? `${currentEmployee.firstName} ${currentEmployee.lastName}` : '...'}</span>
                <span className=' block overflow-hidden whitespace-nowrap text-ellipsis'>{currentEmployee && currentEmployee.email}</span>
              </div>
            </Link>
            <hr />

            <button
              onClick={() => {
                logoutUser()
              }}
              className='w-full flex space-x-2 px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900'
            >
              <IconLogout className='w-5 h-5' />
              <span>Log out</span>
            </button>
          </Card.Shell>
        </Popover.Panel>
      </Transition>

    </Popover >
  )
}

export default connect(
  (state: IApplicationState) => ({
    ...state.dashboard,
  }),
  { ...dashboard.actionCreators },
)(UserMenu as any) as React.FC
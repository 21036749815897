import "./styles/DoctorDetails.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Employee } from "../../models/Employee";
import { PersonalDetailsForm, PersonalDetailsFormRef } from "../auth/PersonalDetailsForm";
import { PersonalDetails } from "../../models/auth/PersonalDetails";
import ProfessionalDetails, { ProfessionalDetailsFormRef } from "../auth/ProfessionalDetails";
import { ProfessionalDetails as ProfessionalDetailsModel } from "../../models/auth/ProfessionalDetails";
import { showErrors } from "../../utils";
import * as dashboard from "../../store/dashboardStore";
import { IApplicationState } from "../../store";
import { ProfessionType } from "../../models/enums/ProfessionType";
import Button from "components/shared/Button";

interface IProps {
    employee: Employee;
    canEdit: boolean;
    onCloseEdit: () => void;
    allowedProfessionalTypes: string[];
}

type Props = IProps &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const DoctorDetails: React.FC<Props> = (props: Props) => {
    const { employee, updateEmployee, onCloseEdit, allowedProfessionalTypes } = props;
    const personalDetailsFormRef = useRef<PersonalDetailsFormRef>();
    const professionalDetailsFormRef = useRef<ProfessionalDetailsFormRef>();

    const [personalDetails, setPersonalDetails] = useState<PersonalDetails>();
    const [professionalDetails, setProfessionalDetails] =
        useState<ProfessionalDetailsModel>();

    const submitForms = useCallback(async () => {
        await personalDetailsFormRef.current.submit();
        await professionalDetailsFormRef.current.submit();
    }, []);

    const handlePersonalDetails = useCallback(async (personalDetails: PersonalDetails) => {
        console.log("handlePersonalDetails", personalDetails);
        setPersonalDetails(personalDetails);
    }, []);

    const handleProfessionalDetails = useCallback(async (
        professionalDetails: ProfessionalDetailsModel
    ) => {
        setProfessionalDetails(professionalDetails);
    }, []);

    const updateUser = useCallback(async (
        personalDetails: PersonalDetails,
        professionalDetails: ProfessionalDetailsModel
    ) => {
        console.log('updating', {
            ...employee,
            ...personalDetails,
            ...professionalDetails,
        });
        updateEmployee({
            ...employee,
            ...personalDetails,
            ...professionalDetails,
        });
        onCloseEdit();
    }, [employee, onCloseEdit, updateEmployee]);

    useEffect(() => {
        if (personalDetails && professionalDetails) {
            console.log(personalDetails, professionalDetails);
            updateUser(personalDetails, professionalDetails).catch((e) =>
                showErrors(`Failed to update user details. ${e}`)
            );
            setPersonalDetails(null);
            setProfessionalDetails(null);
        }
    }, [personalDetails, professionalDetails, updateUser]);

    const personalDetailsInitialValue: PersonalDetails = useMemo(() => {
        const { email, firstName, gender, lastName, phoneNumber, professionType } = employee;
        return {
            email, firstName, gender, lastName, phoneNumber, professionType
        }
    }, [employee]);

    const professionalDetailsInitialValue: ProfessionalDetailsModel = useMemo(() => {
        const { ahpraNumber, otherPracticeLocation, otherPracticeLocationState, primaryPracticeLocationId, racgpNumber, fellowshipCompletionYear, registrarClassification, registrarCommencementMonth, registrarCommencementYear } = employee;
        return { ahpraNumber, otherPracticeLocation, otherPracticeLocationState, primaryPracticeLocationId, racgpNumber, fellowshipCompletionYear, registrarClassification, registrarCommencementMonth, registrarCommencementYear };
    }, [employee])

    return (
        <React.Fragment>
            <div className="py-3 employee-details flex-grow-1 overflow-auto flex flex-col">
                <div className="px-3">
                    <h5>Personal details</h5>
                    <PersonalDetailsForm
                        canEdit={props.canEdit}
                        hideTitle
                        personalDetails={personalDetailsInitialValue}
                        onContinue={handlePersonalDetails}
                        allowedProfessionTypes={allowedProfessionalTypes ?? [
                            ProfessionType.GeneralPractitioner,
                            ProfessionType.Registrar,
                            ProfessionType.Nurse,
                        ]}
                        ref={personalDetailsFormRef}
                    />
                    <hr />
                    <h5>Professional details</h5>
                    <ProfessionalDetails
                        canEdit={props.canEdit}
                        hideTitle
                        professionalDetails={professionalDetailsInitialValue}
                        onNext={handleProfessionalDetails}
                        professionType={employee.professionType}
                        ref={professionalDetailsFormRef}
                        hideTermsAndConditions
                    />
                </div>
            </div>
            {props.canEdit && (
                <div>
                    <hr className="my-1" />
                    <div className="px-3 flex justify-content-end mb-3">
                        <Button
                            className="me-3"
                            variant="secondary"
                            onClick={onCloseEdit}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={submitForms}>
                            Save changes
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(DoctorDetails as any) as React.FC<IProps>;

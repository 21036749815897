import styled from "styled-components";
import SVG from "react-inlinesvg";
import { EmployeeStatus, ReviewStatus } from "../../../models/ShiftAdjustment";
import searchIcon from "../../../images/searchIcon.png";

export interface ModalProps {
    background?: string;
    width?: string;
    height?: string;
}

export const Modal = styled.div<ModalProps>`
    z-index: 999;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: ${(props) => props.height || "100vh"};
    width: ${(props) => props.width || "100vw"};
    align-items: center;
    justify-content: center;
    background: ${(props) => props.background || "rgba(0, 0, 0, 0.5)"};
`;

export interface FormContainerProps {
    width?: string;
}

export const FormContainer = styled.div<FormContainerProps>`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width || "68%"};
    height: 100%;
`;

export interface UploadContainerProps {
    isDragHover: boolean;
    width?: string;
}

export const UploadContainer = styled.div<UploadContainerProps>`
    width: ${(props) => props.width || "500px"};
    height: 130px;
    border-radius: 4px;
    border: dashed 1px var(--lightGrey);
    background-color: ${(props) =>
        props.isDragHover ? "var(--white)" : "#f6f7f7"};
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
`;

export const ContractedLabel = styled.div`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--darkGrey);
`;

export const RegularLabel = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
`;

export interface DropDownIconProps {
    isOpen?: boolean;
    isPointer?: boolean;
    margin?: string;
    size?: string;
}

export const DropDownIcon = styled(SVG)<DropDownIconProps>`
    width: ${(props) => props.size || "24px"};
    height: ${(props) => props.size || "24px"};
    margin-left: 0px;
    margin-right: 10px;
    border: none;
    margin: ${(props) => props.margin};
    cursor: ${(props) => props.isPointer && "pointer"};
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "none")};
`;

export interface AlertContainerProps {
    width?: string;
    height?: string;
    margin?: string;
}

export const AlertContainer = styled.div<AlertContainerProps>`
    width: ${(props) => props.width || "499px"};
    height: ${(props) => props.height || "44px"};
    border-radius: 4px;
    border: solid 1px #65afa1;
    background-color: rgba(101, 175, 161, 0.1);
    display: flex;
    flex-direction: row;
    margin: ${(props) => props.margin}
    padding: 11px;
    align-items: center
`;

export const WarningRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const WarningContainer = styled.div<AlertContainerProps>`
    border-radius: 4px;
    width: 90%;
    background-color: rgba(239, 70, 35, 0.1);
    display: flex;
    flex-direction: row;
    margin: 0 20px 15px 20px;
    padding: 13px 10px 13px 10px;
    align-items: center;
`;

export const WarningContainerOutlined = styled.div<AlertContainerProps>`
    border-radius: 4px;
    width: ${(props) => props.width ?? "90%"};
    height: ${(props) => props.height ?? "44px"};
    border: solid 1px rgba(239, 70, 35, 0.1);
    display: flex;
    flex-direction: row;
    margin: ${(props) => props.margin ?? "0 20px 15px 20px"};
    padding: 13px 10px 13px 10px;
    align-items: center;
`;

export const WarningTitle = styled.div`
    color: #ef4623;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 15px;
`;

export interface TableHeaderTitleProps {
    cursor?: string;
}

export const TableHeaderTitle = styled.div<TableHeaderTitleProps>`
    color: var(--medGrey);
    height: 19px;
    cursor: ${(props) => props.cursor};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

export const AlertTitle = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #65afa1;
    margin-right: 15px;
`;

export const DayLabel = styled.div`
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    margin-right: 0px;
`;

export const Line = styled.div`
    width: 100%;
    height: 0px;
    border-top: solid 1px var(--lightGrey);
    margin-top: 5px;
`;

export interface AddButtonProps {
    width?: string;
    height?: string;
    marginRight?: string;
    borderRadius?: string;
    backgroundColor?: string;
    fontSize?: string;
    lineHeight?: string;
    fontWeight?: string;
}

export const AddButton = styled.button<AddButtonProps>`
    width: ${(props) => props.width};
    height: ${(props) => props.height || "32px"};
    padding: 7px 20px 7px 20px;
    background-color: ${(props) => props.backgroundColor || "#1b6893"};
    margin-right: ${(props) => props.marginRight || "20px"};
    color: var(--white);
    border: none;
    font-size: ${(props) => props.fontSize || "12px"};
    font-weight: ${(props) => props.fontWeight || "normal"};
    line-height: ${(props) => props.lineHeight || "normal"};
    border-radius: ${(props) => props.borderRadius};
`;

export interface PaddedAddButtonProps {
    margin?: string;
    width?: string;
    height?: string;
}

export const PaddedAddButton = styled.button<PaddedAddButtonProps>`
    width: ${(props) => props.width || "auto"};
    height: ${(props) => props.height || "32px"};
    padding: 8px 20px;
    background-color: var(--primaryButtonsLinksColor);
    margin: ${(props) => props.margin || "0 20px 0 0"};
    color: var(--white);
    border: none;
    border-radius: 2px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
`;

export interface CancelButtonProps {
    width?: string;
    marginRight?: string;
}

export const CancelButton = styled.button<CancelButtonProps>`
    width: ${(props) => props.width || "81px"};
    height: 40px;
    padding: 7px 14px 7px 14px;
    border: solid 1px var(--lightGrey);
    border-radius: 2px;
    background-color: var(--white);
    margin-right: ${(props) => props.marginRight || "10px"};
    margin-left: auto;
    font-size: 16px;
    font-weight: 600;
    color: var(--darkGrey);
    line-height: 24px;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 19px;
    margin-bottom: 15px;
`;

interface DefaultImageProps {
    isLive?: boolean;
    size?: number;
    clickable?: boolean;
}

export const ProfileSegment = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    padding-left: 19px;
    left: 0;
    flex: 1;
`;

export const InputTitleLabel = styled.div`
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: var(--black);
    margin-top: 15px;
`;

export const ModalTitleLabel = styled.div`
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    margin-bottom: 10px;
`;

export const ModalDescriptionLabel = styled.div`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

export const CheckboxTitleLabel = styled.div`
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: var(--black);
    margin-left: 10px;
    cursor: default;
`;

export const TimeSheetCellDateLabel = styled.div`
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    margin-left: 0px;
`;

export const TextArea = styled.textarea`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    height: 109px;
    max-height: 109px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--lightGrey);
    background-color: var(--white);
    padding-left: 10px;
    margin-top: 5px;

    :disabled {
        background-color: #f6f7f7;
    }
`;

export interface TextInputProps {
    fontSize?: number;
    width?: string;
}

export const TextInput = styled.input<TextInputProps>`
    font-size: ${(props) => props.fontSize || "13px"};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    height: 39px;
    margin-top: 5px;
    width: ${(props) => props.width || "90%"};
    border-radius: 4px;
    border: solid 1px var(--lightGrey);
    background-color: var(--white);
    padding-left: 10px;

    :disabled {
        background-color: #f6f7f7;
    }
`;

interface HorizontalSeparatorProps {
    marginTop?: string;
    marginBottom?: string;
}

export const InputErrorLabel = styled.div`
    font-size: 11px;
    color: red;
    margin-top: 5px;
`;

export const HorizontalSeparator = styled.hr<HorizontalSeparatorProps>`
    color: var(--lightGrey);
    width: 100%;
    margin-top: ${(props) => props.marginTop || "0px"};
    margin-bottom: ${(props) => props.marginBottom || "0px"};
    border: 1px solid var(--lightGrey);
`;

export const DefaultImage = styled.div<DefaultImageProps>`
    background-color: var(--gray-100);
    cursor: ${(props) => (props.clickable ? "pointer" : "none")};
    width: ${(props) => (props.size ? `${props.size}vw` : "40px")};
    height: ${(props) => (props.size ? `${props.size}vw` : "40px")};
    border-radius: ${(props) =>
        props.size ? `${props.size / 2.0}vw` : "20px"};
    margin: 2px 0 0 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-self: center;
    justify-content: center;
    border: ${(props) => (props.isLive ? "solid 2px #65afa1" : "none")};
    flex-shrink: 0;
`;

export const FixedDefaultImage = styled.div<DefaultImageProps>`
    background-color: var(--gray-100);
    cursor: ${(props) => (props.clickable ? "pointer" : "none")};
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    border: ${(props) => (props.isLive ? "solid 2px #65afa1" : "none")};
`;

export const SmallDefaultImage = styled.div`
    background-color: var(--gray-100);
    cursor: none;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    border: none;
`;

interface DefaultNameOverlayProps {
    fontSize?: string;
    position?: string;
    left?: number;
}

export const DefaultNameOverlay = styled.div<DefaultNameOverlayProps>`
    font-size: ${(props) => props.fontSize || "max(12px, 1.111vw)"};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--darkGrey);
    position: ${(props) => props.position || "unset"};
    left: ${(props) => props.left || "unset"};
`;

const backgroundColorForReviewStatus = (status: ReviewStatus) => {
    switch (status) {
        case ReviewStatus.PENDING:
            return "rgba(252, 196, 154, 0.3)";
        case ReviewStatus.APPROVED:
            return "rgba(101, 175, 161, 0.3)";
        case ReviewStatus.DECLINED:
            return "rgba(239, 70, 35, 0.3)";
        case ReviewStatus.DELETED:
            return "rgba(239, 70, 35, 0.3)";
        case ReviewStatus.EDITED:
            return "#fcc49a";
    }
};

const backgroundColorForEmployeeStatus = (status: EmployeeStatus) => {
    switch (status) {
        case EmployeeStatus.PENDING:
            return "rgba(252, 196, 154, 0.3)";
        case EmployeeStatus.ACCEPTED:
            return "rgba(101, 175, 161, 0.3)";
        case EmployeeStatus.DECLINED:
            return "rgba(239, 70, 35, 0.3)";
    }
};
const colorForEmployeeStatus = (status: EmployeeStatus) => {
    switch (status) {
        case EmployeeStatus.PENDING:
            return "#f56007";
        case EmployeeStatus.ACCEPTED:
            return "#65afa1";
        case EmployeeStatus.DECLINED:
            return "#ef4623";
    }
};

const colorForReviewStatus = (status: ReviewStatus) => {
    switch (status) {
        case ReviewStatus.PENDING:
            return "#f56007";
        case ReviewStatus.APPROVED:
            return "#65afa1";
        case ReviewStatus.DECLINED:
            return "#ef4623";
        case ReviewStatus.DELETED:
            return "#ef4623";
        case ReviewStatus.EDITED:
            return "#f56007";
    }
};

const shiftAdjustmentTagWidthForStatus = (
    emplyoeeStatus: EmployeeStatus,
    reviewStatus: ReviewStatus
) => {
    if (reviewStatus) {
        switch (reviewStatus) {
            case ReviewStatus.EDITED:
                return "50px";
            default:
                return "60px";
        }
    } else if (emplyoeeStatus) {
        return "60px";
    }
};

export interface ShiftAdjustmentTagProps {
    reviewStatus?: ReviewStatus;
    employeeStatus?: EmployeeStatus;
    width?: string;
}

export const ShiftAdjustmentTag = styled.div<ShiftAdjustmentTagProps>`
    background-color: ${(props) =>
        props.employeeStatus !== EmployeeStatus.PENDING
            ? backgroundColorForEmployeeStatus(props.employeeStatus)
            : backgroundColorForReviewStatus(props.reviewStatus)};
    color: ${(props) =>
        props.employeeStatus !== EmployeeStatus.PENDING
            ? colorForEmployeeStatus(props.employeeStatus)
            : colorForReviewStatus(props.reviewStatus)};
    font-size: 12px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-weight: normal;
    width: ${(props) =>
        props.width ||
        shiftAdjustmentTagWidthForStatus(
            props.employeeStatus,
            props.reviewStatus
        )};
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    border-radius: 2px;
    height: 19px;
    display: flex;
    flex-direction: row;
`;

export const ReviewStatusLabel = styled.div`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-alignment: center;
`;

interface SearchBarProps {
    searchIcon?: string;
    width?: string;
    marginLeft?: string;
    padding?: string;
    placeholderBackgroundPosition?: string;
}

export const SearchBar = styled.input<SearchBarProps>`
    height: 38px;
    border-radius: 4px;
    margin-bottom: 13.5px;
    border: solid 1px var(--lightGrey);
    background-color: var(--white);
    font-size: 14px;
    padding: ${(props) => props.padding || "5px 15px 5px 30px"};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: ${(props) => props.marginLeft || "20px"};
    margin-right: 10px;
    margin-top: 15px;
    width: ${(props) => props.width};
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: ${(props) =>
        props.placeholderBackgroundPosition || "left"};
`;

export interface DateContainerProps {
    width?: string;
}

export const DateContainer = styled.div<DateContainerProps>`
    display: flex;
    flex-direction: row;
    width: ${(props) => props.width || "9.236vw"};
    height: 39px;
    align-items: center;
    border: solid 1px var(--lightGrey);
    border-radius: 4px;
    margin-top: 5px;
    justify-content: center;
`;

export interface DateInputProps {
    customWidth?: string;
}

export const DateInput = styled.input<DateInputProps>`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    width: ${(props) => props.customWidth || "5.6vw"};
    background-color: var(--white);
    padding-left: 10px;
    margin-right: 20px;
    border: none;
    height: 34px;

    &:focus {
        outline: none;
    }
`;

export interface FilterSortModalProps {
    top?: string;
    width?: string;
    minHeight?: string;
}

export const FilterSortModal = styled.div<FilterSortModalProps>`
    z-index: 999;
    display: block;
    position: absolute;
    float: right;
    width: ${(props) => props.width || "252px"};
    top: ${(props) => props.top || "45px"};
    right: 15px;
    min-height: ${(props) => props.minHeight || "162px"};
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

interface FilterSortContainerProps {
    marginLeft?: string;
}

export const FilterSortContainer = styled.div<FilterSortContainerProps>`
    display: flex;
    flex-direction: row;
    margin-left: ${(props) => props.marginLeft || "auto"};
    margin-right: 0px;
    cursor: pointer;
`;

export const FilterSortTitle = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-top: 11px;
    margin-left: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e3e50;
    margin-bottom: 11px;
`;

export const FilterSortImageContainer = styled.div`
    width: 55px;
    padding-left: 15px;
    padding-right: 15px;
`;

export const DeclineModal = styled.div`
    z-index: 999;
    display: block;
    position: absolute;
    float: right;
    width: 144px;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 5px;
    top: 35px;
    right: 0px;
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    max-height: 152px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

export interface FilterSortRowProps {
    height?: string;
}

export const FilterSortRow = styled.div<FilterSortRowProps>`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    height: ${(props) => props.height || "24px"};
    margin-bottom: 10px;
`;

interface FilterSortItemProps {
    color?: string;
}

export const FilterSortItem = styled.div<FilterSortItemProps>`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-left: 5px;
    color: ${(props) => props.color || "var(--black)"};
    &:hover {
        color: #ef4623;
    }
`;

export const DeclineItem = styled.div<FilterSortItemProps>`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--darkGrey);
    margin-bottom: 10px;

    &:hover {
        color: #ef4623;
    }
`;

export const FilterSortModalOverlay = styled.div`
    z-index: 999;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
`;

export enum SortOptions {
    FIRST_NAME = "First name",
    LAST_NAME = "Last name",
    POSITION = "Position",
}

export enum FilterOptions {
    ACTIVE = "Active",
    INACTIVE = "Inactive",
    PENDING_INVITE = "Pending Invite",
    ALL = "All",
    CONTRACTS = "Contracts",
    OTHER = "Other",
}

interface SVGProps {
    color?: string;
    size?: string;
    marginLeft?: string;
    marginRight?: string;
    transform?: string;
    cursor?: string;
    marginTop?: string;
}

export const StyledSVG = styled(SVG)<SVGProps>`
    width: ${(props) => props.size || "24px"};
    height: ${(props) => props.size || "24px"};
    margin-left: ${(props) => props.marginLeft || "20px"};
    margin-right: ${(props) => props.marginRight || "20px"};
    transform: ${(props) => props.transform};
    margin-right: ${(props) => props.marginRight || "8px"};
    margin-top: ${(props) => props.marginTop || "-2px"};
    cursor: ${(props) => props.cursor} & path {
        stroke: ${(props) => props.color || "inherit"};
    }

    & g {
        stroke: ${(props) => props.color || "inherit"};
    }
`;

export const EmptyLabel = styled.div`
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #898989;
`;

export const AhpraLink = styled.a`
    color: var(--primaryButtonsLinksColor) !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
`;

interface SortIconProps {
    isAscending?: boolean;
    isHidden?: boolean;
}

export const SortIcon = styled.img<SortIconProps>`
    display: inline-block;
    height: 24px;
    width: 24px;
    margin-left: 8px;
    visibility: ${(props) => (props.isHidden ? "hidden" : "visible")};
    transform: ${(props) => (props.isAscending ? "rotate(0.5turn)" : "none")};
`;

import React, { useEffect, useRef, useState } from 'react'

import {
  BadgeContainer,
  BadgeText,
  BadgeWrapper,
  CourseCard,
  CourseCardBody,
  CourseCardContent,
  CourseCardDescription,
  CourseCardHeader,
  CourseCardImage,
  CourseCardImageContainer,
  CourseCardTitle,
  CourseCardWrapper,
  CourseCarouselContainer,
  CourseCarouselSection,
  CourseCarouselWrapper,
  CourseCategoryBadgeProps,
  CpdContainer,
  CpdHours,
  CpdIconContainer,
  CpdText,
  SwiperButtonContainer,
  SwiperButtonsContainer,
  SwiperIconContainer,
} from './styles/CourseCarousel'
import CpdIcon from '../../images/landing-page/CPD icon.svg'
import ArrowLeft from '../../images/icons/arrow-left.svg'
import ArrowRight from '../../images/icons/arrow-right.svg'
import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Scrollbar, Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/bundle'

import { Course } from '../../models/Course'
import { CourseCategoryFilterValue } from '../../common/CourseCategoryFilterValue'
import { CarouselContainer, PaddingContainer } from './styles/General'

interface CourseCarouselProps {
  courses: Course[]
}

const CourseCarousel = (props: CourseCarouselProps) => {
  const { courses } = props;
  const [isAutoplay, setIsAutoplay] = useState(window.innerWidth > 650);

  const getThumbnailBase64 = (course: Course) => {
    return course?.thumbnailBase64
  }

  useEffect(() => {
    const handleResize = () => {
      setIsAutoplay(window.innerWidth > 650);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderCourseCard = (course: Course) => {
    const categoryProps = getCategoryBadgeProps(course.category)

    return (
      <CourseCardWrapper>
        <Link to={'/auth/signin'}>
          <CourseCard>
            <CourseCardImageContainer>
              <CourseCardImage
                src={course.thumbnailUrl ?? `data:image/png;base64,${getThumbnailBase64(course)}`}
              />
            </CourseCardImageContainer>
            <CourseCardContent>
              <CourseCardHeader>
                <BadgeContainer>
                  <BadgeWrapper {...categoryProps}>
                    <BadgeText {...categoryProps}>
                      {getCategoryName(course.category)}
                    </BadgeText>
                  </BadgeWrapper>
                </BadgeContainer>
                <CpdContainer>
                  <CpdIconContainer src={CpdIcon} />
                  <CpdHours>{course.cpd}h</CpdHours>
                  <CpdText>CPD</CpdText>
                </CpdContainer>
              </CourseCardHeader>
              <CourseCardBody>
                <CourseCardTitle>{course.title}</CourseCardTitle>
                <CourseCardDescription>
                  {course.description}
                </CourseCardDescription>
              </CourseCardBody>
            </CourseCardContent>
          </CourseCard>
        </Link>
      </CourseCardWrapper>
    )
  }

  const getCategoryBadgeProps = (
    category: CourseCategoryFilterValue,
  ): CourseCategoryBadgeProps => {
    switch (category) {
      case CourseCategoryFilterValue.ClinicalAudits:
        return { color: '#717BBC', backgroundColor: '#F8F9FC' }
      case CourseCategoryFilterValue.Webinars:
        return { color: '#53B1FD', backgroundColor: '#F0F9FF' }
      case CourseCategoryFilterValue.MeetingSeries:
        return { color: '#FD6F8E', backgroundColor: 'var(--white)1F3' }
      case CourseCategoryFilterValue.Meetings:
        return { color: '#3CCB7F', backgroundColor: '#EDFCF2' }
      case CourseCategoryFilterValue.Conferences:
        return { color: '#9B8AFB', backgroundColor: '#F4F3FF' }
      case CourseCategoryFilterValue.Workshops:
        return { color: '#2ED3B7', backgroundColor: '#F0FDF9' }
      case CourseCategoryFilterValue.RecordedWebinars:
        return { color: '#53B1FD', backgroundColor: '#F0F9FF' }
      default:
        return {}
    }
  }

  const getCategoryName = (categoryFilterValue: CourseCategoryFilterValue) => {
    switch (categoryFilterValue) {
      case CourseCategoryFilterValue.ClinicalAudits:
        return 'Clinical Audits'
      case CourseCategoryFilterValue.Webinars:
        return 'Webinars'
      case CourseCategoryFilterValue.MeetingSeries:
        return 'Meeting Series'
      case CourseCategoryFilterValue.Meetings:
        return 'Meetings'
      case CourseCategoryFilterValue.Conferences:
        return 'Conferences'
      case CourseCategoryFilterValue.Workshops:
        return 'Workshops'
      case CourseCategoryFilterValue.RecordedWebinars:
        return 'Recorded Webinars'
      default:
        return ''
    }
  }

  const swiperRef = useRef<SwiperCore>(null)

  useEffect(() => {
    const swiperElement = swiperRef.current?.el;

    if (swiperElement) {
      swiperElement.addEventListener("mouseenter", () => {
        if (swiperRef.current && swiperRef.current.autoplay.running) {
          swiperRef.current.autoplay.stop();
        }
      });

      swiperElement.addEventListener("mouseleave", () => {
        if (swiperRef.current && !swiperRef.current.autoplay.running) {
          swiperRef.current.autoplay.start();
        }
      });

      return () => {
        if (swiperElement) {
          swiperElement.removeEventListener("mouseenter", () => { });
          swiperElement.removeEventListener("mouseleave", () => { });
        }
      };
    }
  }, []);

  const goToNextSlide = () => {
    swiperRef.current?.slideNext()
  }

  const goToPrevSlide = () => {
    swiperRef.current?.slidePrev()
  }

  return (
    <CourseCarouselWrapper>
      <CourseCarouselSection>
        <CourseCarouselContainer>
          <Swiper
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            modules={[Autoplay, Scrollbar, Navigation]}
            loop={true}
            watchSlidesProgress={true}
            slidesPerView={'auto'}
            spaceBetween={32}
            autoplay={isAutoplay ? {
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
              stopOnLastSlide: false,
              waitForTransition: true,
            } : false}
          >
            {courses.concat(courses).map((course, index) => {
              return (
                <SwiperSlide
                  key={`course-key-${course.id}-${index}`}
                  style={{ width: '280px' }}
                >
                  {renderCourseCard(course)}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </CourseCarouselContainer>
      </CourseCarouselSection>

      <SwiperButtonsContainer>
        <SwiperButtonContainer onClick={goToPrevSlide}>
          <SwiperIconContainer src={ArrowLeft} />
        </SwiperButtonContainer>
        <SwiperButtonContainer onClick={goToNextSlide}>
          <SwiperIconContainer src={ArrowRight} />
        </SwiperButtonContainer>
      </SwiperButtonsContainer>
    </CourseCarouselWrapper>
  )
}

export default CourseCarousel

import React from "react";
import { Button } from "reactstrap";

import { StyledSVG } from "components/dashboard/styles/Common";
import { UserCourseCertificateDetails } from "models/UserCourseCertificateDetails";
import iconCertificate from "images/icon-certificate.svg";
import iconDownload from "images/icon-download-white.svg";
import myHealthLogo from "images/logo.png";
import racgpCpdProviderLogo from "images/logo_racgp_cpd_provider.png";
import racgpCpdApprovedActivityLogo from "images/logo_racgp_cpd_approved_activity.png";

import "./styles/LearningMaterialDetails.scss";
import {
    CertificateMissingMessageContainer,
    CertificateContainer,
    RacgpCpdProviderLogo,
    CertificateStatement,
    MyHealthLogo,
    EmployeeName,
    RacgpNumber,
    CertificateText,
    CourseName,
    ActivityId,
    ApprovedAt,
    CpdTable,
    CpdInnerTableContainer,
    RacgpCpdApprovedActivityLogo,
    CpdInnerTable,
    CpdTitle,
    CpdValue,
    CpdHoursLabel,
    DownloadCertificateButtonText,
} from "../styles/LearningMaterials";

const DateFormat = new Intl.DateTimeFormat("en-AU", {
    year: "numeric",
    month: "long",
    day: "numeric",
});

interface LearningMaterialUserCertificateProps {
    courseId: number;
    userCourseCertificateDetails: UserCourseCertificateDetails;
    requestCountLoader: number;
    downloadUserCourseCertificateExport: (courseId: number) => void;
}

const LearningMaterialUserCertificate: React.FC<
    LearningMaterialUserCertificateProps
> = (props: LearningMaterialUserCertificateProps) => {
    const {
        courseId,
        userCourseCertificateDetails,
        requestCountLoader,
        downloadUserCourseCertificateExport,
    } = props;

    return (
        <React.Fragment>
            {userCourseCertificateDetails == null &&
                requestCountLoader === 0 && (
                    <div className="flex flex-col align-items-center pt-5">
                        <div className="pt-5 mb-4">
                            <StyledSVG src={iconCertificate} size="40px" />
                        </div>
                        <CertificateMissingMessageContainer>
                            Please note that your certificate will only be
                            available to view and download
                            <br />
                            once you have completed the course and it has been
                            approved by our CPD Admin Team.
                        </CertificateMissingMessageContainer>
                    </div>
                )}
            {userCourseCertificateDetails != null && (
                <div className="flex flex-col align-items-center mt-4 certificate-container">
                    <CertificateContainer className="p-5 mb-3 flex flex-col align-items-center">
                        <RacgpCpdProviderLogo src={racgpCpdProviderLogo} />
                        <CertificateStatement>
                            STATEMENT OF ATTENDANCE
                        </CertificateStatement>
                        <MyHealthLogo src={myHealthLogo} />
                        <EmployeeName>
                            {userCourseCertificateDetails.employeeFirstName}{" "}
                            {userCourseCertificateDetails.employeeLastName}
                        </EmployeeName>
                        <RacgpNumber>
                            {userCourseCertificateDetails.employeeRacgpNo}
                        </RacgpNumber>
                        <CertificateText>Attended the</CertificateText>
                        <CourseName>
                            {userCourseCertificateDetails.courseName}
                        </CourseName>
                        <ActivityId>
                            {userCourseCertificateDetails.activityId}
                        </ActivityId>
                        <ApprovedAt>
                            On{" "}
                            {DateFormat.format(
                                userCourseCertificateDetails.approvedAt
                            )}
                        </ApprovedAt>
                        <CertificateText>
                            This activity has been approved for the following
                            hours and types:
                        </CertificateText>
                        <CpdTable>
                            <CpdInnerTableContainer className="flex flex-col justify-content-center">
                                <RacgpCpdApprovedActivityLogo
                                    src={racgpCpdApprovedActivityLogo}
                                />
                                <CpdInnerTable className="flex justify-between align-items-center">
                                    <div>
                                        <CpdTitle>
                                            Educational
                                            <br />
                                            Activities
                                        </CpdTitle>
                                        <CpdValue>
                                            {userCourseCertificateDetails.educationalActivitiesCpd ||
                                                "\u00A0"}
                                        </CpdValue>
                                        <CpdHoursLabel>hours</CpdHoursLabel>
                                    </div>
                                    <div>
                                        <CpdTitle>
                                            Measuring
                                            <br />
                                            Outcomes
                                        </CpdTitle>
                                        <CpdValue>
                                            {userCourseCertificateDetails.measuringOutcomesCpd ||
                                                "\u00A0"}
                                        </CpdValue>
                                        <CpdHoursLabel>hours</CpdHoursLabel>
                                    </div>
                                    <div>
                                        <CpdTitle>
                                            Reviewing
                                            <br />
                                            Performance
                                        </CpdTitle>
                                        <CpdValue>
                                            {userCourseCertificateDetails.reviewingPerformanceCpd ||
                                                "\u00A0"}
                                        </CpdValue>
                                        <CpdHoursLabel>hours</CpdHoursLabel>
                                    </div>
                                </CpdInnerTable>
                            </CpdInnerTableContainer>
                        </CpdTable>
                        <CertificateText marginTop="40px" marginBottom="10px">
                            Conducted by
                        </CertificateText>
                        <MyHealthLogo marginBottom="0" src={myHealthLogo} />
                    </CertificateContainer>
                    <Button
                        className="btn btn-primary px-5 flex align-items-center mb-3"
                        role="button"
                        onClick={() =>
                            downloadUserCourseCertificateExport(courseId)
                        }
                    >
                        <StyledSVG
                            src={iconDownload}
                            size="24px"
                            marginLeft="0"
                            marginRight="0"
                        />
                        <DownloadCertificateButtonText className="ms-2">
                            Download certificate
                        </DownloadCertificateButtonText>
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
};

export default LearningMaterialUserCertificate;

export enum ApiCall {
    Unspecified,
    GetPractices,
    GetClockInOutsForDate,
    fetchPracticeEmployees,
    GetClockInOuts,
    GetClockInOutPairs,
    GetDocuments,
    AddEmployee,
    InviteEmployee,
    ResendInvite,
    DeleteDocument,
    FetchEmployee,
    FetchCurrentEmployee,
    SearchEmployees,
    FetchEmployeeRoles,
    FetchTimeSheets,
    FetchCustomTimeSheets,
    FetchAllTimeSheets,
    UpdatePractice,
    UpdateEmployee,
    UpdateUser,
    AuthSignin,
    AuthVerifyMobile,
    AuthVerifyMobileNumber,
    AuthResendCode,
    AuthValidateIsNewUser,
    AuthRequestCode,
    AuthValidateCode,
    AuthForgottenPassword,
    AdminGetRoles,
    AdminDeleteUserFromRole,
    AdminFindUserByEmail,
    AdminAddUserToRole,
    AdminGetUserRoles,
    AdminGetClients,
    AdminCreateClient,
    AdminDeleteClient,
    AddClockInOut,
    DeleteEmployee,
    UploadDocument,
    FetchAllDocuments,
    GetPresignedUrl,
    UpdateShiftAdjustment,
    AddShiftAdjustment,
    AddShiftAdjustments,
    GetPayGrades,
    FetchAllUsers,
    RevokeInvite,
    GetAdminNotifications,
    GetIncompleteClockInOutPairs,
    FetchPracticesForEmployee,
    SendContactUsRequest,
    GetAllCourses,
    GetAllLandingPageCourses,
    GetNotStartedCourses,
    GetSubmittedCourses,
    GetCompletedCourses,
    GetBanners,
    StartCourse,
    MarkCourseAsSubmitted,
    GetCourseCompletionFormQrCode,
    FetchUserCourseSubmissions,
    ApproveEvaluationForm,
    DismissEvaluationForm,
    GetCourseDetails,
    GetEvaluationFormSubmissionDocument,
    UploadEvaluationFormSubmission,
    ToggleUserActiveFlag,
    FetchEvaluationFormData,
    SaveEvaluationFormData,
    GetCourseResponses,
    GetOwnCourseResponses,
    GetCourseResponsesCsvExport,
    GetUserCourseCertificateDetails,
    GetUserCourseCertificateExport,
    GetSessions,
    GetSessionLinks,
    GetSessionLinkDetails,
    GetSessionAttendances,
    LogUserAttendanceByToken,
    GetUserCourseCertificateExportAdmin,
    LogUserAttendance,
    DownloadSessionAttendancesCsv,
    DownloadSessionAttendancePdf,
    GetCourseEventsToBeDisplayedInCoursesPage,
    GetUpcomingCourseEvents,
    GetAllUpcomingCourseEvents,
    GetPastCourseEvents,
    GetCourseEventById,
    SaveState,
    SuspendData,
    SaveLastSlideReached,
    SaveCompletionStatus,
    UpdateOwnUserCourseAnswer,
    UpdateUserCourseAnswer,
    CreateCourse,
    UpdateCourse,
    GetCourse,
    CourseUploadFile,
}

import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { Practice } from "../models/Practice";
import { Employee } from "../models/Employee";
import { ClockInOut } from "../models/ClockInOut";
import { ShiftAdjustment } from "../models/ShiftAdjustment";
import { ClockInOutPair } from "../models/ClockInOutPair";

class ShiftAdjustmentServiceClass extends ServiceBase {
  private static _shiftAdjustmentService: ShiftAdjustmentServiceClass;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): ShiftAdjustmentServiceClass {
    return (
      this._shiftAdjustmentService ||
      (this._shiftAdjustmentService = new this("shiftadjustments"))
    );
  }

  public async UpdateShiftAdjustment(
    shiftAdjustment: ShiftAdjustment
  ): Promise<Result<{}>> {
    const result = await super.requestJson<ShiftAdjustment[]>({
      method: "PUT",
      url: `${shiftAdjustment.id}`,
      callType: ApiCall.UpdateShiftAdjustment,
      data: {
        ...shiftAdjustment,
      },
    });

    return result;
  }

  public async AddShiftAdjustments(
    shiftAdjustments: ShiftAdjustment[]
  ): Promise<Result<{}>> {
    const result = await super.requestJson<ShiftAdjustment[]>({
      method: "POST",
      url: ``,
      callType: ApiCall.AddShiftAdjustments,
      data: shiftAdjustments,
    });

    return result;
  }

  public async FetchAdminNotifications(
    employeeId: number
  ): Promise<Result<ClockInOutPair[]>> {
    const result = await super.requestJson<ClockInOutPair[]>({
      method: "GET",
      url: `clockinoutpairs`,
      callType: ApiCall.GetAdminNotifications,
    });

    return result;
  }
}

export const ShiftAdjustmentService = ShiftAdjustmentServiceClass.Instance;

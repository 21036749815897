import React, { forwardRef } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import { InputDecorator, InputDecoratorProps } from './InputDecorator';

type CheckboxProps = {
    type?: InputType;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>,
    "type"
> & InputDecoratorProps

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ className, hint, label, error, id, fieldError, ...inputProps }, ref) => {
    return <InputDecorator
        className={className}
        error={error}
        hint={hint}
        fieldError={fieldError}
    >
        <FormGroup check>
            <Input
                invalid={error || !!fieldError}
                {...inputProps}
                type="checkbox"
                id={id}
                innerRef={ref}
            />
            <Label check htmlFor={id}>{label}</Label>
        </FormGroup>
    </InputDecorator>
});

export default class Result<T> {
  public value: T;
  public headers: any;
  public errors: string[];
  public friendlyError?: string;
  public get hasErrors(): boolean {
    return (
      this.errors !== null &&
      Array.isArray(this.errors) &&
      this.errors.length > 0
    );
  }

  constructor(value: T, headers: any, friendlyError: string | undefined, ...errors: string[]) {
    this.value = value;
    this.headers = headers;
    this.friendlyError = friendlyError;
    this.errors = errors[0] === undefined || errors[0] === null ? [] : errors;
  }
}

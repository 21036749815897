import styled from "styled-components";

export const TitleLabel = styled.div`
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: var(--primaryHeadingsColor);
    margin-bottom: 10px;
`;

export const DetailLabel = styled.div`
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: var(--black);
    margin-bottom: 16px;
`;

export const DialogueContainer = styled.div`
    z-index: auto;
    display: block;
    position: fixed;
    float: right;
    width: 400px;
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding-left: 21px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 19px;
`;

export const DateContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 9.236vw;
    height: 39px;
    align-items: center;
    border: solid 1px var(--lightGrey);
    border-radius: 4px;
    margin-top: 5px;
`;

export const DateInput = styled.input`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    width: 6.236vw;
    background-color: var(--white);
    padding-left: 10px;
    margin-right: 20px;
    border: none;
    height: 37px;
`;

export const SmallTitleLabel = styled.div`
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: var(--black);
`;

import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { Employee } from "../models/Employee";
import { UserPayload } from "../models/UserPayload";
import { InviteEmployeePayload } from "../models/InviteEmployeePayload";
import { ODataPageResult } from "../common/ODataPageResult";
import { SortDirection } from "../common/SortDirection";
import { UsersPageListItem } from "models/UsersPageListItem";
import { UsersPageFilter } from "common/UserPageFilter";

class UserServiceClass extends ServiceBase {
    private static _userService: UserServiceClass;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): UserServiceClass {
        return this._userService || (this._userService = new this("users"));
    }

    public async InviteEmployee(
        inviteEmployeePayload: InviteEmployeePayload
    ): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "POST",
            url: "",
            callType: ApiCall.InviteEmployee,
            data: {
                ...inviteEmployeePayload,
            },
        });

        return result;
    }

    public async ResendInvite(employee: Employee): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "POST",
            url: `resendinviteemail`,
            callType: ApiCall.ResendInvite,
            data: {
                ...employee,
            },
        });

        return result;
    }

    public async FetchAllUsers(): Promise<Result<Employee[]>> {
        const result = await super.requestJson<Employee[]>({
            method: "GET",
            url: ``,
            callType: ApiCall.FetchAllUsers,
        });

        return result;
    }

    public async FetchUsersPage(
        pageSize: number,
        currentPage: number,
        filter: UsersPageFilter[],
        sortBy: string,
        sortDirection?: SortDirection
    ): Promise<Result<ODataPageResult<UsersPageListItem>>> {
        let url: string = `/v1/odata/UsersPage?api-version=2&$top=${pageSize}&$skip=${
            currentPage * pageSize
        }`;

        let filterParam = "&$filter=";
        let childLevelFilterAdded = false;
        for (let i = 0; i < filter.length; i++) {
            if (
                childLevelFilterAdded &&
                !filterParam.trim().endsWith("and%20")
            ) {
                filterParam += encodeURIComponent(" and ");
            }

            if (
                filter[i].childLevelFilters != null &&
                filter[i].childLevelFilters.length > 0
            ) {
                filterParam += "(";
                for (let j = 0; j < filter[i].childLevelFilters.length; j++) {
                    if (
                        !filter[i].childLevelFilters[j].filterName ||
                        !filter[i].childLevelFilters[j].filterValue
                    ) {
                        continue;
                    }
                    filterParam = this.createPageQuery(
                        filterParam,
                        filter[i].childLevelFilters[j]
                    );

                    if (j !== filter[i].childLevelFilters.length - 1) {
                        filterParam += encodeURIComponent(" or ");
                    }
                }

                if (filterParam === "&$filter=(") {
                    filterParam = "&$filter=";
                } else {
                    filterParam += ")";
                    childLevelFilterAdded = true;
                }

                continue;
            }

            if (!filter[i].filterName || !filter[i].filterValue) {
                continue;
            }

            if (childLevelFilterAdded) {
                if (!filterParam.trim().endsWith("and%20")) {
                    filterParam += encodeURIComponent(" and ");
                }

                childLevelFilterAdded = false;
            }

            filterParam = this.createPageQuery(filterParam, filter[i]);

            if (
                i !== filter.length - 1 &&
                !filterParam.trim().endsWith("and%20")
            ) {
                filterParam += encodeURIComponent(" and ");
            }
        }
        if (filterParam !== "&$filter=") {
            url += filterParam;
        }

        if (sortBy) {
            let encodedSortDirection = `${
                sortDirection === SortDirection.Desc ? "Desc" : "Asc"
            }`;
            url += `&$orderby=${sortBy} ${encodedSortDirection}`;
        }

        url += "&$count=true";
        const result = await super.requestJson<
            ODataPageResult<UsersPageListItem>
        >({
            isUrlOverridden: true,
            method: "GET",
            url: url,
            callType: ApiCall.FetchAllUsers,
        });

        return result;
    }

    private createUsersPageQuery(
        filterParam,
        filterName,
        filterValue,
        isContains,
        skipQuotes,
        isIn
    ) {
        if (isIn) {
            const encodedFilterValue = encodeURIComponent(
                ` in (${filterValue
                    .map((x) => (x === null ? "null" : `'${x}'`))
                    .join(", ")})`
            );
            filterParam += `${filterName}${encodedFilterValue}`;
        } else if (isContains) {
            const encodedFilterValue = encodeURIComponent(
                `'${filterValue.toLowerCase()}'`
            );
            filterParam += `contains(toLower(${filterName}),${encodedFilterValue})`;
        } else {
            const encodedFilterValue = skipQuotes
                ? encodeURIComponent(` eq ${filterValue}`)
                : encodeURIComponent(` eq '${filterValue}'`);
            filterParam += `${filterName}${encodedFilterValue}`;
        }
        return filterParam;
    }

    public async UpdateUser(userPayload: UserPayload): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "PUT",
            url: `${userPayload.id}`,
            callType: ApiCall.UpdateUser,
            data: {
                ...userPayload,
            },
        });

        return result;
    }

    public async RevokeInvite(employeeId: number): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "DELETE",
            url: `${employeeId}`,
            callType: ApiCall.RevokeInvite,
        });

        return result;
    }
}

export const UserService = UserServiceClass.Instance;

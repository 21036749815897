import React, { useCallback, useEffect } from 'react';
import { useForm } from "react-hook-form"
import { Col, Row } from 'reactstrap';
import Button from 'components/shared/Button';
import { useHistory } from 'react-router';
import { FileInput } from 'components/form/FileInput';
import { CourseFileUploadPayload } from 'models/CourseFileUploadPayload';
import { CourseUploadFileType } from 'models/enums/CourseUploadFileType';

import { ReactComponent as IconTick } from "images/icon-tick.svg";
import { required } from 'validate';

type Props = {
    handleSubmit(values: CourseFileUploadPayload): Promise<void>;
    handleCancel?();
    type: CourseUploadFileType;
    title: string;
    accept?: string;
}

export default function CourseFileUpload({
    handleSubmit,
    handleCancel,
    type,
    title,
    accept
}: Props) {
    const {
        register,
        control,
        handleSubmit: innerSubmit,
        reset,
        formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
    } = useForm<CourseFileUploadPayload>({ resetOptions: { keepIsSubmitSuccessful: true } });

    const submitHandler = useCallback(async (values: CourseFileUploadPayload) => {
        await handleSubmit(values);

        reset();
    }, [handleSubmit, reset]);

    const { block } = useHistory();
    useEffect(() => {
        if (isDirty) {
            const unblock = block(`You haven't saved ${title}`);
            return unblock;
        }
    }, [block, isDirty, title]);

    return (
        <form onSubmit={innerSubmit(submitHandler)}>
            <div className='py-3'>
                <Row>
                    <Col>
                        <FileInput
                            accept={accept}
                            label={title}
                            fieldError={errors.file}
                            name="file"
                            control={control}
                            rules={{ validate: required() }}
                        />
                    </Col>
                </Row>

                <input type='hidden' {...register("type", { value: type })} />

                <Row className='justify-content-start mt-2'>
                    {handleCancel && <Col xs="auto">
                        <Button
                            type="button"
                            variant='secondary'
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                    }
                    <Col xs="auto">
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Update
                        </Button>
                    </Col>
                    {isSubmitSuccessful &&
                        <Col xs="auto">
                            <IconTick
                                className="text-success" />
                        </Col>
                    }
                </Row>
            </div>
        </form>
    )
}
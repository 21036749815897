import React, { useEffect } from 'react';
import { History } from 'history';
import ReactGA from 'react-ga4';

interface RouteChangeTrackerProps {
    history: History;
}

const RouteChangeTracker: React.FC<RouteChangeTrackerProps> = ({ history }) => {
    useEffect(() => {
        return history.listen((location) => {
            ReactGA.send({ hitType: 'pageview', page: location.pathname });
        });
    }, [history]);

    return null;
};

export default RouteChangeTracker;

// 1. Imports
import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

// 2. Interfaces
export interface EventCardProps {
  backgroundImage: string;
}

export interface CardProps {
  secondary?: boolean;
}

// 3. Styled Components

// Layout
export const EventGridSection = styled.section``;

export const EventGridSectionContainer = styled.div``;

export const EventGridRow = styled.div`
  display: flex;

  @media ${device.md} {
    flex-direction: column;
  }
`;

export const EventGridCard = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  min-height: 450px;
  overflow: hidden;
`;

export const EventCardWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 720px;
  z-index: 1;

  @media ${device.md} {
    max-width: 100%;
  }
`;

export const EventCardContent = styled.div``;

export const EventCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const EventLocationAddressContainer = styled.div`
  display: flex;
  align-items: center;
  color: var(--primary-700);
  gap: 8px;
`;

// Inherited Components
export const EventCard1 = styled(EventGridCard)`
  background: var(--white, #fff);
  justify-content: flex-end;

  @media ${device.md} {
    justify-content: flex-start;
  }
`;

export const EventCard2 = styled(EventGridCard)<EventCardProps>`
  background: var(--orange-25, #fefaf5);
`;

export const EventCard3 = styled(EventGridCard)`
  background: var(--orange-100, #fdead7);
`;

export const EventCard4 = styled(EventGridCard)`
  background: var(--primary-600, #f47721);
`;

export const EventCardLeftWrapper = styled(EventCardWrapper)`
  justify-content: flex-start;
  padding-left: 12px;
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.xl} {
    padding-left: 32px;
    padding-right: 24px;
  }

  @media ${device.md} {
    padding-left: 16px;
    justify-content: flex-start;
  }
`;

export const EventCardInfoWrapper = styled(EventCardWrapper)`
  justify-content: flex-start;
  padding-left: 12px;
  padding-top: 80px;
  padding-bottom: 80px;

  @media ${device.xl} {
    padding-left: 32px;
    padding-right: 24px;
  }

  @media ${device.md} {
    padding-left: 16px;
    justify-content: flex-start;
  }
`;

export const EventCardRightWrapper = styled(EventCardWrapper)`
  justify-content: flex-end;
  padding-right: 12px;
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.xl} {
    padding-left: 32px;
    padding-right: 24px;
  }

  @media ${device.md} {
    padding-left: 16px;
    justify-content: flex-start;
  }
`;

export const EventCardLeftContent = styled(EventCardContent)`
  display: flex;
  gap: 64px;
  flex-direction: column;
`;

export const EventCardRightContent = styled(EventCardContent)`
  display: flex;
  gap: 64px;
  flex-direction: column;
`;

export const EventCard1BackgroundWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 0;
`;

export const EventCard2BackgroundWrapper = styled.div`
  position: absolute;
  z-index: 0;
`;

export const EventCard3BackgroundWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 0;
`;

export const EventCard4BackgroundWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 0;
`;

export const SvgContainer = styled.svg`
  max-width: 100%;
  max-height: 100%;
`;

export const EventCardTitleText = styled.h1<CardProps>`
  color: ${(props) => (props.secondary ? "white" : "var(--primary-700, #f46e15)")};
  font-size: clamp(24px, 2.5vw, 30px);
  max-width: 550px;
  font-style: normal;
  font-weight: 600;
  line-height: clamp(32px, 3vw, 38px);
`;

export const EventCardInfoTitleText = styled.h1`
  color: var(--gray-900, #101828);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;

  @media ${device.xs} {
    font-size: 30px;
    line-height: 38px;
  }
`;

export const EventCardDescriptionText = styled.p`
  color: var(--gray-600, #475467);
  font-size: 20px;
  font-style: normal;
  max-width: 550px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0px;
`;

export const EventCardDateText = styled.p<CardProps>`
  color: ${(props) => (props.secondary ? "white" : "var(--gray-900, #101828)")};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
`;

export const EventLocationPinContainer = styled.img`
  width: 24px;
  height: 24px;
  display: block;

  @media ${device.xs} {
    width: 20px;
    height: 20px;
  }
`;

export const EventLocationAddressButton = styled.a<CardProps>`
  cursor: pointer;
  display: block;
  color: ${(props) => (props.secondary ? "white" : "var(--primary-700, #f46e15)")} !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 0px;

  @media ${device.xs} {
    font-size: 16px;
    line-height: 24px; /* 150% */
  }
`;

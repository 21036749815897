import styled, { css } from "styled-components";
import { device } from "../../../styles/sizes/device";
import { Link } from "react-router-dom";

export interface TopBannerProps {
    topBannerIsVisible?: boolean;
}

export const LandingPageContainer = styled.div<TopBannerProps>`
    padding-top: 64px;
`;

export const WelcomeAndAboutSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(
        --gradient-linear-gray-neutral-01,
        linear-gradient(180deg, #fff 0%, #f3f5f7 100%)
    );
    @media ${device.xs} {
        padding-top: 48px;
        padding-bottom: 48px;
    }
`;

export const WelcomeContainer = styled.div`
    display: flex;
    min-height: clamp(350px, 50vw, 750px);

    @media ${device.xl} {
        flex-direction: column;
    }

    @media ${device.lg} {
        flex-direction: column;
    }

    @media ${device.md} {
        flex-direction: column;
    }

    @media ${device.sm} {
        flex-direction: column;
        padding-top: 30px;
    }

    @media ${device.xs} {
        flex-direction: column;
        padding-top: 30px;
    }
`;

export const WelcomeTextContainer = styled.div`
    display: flex;
    max-width: 580px;
    //padding-left: 123px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;

    @media ${device.xxl} {
        max-width: 650px;
    }

    @media ${device.lg} {
        padding-left: 16px;
    }

    @media ${device.md} {
        max-width: 100%;
    }

    @media ${device.xs} {
        padding: 0px 16px;
    }
`;

export const WelcomeLogoContainer = styled.img``;

export const WelcomeTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

export const WelcomeTitleText = styled.h1`
    color: var(--primary-700, #f46e15);
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.96px;
    align-self: stretch;
    @media ${device.xs} {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.72px;
    }
`;

export const WelcomeSubtitleText = styled.p`
    align-self: stretch;
    color: var(--gray-900, #101828);
    /* Text xl/Medium */

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    margin-bottom: 0px;
`;

export const WelcomeButtonContainer = styled(Link)``;

export const WelcomeImageContainer = styled.div`
    position: unset;
    right: unset;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @media ${device.xxxl} {
        position: absolute;
        right: 0;
    }

    @media ${device.xxl} {
        position: absolute;
        right: 0;
    }

    @media ${device.xl} {
        position: unset;
        right: unset;
        display: flex;
        justify-content: flex-end;
    }

    @media ${device.lg} {
        position: unset;
        right: unset;
        display: flex;
        justify-content: flex-end;
    }
`;

export const WelcomeImage = styled.img`
    height: clamp(350px, 50vw, 719px);

    @media ${device.md} {
    }

    @media ${device.sm} {
        max-width: 100%;
    }

    @media ${device.xs} {
        max-width: 100%;
        align-self: stretch;
    }
`;

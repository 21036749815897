import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { IApplicationState } from ".";
import { User } from "../models/admin/User";
// Declare an interface of the store's state.
export interface AuthState {
  user: User;
}

// Create the slice.
const slice = createSlice({
  name: "dashboard",
  initialState: {
    user: {
      email: "",
      lastName: "",
    },
  } as AuthState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

// Export reducer from the slice.
export const { reducer } = slice;

// Define action creators.
export const actionCreators = {
  setUser:
    (user: User) =>
      async (dispatch: Dispatch, getState: () => IApplicationState) => {
        dispatch(slice.actions.setUser(user));
      },
};

import Select from "react-dropdown-select";
import styled from "@emotion/styled";
import {
    DeclineReason,
    DeclineReasonToString,
} from "../../../models/ShiftAdjustment";

export function mapEnumToOption<T>(
    enumerable: Record<string, T>
): { value: T; label: string }[] {
    let enumMembers: T[] = Object.keys(enumerable).map(
        (key) => enumerable[key]
    );
    return enumMembers.map((m: T) => {
        return { value: m, label: `${m}` };
    });
}

export const mapDeclineReasonToOption = (enumerable: any) => {
    let enumMembers: any[] = Object.keys(enumerable)
        .filter((x) => isNaN(Number(x)))
        .map((key) => enumerable[key]);
    return enumMembers.map((m) => {
        return { value: m, label: `${DeclineReasonToString(m)}` };
    });
};

export const StyledSelect = styled(Select)`
    display: flex;

    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    height: 39px;
    margin-top: 5px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--lightGrey);
    margin-bottom: 15px;
    background-color: var(--white);
    padding-left: 10px;
    :disabled {
        background-color: #f6f7f7;
    }
    .react-dropdown-select-item {
        color: var(--black);
    }
    .react-dropdown-select-content {
        padding-left: 8px;
    }
    .react-dropdown-select-item.react-dropdown-select-item-selected,
    .react-dropdown-select-item.react-dropdown-select-item-active {
        //background: #111;
        border-bottom: 1px solid #333;
        color: var(--white);
        background: #1b6893;
        font-weight: bold;
    }
`;

export const AddNewEmployeeStyledSelect = styled(Select)`
    display: flex;

    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    height: 32px;
    margin-top: 5px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--lightGrey);
    margin-bottom: 15px;
    background-color: var(--white);
    padding-left: 10px;
    :disabled {
        background-color: #f6f7f7;
    }
    .react-dropdown-select-item {
        color: var(--black);
    }
    .react-dropdown-select-content {
        padding-left: 8px;
    }
    .react-dropdown-select-item.react-dropdown-select-item-selected,
    .react-dropdown-select-item.react-dropdown-select-item-active {
        //background: #111;
        border-bottom: 1px solid #333;
        color: var(--white);
        background: #1b6893;
        font-weight: bold;
    }
`;

export const EditProfileStyledSelect = styled(Select)`
    display: flex;

    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    height: 39px;
    margin-top: 5px;
    border-radius: 4px;
    border: solid 1px var(--lightGrey);
    margin-bottom: 15px;
    background-color: var(--white);
    :disabled {
        background-color: #f6f7f7;
    }
    .react-dropdown-select-item {
        color: var(--black);
    }
    .react-dropdown-select-content {
        padding-left: 8px;
    }
    .react-dropdown-select-item.react-dropdown-select-item-selected,
    .react-dropdown-select-item.react-dropdown-select-item-active {
        border-bottom: 1px solid #333;
        color: var(--white);
        background: #1b6893;
        font-weight: bold;
    }
`;

import React, { useState } from 'react'
import {
  EventSpeakerContainer,
  EventSpeakerImage,
  EventSpeakerImageContainer,
  EventSpeakerName,
  EventSpeakerNameAndRoleContainer,
  EventSpeakerNameAndSupportingTextContainer,
  EventSpeakerRole,
  EventSpeakerShortDescription,
  EventSpeakerSocialMediaContainer,
  EventSpeakerSocialMediaIcon,
  EventSpeakerTextAndSocialLinksContainer,
  EventSpeakerTopic,
} from './styles/EventDetails'
import { CourseEventSpeaker } from '../../models/CourseEventSpeaker'

import FacebookIcon from '../../images/events/facebook.svg'
import WebsiteIcon from '../../images/events/web.svg'
import TwitterIcon from '../../images/events/twitter.svg'
import LinkedInIcon from '../../images/events/linkedin.svg'
import InstagramIcon from '../../images/events/instagram.svg'
import styled from 'styled-components'

interface EventSpeakerProps {
  speaker: CourseEventSpeaker
}

interface ContentProps {
  expanded: boolean
}

const Content = styled.div<ContentProps>`
  overflow: hidden;
  max-height: ${(props) => (props.expanded ? '2000px' : '50px')};
  transition: max-height 0.3s ease;
`

const ToggleButton = styled.button`
  margin-top: 10px;
  color: var(--primary-700, #f46e15);

  padding-left: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;  
  background: none;
  border: none;
`

function attachIcon(socialMediaUrl: string, icon: string) {
  if (socialMediaUrl) {
    return { url: socialMediaUrl, icon };
  }
  return undefined;
}

const EventSpeaker = (props: EventSpeakerProps) => {
  const { speaker } = props
  const [isExpanded, setIsExpanded] = useState(false)

  const TextWithBreaks = ({ text }) => {
    const lines = (text ?? "").split('\n')
    return (
      <div>
        {lines.map((line, index) => (
          <EventSpeakerShortDescription key={index}>
            {line}
          </EventSpeakerShortDescription>
        ))}
      </div>
    )
  }

  const socialMedia = React.useMemo(() => {
    return [
      attachIcon(speaker.websiteUrl, WebsiteIcon),
      attachIcon(speaker.instagramUrl, InstagramIcon),
      attachIcon(speaker.linkedInUrl, LinkedInIcon),
      attachIcon(speaker.facebookUrl, FacebookIcon),
      attachIcon(speaker.twitterUrl, TwitterIcon),
    ].filter(x => x);
  }, [speaker.facebookUrl, speaker.instagramUrl, speaker.linkedInUrl, speaker.twitterUrl, speaker.websiteUrl]);

  const renderSpeaker = (speaker: CourseEventSpeaker) => {
    return (
      <EventSpeakerContainer>
        {speaker.thumbnailBase64 &&
          <img
            className='rounded-full w-20 h-20 object-cover shrink-0 grow-0'
            src={`data:image/png;base64,${speaker.thumbnailBase64}`}
            alt=''
          />
        }
        <EventSpeakerTextAndSocialLinksContainer>
          <EventSpeakerNameAndSupportingTextContainer>
            <EventSpeakerNameAndRoleContainer>
              <EventSpeakerName>
                {speaker.name} {speaker.titles && `(${speaker.titles})`}
              </EventSpeakerName>
              {speaker.role && <EventSpeakerRole>{speaker.role}</EventSpeakerRole>}
              {speaker.topic && <EventSpeakerTopic>Topic: {speaker.topic}</EventSpeakerTopic>}
            </EventSpeakerNameAndRoleContainer>
            <div>
              {speaker.description && <>
                <Content expanded={isExpanded}>
                  <TextWithBreaks text={speaker.description} />
                </Content>
                <ToggleButton onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? 'Show Less' : 'Read More'}
                </ToggleButton>
              </>}
            </div>
          </EventSpeakerNameAndSupportingTextContainer>
          {!!socialMedia.length &&
            <EventSpeakerSocialMediaContainer>
              {socialMedia.map(({ url, icon }, i) => (
                <a href={url}>
                  <EventSpeakerSocialMediaIcon src={icon} />
                </a>
              ))}
            </EventSpeakerSocialMediaContainer>
          }
        </EventSpeakerTextAndSocialLinksContainer>
      </EventSpeakerContainer>
    )
  }

  return renderSpeaker(speaker)
}

export default EventSpeaker

import styled from "styled-components";

export const ProfileContainer = styled.div`
    width: 100%;
    border-left: solid 1px var(--lightGrey);
    height: 65vh;
    overflow-x: hidden;
    overflow-y: auto;
`;

export const InvitationHeader = styled.div`
    height: 39px;
    background-color: #f6f7f7;
    height: 39px;
    display: flex;
    flex-direction: row;
    padding-left: 21px;
    align-items: center;
`;

export const InvitationLabel = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--darkGrey);
`;

export const PracticeLabel = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
`;

export const InvitationBoldLabel = styled.div`
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--darkGrey);
    margin-right: 5px;
`;

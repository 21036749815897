import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { Course } from "../models/Course";
import { CourseDetails } from "../models/CourseDetails";
import { CourseUserResponse } from "../models/CourseUserResponse";
import { CourseCategoryFilterValue } from "../common/CourseCategoryFilterValue";
import { UserCourseCertificateDetails } from "../models/UserCourseCertificateDetails";
import { CourseSession } from "../models/CourseSession";
import { CourseSessionLink } from "../models/CourseSessionLink";
import { CourseSessionAttendance } from "../models/CourseSessionAttendance";
import { CourseSessionLinkDetails } from "../models/CourseSessionLinkDetails";
import { UserCourseSubmissionStatus } from "../models/enums/UserCourseSubmissionStatus";
import { CourseEvent } from "../models/CourseEvent";
import { CourseBanner } from "../models/CourseBanner";
import { UpdateUserCourseAnswerPayload } from "../models/UpdateUserCourseAnswerPayload";
import { CourseState } from "../models/CourseState";
import { CpdType } from "common/CpdType";
import { CreateCourse } from "models/CreateCourse";
import { UpdateCourse } from "models/UpdateCourse";
import { CourseEntity } from "models/entities/CourseEntitiy";
import { PageQuery } from "common/PageQuery";
import { PageResult } from "common/PageResult";
import { CourseFileUploadPayload } from "models/CourseFileUploadPayload";

class CourseServiceClass extends ServiceBase {
    private static _courseService: CourseServiceClass;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): CourseServiceClass {
        return (
            this._courseService || (this._courseService = new this("courses"))
        );
    }

    public async GetAll(
        filter: string,
        sort: string,
        categoryFilter: CourseCategoryFilterValue,
        cpd: CpdType,
        adminViewAs: string | null,
        year?: number
    ): Promise<Result<Course[]>> {
        const result = await super.requestJson<Course[]>({
            method: "GET",
            url: `all`,
            data: {
                filter: filter,
                categoryFilter:
                    categoryFilter === CourseCategoryFilterValue.All
                        ? undefined
                        : categoryFilter,
                adminViewAs,
                sort,
                cpd,
                year,
            },
            callType: ApiCall.GetAllCourses,
        });

        return result;
    }

    public async GetAllLandingPage(): Promise<Result<Course[]>> {
        const result = await super.requestJson<Course[]>({
            method: "GET",
            url: `all-landing`,
            callType: ApiCall.GetAllLandingPageCourses,
        });

        return result;
    }

    public async GetNotStarted(
        filter: string,
        sort: string,
        categoryFilter: CourseCategoryFilterValue,
        cpd: CpdType,
        adminViewAs: string | null,
        year?: number
    ): Promise<Result<Course[]>> {
        const result = await super.requestJson<Course[]>({
            method: "GET",
            url: `not-started`,
            data: {
                filter,
                categoryFilter:
                    categoryFilter === CourseCategoryFilterValue.All
                        ? undefined
                        : categoryFilter,
                adminViewAs,
                sort,
                cpd,
                year,
            },
            callType: ApiCall.GetNotStartedCourses,
        });

        return result;
    }

    public async GetSubmitted(
        filter: string,
        sort: string,
        categoryFilter: CourseCategoryFilterValue,
        cpd: CpdType,
        adminViewAs: string | null,
        year?: number
    ): Promise<Result<Course[]>> {
        const result = await super.requestJson<Course[]>({
            method: "GET",
            url: `submitted`,
            data: {
                filter,
                categoryFilter:
                    categoryFilter === CourseCategoryFilterValue.All
                        ? undefined
                        : categoryFilter,
                adminViewAs,
                sort,
                cpd,
                year,
            },
            callType: ApiCall.GetSubmittedCourses,
        });

        return result;
    }

    public async GetCompleted(
        filter: string,
        sort: string,
        categoryFilter: CourseCategoryFilterValue,
        cpd: CpdType,
        adminViewAs: string | null,
        year?: number
    ): Promise<Result<Course[]>> {
        const result = await super.requestJson<Course[]>({
            method: "GET",
            url: `completed`,
            data: {
                filter,
                categoryFilter:
                    categoryFilter === CourseCategoryFilterValue.All
                        ? undefined
                        : categoryFilter,
                adminViewAs,
                sort,
                cpd,
                year,
            },
            callType: ApiCall.GetCompletedCourses,
        });

        return result;
    }

    public async StartCourse(courseId: number): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "POST",
            url: `start?courseId=${courseId}`,
            callType: ApiCall.StartCourse,
        });

        return result;
    }

    public async MarkCourseAsSubmitted(courseId: number): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "POST",
            url: `mark-as-submitted?courseId=${courseId}`,
            callType: ApiCall.MarkCourseAsSubmitted,
        });

        return result;
    }

    public async GetCourseCompletionFormQrCode(
        courseId: number
    ): Promise<Result<Blob>> {
        const result = await super.requestJson<Blob>({
            method: "GET",
            url: `course-completion-form-qr-code?courseId=${courseId}`,
            callType: ApiCall.GetCourseCompletionFormQrCode,
            isBlob: true,
        });

        return result;
    }

    public async GetDetails(courseId: number): Promise<Result<CourseDetails>> {
        const result = await super.requestJson<CourseDetails>({
            method: "GET",
            url: `${courseId}/details`,
            callType: ApiCall.GetCourseDetails,
        });

        return result;
    }

    public async GetCourseResponses(
        courseId: number,
        filter: string,
        sort: string,
        status: UserCourseSubmissionStatus | null
    ): Promise<Result<CourseUserResponse[]>> {
        const result = await super.requestJson<CourseUserResponse[]>({
            method: "GET",
            url: `${courseId}/responses?filter=${filter}&sort=${sort}&status=${
                status == null ? "" : status
            }`,
            callType: ApiCall.GetCourseResponses,
        });

        return result;
    }

    public async GetOwnCourseResponses(
        courseId: number
    ): Promise<Result<CourseUserResponse>> {
        const result = await super.requestJson<CourseUserResponse>({
            method: "GET",
            url: `${courseId}/own-responses`,
            callType: ApiCall.GetOwnCourseResponses,
        });

        return result;
    }

    public async GetCertificateDetails(
        courseId: number
    ): Promise<Result<UserCourseCertificateDetails>> {
        const result = await super.requestJson<UserCourseCertificateDetails>({
            method: "GET",
            url: `${courseId}/certificate`,
            callType: ApiCall.GetUserCourseCertificateDetails,
        });

        return result;
    }

    public async GetSessions(
        courseId: number
    ): Promise<Result<CourseSession[]>> {
        const result = await super.requestJson<CourseSession[]>({
            method: "GET",
            url: `${courseId}/sessions`,
            callType: ApiCall.GetSessions,
        });

        return result;
    }

    public async GetSessionLinks(
        courseId: number
    ): Promise<Result<CourseSessionLink[]>> {
        const result = await super.requestJson<CourseSessionLink[]>({
            method: "GET",
            url: `${courseId}/sessionlinks`,
            callType: ApiCall.GetSessionLinks,
        });

        return result;
    }

    public async GetSessionLinkDetails(
        token: string
    ): Promise<Result<CourseSessionLinkDetails>> {
        const result = await super.requestJson<CourseSessionLinkDetails>({
            method: "GET",
            url: `sessionlink/${token}`,
            callType: ApiCall.GetSessionLinkDetails,
        });

        return result;
    }

    public async GetSessionLinkAttendances(
        courseId: number,
        sessionLinkId: number
    ): Promise<Result<CourseSessionAttendance[]>> {
        const result = await super.requestJson<CourseSessionAttendance[]>({
            method: "GET",
            url: `${courseId}/sessionlink/${sessionLinkId}/attendances`,
            callType: ApiCall.GetSessionAttendances,
        });

        return result;
    }

    public async GetCourseEventsToBeDisplayedInCoursesPage(
        filter: string,
        sort: string
    ): Promise<Result<CourseEvent[]>> {
        const result = await super.requestJson<CourseEvent[]>({
            method: "GET",
            url: `events-in-courses-page?filter=${filter}&sort=${sort}`,
            callType: ApiCall.GetCourseEventsToBeDisplayedInCoursesPage,
        });

        return result;
    }

    public async GetUpcomingEvents(
        filter: string,
        sort: string
    ): Promise<Result<CourseEvent[]>> {
        const result = await super.requestJson<CourseEvent[]>({
            method: "GET",
            url: `upcoming-events?filter=${filter}&sort=${sort}`,
            callType: ApiCall.GetUpcomingCourseEvents,
        });

        return result;
    }

    public async GetAllUpcomingEvents(
        sort: string
    ): Promise<Result<CourseEvent[]>> {
        const result = await super.requestJson<CourseEvent[]>({
            method: "GET",
            url: `all-upcoming-events?sort=${sort}`,
            callType: ApiCall.GetAllUpcomingCourseEvents,
        });

        return result;
    }

    public async GetPastEvents(
        filter: string,
        sort: string
    ): Promise<Result<CourseEvent[]>> {
        const result = await super.requestJson<CourseEvent[]>({
            method: "GET",
            url: `past-events?filter=${filter}&sort=${sort}`,
            callType: ApiCall.GetPastCourseEvents,
        });

        return result;
    }

    public async GetEventById(id: number): Promise<Result<CourseEvent>> {
        const result = await super.requestJson<CourseEvent>({
            method: "GET",
            url: `events/${id}`,
            callType: ApiCall.GetCourseEventById,
        });

        return result;
    }

    public async DownloadSessionAttendancesCsv(
        courseId: number,
        sessionLinkId?: number,
        employeeId?: number
    ): Promise<Result<Blob>> {
        const result = await super.requestJson<Blob>({
            method: "GET",
            url: `${courseId}/session/report?${
                employeeId ? `employeeId=${employeeId}` : ""
            }${sessionLinkId ? `&sessionLinkId=${sessionLinkId}` : ""}`,
            callType: ApiCall.DownloadSessionAttendancesCsv,
            isBlob: true,
        });

        return result;
    }

    public async DownloadSessionAttendancePdf(
        courseId: number,
        sessionLinkId: number,
        employeeId: number
    ): Promise<Result<Blob>> {
        const result = await super.requestJson<Blob>({
            method: "GET",
            url: `${courseId}/sessionlink/${sessionLinkId}/attendance/${employeeId}/report`,
            callType: ApiCall.DownloadSessionAttendancePdf,
            isBlob: true,
        });

        return result;
    }

    public async CreateSessionLink(
        courseId: number,
        sessionLink: CourseSessionLink
    ): Promise<Result<string>> {
        const result = await super.requestJson<string>({
            method: "PUT",
            url: `${courseId}/sessions/${sessionLink.sessionId}/link`,
            callType: ApiCall.LogUserAttendance,
            data: sessionLink,
        });

        return result;
    }

    public async SetSessionLinkStatus(
        courseId: number,
        sessionId: number,
        sessionLinkId: number,
        status: string
    ): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "POST",
            url: `${courseId}/session/${sessionId}/link/${sessionLinkId}/status/${status}`,
            callType: ApiCall.LogUserAttendance,
        });

        return result;
    }

    public async LogUserAttendance(
        courseId: number,
        sessionId: number,
        sessionLinkId: number,
        employeeId: number
    ): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "PUT",
            url: `${courseId}/session/${sessionId}/link/${sessionLinkId}/attendance/${employeeId}`,
            callType: ApiCall.LogUserAttendance,
        });

        return result;
    }

    public async LogUserAttendanceByToken(
        token: string,
        signatureText: string,
        signatureBase64: string,
        isAcknowledged: boolean
    ): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "PUT",
            url: `sessionlink/${token}?isAcknowledged=${isAcknowledged}`,
            callType: ApiCall.LogUserAttendanceByToken,
            data: {
                signatureText,
                signatureBase64,
            },
        });

        return result;
    }

    public async DownloadCourseResponsesCsvExport(
        courseId: number,
        filter: string,
        sort: string,
        employeeId: number | null = null
    ): Promise<Result<Blob>> {
        const result = await super.requestJson<Blob>({
            method: "GET",
            url: `${courseId}/responses-export?filter=${filter}&sort=${sort}${
                employeeId === null ? "" : `&employeeId=${employeeId}`
            }`,
            callType: ApiCall.GetCourseResponsesCsvExport,
            isBlob: true,
        });

        return result;
    }

    public async DownloadUserCourseCertificateExport(
        courseId: number
    ): Promise<Result<Blob>> {
        const result = await super.requestJson<Blob>({
            method: "GET",
            url: `${courseId}/certificate-export`,
            callType: ApiCall.GetUserCourseCertificateExport,
            isBlob: true,
        });

        return result;
    }

    public async DownloadUserCourseCertificateExportAdmin(
        userCourseId: number
    ): Promise<Result<Blob>> {
        const result = await super.requestJson<Blob>({
            method: "GET",
            url: `certificate-export/${userCourseId}`,
            callType: ApiCall.GetUserCourseCertificateExportAdmin,
            isBlob: true,
        });

        return result;
    }

    public async FetchBanners(): Promise<Result<CourseBanner[]>> {
        const result = await super.requestJson<CourseBanner[]>({
            method: "GET",
            url: `banners`,
            callType: ApiCall.GetBanners,
        });

        return result;
    }

    public async SaveState(stateDetails: CourseState): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "POST",
            url: `state`,
            callType: ApiCall.SaveState,
            data: stateDetails,
        });

        return result;
    }

    public async SuspendData(
        courseId: number,
        suspendData: string
    ): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "POST",
            url: `suspend-data`,
            callType: ApiCall.SuspendData,
            data: {
                courseId,
                suspendData,
            },
        });

        return result;
    }

    public async SaveLastSlideReached(
        courseId: number,
        slideId: string
    ): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "POST",
            url: `${courseId}/last-slide-reached?slideId=${slideId}`,
            callType: ApiCall.SaveLastSlideReached,
        });

        return result;
    }

    public async SaveCompletionStatus(courseId: number): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "POST",
            url: `${courseId}/completion-status`,
            callType: ApiCall.SaveCompletionStatus,
        });

        return result;
    }

    public async UpdateOwnUserCourseAnswer(
        courseQuestionId: string,
        payload: UpdateUserCourseAnswerPayload
    ): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "POST",
            url: `user-course-answers/${courseQuestionId}`,
            callType: ApiCall.UpdateOwnUserCourseAnswer,
            data: payload,
        });

        return result;
    }

    public async UpdateUserCourseAnswer(
        courseQuestionId: string,
        userId: number,
        payload: UpdateUserCourseAnswerPayload
    ): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "POST",
            url: `user-course-answers-admin/${courseQuestionId}?userId=${userId}`,
            callType: ApiCall.UpdateUserCourseAnswer,
            data: payload,
        });

        return result;
    }

    public async Create(createCourse: CreateCourse): Promise<Result<Course>> {
        const result = await super.requestJson<Course>({
            method: "POST",
            url: ``,
            callType: ApiCall.CreateCourse,
            data: createCourse,
        });

        return result;
    }

    public async Update(
        courseId: number,
        updateCourse: UpdateCourse
    ): Promise<Result<Course>> {
        const result = await super.requestJson<Course>({
            method: "PUT",
            url: `${courseId}`,
            callType: ApiCall.UpdateCourse,
            data: updateCourse,
        });

        return result;
    }

    public async Get(courseId: number): Promise<Result<CourseEntity>> {
        const result = await super.requestJson<CourseEntity>({
            method: "GET",
            url: `${courseId}`,
            callType: ApiCall.GetCourse,
        });

        return result;
    }

    public async GetPaged(
        query: PageQuery<CourseEntity>
    ): Promise<Result<PageResult<CourseEntity>>> {
        const queryString = this.getPageQueryString(query);

        const result = await super.requestJson<PageResult<CourseEntity>>({
            method: "GET",
            url: `list?${queryString}`,
            callType: ApiCall.GetCourse,
        });

        return result;
    }

    public async FileUpload(
        courseId: number,
        fileInfo: CourseFileUploadPayload
    ): Promise<Result<Course>> {
        var form_data = new FormData();

        for (var key in fileInfo) {
            form_data.append(key, fileInfo[key]);
        }

        const result = await super.requestJson<Course>({
            method: "POST",
            url: `${courseId}/uploadFile`,
            callType: ApiCall.CourseUploadFile,
            data: form_data,
        });

        return result;
    }
}

export const CourseService = CourseServiceClass.Instance;

import CourseDetailsForm from 'components/admin/CourseDetailsForm';
import CourseFileUpload from 'components/admin/CourseFileUpload';
import Button from 'components/shared/Button';
import { CourseFileUploadPayload } from 'models/CourseFileUploadPayload';
import { UpdateCourse } from 'models/UpdateCourse';
import { CourseEntity } from 'models/entities/CourseEntitiy';
import { CourseUploadFileType } from 'models/enums/CourseUploadFileType';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Col, Container, Nav, NavItem, NavLink, Row, TabContent } from 'reactstrap';
import { CourseService } from 'services/CourseService';
import { IApplicationState } from 'store';
import * as dashboard from "store/dashboardStore";

type Props = RouteComponentProps<{ id: string }> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

type Tabs = "files" | "info";

const UpdateCoursePage = ({
    match: { params: { id }, path },
}: Props) => {

    const history = useHistory();

    const [course, setCourse] = useState<CourseEntity>();
    const [activeTab, setActiveTab] = useState<Tabs>("files");

    const getCourse = useCallback(async () => {
        const course = await CourseService.Get(Number(id));
        if (!course.hasErrors) {
            setCourse(course.value);
        }
    }, [id])

    useEffect(() => {
        if (isNaN(Number(id))) {
            history.replace(`${path.substring(0, path.lastIndexOf(':id'))}`)
            return;
        }

        getCourse();
    }, [id, history, path, getCourse]);

    const submitUpdate = useCallback(async (updateCourse: UpdateCourse) => {
        const result = await CourseService.Update(Number(id), updateCourse);

        if (!result.hasErrors) {
            toast.success("Updated course details");
            await getCourse();
        }

    }, [getCourse, id]);

    const initialValues: Partial<UpdateCourse> | undefined = useMemo(() => (course && course.id === Number(id) ? {
        ...course,
    } : undefined), [course, id]);

    const uploadFile = useCallback(async (form: CourseFileUploadPayload) => {
        const result = await CourseService.FileUpload(Number(id), form);

        if (!result.hasErrors) {
            toast.success("Updated file");
            await getCourse();
        }
    }, [getCourse, id]);

    if (!course) {
        return <></>;
    }

    return <>
        <Container className='py-4'>
            <Row>
                <Col>
                    <h3 className='mb-3'>Update <strong>{course.title}</strong></h3>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Button variant='secondary' className='mb-3' to={`${path.substring(0, path.lastIndexOf(':id'))}`}>Back</Button>
                </Col>
            </Row>
            <Nav tabs>
                <NavItem>
                    <NavLink tag="button" active={activeTab === "files"} onClick={() => setActiveTab("files")}>
                        Files
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag="button" active={activeTab === "info"} onClick={() => setActiveTab("info")}>
                        Information
                    </NavLink>
                </NavItem>
            </Nav>
        </Container>

        {activeTab === "files" &&
            <TabContent>
                <Container>
                    <CourseFileUpload type={CourseUploadFileType.Course} title="Course File" handleSubmit={uploadFile} accept='.zip' />
                    <hr />
                    <CourseFileUpload type={CourseUploadFileType.CourseThumbnail} title="Thumbnail" handleSubmit={uploadFile} accept='.jpg,.jpeg,.png' />
                </Container>
            </TabContent >
        }
        {
            activeTab === "info" && initialValues &&
            <TabContent>
                <Container>
                    <CourseDetailsForm
                        actionLabel='Update'
                        handleSubmit={submitUpdate}
                        initialValues={initialValues} />
                </Container>
            </TabContent>
        }

    </>
}

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(UpdateCoursePage as any);
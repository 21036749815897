import styled from "styled-components";
export const FieldSelectNotSelectedDropDownIcon = styled.img`
    position: absolute;
    right: 10px;
    top: 32px;
    pointer-events: none;
`;
export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MobileLoginLogo = styled.img`
    position: absolute;
    top: 0;
    width: 104px;
    margin: 26px 0 32px 24px;
`;

export const LoginLogo = styled.img`
    position: absolute;
    top: 0;
    width: 172px;
    margin: 26px 0 32px 24px;
`;

import "./styles/Doctors.scss";
import * as dashboard from "../../store/dashboardStore";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card } from "reactstrap";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { RouteComponentProps } from "react-router";
import { SearchBar, SortIcon } from "../../components/dashboard/styles/Common";
import { DoctorDetailsModal } from "./DoctorDetailsModal";
import { FilterDropDown } from "../../components/shared/FilterDropDown";
import UploadSubmissionFormAndButton from "./UploadSubmission";
import { showDefault, showErrors } from "../../utils";
import { compose } from "redux";
import { useHistory, withRouter } from "react-router-dom";
import { EmployeeSortColumn } from "../../common/EmployeeSortColumn";
import { SortDirection } from "../../common/SortDirection";
import RevokeInviteDialogue from "../../components/dashboard/RevokeInviteDialogue";
import InviteUserFormAndButton from "./InviteUserFormAndButton";
import { PageFilter } from "../../common/PageFilter";
import { PAGE_SIZE } from "../../consts";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import rightEnabled from "../../images/pagination-arrow-right-enabled.svg";
import rightDisabled from "../../images/pagination-arrow-right-disabled.svg";
import leftEnabled from "../../images/pagination-arrow-left-enabled.svg";
import leftDisabled from "../../images/pagination-arrow-left-disabled.svg";

interface IProps {
    title: string;
    subTitle: string;
    routePath: string;
    allowedProfessionTypes: (string | null)[];
    hideProfessionTypeFilter?: boolean;
    hideUploadSubmission?: boolean;
    hideInviteUser?: boolean;
    hidePendingInvite?: boolean;
}

type Props = IProps &
    RouteComponentProps<{ id?: string }> &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;


const stateFilterItems = [
    "All States",
    "NSW",
    "VIC",
    "QLD",
    "WA",
    "SA",
    "TAS",
];

const Doctors: React.FC<Props> = (props: Props) => {
    //TODO redirect if not admin

    const employeeId = parseInt(props.match.params.id);

    const {
        fetchAllUsers,
        allUsersPage,
        fetchAllUsersPage,
        employeeById,
        fetchEmployeeById,
        deleteUser,
        toggleUserActiveFlag,
        setShowRevokeInviteModal,
        showRevokeInviteModal,
        allowedProfessionTypes,
    } = props;

    const history = useHistory();
    const [sortBy, setSortBy] = useState(EmployeeSortColumn.Name);
    const [sortDirection, setSortDirection] = useState(SortDirection.Asc);
    const [professionFilterValue, setProfessionFilterValue] = useState(
        "All Profession Types"
    );
    const [stateFilterValue, setStateFilterValue] = useState("All States");
    const [statusFilterValue, setStatusFilterValue] = useState("All Statuses");
    const [selectedUsersPageListItem, setSelectedUsersItemId] =
        useState<number>();
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState("");

    useEffect(() => {
        fetchAllUsers();
    }, [fetchAllUsers]);

    const professionFilterItems = useMemo(() => [
        "All Profession Types",
        ...allowedProfessionTypes.sort((a, b) => (a > b ? 1 : -1)),
    ], [allowedProfessionTypes]);

    const statusFilterItems = useMemo(() => [
        "All Statuses",
        "Active",
        "Inactive",
        ...(props.hidePendingInvite ? [] : ["Pending Invite"]),
    ], [props.hidePendingInvite]);

    const debouncedResults = useMemo(() => {
        return _.debounce((e: string) => {
            setSearch(e);
            setCurrentPage(0);
        }, 500);
    }, []);

    const updatePageContent = useCallback(() => {
        const filter: PageFilter[] = [];

        if (allowedProfessionTypes) {
            filter.push({
                filterName: "professionType",
                filterValue: allowedProfessionTypes,
                isContains: false,
                isIn: true,
            });
        }
        if (
            professionFilterValue &&
            professionFilterValue !== "All Profession Types"
        ) {
            filter.push({
                filterName: "professionType",
                filterValue: professionFilterValue,
                isContains: false,
            });
        }
        if (stateFilterValue && stateFilterValue !== "All States") {
            filter.push({
                filterName: "state",
                filterValue: stateFilterValue,
                isContains: false,
            });
        }
        if (statusFilterValue && statusFilterValue !== "All Statuses") {
            filter.push({
                filterName: "status",
                filterValue: statusFilterValue,
                isContains: false,
            });
        }
        if (search) {
            const childLevelFilters: PageFilter[] = [];
            childLevelFilters.push({
                filterName: "firstName",
                filterValue: search,
                isContains: true,
            });
            childLevelFilters.push({
                filterName: "lastName",
                filterValue: search,
                isContains: true,
            });
            filter.push({
                filterName: "",
                filterValue: "",
                isContains: false,
                childLevelFilters: childLevelFilters,
            });
        }

        fetchAllUsersPage(
            PAGE_SIZE,
            currentPage,
            filter,
            sortBy.toString(),
            sortDirection
        );
    }, [allowedProfessionTypes, currentPage, fetchAllUsersPage, professionFilterValue, search, sortBy, sortDirection, stateFilterValue, statusFilterValue]);

    const revokeInviteDialogueProps = useCallback(() => {
        return {
            onCancel: () => {
                setShowRevokeInviteModal(false);
            },
            onConfirm: async () => {
                setShowRevokeInviteModal(false);
                if (await deleteUser(employeeById.id)) {
                    updatePageContent();
                }
            },
            selectedEmployee: employeeById,
        };
    }, [deleteUser, employeeById, setShowRevokeInviteModal, updatePageContent]);

    const removeUser = useCallback(async (employeeId: number) => {
        if (await deleteUser(employeeId)) {
            history.push(props.routePath);
            showDefault("User successfully deleted.");
            setSelectedUsersItemId(undefined);
            updatePageContent();
        } else {
            showErrors("Failed to delete this user.");
        }
    }, [deleteUser, history, props.routePath, updatePageContent]);

    const toggleActiveFlag = useCallback(async (employeeId: number) => {
        const isActiveOriginally = employeeById?.isActive;

        if (await toggleUserActiveFlag(employeeId)) {
            showDefault(
                `User successfully set to ${isActiveOriginally ? "inactive" : "active"
                }.`
            );
        } else {
            showErrors(
                `Failed to set this user to ${isActiveOriginally ? "inactive" : "active"
                }`
            );
        }
    }, [employeeById?.isActive, toggleUserActiveFlag]);

    const updateSort = useCallback((employeeSortColumn: EmployeeSortColumn) => {
        setSortBy(employeeSortColumn);
        setSortDirection(
            employeeSortColumn === sortBy
                ? sortDirection === SortDirection.Asc
                    ? SortDirection.Desc
                    : SortDirection.Asc
                : SortDirection.Asc
        );
    }, [sortBy, sortDirection]);

    useEffect(() => {
        updatePageContent();
    }, [updatePageContent]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    }, [debouncedResults]);

    useEffect(() => {
        setCurrentPage(0);
    }, [
        professionFilterValue,
        stateFilterValue,
        statusFilterValue,
        sortBy,
        sortDirection,
        search,
    ]);

    useEffect(() => {
        if (selectedUsersPageListItem || !isNaN(employeeId)) {
            fetchEmployeeById(employeeId);
        }
    }, [employeeId, fetchEmployeeById, selectedUsersPageListItem]);

    useEffect(() => {
        if (!isNaN(employeeId)) {
            setSelectedUsersItemId(employeeId);
        }
    }, [employeeId]);

    return (
        <div className="flex flex-grow-1 flex-col overflow-hidden min-height-0">
            {showRevokeInviteModal && employeeById && (
                <RevokeInviteDialogue {...revokeInviteDialogueProps()} />
            )}

            <div className="p-3 flex justify-between">
                <div>
                    <div className="fs-3">
                        {props.title} ({allUsersPage["@odata.count"]})
                    </div>
                    <div className="">{props.subTitle}</div>
                </div>
                {!props.hideUploadSubmission && (
                    <UploadSubmissionFormAndButton />
                )}
                {!props.hideInviteUser && (
                    <InviteUserFormAndButton
                        onInvitedUser={async () => updatePageContent()}
                    />
                )}
            </div>
            <div className="px-3 mt-3 mb-3 flex justify-between">
                <SearchBar
                    className="m-0"
                    placeholder="Search"
                    onChange={(e) => debouncedResults(e.target.value)}
                    style={{
                        backgroundPosition: "10px center",
                        width: "400px",
                        paddingLeft: "40px",
                        marginLeft: 0,
                    }}
                />
                <div className="align-self-center d-inline-flex">
                    {!props.hideProfessionTypeFilter && (
                        <FilterDropDown
                            className="me-3"
                            options={professionFilterItems}
                            onSelected={setProfessionFilterValue}
                            selectedValue={professionFilterValue}
                        />
                    )}
                    <FilterDropDown
                        className="me-3"
                        options={stateFilterItems}
                        onSelected={setStateFilterValue}
                        selectedValue={stateFilterValue}
                    />
                    <FilterDropDown
                        className="me-3"
                        options={statusFilterItems}
                        onSelected={setStatusFilterValue}
                        selectedValue={statusFilterValue}
                    />
                </div>
            </div>
            <div className="p-0 approval-table doctors-table-container mt-0">
                {selectedUsersPageListItem && employeeById && (
                    <DoctorDetailsModal
                        employee={employeeById}
                        onClose={() => {
                            setSelectedUsersItemId(undefined);
                            history.push(props.routePath);
                        }}
                        onRmeoveUser={removeUser}
                        onMarkUserAsActiveOrInactive={toggleActiveFlag}
                        onShowRevokeInviteModal={() =>
                            setShowRevokeInviteModal(true)
                        }
                        allowedProfessionalTypes={props.allowedProfessionTypes.filter(
                            (p) => !!p
                        )}
                    />
                )}
                <div className="h-100 overflow-auto flex flex-col">
                    <div className="flex-grow-1 min-height-0 overflow-auto">
                        <table className="table table-hover doctors-table w-full mb-0">
                            <thead>
                                <tr className="p-2">
                                    <th
                                        className="table-cell"
                                        onClick={() =>
                                            updateSort(EmployeeSortColumn.Name)
                                        }
                                    >
                                        Name
                                        <SortIcon
                                            isAscending={
                                                sortDirection ===
                                                SortDirection.Asc
                                            }
                                            isHidden={
                                                EmployeeSortColumn.Name !==
                                                sortBy
                                            }
                                            src={
                                                require("../../images/icon-filter-down.svg")
                                                    .default
                                            }
                                            alt="sort-icon"
                                        />
                                    </th>
                                    <th
                                        className="table-cell"
                                        onClick={() =>
                                            updateSort(
                                                EmployeeSortColumn.ProfessionType
                                            )
                                        }
                                    >
                                        Profession Type
                                        <SortIcon
                                            isAscending={
                                                sortDirection ===
                                                SortDirection.Asc
                                            }
                                            isHidden={
                                                EmployeeSortColumn.ProfessionType !==
                                                sortBy
                                            }
                                            src={
                                                require("../../images/icon-filter-down.svg")
                                                    .default
                                            }
                                            alt="sort-icon"
                                        />
                                    </th>
                                    <th
                                        className="table-cell"
                                        onClick={() =>
                                            updateSort(
                                                EmployeeSortColumn.PrimaryPracticeLocation
                                            )
                                        }
                                    >
                                        Primary Practice Location
                                        <SortIcon
                                            isAscending={
                                                sortDirection ===
                                                SortDirection.Asc
                                            }
                                            isHidden={
                                                EmployeeSortColumn.PrimaryPracticeLocation !==
                                                sortBy
                                            }
                                            src={
                                                require("../../images/icon-filter-down.svg")
                                                    .default
                                            }
                                            alt="sort-icon"
                                        />
                                    </th>
                                    <th
                                        className="table-cell"
                                        onClick={() =>
                                            updateSort(EmployeeSortColumn.State)
                                        }
                                    >
                                        State
                                        <SortIcon
                                            isAscending={
                                                sortDirection ===
                                                SortDirection.Asc
                                            }
                                            isHidden={
                                                EmployeeSortColumn.State !==
                                                sortBy
                                            }
                                            src={
                                                require("../../images/icon-filter-down.svg")
                                                    .default
                                            }
                                            alt="sort-icon"
                                        />
                                    </th>
                                    <th
                                        className="table-cell"
                                        onClick={() =>
                                            updateSort(
                                                EmployeeSortColumn.Status
                                            )
                                        }
                                    >
                                        Status
                                        <SortIcon
                                            isAscending={
                                                sortDirection ===
                                                SortDirection.Desc
                                            }
                                            isHidden={
                                                EmployeeSortColumn.Status !==
                                                sortBy
                                            }
                                            src={
                                                require("../../images/icon-filter-down.svg")
                                                    .default
                                            }
                                            alt="sort-icon"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allUsersPage.value.map((userPageItem) => {
                                    return (
                                        <tr
                                            key={userPageItem.employeeId}
                                            className={
                                                "p-2" +
                                                (employeeId ===
                                                    userPageItem.employeeId
                                                    ? " active"
                                                    : "")
                                            }
                                            role="button"
                                            onClick={(_) => {
                                                history.push(
                                                    `${props.routePath}/${userPageItem.employeeId}`
                                                );
                                            }}
                                        >
                                            <td className="approval-table-cell">
                                                {userPageItem.firstName}{" "}
                                                {userPageItem.lastName}
                                            </td>
                                            <td className="approval-table-cell">
                                                {userPageItem.professionType}
                                            </td>
                                            <td className="approval-table-cell">
                                                {
                                                    userPageItem.primaryPracticeLocation
                                                }
                                            </td>
                                            <td className="approval-table-cell">
                                                {userPageItem.state}
                                            </td>
                                            <td
                                                className={`approval-table-cell ${userPageItem.isInvitePending
                                                    ? "text-warning-notification"
                                                    : ""
                                                    }`}
                                            >
                                                {userPageItem.status}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        forcePage={currentPage}
                        className="react-paginate"
                        breakLabel="..."
                        nextLabel={
                            <div className="pagination-buttons flex align-items-center align-content-center">
                                Next
                                <img
                                    alt=""
                                    src={
                                        currentPage !==
                                            Math.ceil(
                                                allUsersPage["@odata.count"] /
                                                PAGE_SIZE
                                            ) -
                                            1
                                            ? rightEnabled
                                            : rightDisabled
                                    }
                                />
                            </div>
                        }
                        onPageChange={(page) => {
                            setCurrentPage(page.selected);
                        }}
                        pageRangeDisplayed={3}
                        pageCount={Math.ceil(
                            allUsersPage["@odata.count"] / PAGE_SIZE
                        )}
                        previousLabel={
                            <div className="pagination-buttons flex align-items-center align-content-center">
                                <img
                                    alt=""
                                    src={
                                        currentPage !== 0
                                            ? leftEnabled
                                            : leftDisabled
                                    }
                                />
                                Previous
                            </div>
                        }
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </div>
    );
};

export default compose(
    withRouter,
    connect(
        (state: IApplicationState) => ({
            ...state.dashboard,
        }),
        { ...dashboard.actionCreators }
    )
)(Doctors as any) as any as React.FC<IProps>;

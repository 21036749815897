import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import { LearningMaterialCarouselImage } from './styles/LearningMaterials';
import { CourseBanner } from 'models/CourseBanner';
import classNames from 'classnames';

interface Props {
    courseBanners: CourseBanner[];
    className?: string;
}

export const LearningMaterialCarousel = ({ courseBanners, className }: Props) => {
    return <div className={classNames("learning-materials-carousel", className)}>
        <Carousel slide={false} indicators={true} controls={false}>
            {courseBanners.map((b) => {
                return (
                    <Carousel.Item key={b.image}>
                        <div className="flex w-full">
                            {b.url ?
                                <a
                                    className="w-full"
                                    href={b.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <LearningMaterialCarouselImage
                                        src={b.image}
                                    />
                                </a>
                                : <LearningMaterialCarouselImage
                                    src={b.image}
                                />}
                        </div>
                    </Carousel.Item>
                );
            })}
        </Carousel>
    </div>
}
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";

import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";
import { FilterDropDown } from "components/shared/FilterDropDown";
import { CourseSessionLink } from "models/CourseSessionLink";

import GenerateLinkButtonAndModal from "../GenerateLinkButtonAndModal";
import LogUserAttendanceButtonAndModal from "../LogUserAttendanceButtonAndModal";
import ExportCsvButtonAndModal from "../ExportCsv";
import LearningMaterialSessionDetailsModal from "../LearningMaterialSessionDetailsModal";
import DeactivateLinkButtonAndModal from "../DeactivateLinkButtonAndModal";
import activeLink from "images/link-active.svg";
import inactiveLink from "images/link-inactive.svg";
import "./styles/LearningMaterialSessionAttendances.scss";

interface IProps {
    courseId: number;
}

type Props = IProps &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const LearningMaterialSessionAttendances: React.FC<Props> = (props: Props) => {
    const statusFilterItems = ["All Statuses", "Active", "Expired", "Cancelled"];

    const stateFilterItems = () => [
        "All Centre/States",
        ...courseSessionLinks
            .map((l) => l?.practiceId ? practices.find(p => p.id == l.practiceId)?.name : l?.state)
            .filter((value, index, array) => array.indexOf(value) === index)
            .filter(x => x),
    ];

    const {
        courseId,
        fetchCourseSessions,
        fetchCourseSessionLinks,
        courseSessions,
        courseSessionLinks,
        practices,
        fetchPractices
    } = props;

    const [sessionFilterValue, setSessionFilterValue] = useState(
        null
    );
    const [statusFilterValue, setStatusFilterValue] = useState(
        statusFilterItems[0]
    );
    const [stateFilterValue, setStateFilterValue] = useState(
        stateFilterItems()[0]
    );
    const [selectedSessionLinkId, setSelectedSessionLinkId] =
        useState<number>();

    useEffect(() => {
        resetData();
    }, [courseId]);

    const resetData = () => {
        fetchCourseSessions(courseId);
        fetchCourseSessionLinks(courseId);
        fetchPractices();
    };

    const sessionLinkFilter = (sessionLink: CourseSessionLink) => {
        return (
            (statusFilterValue == "All Statuses" ||
                sessionLink.status?.toLowerCase() ==
                statusFilterValue?.toLowerCase()) &&
            (stateFilterValue == "All Centre/States" ||
                (sessionLink?.practiceId ? practices?.find(p => p.id == sessionLink.practiceId)?.name?.toLowerCase() == stateFilterValue?.toLowerCase() : sessionLink?.state?.toLowerCase() == stateFilterValue?.toLowerCase())
            ) &&
            (sessionFilterValue == null || sessionLink.sessionId == sessionFilterValue)
        );
    };

    const selectedSessionLink = courseSessionLinks.find(
        (l) => l.id == selectedSessionLinkId
    );
    const selectedSession = courseSessions.find(
        (s) => s.id == selectedSessionLink?.sessionId
    );

    const toggleSelectedSessionLinkId = (id: number) =>
        setSelectedSessionLinkId(selectedSessionLinkId == id ? undefined : id);

    return (
        <Card className="flex flex-grow-1 main-card">
            <div className="p-3 flex justify-between">
                <div>
                    <div className="fs-3">
                        Session Attendances ({courseSessionLinks?.length || 0})
                    </div>
                    <div>
                        Keep track of attendance for each session.
                    </div>
                </div>
                <div className="flex space-x-2">
                    <ExportCsvButtonAndModal courseId={courseId} />
                    <LogUserAttendanceButtonAndModal
                        courseId={courseId}
                        sessionLinkId={selectedSessionLinkId}
                    />
                    <span>
                        <GenerateLinkButtonAndModal courseId={courseId} />
                    </span>
                </div>
            </div>
            <hr />
            <div className="px-3 mt-3 mb-3 flex justify-between">
                <div className="btn-group">
                    <button type="button" className={`btn btn-secondary ${sessionFilterValue == null ? 'active' : ''}`} onClick={_ => setSessionFilterValue(null)}>View all</button>
                    {
                        courseSessions.map(s =>
                            <button key={s.id} type="button" className={`btn btn-secondary ${s.id == sessionFilterValue ? 'active' : ''}`} onClick={_ => setSessionFilterValue(s.id)}>{s.sessionName}</button>
                        )
                    }
                </div>
                <div className="align-self-center d-inline-flex">
                    <FilterDropDown
                        className="me-3"
                        options={stateFilterItems()}
                        onSelected={setStateFilterValue}
                        selectedValue={stateFilterValue}
                    />
                    <FilterDropDown
                        className="me-3"
                        options={statusFilterItems}
                        onSelected={setStatusFilterValue}
                        selectedValue={statusFilterValue}
                    />
                </div>
            </div>

            <div className="p-0 approval-table doctors-table-container mt-0">
                {selectedSessionLinkId && (
                    <LearningMaterialSessionDetailsModal
                        courseId={courseId}
                        session={selectedSession}
                        sessionLink={selectedSessionLink}
                        onClose={() => setSelectedSessionLinkId(undefined)}
                    />
                )}
                <div className="h-100 overflow-auto">
                    <table className="table table-hover doctors-table w-full mb-0">
                        <thead>
                            <tr className="p-2">
                                <th className="table-cell">Session Name</th>
                                <th className="table-cell">Centre/State</th>
                                <th className="table-cell">Type</th>
                                <th className="table-cell">Attendances</th>
                                <th className="table-cell">Date and Time</th>
                                <th className="table-cell">Status</th>
                                <th className="table-cell">Link</th>
                                <th className="table-cell"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {courseSessionLinks
                                .filter(sessionLinkFilter)
                                .map((sessionLink) => (
                                    <tr
                                        key={sessionLink.id}
                                        className={
                                            "p-2" +
                                            (sessionLink.id ==
                                                selectedSessionLinkId
                                                ? " active"
                                                : "")
                                        }
                                    >
                                        <td className="approval-table-cell">
                                            {sessionLink.sessionLinkName}
                                        </td>
                                        <td>
                                            {sessionLink.practiceId ? practices.find(p => p.id == sessionLink.practiceId)?.name : sessionLink.state}
                                        </td>
                                        <td className="approval-table-cell">
                                            {courseSessions.find(s => s.id == sessionLink.sessionId)?.sessionName}
                                        </td>
                                        <td className="approval-table-cell">
                                            {sessionLink.attendances}
                                        </td>
                                        <td className="approval-table-cell">
                                            {sessionLink.date.format(
                                                "DD/MM/YYYY hh:mm a"
                                            )}
                                        </td>
                                        <td className="approval-table-cell">
                                            <span className={`text-status-badge ${sessionLink.status.toLowerCase()}`}>
                                                {sessionLink.status}
                                            </span>
                                        </td>
                                        <td>
                                            <span
                                                role={
                                                    sessionLink.status ==
                                                        "Active"
                                                        ? "button"
                                                        : undefined
                                                }
                                                onClick={(_) => {
                                                    navigator.clipboard.writeText(
                                                        sessionLink.registrationUrl
                                                    );
                                                }}
                                            >
                                                <img
                                                    alt="Copy"
                                                    title="Copy"
                                                    src={
                                                        sessionLink.status ==
                                                            "Active"
                                                            ? activeLink
                                                            : inactiveLink
                                                    }
                                                />
                                            </span>
                                        </td>
                                        <td className="approval-table-cell">
                                            <div className="flex justify-content-end">
                                                {sessionLink.status ==
                                                    "Active" ? (
                                                    <DeactivateLinkButtonAndModal
                                                        courseId={courseId}
                                                        sessionId={
                                                            sessionLink.sessionId
                                                        }
                                                        sessionLinkId={
                                                            sessionLink.id
                                                        }
                                                        sessionName={
                                                            courseSessions?.find(
                                                                (s) =>
                                                                    s.id ==
                                                                    sessionLink.sessionId
                                                            )?.sessionName || ""
                                                        }
                                                    />
                                                ) : (
                                                    <span className="me-4 text-light-notification font-weight-600">
                                                        Deactivate
                                                    </span>
                                                )}
                                                <span
                                                    className="me-4 text-link"
                                                    role="button"
                                                    onClick={(_) =>
                                                        setSelectedSessionLinkId(
                                                            sessionLink.id
                                                        )
                                                    }
                                                >
                                                    View
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(LearningMaterialSessionAttendances as any) as React.FC<IProps>;

import "./EvaluationForm.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { Employee } from "../../models/Employee";
import { EvaluationFormData } from "../../models/EvaluationFormData";
import { EditSignature } from "../../components/shared/EditSignature";
import { CheckboxRow } from "./CheckboxRow";
import { CourseDetails } from "../../models/CourseDetails";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../components/shared/Button";

export enum SignatureMode {
    Text,
    Draw,
}

export interface Signature {
    signatureText?: string;
    signatureBase64?: string;
}

interface Props {
    employee: Employee;
    course: CourseDetails;
    evaluationForm?: EvaluationFormData;
    onSubmit: (evaluationForm: EvaluationFormData) => Promise<void>;
}

export const EvaluationForm: React.FC<Props> = (props: Props) => {
    const { employee, course } = props;

    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitPressed, setIsSubmitPressed] = useState(false);
    const [questionTwoContent, setQuestionTwoContent] = useState(
        props.evaluationForm?.questionTwoContent ?? "EntirelyMet"
    );
    const [questionTwoContentComment, setQuestionTwoContentComment] = useState(
        props.evaluationForm?.questionTwoContentComment ?? ""
    );

    const [questionTwoDelivery, setQuestionTwoDelivery] = useState(
        props.evaluationForm?.questionTwoDelivery ?? "EntirelyMet"
    );
    const [questionTwoDeliveryComment, setQuestionTwoDeliveryComment] =
        useState(props.evaluationForm?.questionTwoDeliveryComment ?? "");

    const [questionTwoPresenter, setQuestionTwoPresenter] = useState(
        props.evaluationForm?.questionTwoPresenter ?? "EntirelyMet"
    );
    const [questionTwoPresenterComment, setQuestionTwoPresenterComment] =
        useState(props.evaluationForm?.questionTwoPresenterComment ?? "");

    const [questionTreeRecommendation, setQuestionTreeRecommendation] =
        useState(props.evaluationForm?.questionTreeRecommendation ?? "");

    const [questionFourSuggestion, setQuestionFourSuggestion] = useState(
        props.evaluationForm?.questionFourSuggestion ?? ""
    );

    const [isSignatureValid, setIsSignatureValid] = useState(false);

    const [dynamicQuestionAnswers, setDynamicQuestionAnswers] = useState(
        props.evaluationForm?.dynamicQuestions.map(
            (x) => x.answer ?? "EntirelyMet"
        ) ?? []
    );

    const [signatureMode, setSignatureMode] = useState(SignatureMode.Text);
    const [signatureText, setSignatureText] = useState<string>("");
    const signatureCanvas = useRef<SignatureCanvas>();
    const validateForm = useCallback(() => {
        let isValid = true;
        if (!questionTreeRecommendation) {
            isValid = false;
        }
        if (!questionFourSuggestion) {
            isValid = false;
        }
        if (!isSignatureValid) {
            isValid = false;
        }
        setIsFormValid(isValid);
    }, [isSignatureValid, questionFourSuggestion, questionTreeRecommendation]);

    useEffect(() => {
        validateForm();
    }, [questionTreeRecommendation, questionFourSuggestion, isSignatureValid, validateForm]);

    const submitForm = async (
        signatureBase64: string,
        signatureText: string
    ) => {
        await props.onSubmit({
            questionTwoContent,
            questionTwoContentComment,
            questionTwoDelivery,
            questionTwoDeliveryComment,
            questionTwoPresenter,
            questionTwoPresenterComment,
            questionTreeRecommendation,
            questionFourSuggestion,
            signatureBase64,
            signatureText,
            dynamicQuestions: props.evaluationForm?.dynamicQuestions.map(
                (x, index) => {
                    return { ...x, answer: dynamicQuestionAnswers[index] };
                }
            ),
        });
    };

    return (
        <React.Fragment>
            {employee && course && (
                <Row>
                    <Col xs="12">
                        <Row>
                            <h4 className="text-center font-weight-bold">
                                2023-25 CPD program Evaluation Form
                            </h4>
                            <h4 className="text-center font-weight-bold mt-3 mb-0">
                                {course.title}
                            </h4>
                            <span className="text-center">
                                (Activity No. {course.activityId}, allocated{" "}
                                {course.cpd} CPD hours in the RACGP2023-25 CPD
                                Program)
                            </span>
                        </Row>
                        <Row className="mt-5">
                            <Col md={6}>
                                <strong>Name:</strong> {employee?.firstName}{" "}
                                {employee?.lastName}
                            </Col>
                            <Col md={6}>
                                <strong>RACGP No.:</strong>{" "}
                                {employee?.racgpNumber}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={12}>
                                <p className="question">
                                    (Participant responses are optional and/or
                                    anonymous)
                                </p>
                                <p className="question">
                                    Q1: Please rate to what degree the learning
                                    outcomes of the program were met:
                                </p>
                            </Col>
                        </Row>

                        <div className="questions-table">
                            <Row>
                                <Col xs={12} md={6}>
                                    At the end of this CPD activity, GP
                                    participants will be able to: <br />
                                    (Please list all your learning outcomes
                                    below, add more rows as required)
                                </Col>
                                <Col
                                    md={2}
                                    className="hidden lg:flex text-center justify-content-center align-items-center"
                                >
                                    Not met
                                </Col>
                                <Col
                                    md={2}
                                    className="hidden lg:flex text-center justify-content-center align-items-center"
                                >
                                    Partially met
                                </Col>
                                <Col
                                    md={2}
                                    className="hidden lg:flex text-center justify-content-center align-items-center"
                                >
                                    Entirely met
                                </Col>
                            </Row>
                            {props.evaluationForm?.dynamicQuestions.map(
                                (i, index) => {
                                    return (
                                        <CheckboxRow
                                            key={i.id}
                                            question={
                                                <span>{i.questionText}</span>
                                            }
                                            option1={
                                                <input
                                                    type={"radio"}
                                                    value="NotMet"
                                                    name={`t1l${index}`}
                                                    onChange={() =>
                                                        setDynamicQuestionAnswers(
                                                            dynamicQuestionAnswers.map(
                                                                (q, qIndex) =>
                                                                    qIndex ===
                                                                        index
                                                                        ? "NotMet"
                                                                        : q
                                                            )
                                                        )
                                                    }
                                                    checked={
                                                        dynamicQuestionAnswers[
                                                        index
                                                        ] === "NotMet"
                                                    }
                                                />
                                            }
                                            option2={
                                                <input
                                                    type={"radio"}
                                                    value="PartiallyMet"
                                                    name={`t1l${index}`}
                                                    onChange={() =>
                                                        setDynamicQuestionAnswers(
                                                            dynamicQuestionAnswers.map(
                                                                (q, qIndex) =>
                                                                    qIndex ===
                                                                        index
                                                                        ? "PartiallyMet"
                                                                        : q
                                                            )
                                                        )
                                                    }
                                                    checked={
                                                        dynamicQuestionAnswers[
                                                        index
                                                        ] === "PartiallyMet"
                                                    }
                                                />
                                            }
                                            option3={
                                                <input
                                                    type={"radio"}
                                                    value="EntirelyMet"
                                                    name={`t1l${index}`}
                                                    onChange={() =>
                                                        setDynamicQuestionAnswers(
                                                            dynamicQuestionAnswers.map(
                                                                (q, qIndex) =>
                                                                    qIndex ===
                                                                        index
                                                                        ? "EntirelyMet"
                                                                        : q
                                                            )
                                                        )
                                                    }
                                                    checked={
                                                        dynamicQuestionAnswers[
                                                        index
                                                        ] === "EntirelyMet"
                                                    }
                                                />
                                            }
                                        />
                                    );
                                }
                            )}
                        </div>

                        <Row className="mt-5 mb-3 question">
                            <Col xs={12}>
                                Q2: Please rate to what degree this CPD activity met
                                your expectation about:
                            </Col>
                        </Row>

                        <div className="questions-table">
                            <Row className="hidden lg:flex text-center">
                                <Col xs={6}></Col>
                                <Col xs={2}>Not met</Col>
                                <Col xs={2}>Partially met</Col>
                                <Col xs={2}>Entirely met</Col>
                            </Row>

                            <CheckboxRow
                                question={
                                    <React.Fragment>
                                        <span className="font-weight-bold">
                                            Content
                                        </span>
                                        <br />
                                        Current, contemporary, evidence-based,
                                        and relevant to general practice
                                    </React.Fragment>
                                }
                                option1={
                                    <input
                                        type={"radio"}
                                        value="NotMet"
                                        name="t2l1"
                                        onChange={() =>
                                            setQuestionTwoContent("NotMet")
                                        }
                                        checked={
                                            questionTwoContent === "NotMet"
                                        }
                                    />
                                }
                                option2={
                                    <input
                                        type={"radio"}
                                        value="PartiallyMet"
                                        name="t2l1"
                                        onChange={() =>
                                            setQuestionTwoContent(
                                                "PartiallyMet"
                                            )
                                        }
                                        checked={
                                            questionTwoContent ===
                                            "PartiallyMet"
                                        }
                                    />
                                }
                                option3={
                                    <input
                                        type={"radio"}
                                        value="EntirelyMet"
                                        name="t2l1"
                                        onChange={() =>
                                            setQuestionTwoContent("EntirelyMet")
                                        }
                                        checked={
                                            questionTwoContent === "EntirelyMet"
                                        }
                                    />
                                }
                            />

                            <Row>
                                <Col md={2} className="comments-column">
                                    <span className="font-weight-bold">
                                        Comments:
                                    </span>
                                    <br />
                                </Col>
                                <Col md={10}>
                                    <input
                                        className="form-control"
                                        value={questionTwoContentComment}
                                        onChange={(e) =>
                                            setQuestionTwoContentComment(
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>

                            <CheckboxRow
                                question={
                                    <React.Fragment>
                                        <span className="font-weight-bold">
                                            Delivery
                                        </span>
                                        <br />
                                        Engaging/interactive, eg with
                                        opportunity for questions and feedback
                                    </React.Fragment>
                                }
                                option1={
                                    <input
                                        type={"radio"}
                                        value="NotMet"
                                        name="t2l2"
                                        onChange={() =>
                                            setQuestionTwoDelivery("NotMet")
                                        }
                                        checked={
                                            questionTwoDelivery === "NotMet"
                                        }
                                    />
                                }
                                option2={
                                    <input
                                        type={"radio"}
                                        value="PartiallyMet"
                                        name="t2l2"
                                        onChange={() =>
                                            setQuestionTwoDelivery(
                                                "PartiallyMet"
                                            )
                                        }
                                        checked={
                                            questionTwoDelivery ===
                                            "PartiallyMet"
                                        }
                                    />
                                }
                                option3={
                                    <input
                                        type={"radio"}
                                        value="EntirelyMet"
                                        name="t2l2"
                                        onChange={() =>
                                            setQuestionTwoDelivery(
                                                "EntirelyMet"
                                            )
                                        }
                                        checked={
                                            questionTwoDelivery ===
                                            "EntirelyMet"
                                        }
                                    />
                                }
                            />

                            <Row>
                                <Col md={2} className="comments-column">
                                    <span className="font-weight-bold">
                                        Comments:
                                    </span>
                                    <br />
                                </Col>
                                <Col md={10}>
                                    <input
                                        className="form-control"
                                        value={questionTwoDeliveryComment}
                                        onChange={(e) =>
                                            setQuestionTwoDeliveryComment(
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>

                            <CheckboxRow
                                question={
                                    <React.Fragment>
                                        <span className="font-weight-bold">
                                            For live: Presenters/Facilitators
                                        </span>
                                        <br />
                                        Engaging, qualified/skilled and
                                        knowledgeable
                                        <br />
                                        <br />
                                        <span className="font-weight-bold">
                                            For eLearning:
                                        </span>
                                        &nbsp; Learning Management System is
                                        user friendly and easily navigated to
                                        achieve Learning Outcomes.
                                    </React.Fragment>
                                }
                                option1={
                                    <input
                                        type={"radio"}
                                        value="NotMet"
                                        name="t2l3"
                                        onChange={() =>
                                            setQuestionTwoPresenter("NotMet")
                                        }
                                        checked={
                                            questionTwoPresenter == "NotMet"
                                        }
                                    />
                                }
                                option2={
                                    <input
                                        type={"radio"}
                                        value="PartiallyMet"
                                        name="t2l3"
                                        onChange={() =>
                                            setQuestionTwoPresenter(
                                                "PartiallyMet"
                                            )
                                        }
                                        checked={
                                            questionTwoPresenter ===
                                            "PartiallyMet"
                                        }
                                    />
                                }
                                option3={
                                    <input
                                        type={"radio"}
                                        value="EntirelyMet"
                                        name="t2l3"
                                        onChange={() =>
                                            setQuestionTwoPresenter(
                                                "EntirelyMet"
                                            )
                                        }
                                        checked={
                                            questionTwoPresenter ===
                                            "EntirelyMet"
                                        }
                                    />
                                }
                            />

                            <Row>
                                <Col md={2} className="comments-column">
                                    <span className="font-weight-bold">
                                        Comments:
                                    </span>
                                    <br />
                                </Col>
                                <Col md={10}>
                                    <input
                                        className="form-control"
                                        value={questionTwoPresenterComment}
                                        onChange={(e) =>
                                            setQuestionTwoPresenterComment(
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>

                        <Row className="mt-5 mb-3 question">
                            Q3:
                            <br />
                            Would you likely recommend this CPD activity to a
                            colleague? Yes/No Why?
                        </Row>
                        <Row>
                            <Col md={12}>
                                <textarea
                                    className={`form-control ${!questionTreeRecommendation &&
                                        isSubmitPressed
                                        ? "answer-required"
                                        : ""
                                        }`}
                                    value={questionTreeRecommendation}
                                    onChange={(e) =>
                                        setQuestionTreeRecommendation(
                                            e.target.value
                                        )
                                    }
                                />
                                {!questionTreeRecommendation &&
                                    isSubmitPressed && (
                                        <span className="invalid-input">
                                            This is a required field
                                        </span>
                                    )}
                            </Col>
                        </Row>

                        <Row className="mt-5 mb-3 question">
                            Q4:
                            <br />
                            Would you likely change anything in your practice as
                            a result of this CPD activity? Yes/No Why?
                        </Row>
                        <Row>
                            <Col md={12}>
                                <textarea
                                    className={`form-control ${!questionFourSuggestion &&
                                        isSubmitPressed
                                        ? "answer-required"
                                        : ""
                                        }`}
                                    value={questionFourSuggestion}
                                    onChange={(e) =>
                                        setQuestionFourSuggestion(
                                            e.target.value
                                        )
                                    }
                                />
                                {!questionFourSuggestion && isSubmitPressed && (
                                    <span className="invalid-input">
                                        This is a required field
                                    </span>
                                )}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            Signature
                            <EditSignature
                                mode={signatureMode}
                                setMode={setSignatureMode}
                                canvas={signatureCanvas}
                                text={signatureText}
                                setText={setSignatureText}
                                isSubmitPressed={isSubmitPressed}
                                setIsSignatureValid={(isValid) => {
                                    setIsSignatureValid(isValid);
                                }}
                                isSignatureValid={isSignatureValid}
                            />
                            <Button
                                className="btn btn-primary evaluation-form-button ms-auto"
                                variant="primary"
                                disabled={isSubmitPressed && !isFormValid}
                                onClick={() => {
                                    setIsSubmitPressed(true);
                                    if (isFormValid) {
                                        if (
                                            signatureMode === SignatureMode.Text
                                        ) {
                                            submitForm(null, signatureText);
                                        } else {
                                            submitForm(
                                                signatureCanvas.current.toDataURL(),
                                                null
                                            );
                                        }
                                    }
                                }}
                            >
                                SUBMIT
                            </Button>
                        </Row>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

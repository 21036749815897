import React, { MutableRefObject } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';

import { AdvancedSelect, Select } from 'components/form';
import Button from 'components/shared/Button';
import { useDebounce } from 'hooks';
import { CourseSessionLink } from 'models/CourseSessionLink';
import { ODataPageResult } from 'common/ODataPageResult';
import { required } from 'validate';
import { UsersPageListItem } from 'models/UsersPageListItem';

type Props = {
    logUserAttendance(values: LogUserAttendanceFormValues): unknown;
    courseSessionLinks: CourseSessionLink[];
    allUsersPage: ODataPageResult<UsersPageListItem>;
    searchUsers(name: string): unknown;
    formRef?: MutableRefObject<HTMLFormElement>;
    onCancel?();
}

export type LogUserAttendanceFormValues = {
    sessionLinkId: number,
    employeeId: number
}

export const LogUserAttendanceForm = ({
    logUserAttendance,
    courseSessionLinks,
    formRef,
    onCancel,
    allUsersPage,
    searchUsers
}: Props) => {

    const {
        register,
        control,
        handleSubmit: innerSubmit,
        formState: { errors },
        reset
    } = useForm<LogUserAttendanceFormValues>();

    const debouncedSearch = useDebounce(searchUsers, 500, [searchUsers])

    return <form
        ref={formRef}
        onSubmit={innerSubmit(logUserAttendance)}
    >
        <div className="p-3">
            <Row>
                <Col>
                    <Select
                        label="Session name"
                        id="SESSION"
                        placeholder='Select a session'
                        {...register("sessionLinkId", { validate: required(), value: null, valueAsNumber: true })}
                        fieldError={errors.sessionLinkId}
                    >
                        {courseSessionLinks.map(
                            (s) => (
                                <option
                                    key={s.id}
                                    value={s.id}
                                >
                                    {s.sessionLinkName}
                                </option>
                            )
                        )}
                    </Select>
                </Col>
            </Row>

            <Row>
                <Col>
                    <AdvancedSelect
                        menuPosition="fixed"
                        id="employeeId"
                        placeholder="Select a user"
                        label="Name of user"
                        onInputChange={
                            debouncedSearch
                        }
                        isSearchable={true}
                        getOptionLabel={(p) =>
                            `${p.firstName} ${p.lastName}`
                        }
                        getOptionValue={(p) =>
                            p?.id
                        }
                        options={allUsersPage?.value.map(
                            (u) => ({
                                id: u.employeeId,
                                firstName:
                                    u.firstName,
                                lastName:
                                    u.lastName,
                            })
                        )}
                        name="employeeId"
                        control={control}
                        fieldError={errors.employeeId}
                    />
                </Col>
            </Row>

            <Row className="mt-4">
                {onCancel &&
                    <Col>
                        <Button
                            className='w-full'
                            variant="secondary"
                            onClick={() => {
                                reset();
                                onCancel();
                            }}
                        >
                            Cancel
                        </Button>
                    </Col>
                }
                <Col>
                    <Button
                        className='w-full'
                        type="submit"
                    >
                        Log user
                    </Button>
                </Col>
            </Row>
        </div>
    </form>
}
import React from "react";
import { FaceToFaceEventsTab } from "../../models/enums/FaceToFaceEventsTab";
import { ResetFiltersButton, SearchBar } from "./styles/FaceToFaceEvents";
import { Col, Row, TabContent, TabPane } from "reactstrap";
import LearningMaterialCard from "../learning-materials/LearningMaterialCard";
import { CourseOrEvent } from "../../models/enums/CourseOrEvent";
import { CourseEvent } from "../../models/CourseEvent";
import { FilterDropDown } from "../../components/shared/FilterDropDown";

const getSortTitle = (sort: string) => {
    switch (sort) {
        case "dateAdded":
            return "Date Added";
        case "title":
            return "Title";
        default:
            return "Date Added";
    }
};

interface FaceToFaceEventsTabContentProps {
    tab: FaceToFaceEventsTab;
    handleSearchChange: (e: any) => void;
    handleSortChange: (sort: string) => void;
    sort: string;
    events: CourseEvent[];
    resetFilters: () => void;
    searchInputRef: any;
    requestCountLoader: number;
    isAdminUser: boolean;
}

export const FaceToFaceEventsTabContent = (
    props: FaceToFaceEventsTabContentProps
) => {
    const {
        tab,
        handleSearchChange,
        handleSortChange,
        sort,
        events,
        resetFilters,
        searchInputRef,
        requestCountLoader,
        isAdminUser,
    } = props;

    const renderSearch = () => {
        return (
            <SearchBar
                width="35%"
                type="text"
                placeholder="Search"
                marginLeft="0"
                onChange={handleSearchChange}
                ref={searchInputRef}
            />
        );
    };

    const renderFilters = () => {
        return (
            <div className="block lg:flex mb-3 mb-lg-0">
                <FilterDropDown
                    className="sort-by-dropdown me-3"
                    options={["dateAdded", "title"]}
                    onSelected={handleSortChange}
                    selectedValue={sort || "dateAdded"}
                    labelSelector={getSortTitle}
                    dropdownHeader="Order by"
                />
            </div>
        );
    };

    return (
        <React.Fragment>
            <TabContent activeTab={tab}>
                <TabPane tabId={tab} className="p-3">
                    <Row>
                        <Col sm="12">
                            <div className="hidden sm:flex justify-between">
                                {renderSearch()}
                                {renderFilters()}
                            </div>

                            <div className="block sm:hidden">
                                <div className="flex flex-col">
                                    {renderSearch()}
                                    {renderFilters()}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="flex flex-wrap learning-materials-container justify-content-start">
                            {events.map((item, index) => (
                                <LearningMaterialCard
                                    key={index}
                                    expandImage={true}
                                    type={CourseOrEvent.Event}
                                    event={item}
                                    startCourse={() => { }}
                                    employee={null}
                                    markCourseAsSubmitted={() => { }}
                                    courseCompletionQrCode={""}
                                    fetchCourseCompletionFormQrCode={() => { }}
                                    hideCompletitionDate={true}
                                    isAdminUser={isAdminUser}
                                    className="max-w-sm"
                                />
                            ))}
                            {(!events || !events.length) &&
                                requestCountLoader === 0 && (
                                    <div className="w-full text-center mt-5">
                                        {tab === FaceToFaceEventsTab.Upcoming
                                            ? "Upcoming"
                                            : "Past"}{" "}
                                        events will appear here.{" "}
                                        <ResetFiltersButton
                                            onClick={resetFilters}
                                        >
                                            Reset filter
                                        </ResetFiltersButton>
                                    </div>
                                )}
                        </div>
                    </Row>
                </TabPane>
            </TabContent>
        </React.Fragment>
    );
};

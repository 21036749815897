import "../styles/loaderSpinner.scss";
import * as React from "react";
import Header from "../components/shared/Header";
import { connect } from "react-redux";
import * as dashboard from "../store/dashboardStore";
import { IApplicationState } from "../store";
import SessionManager from "../core/session";
import { isAdmin } from "../models/admin/UserRoles";
import { TailSpin } from "react-loader-spinner";

interface IProps {
    children?: React.ReactNode;
}

type Props = IProps &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const AuthorizedLayout: React.FC<Props> = (props: Props) => {
    const {
        currentEmployee,
        fetchEmployee,
        fetchEmployeeRoles,
        requestCountLoader,
    } = props;

    React.useEffect(() => {
        const user = SessionManager.getUser();
        if (currentEmployee === null && user && user.userName && user.email) {
            fetchEmployee(user.email);
        }
    }, [currentEmployee, fetchEmployee]);

    React.useEffect(() => {
        const user = SessionManager.getUser();
        if (currentEmployee != null && user && user.userName && user.email) {
            fetchEmployeeRoles();
        }
    }, [currentEmployee, fetchEmployeeRoles]);

    return (
        <React.Fragment>
            <TailSpin
                height="80"
                width="80"
                color="var(--primaryButtonsLinksColor)"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperClass="tail-spinner"
                visible={requestCountLoader > 0}
            />
            <div className="h-100 flex flex-col">
                <Header
                    hideSearchBar={currentEmployee && !isAdmin(currentEmployee.userRoles?.role)}
                    hideAcademyLogo
                />
                {props.children}
            </div>
        </React.Fragment>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(AuthorizedLayout as any);

import { UserCourseCompletionStatus } from "models/enums/UserCourseCompletionStatus";
import { CompletionStatus as ScormCompletionStatus } from "../types";

export function mapCompletionStatus(
    e: UserCourseCompletionStatus
): ScormCompletionStatus {
    switch (e) {
        case UserCourseCompletionStatus.Completed:
            return "completed";
        case UserCourseCompletionStatus.Incomplete:
            return "incomplete";
        case UserCourseCompletionStatus.NotAttempted:
            return "not attempted";
        default:
            return "unknown";
    }
}

import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router";

export function useQuery<T extends Record<string, string>>(
    defaultValues?: Partial<T>
): [T, (newValues: Partial<T>) => void] {
    const { search: queryString, pathname } = useLocation();
    const history = useHistory();

    const query = React.useMemo(() => {
        const urlParams = new URLSearchParams(queryString);
        const entries = urlParams.entries();
        const result = {};
        for (const [key, value] of entries) {
            result[key] = value;
        }
        return { ...defaultValues, ...result };
    }, [queryString, defaultValues]);

    const setQueryValue = useCallback(
        (newValues: Partial<T>) => {
            const urlParams = new URLSearchParams(queryString);
            for (const [key, value] of Object.entries(newValues)) {
                if (value) {
                    urlParams.set(
                        key,
                        typeof value === "string"
                            ? value
                            : JSON.stringify(value)
                    );
                } else {
                    urlParams.delete(key);
                }
            }

            history.replace({ pathname, search: urlParams.toString() });
        },
        [history, pathname, queryString]
    );

    return [query as T, setQueryValue];
}

import React from 'react'
import {
  EventCard1,
  EventCard1BackgroundWrapper,
  EventCard2BackgroundWrapper,
  EventCard3BackgroundWrapper,
  EventCard4BackgroundWrapper,
  EventCard2,
  EventCard3,
  EventCard4,
  EventGridRow,
  EventGridSection,
  EventGridSectionContainer,
  EventCardWrapper,
  EventCardContent,
  EventCardTextContainer,
  EventCardDateText,
  EventCardTitleText,
  EventLocationAddressContainer,
  EventLocationPinContainer,
  EventLocationAddressButton,
  EventCardInfoTitleText,
  EventCardDescriptionText,
  SvgContainer,
  EventCardLeftWrapper,
  EventCardRightWrapper,
  EventCardLeftContent,
  EventCardRightContent,
} from './styles/EventGrid'
import { CourseEvent } from '../../models/CourseEvent'
import { ReactComponent as Card1Background } from '../../images/landing-page/card_1_background.svg'
import { ReactComponent as Card2Background } from '../../images/landing-page/card_2_background.svg'
import { ReactComponent as Card3Background } from '../../images/landing-page/card_3_background.svg'
import { ReactComponent as Card4Background } from '../../images/landing-page/card_4_background.svg'
import IconMarker from '../../images/marker.svg'
import Button from '../shared/Button'
import moment from 'moment'

interface EventGridProps {
  events: CourseEvent[]
}

const EventGrid = (props: EventGridProps) => {
  const { events } = props

  const getDate = (courseEvent: CourseEvent) => {
    const day = moment(courseEvent.startDate).format('MMMM DD YYYY')

    return `${day},`
  }

  const getTime = (courseEvent: CourseEvent) => {
    const startTime = moment(courseEvent.startDate).format('h:mm a')
    const endTime = moment(courseEvent.endDate).format('h:mm a')

    return `${startTime}-${endTime}`
  }

  const renderEventGridRow1 = (events: CourseEvent[]) => {
    if (events.length == 0) {
      return null
    }

    // if (events.length === 1) {
    //   return (
    //     <>
    //       <EventGridRow>
    //         {renderEventCard3(events[0])}
    //       </EventGridRow>
    //     </>
    //   )
    // }

    return (
      <>
        <EventGridRow>
          {renderEventCard1()}
          {renderEventCard2(events[0])}
        </EventGridRow>
      </>
    )
  }

  const renderEventGridRow2 = (events: CourseEvent[]) => {
    if (events.length < 3) {
      return null
    }

    return (
      <>
        <EventGridRow>
          {renderEventCard3(events[1])}
          {renderEventCard4(events[2])}
        </EventGridRow>
      </>
    )
  }

  const renderEventCardBase = (
    event: CourseEvent,
    isSecondary: boolean = false,
  ) => {
    const lat = +event.locationLat
    const lng = +event.locationLong

    return (
      <EventCardRightWrapper>
        <EventCardRightContent>
          <EventCardTextContainer>
            <EventCardDateText secondary={isSecondary}>
              <>{getDate(event)}</>
              <br />
              <>{getTime(event)}</>
            </EventCardDateText>
            <EventCardTitleText secondary={isSecondary}>
              {event.title}
            </EventCardTitleText>
            <EventLocationAddressContainer>
              <EventLocationPinContainer src={IconMarker} />
              <EventLocationAddressButton
                secondary={isSecondary}
                href={`https://maps.google.com/?q=${lat},${lng}`}
                target="_blank"
              >
                {event.locationVenue}
              </EventLocationAddressButton>
            </EventLocationAddressContainer>
          </EventCardTextContainer>
          <Button variant="secondary" to={`/event/${event.id}`} shouldNotExpand>
            Find out more
          </Button>
        </EventCardRightContent>
      </EventCardRightWrapper>
    )
  }

  const renderEventCard1 = (event?: CourseEvent) => {
    if (event) {
      return (
        <EventCard1>
          <EventCard1BackgroundWrapper>
            <Card1Background />
          </EventCard1BackgroundWrapper>
          {renderEventCardBase(event)}
        </EventCard1>
      )
    }

    return (
      <EventCard1>
        <EventCard1BackgroundWrapper>
          <Card1Background />
        </EventCard1BackgroundWrapper>
        <EventCardLeftWrapper>
          <EventCardLeftContent>
            <EventCardTextContainer>
              <EventCardInfoTitleText>
                Conferences and Events
              </EventCardInfoTitleText>
              <EventCardDescriptionText>
                Register for Myhealth Academy for access to a range of
                conferences and events across different topics, areas of
                interests and specialisations to support your CPD.
              </EventCardDescriptionText>
            </EventCardTextContainer>
            {/* <Button variant="secondary" to={'/event/1'} shouldNotExpand>
                View all
              </Button> */}
          </EventCardLeftContent>
        </EventCardLeftWrapper>
      </EventCard1>
    )
  }

  const renderEventCard2 = (event: CourseEvent) => {
    return (
      <EventCard2 backgroundImage={Card2Background}>
        <EventCard2BackgroundWrapper>
          <Card2Background />
        </EventCard2BackgroundWrapper>
        {renderEventCardBase(event)}
      </EventCard2>
    )
  }

  const renderEventCard3 = (event: CourseEvent) => {
    return (
      <EventCard3>
        <EventCard3BackgroundWrapper>
          <Card3Background />
        </EventCard3BackgroundWrapper>
        {renderEventCardBase(event)}
      </EventCard3>
    )
  }

  const renderEventCard4 = (event: CourseEvent) => {
    return (
      <EventCard4>
        <EventCard4BackgroundWrapper>
          <Card4Background />
        </EventCard4BackgroundWrapper>
        {renderEventCardBase(event, true)}
      </EventCard4>
    )
  }

  return (
    <>
      <EventGridSection>
        <EventGridSectionContainer>
          {renderEventGridRow1(events)}
          {renderEventGridRow2(events)}
        </EventGridSectionContainer>
      </EventGridSection>
    </>
  )
}

export default EventGrid

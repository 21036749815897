import { DependencyList, useEffect } from "react";
import { Cancelable, debounce } from "lodash";

export type UseDebounceReturn = [() => boolean | null, () => void];

export function useDebounce<T extends Function>(
    fn: T,
    ms: number = 0,
    deps: DependencyList = []
): T & Cancelable {
    const debouncedFn = debounce(fn as any, ms);

    useEffect(() => {
        return () => debouncedFn.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return debouncedFn;
}

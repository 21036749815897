import * as React from "react";
import {
    UncontrolledAlert, Alert
} from "reactstrap";

export default class ErrorHandler extends React.Component<React.PropsWithChildren<{}>, {hasError: boolean}> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // TODO: error service
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div className="container p-5">
                <Alert color={"danger"} isOpen toggle={e => this.setState({ hasError: false })}>
                            <div className="alert-message">
                                <strong>An error has occurred.</strong> We apologize for the inconvinience it may have caused.
                            </div>
                        </Alert>
                    </div>
        }

        return this.props.children;
    }
}
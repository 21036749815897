import React from "react";
import {
  FileCell,
  FileCellColumn,
  FileNameLabel,
  FileSizeLabel,
} from "./styles/DocumentFileCell";
import { getDocumentIcon, formatBytes } from "../helper/DocumentHelper";
import { DropDownIcon } from "./styles/Common";
import iconClose from "../../images/icon-close.svg";

export interface DocumentFileCellProps {
  file: any;
  onRemoveFile: (file: any) => void;
}

const DocumentFileCell = (props: DocumentFileCellProps) => {
  const { file, onRemoveFile } = props;

  return (
    <FileCell key={file.name}>
      <DropDownIcon size={"32px"} src={getDocumentIcon(file.name)} />
      <FileCellColumn>
        <FileNameLabel>{file.name}</FileNameLabel>
        <FileSizeLabel>{`${formatBytes(file.size)}`}</FileSizeLabel>
      </FileCellColumn>
      <div style={{ marginLeft: "auto", marginRight: 5, marginTop: -10 }}>
        <DropDownIcon
          role="button"
          size={"16px"}
          src={iconClose}
          onClick={() => {
            onRemoveFile(file);
          }}
        />
      </div>
    </FileCell>
  );
};

export default DocumentFileCell;

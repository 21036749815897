import * as React from "react";
import { Route, RouteProps, Redirect } from "react-router";
import SessionManager from "../../core/session";
import { connect } from "react-redux";
import * as dashboard from "../../store/dashboardStore";
import { IApplicationState } from "../../store";

export interface IProps extends RouteProps {
    layout: React.ComponentClass<any> | React.FC<any>;
    layoutProps?: any;
}

type Props = IProps & dashboard.DashboardState;

const AppRoute: React.FC<Props> =
    ({ component: Component, layout: Layout, path: Path, currentEmployee, location, ...rest }: Props) => {
        const isAnonymousPath = typeof Path === "string" && (
            (Path as string).startsWith("/home") ||
            (Path as string).startsWith("/privacy-policy") ||
            (Path as string).startsWith("/auth/") ||
            (Path as string).startsWith("/evaluation-form") ||
            (Path as string).startsWith("/event/") ||
            (Path as string).startsWith("/event-group/")
        );

        if (!SessionManager.isAuthenticated && (
            (Path as string) === "" ||
            (Path as string) === "/"
        )) {
            return <Redirect to="/home" />;
        }

        if (!SessionManager.isAuthenticated && !isAnonymousPath) {
            return <Redirect to={{ pathname: '/auth/signin', state: { from: location } }} />;
        }

        if (currentEmployee?.professionType == 'Nurse' && !currentEmployee?.ahpraNumber && !location?.pathname.startsWith('/auth/signup/finalize')) {
            return <Redirect to="/auth/signup/finalize" />;
        }

        // TODO: change how to manage paths and redirects
        if (SessionManager.isAuthenticated
            && isAnonymousPath
            && !(Path as string).startsWith("/evaluation-form")
            && !(Path as string).startsWith("/auth/signup/finalize")
            && !(Path as string).startsWith("/home")
            && !(Path as string).startsWith("/event/")
            && !(Path as string).startsWith("/event-group/")
        ) {
            return <Redirect to={(location?.state as any)?.from != null ? (location.state as any).from : "/"} />;
        }

        return <Route {...rest} render={props => (
            <Layout {...rest.layoutProps}>
                <Component {...props} />
            </Layout>
        )} />;
    };

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(AppRoute as any) as React.FC<IProps>;

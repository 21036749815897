
import React from "react";
import { ToastContainer as TToastContainer, TypeOptions } from "react-toastify";
import classNames from "classnames";

const allToastClassNames = 'p-3 rounded-lg shadow';

const toastThemedClassNames: Partial<Record<TypeOptions, string>> = {
    success: 'bg-success text-white',
    error: 'bg-error text-white',
    info: 'bg-white-700 text-dark'
}

function toastClassName({
    type,
    defaultClassName,
}: {
    type?: TypeOptions;
    defaultClassName?: string;
}) {
    return classNames(defaultClassName, allToastClassNames, toastThemedClassNames[type]);
}

export const ToastContainer = () => <TToastContainer
    autoClose={3500}
    draggable={false}
    newestOnTop={true}
    position="bottom-right"
    toastClassName={toastClassName}
    hideProgressBar
    icon={false}
    closeOnClick={false}
/>;
import styled from "styled-components";

export const FileCell = styled.div`
    height: 56px;
    border: solid 1px var(--lightGrey);
    margin-top: 10px;
    align-items: center;
    justify-items: center;
    display: flex;
    padding-left: 10px;
`;

export const FileNameLabel = styled.div`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
`;

export const FileSizeLabel = styled.div`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--darkGrey);
`;

export const FileCellColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

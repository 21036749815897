import { useEffect, useMemo, useState } from "react";
import { Scorm2004Api } from "../ScormApi2004";
import { mapCompletionStatus } from "../helpers/mapCompletionStatus";
import { CourseService } from "services/CourseService";
import { CourseDetails } from "models/CourseDetails";
import { Employee } from "models/Employee";

type ScormApiArgs = {
    courseDetails: CourseDetails;
    currentEmployee: Employee;
    onCourseCompleted?(): void;
};

export function useScormApi({
    courseDetails,
    currentEmployee,
    onCourseCompleted,
}: ScormApiArgs) {
    const [isLoaded, setLoaded] = useState(false);

    const cmi = useMemo(() => {
        if (!courseDetails || !currentEmployee) {
            setLoaded(false);
            return;
        }

        return new Scorm2004Api({
            completion_status: mapCompletionStatus(
                courseDetails.completionStatus
            ),
            learner_name: currentEmployee.fullName,
            learner_id: `${currentEmployee.id}`,
            location:
                courseDetails.lastSlideId && `/${courseDetails.lastSlideId}`,
            suspend_data: courseDetails.suspendData ?? "",
        });
    }, [courseDetails, currentEmployee]);

    useEffect(() => {
        if (!cmi || !courseDetails?.id) {
            setLoaded(false);
            return;
        }
        (window as any).API_1484_11 = cmi;
        cmi.SaveSuspendData = (data) => {
            CourseService.SuspendData(courseDetails.id, data);
        };
        cmi.SaveLocation = (path) => {
            const slideId = path.replaceAll(/\D/gi, "");
            if (slideId) {
                CourseService.SaveLastSlideReached(courseDetails.id, slideId);
            }
        };
        cmi.SaveInteraction = (interaction) => {
            CourseService.SaveState({
                answer: interaction.learner_response,
                courseId: courseDetails.id,
                questionId: Number.parseInt(interaction.id),
                questionText: interaction.description,
            });
        };
        cmi.SaveCompletionStatus = (status) => {
            if (status === "completed") {
                CourseService.SaveCompletionStatus(courseDetails.id);
                onCourseCompleted?.();
            }
        };
        setLoaded(true);
        return () => {
            (window as any).API_1484_11 = undefined;
            setLoaded(false);
        };
    }, [cmi, courseDetails?.id, onCourseCompleted]);

    return { cmi, isLoaded };
}

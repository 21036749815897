import * as React from 'react'
import './styles/Footer.scss'
import iconLogo from '../../images/myhealth-logo-footer.svg'
import iconFacebook from '../../images/footer/facebook.svg'
import iconLinkedin from '../../images/footer/linkedin.svg'
import iconInstagram from '../../images/footer/instagram.svg'
import iconFooterLogo from '../../images/footer/myhealth_logo.svg'
import { Link } from 'react-router-dom'
import {
  FooterBottomContainer,
  FooterBottomTextContainer,
  FooterCopyrightText,
  FooterHeaderContainer,
  FooterHeaderContainerContent,
  FooterHeaderContainerInnerContent,
  FooterHr,
  FooterLink,
  FooterLinkA,
  FooterLinksContainer,
  FooterLinksContainerContent,
  FooterLinksContainerContentInnerWrapper,
  FooterLinksContainerContentWrapper,
  FooterLogo,
  FooterLogoContainer,
  FooterLogoText,
  FooterSection,
  FooterSectionContainer,
  FooterSocialContainer,
  FooterSocialIcon,
  FooterSocialIconLink,
  FooterSocialIconsContainer,
} from './styles/Footer'
import { PaddingContainer } from '../landing-page/styles/General'

const Footer = () => (
  <>
    <FooterSection>
      <div className="container">
        <PaddingContainer>
          <FooterSectionContainer>
            <FooterHeaderContainer>
              <FooterHeaderContainerContent>
                <FooterHeaderContainerInnerContent>
                  <FooterLinksContainer>
                    <FooterLogoContainer>
                      <FooterLogoText>Powered by:</FooterLogoText>
                      <FooterLogo src={iconFooterLogo} />
                    </FooterLogoContainer>
                    <FooterLinksContainerContent>
                      <FooterLinksContainerContentWrapper>
                        <FooterLinksContainerContentInnerWrapper>
                          <FooterLink to="/privacy-policy">
                            Privacy Policy
                          </FooterLink>
                        </FooterLinksContainerContentInnerWrapper>
                      </FooterLinksContainerContentWrapper>
                      <FooterLinksContainerContentWrapper>
                        <FooterLinksContainerContentInnerWrapper>
                          <FooterLinkA href="mailto:CPD@myhealth.net.au">
                            Contact
                          </FooterLinkA>
                        </FooterLinksContainerContentInnerWrapper>
                      </FooterLinksContainerContentWrapper>
                    </FooterLinksContainerContent>
                  </FooterLinksContainer>
                </FooterHeaderContainerInnerContent>
              </FooterHeaderContainerContent>
            </FooterHeaderContainer>
            <FooterBottomContainer>
              <FooterBottomTextContainer>
                <FooterCopyrightText>
                  © 2024 Myhealth. All rights reserved.
                </FooterCopyrightText>
              </FooterBottomTextContainer>
              <FooterSocialContainer>
                <FooterSocialIconsContainer>
                  <FooterSocialIconLink
                    href="https://www.facebook.com/MyhealthMedical/"
                    target="_blank"
                  >
                    <FooterSocialIcon src={iconFacebook} />
                  </FooterSocialIconLink>
                  <FooterSocialIconLink
                    href="https://www.instagram.com/myhealthau/"
                    target="_blank"
                  >
                    <FooterSocialIcon src={iconInstagram} />
                  </FooterSocialIconLink>
                  <FooterSocialIconLink
                    href="https://au.linkedin.com/company/myhealth"
                    target="_blank"
                  >
                    <FooterSocialIcon src={iconLinkedin} />
                  </FooterSocialIconLink>
                </FooterSocialIconsContainer>
              </FooterSocialContainer>
            </FooterBottomContainer>
          </FooterSectionContainer>
        </PaddingContainer>
      </div>
    </FooterSection>
  </>
)
export default Footer

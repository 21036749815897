import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { KeyValuePair } from "../models/KeyValuePair";
import { ApiCall } from "../models/enums/ApiCall";
import { IAppThunkActionAsync, IAppThunkAction, IApplicationState } from ".";

// Declare an interface of the store's state.
export type LoadingState = KeyValuePair<ApiCall, string>[];

// Create the slice.
const slice = createSlice({
  name: "loading",
  initialState: [] as LoadingState,
  reducers: {
    setLoading: (
      state,
      action: PayloadAction<KeyValuePair<ApiCall, string>[]>
    ) => {
      return action.payload;
    },
  },
});

// Export reducer from the slice.
export const { reducer } = slice;

// Define action creators.
export const actionCreators = {
  addLoading:
    (guid: string, call: ApiCall) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const loading = [...getState().loading, { key: call, value: guid }];
      dispatch(slice.actions.setLoading(loading));
    },
  removeLoading:
    (guid: string) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const loading = getState().loading.filter((l) => l.value !== guid);
      dispatch(slice.actions.setLoading(loading));
    },
};

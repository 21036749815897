import React from 'react';
import classNames from 'classnames';

type DynamicTagProps<T extends keyof JSX.IntrinsicElements> = {
    as?: T | keyof JSX.IntrinsicElements;
    children?: React.ReactNode;
} & (React.ComponentPropsWithoutRef<T> & React.HTMLAttributes<HTMLOrSVGElement>);

const Shell = <T extends keyof JSX.IntrinsicElements = 'div',>({ className, as = 'div', ...props }: DynamicTagProps<T>) => {
    const Tag: keyof JSX.IntrinsicElements = as;

    return <Tag
        className={classNames("overflow-hidden rounded-lg bg-white border shadow", className)}
        {...props}
    />
}

const Body = <T extends keyof JSX.IntrinsicElements = 'div',>({ className, as = 'div', ...props }: DynamicTagProps<T>) => {
    const Tag: keyof JSX.IntrinsicElements = as;

    return <Tag className={classNames("p-4", className)} {...props} />;
}


const Card = <T extends keyof JSX.IntrinsicElements = 'div',>({ className, as = 'div', ...props }: DynamicTagProps<T>) => {
    const Tag: keyof JSX.IntrinsicElements = as;

    return <Shell className={className}><Body {...props} /></Shell>;
}

Card.Shell = Shell;
Card.Body = Body;

export { Card };
import "./styles/Dashboard.scss";
import * as dashboard from "../../store/dashboardStore";
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "reactstrap";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { RouteComponentProps, useHistory } from "react-router";
import { isAdmin } from "../../models/admin/UserRoles";
import { SortableColumnText, UserCourseSubmissionStatusCellValue, ViewResponsesLink } from "./styles/Dashboard";
import { SearchBar, SortIcon, } from "../../components/dashboard/styles/Common";
import { UserCourseSubmissionStatus } from "../../models/enums/UserCourseSubmissionStatus";
import { UserCourseSubmissionSort } from "../../models/enums/UserCourseSubmissionSort";
import { Link } from "react-router-dom";
import _ from "lodash";
import iconSort from "../../images/icon-filter-down.svg";
import { CourseCategoryFilterValue } from "../../common/CourseCategoryFilterValue";
import { UserCourseSubmissionDismissReason } from "../../models/enums/UserCourseSubmissionDismissReason";
import { FilterDropDown } from "../../components/shared/FilterDropDown";

const DateFormat = new Intl.DateTimeFormat("en-AU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
});

const getSubmissionStatusText = (
    status: UserCourseSubmissionStatus
): string => {
    switch (status) {
        case UserCourseSubmissionStatus.PendingApproval:
            return "Pending Approval";
        default:
            return UserCourseSubmissionStatus[status].toString();
    }
};

const getSubmissionDismissReasonText = (
    reason: UserCourseSubmissionDismissReason
): string => {
    switch (reason) {
        case UserCourseSubmissionDismissReason.InsufficientResponses:
            return "Insufficient Responses";
        case UserCourseSubmissionDismissReason.InsufficientSubmissionForm:
            return "Insufficient Submission Form";
        default:
            return UserCourseSubmissionDismissReason[reason].toString();
    }
};

const statusFilterItems: (UserCourseSubmissionStatus | null)[] = [null].concat(
    Object.keys(UserCourseSubmissionStatus)
        .filter((v) => isNaN(Number(v)))
        .map((x) => x)
);

type Props = RouteComponentProps<{}> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const Dashboard: React.FC<Props> = (props: Props) => {
    const history = useHistory();

    if (
        props.currentEmployee != null &&
        props.currentEmployee.userRoles != null &&
        !isAdmin(props.currentEmployee.userRoles.role)
    ) {
        history.push("/");
    }

    const {
        fetchUserCourseSubmissions,
        userCourseSubmissions,
        fetchAllCourses,
        allCourses,
    } = props;

    useEffect(() => {
        fetchAllCourses("", "", CourseCategoryFilterValue.All, null, null);
    }, [fetchAllCourses]);

    const [filter, setFilter] = useState("");
    const [courseFilter, setCourseFilter] = useState<number | null>(null);
    const [statusFilter, setStatusFilter] = useState<UserCourseSubmissionStatus | null>(UserCourseSubmissionStatus[UserCourseSubmissionStatus.PendingApproval] as any);
    const [sort, setSort] = useState(UserCourseSubmissionSort.Date);
    const [isAscendingSort, setIsAscendingSort] = useState(false);

    useEffect(() => {
        fetchUserCourseSubmissions(
            filter,
            courseFilter,
            statusFilter,
            sort,
            isAscendingSort
        );
    }, [
        filter,
        courseFilter,
        statusFilter,
        sort,
        isAscendingSort,
        fetchUserCourseSubmissions,
    ]);

    const debouncedResults = useMemo(() => {
        return _.debounce((e: string) => {
            setFilter(e);
        }, 500);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const courseFilterItems: (number | null)[] = [null].concat(
        allCourses.map((x) => x.id)
    );

    const updateSort = (sortColumn: UserCourseSubmissionSort) => {
        setSort(sortColumn);
        setIsAscendingSort(
            sortColumn === sort ? (isAscendingSort ? false : true) : true
        );
    };

    const pendingApprovalLength = userCourseSubmissions.filter(
        (x) =>
            (x.status as any) ===
            UserCourseSubmissionStatus[
                UserCourseSubmissionStatus.PendingApproval
            ].toString()
    ).length;

    return (
        <>
            <div className="flex flex-grow-1 flex-col overflow-hidden">
                <div className="p-3">
                    <div className="fs-3">
                        Welcome{" "}
                        {props.currentEmployee != null
                            ? props.currentEmployee.firstName
                            : ""}
                        ,
                    </div>
                    <div className="text-approval-count">
                        You have <strong>{pendingApprovalLength}</strong> course
                        attendance upload
                        {pendingApprovalLength !== 1 && "s"} that need your
                        approval
                    </div>
                </div>
                <div className="flex justify-between px-3 mb-3">
                    <SearchBar
                        className="mx-0"
                        placeholder="Search"
                        onChange={(e) => debouncedResults(e.target.value)}
                        style={{
                            backgroundPosition: "10px center",
                            width: "250px",
                            paddingLeft: "40px",
                            marginLeft: 0,
                            marginTop: "1px",
                            marginBottom: "1px",
                        }}
                    />
                    <div className="flex justify-between">
                        <FilterDropDown
                            className="me-3"
                            options={courseFilterItems}
                            onSelected={setCourseFilter}
                            selectedValue={courseFilter}
                            labelSelector={
                                courseFilterId => courseFilterId == null
                                    ? "All Courses"
                                    : allCourses.find(x => x.id === courseFilterId).title
                            }
                        />

                        <FilterDropDown
                            className="me-3"
                            options={statusFilterItems}
                            onSelected={x => setStatusFilter(
                                UserCourseSubmissionStatus[
                                UserCourseSubmissionStatus[x == null ? null : x.toString().replace(" ", "")]
                                ]
                            )}
                            selectedValue={statusFilter}
                            labelSelector={
                                statusFilter => statusFilter == null
                                    ? "All Statuses"
                                    : getSubmissionStatusText(
                                        UserCourseSubmissionStatus[statusFilter] as any
                                    )
                            }
                        />
                    </div>
                </div>
                <div className="p-0 flex-grow-1 overflow-auto approval-table">
                    <table className="table approval-table w-full">
                        <thead>
                            <tr className="p-2">
                                <th
                                    className="approval-table-cell"
                                    onClick={() =>
                                        updateSort(
                                            UserCourseSubmissionSort.Date
                                        )
                                    }
                                >
                                    <SortableColumnText
                                        isSorted={
                                            sort ===
                                            UserCourseSubmissionSort.Date
                                        }
                                    >
                                        Date
                                    </SortableColumnText>
                                    <SortIcon
                                        isAscending={isAscendingSort}
                                        isHidden={
                                            sort !==
                                            UserCourseSubmissionSort.Date
                                        }
                                        src={iconSort}
                                        alt="sort-icon"
                                    />
                                </th>
                                <th
                                    className="approval-table-cell"
                                    onClick={() =>
                                        updateSort(
                                            UserCourseSubmissionSort.DoctorName
                                        )
                                    }
                                >
                                    <SortableColumnText
                                        isSorted={
                                            sort ===
                                            UserCourseSubmissionSort.DoctorName
                                        }
                                    >
                                        Name
                                    </SortableColumnText>
                                    <SortIcon
                                        isAscending={isAscendingSort}
                                        isHidden={
                                            sort !==
                                            UserCourseSubmissionSort.DoctorName
                                        }
                                        src={iconSort}
                                        alt="sort-icon"
                                    />
                                </th>
                                <th
                                    className="approval-table-cell"
                                    onClick={() =>
                                        updateSort(
                                            UserCourseSubmissionSort.CourseName
                                        )
                                    }
                                >
                                    <SortableColumnText
                                        isSorted={
                                            sort ===
                                            UserCourseSubmissionSort.CourseName
                                        }
                                    >
                                        Course Name
                                    </SortableColumnText>
                                    <SortIcon
                                        isAscending={isAscendingSort}
                                        isHidden={
                                            sort !==
                                            UserCourseSubmissionSort.CourseName
                                        }
                                        src={iconSort}
                                        alt="sort-icon"
                                    />
                                </th>
                                <th
                                    className="approval-table-cell"
                                    onClick={() =>
                                        updateSort(
                                            UserCourseSubmissionSort.Status
                                        )
                                    }
                                >
                                    <SortableColumnText
                                        isSorted={
                                            sort ===
                                            UserCourseSubmissionSort.Status
                                        }
                                    >
                                        Status
                                    </SortableColumnText>
                                    <SortIcon
                                        isAscending={isAscendingSort}
                                        isHidden={
                                            sort !==
                                            UserCourseSubmissionSort.Status
                                        }
                                        src={iconSort}
                                        alt="sort-icon"
                                    />
                                </th>
                                <th
                                    className="approval-table-cell"
                                    onClick={() =>
                                        updateSort(
                                            UserCourseSubmissionSort.Reason
                                        )
                                    }
                                >
                                    <SortableColumnText
                                        isSorted={
                                            sort ===
                                            UserCourseSubmissionSort.Reason
                                        }
                                    >
                                        Reason
                                    </SortableColumnText>
                                    <SortIcon
                                        isAscending={isAscendingSort}
                                        isHidden={
                                            sort !==
                                            UserCourseSubmissionSort.Reason
                                        }
                                        src={iconSort}
                                        alt="sort-icon"
                                    />
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {userCourseSubmissions.map((submission, index) => (
                                <tr key={index} className="p-2">
                                    <td className="approval-table-cell">
                                        {DateFormat.format(
                                            submission.submissionDate
                                        )}
                                    </td>
                                    <td className="approval-table-cell">
                                        {submission.doctorName}
                                    </td>
                                    <td className="approval-table-cell">
                                        {submission.courseName}
                                    </td>
                                    <td className="approval-table-cell">
                                        <UserCourseSubmissionStatusCellValue
                                            status={
                                                UserCourseSubmissionStatus[
                                                submission.status.toString() as keyof typeof UserCourseSubmissionStatus
                                                ]
                                            }
                                        >
                                            {getSubmissionStatusText(
                                                UserCourseSubmissionStatus[
                                                submission.status.toString() as keyof typeof UserCourseSubmissionStatus
                                                ]
                                            )}
                                        </UserCourseSubmissionStatusCellValue>
                                    </td>
                                    <td className="approval-table-cell">
                                        {submission.dismissReason == null
                                            ? ""
                                            : getSubmissionDismissReasonText(
                                                UserCourseSubmissionDismissReason[
                                                submission.dismissReason.toString() as keyof typeof UserCourseSubmissionDismissReason
                                                ]
                                            )}
                                        {submission.dismissReason != null &&
                                            submission.dismissComment &&
                                            ", "}
                                        {submission.dismissComment || ""}
                                    </td>
                                    <td className="approval-table-cell">
                                        <Link
                                            to={`/learning-materials/${submission.courseId}-${submission.courseSlug}?activeTab=responses&employeeId=${submission.employeeId}`}
                                        >
                                            <ViewResponsesLink>
                                                View Responses
                                            </ViewResponsesLink>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(Dashboard as any);

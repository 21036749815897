import styled from "styled-components";

export const TabContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: var(--gray-50, #f9fafb);
`;

interface TabItemProps {
    isSelected?: boolean;
}

export const TabItem = styled.div<TabItemProps>`
    cursor: pointer;
    user-select: none;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    color: var(--gray-500, #667085);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    background: ${(props) =>
        props.isSelected ? "var(--base-white, #fff)" : "default"};
    background: ${(props) =>
        props.isSelected
            ? "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)"
            : "default"};
`;

import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { Document } from "../models/Document";

class DocumentServiceClass extends ServiceBase {
  private static _documentService: DocumentServiceClass;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): DocumentServiceClass {
    return (
      this._documentService || (this._documentService = new this("documents"))
    );
  }

  public async DownloadFile(url: string, name: string) {
    await super.downloadFile(url, name);
  }

  public async FetchAllDocuments(): Promise<Result<Document[]>> {
    const result = await super.requestJson<Document[]>({
      method: "GET",
      url: ``,
      callType: ApiCall.FetchAllDocuments,
    });

    return result;
  }

  public async DeleteDocument(documentId: number): Promise<Result<{}>> {
    const result = await super.requestJson<{}>({
      method: "DELETE",
      url: `${documentId}`,
      callType: ApiCall.DeleteDocument,
      data: {},
    });

    return result;
  }

  public async UploadDocument(document: FormData): Promise<Result<{}>> {
    const result = await super.sendFormData<{}>({
      method: "POST",
      url: ``,
      callType: ApiCall.UploadDocument,
      data: document,
    });

    return result;
  }

  public async UpdateDocument(document: Document): Promise<Result<{}>> {
    const result = await super.requestJson<{}>({
      method: "PUT",
      url: `${document.id}`,
      callType: ApiCall.UploadDocument,
      data: document,
    });

    return result;
  }

  public async GetPresignedUrl(documentId: number): Promise<Result<Document>> {
    const result = await super.requestJson<Document>({
      method: "GET",
      url: `${documentId}/?fields=presignedurl`,
      callType: ApiCall.GetPresignedUrl,
    });

    return result;
  }
}

export const DocumentService = DocumentServiceClass.Instance;

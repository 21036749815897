import React, { ReactElement } from "react";
import { Col, Row } from "reactstrap";

interface Props {
    question: ReactElement;
    option1: ReactElement;
    option2: ReactElement;
    option3: ReactElement;
}

export const CheckboxRow: React.FC<Props> = (props: Props) => {
    return (
        <Row>
            <Col md={6}>
                {props.question}
            </Col>
            <Col xs={4} md={2} className="text-center">
                <Row className="h-100">
                    <Col xs={12} className="m-0 p-0 md:hidden lg:block align-self-center">Not met</Col>
                    <Col xs={12} className="m-0 p-0 align-self-center">{props.option1}</Col>
                </Row>
            </Col>
            <Col xs={4} md={2} className="text-center">
                <Row className="h-100">
                    <Col xs={12} className="m-0 p-0 md:hidden lg:block align-self-center">Partially met</Col>
                    <Col xs={12} className="m-0 p-0 align-self-center">{props.option2}</Col>
                </Row>
            </Col>
            <Col xs={4} md={2} className="text-center">
                <Row className="h-100">
                    <Col xs={12} className="m-0 p-0 md:hidden lg:block align-self-center">Entirely met</Col>
                    <Col xs={12} className="m-0 p-0 align-self-center">{props.option3}</Col>
                </Row>
            </Col>
        </Row>
    );
}
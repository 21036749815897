import "./styles/Users.scss";
import "./styles/ReactPaginate.scss";
import leftDisabled from "../../images/pagination-arrow-left-disabled.svg";
import leftEnabled from "../../images/pagination-arrow-left-enabled.svg";
import rightDisabled from "../../images/pagination-arrow-right-disabled.svg";
import rightEnabled from "../../images/pagination-arrow-right-enabled.svg";
import _ from "lodash";
import React, { useState, useEffect, useRef, useMemo } from "react";
import * as dashboard from "../../store/dashboardStore";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { Row, Col, Nav, NavItem } from "reactstrap";
import iconDropdown from "../../images/icon-dropdown.svg";
import {
    UsersContainer,
    UserSearchContainer,
    UserSearchTitleLabel,
    UserSearchDefinitionLabel,
    TableHeaderLabel,
    UserTable,
    TableNameLabel,
    UsersBottomContainer,
    UsersListContainer,
} from "./styles/Users";
import {
    SearchBar,
    FilterSortContainer,
    FilterSortModal,
    FilterSortItem,
    FilterSortTitle,
    FilterSortImageContainer,
    FilterSortRow,
    FilterSortModalOverlay,
    FilterOptions,
    StyledSVG,
    SortIcon,
} from "./styles/Common";
import InviteUsers from "./InviteUsers";
import UserProfile from "./UserProfile";
import InactiveUserDialogue from "./InactiveUserDialogue";
import { SortingLabel } from "./styles/Employees";
import iconTick from "../../images/icon-tick.svg";
import DeleteUserDialogue from "./DeleteUserDialogue";
import { showDefault, showErrors } from "../../utils";
import RevokeInviteDialogue from "./RevokeInviteDialogue";
import { UserSortColumn } from "../../common/UserSortColumn";
import { SortDirection } from "../../common/SortDirection";
import ReactPaginate from "react-paginate";
import { PAGE_SIZE } from "../../consts";
import { PageFilter } from "../../common/PageFilter";
import { UsersPageListItem } from "../../models/UsersPageListItem";
import { getRoleString } from "../helper/EmployeeHelper";
import { RoleFilterValue } from "../../models/admin/UserRoles";
import Button from "components/shared/Button";

type Props = RouteComponentProps<{}> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const Users: React.FC<Props> = (props: Props) => {
    const {
        fetchAllUsersPage,
        allUsersPage,
        fetchAdminUsersPage,
        adminUsersPage,
        fetchSuperAdminUsersPage,
        superAdminUsersPage,
        employeeById,
        fetchEmployeeById,
        toggleUserActiveFlag,
        showMarkEmployeeModal,
        setShowMarkEmployeeModal,
        showDeleteEmployeeModal,
        setShowDeleteEmployeeModal,
        showRevokeInviteModal,
        setShowRevokeInviteModal,
        deleteUser,
        toastMessage,
        clearToastMessage,
        clearEmployeeById,
    } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [selectedNavItem, setSelectedNavItem] = useState("allusers");
    const [showInviteUsers, setShowInviteUsers] = useState(false);
    const [showEditUsers, setShowEditUsers] = useState(false);
    const [showFilterSortModal, setShowFilterSortModal] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(FilterOptions.ALL);
    const [filterName, setFilterName] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState(UserSortColumn.Name);
    const [sortDirection, setSortDirection] = useState(SortDirection.Asc);
    const [currentPage, setCurrentPage] = useState(0);

    const tableRef = useRef(null);

    useEffect(() => {
        if (toastMessage != null) {
            showDefault(toastMessage);
            clearToastMessage();
        }
    }, [toastMessage, clearToastMessage]);

    const inviteUserProps = {
        isModalOpen: showInviteUsers,
        closeClicked: () => {
            setShowInviteUsers(false);
            setShowEditUsers(false);
        },
        user: showEditUsers ? employeeById : null,
    };

    const filterItems = [
        FilterOptions.ACTIVE,
        FilterOptions.INACTIVE,
        FilterOptions.PENDING_INVITE,
        FilterOptions.ALL,
    ];

    const debouncedResults = useMemo(() => {
        return _.debounce((e: string) => {
            setSearch(e);
            setCurrentPage(0);
        }, 500);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    useEffect(() => {
        const filter: PageFilter[] = [
            {
                filterName: filterName,
                filterValue: filterValue,
                isContains: false,
            },
        ];

        if (search) {
            const childLevelFilters: PageFilter[] = [];
            childLevelFilters.push({
                filterName: "firstName",
                filterValue: search,
                isContains: true,
            });
            childLevelFilters.push({
                filterName: "lastName",
                filterValue: search,
                isContains: true,
            });
            filter.push({
                filterName: "",
                filterValue: "",
                isContains: false,
                childLevelFilters: childLevelFilters,
            });
        }

        const allUsersFilter = filter.slice();
        allUsersFilter.push({
            filterName: "",
            filterValue: "",
            isContains: false,
            childLevelFilters: [
                {
                    filterName: "userRole",
                    filterValue: RoleFilterValue.ADMIN,
                    isContains: false,
                },
                {
                    filterName: "userRole",
                    filterValue: RoleFilterValue.SUPER_ADMIN,
                    isContains: false,
                }
            ],
        });

        fetchAllUsersPage(
            PAGE_SIZE,
            currentPage,
            allUsersFilter,
            sortBy.toString(),
            sortDirection
        );
        fetchAdminUsersPage(
            PAGE_SIZE,
            currentPage,
            filter,
            sortBy.toString(),
            sortDirection
        );
        fetchSuperAdminUsersPage(
            PAGE_SIZE,
            currentPage,
            filter,
            sortBy.toString(),
            sortDirection
        );
    }, [currentPage, filterName, filterValue, sortBy, sortDirection, search, employeeById, fetchAllUsersPage, fetchAdminUsersPage, fetchSuperAdminUsersPage]);

    const titleText = () => {
        switch (selectedNavItem) {
            case "admin":
                return "Admin";
            case "superadmin":
                return "Super Admin";
            case "allusers":
                return "All Users";
        }
    };

    const UserCell = (listItem: UsersPageListItem, count: number) => {
        return (
            <Row
                className={`user-row ${listItem.employeeId === employeeById?.id ? "active" : ""
                    }`}
                style={{
                    margin: 0,
                    padding: 0,
                    paddingLeft: 0,
                    paddingTop: 10,
                    paddingBottom: 12,
                    height: 45,
                }}
                key={`${listItem.employeeId}-user-cell`}
                onClick={() => {
                    if (
                        employeeById &&
                        employeeById.id === listItem.employeeId
                    ) {
                        clearEmployeeById();
                    } else {
                        fetchEmployeeById(listItem.employeeId);
                    }
                    setIsEditing(false);
                }}
            >
                <Col xs={employeeById ? 6 : 4}>
                    <Row style={{ alignItems: "center" }}>
                        <TableNameLabel>
                            {`${listItem.fullName}`}
                        </TableNameLabel>
                    </Row>
                </Col>
                <Col xs={employeeById ? 6 : 4}>
                    <TableNameLabel>{`${getRoleString(
                        listItem
                    )}`}</TableNameLabel>
                </Col>
                {employeeById === null && (
                    <Col xs={4}>
                        <TableNameLabel
                            color={
                                listItem.isInvitePending ? "#CB2424" : undefined
                            }
                        >
                            {`${listItem.status}`}
                        </TableNameLabel>
                    </Col>
                )}
            </Row>
        );
    };

    const toggleActiveFlagForSelectedUser = async () => {
        const isActiveOriginally = employeeById.isActive;

        if (await toggleUserActiveFlag(employeeById.id)) {
            showDefault(
                `User successfully set to ${isActiveOriginally ? "inactive" : "active"
                }.`
            );
        } else {
            showErrors(
                `Failed to set this user to ${isActiveOriginally ? "inactive" : "active"
                }`
            );
        }
    };

    const inactiveDialogueProps = () => {
        return {
            onCancel: () => {
                setShowMarkEmployeeModal(false);
            },
            onConfirm: () => {
                setShowMarkEmployeeModal(false);
                toggleActiveFlagForSelectedUser();
            },
            selectedEmployee: employeeById,
        };
    };

    const deleteDialogueProps = () => {
        return {
            onCancel: () => {
                setShowDeleteEmployeeModal(false);
            },
            onConfirm: () => {
                setShowDeleteEmployeeModal(false);
                deleteUser(employeeById.id);
            },
            selectedEmployee: employeeById,
        };
    };

    const revokeInviteDialogueProps = () => {
        return {
            onCancel: () => {
                setShowRevokeInviteModal(false);
            },
            onConfirm: () => {
                setShowRevokeInviteModal(false);
                deleteUser(employeeById.id);
            },
            selectedEmployee: employeeById,
        };
    };

    const updateSort = (userSortColumn: UserSortColumn) => {
        setSortBy(userSortColumn);
        setSortDirection(
            userSortColumn === sortBy
                ? sortDirection === SortDirection.Asc
                    ? SortDirection.Desc
                    : SortDirection.Asc
                : SortDirection.Asc
        );
    };

    const getFilterSortMenu = () => {
        return (
            <div style={{ position: "relative" }}>
                <FilterSortModalOverlay
                    onClick={() => {
                        setShowFilterSortModal(!showFilterSortModal);
                    }}
                />

                <FilterSortModal>
                    <div>
                        <FilterSortTitle>Filters</FilterSortTitle>
                        {filterItems.map((x) => {
                            return (
                                <FilterSortRow
                                    key={x}
                                    onClick={(item) => {
                                        setSelectedFilter(x);
                                        if (x === FilterOptions.ALL) {
                                            setFilterName("");
                                            setFilterValue("");
                                        } else {
                                            setFilterName("status");
                                            setFilterValue(x);
                                        }
                                        setCurrentPage(0);
                                    }}
                                >
                                    <FilterSortImageContainer>
                                        {selectedFilter === x && (
                                            <StyledSVG
                                                src={iconTick}
                                                marginLeft="15px"
                                                marginTop="-8px"
                                                size="20px"
                                            />
                                        )}
                                    </FilterSortImageContainer>
                                    <FilterSortItem>{x}</FilterSortItem>
                                </FilterSortRow>
                            );
                        })}
                    </div>
                </FilterSortModal>
            </div>
        );
    };

    let selectedUsersPage = allUsersPage;

    if (selectedNavItem === "admin") {
        selectedUsersPage = adminUsersPage;
    } else if (selectedNavItem === "superadmin") {
        selectedUsersPage = superAdminUsersPage;
    }

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <InviteUsers {...inviteUserProps} />
            {showMarkEmployeeModal && employeeById && (
                <InactiveUserDialogue {...inactiveDialogueProps()} />
            )}
            {showDeleteEmployeeModal && employeeById && (
                <DeleteUserDialogue {...deleteDialogueProps()} />
            )}
            {showRevokeInviteModal && employeeById && (
                <RevokeInviteDialogue {...revokeInviteDialogueProps()} />
            )}
            <UserSearchContainer>
                <div className="flex justify-between p-3 pb-0">
                    <div>
                        <UserSearchTitleLabel>
                            {titleText()}
                        </UserSearchTitleLabel>
                        <UserSearchDefinitionLabel>
                            View, create or edit users
                        </UserSearchDefinitionLabel>
                    </div>
                    <div>
                        <Button
                            onClick={() => setShowInviteUsers(true)}
                        >
                            Invite a new user
                        </Button>
                    </div>
                </div>

                <Nav tabs className="p-0 p-sm-3 learning-materials-nav">
                    <NavItem
                        role="button"
                        key={"allusers"}
                        isSelected={selectedNavItem === "allusers"}
                        onClick={() => {
                            setSelectedNavItem("allusers");
                            setCurrentPage(0);
                        }}
                        className={`category-nav-tab${selectedNavItem === "allusers" ? " active" : ""
                            }`}
                    >
                        <span className="nav-link">{`All Users (${allUsersPage["@odata.count"]})`}</span>
                    </NavItem>
                    <NavItem
                        role="button"
                        key={"superadmin"}
                        isSelected={selectedNavItem === "superadmin"}
                        onClick={() => {
                            setSelectedNavItem("superadmin");
                            setCurrentPage(0);
                        }}
                        className={`category-nav-tab${selectedNavItem === "superadmin" ? " active" : ""
                            }`}
                    >
                        <span className="nav-link">{`Super Admin (${superAdminUsersPage["@odata.count"]})`}</span>
                    </NavItem>
                    <NavItem
                        role="button"
                        key={"admin"}
                        isSelected={selectedNavItem === "admin"}
                        onClick={() => {
                            setSelectedNavItem("admin");
                            setCurrentPage(0);
                        }}
                        className={`category-nav-tab${selectedNavItem === "admin" ? " active" : ""
                            }`}
                    >
                        <span className="nav-link">{`Admin (${adminUsersPage["@odata.count"]})`}</span>
                    </NavItem>
                </Nav>
                <Row
                    style={{
                        margin: 0,
                        padding: 0,
                        alignItems: "center",
                        borderBottom: "solid 1px var(--lightGrey)",
                    }}
                >
                    <div className="flex justify-between align-items-center align-content-center">
                        <SearchBar
                            marginLeft={"0"}
                            width={"35%"}
                            placeholder="Search"
                            placeholderBackgroundPosition="10px center"
                            padding="10px 15px 10px 40px"
                            onChange={(e) => debouncedResults(e.target.value)}
                        />
                        <FilterSortContainer
                            onClick={() => {
                                setShowFilterSortModal(!showFilterSortModal);
                            }}
                        >
                            <SortingLabel className="me-2">{`By ${selectedFilter === FilterOptions.ALL
                                ? "All Statuses"
                                : selectedFilter
                                }`}</SortingLabel>
                            <StyledSVG
                                src={iconDropdown}
                                marginLeft="0"
                                marginTop="0"
                            />
                            {showFilterSortModal && getFilterSortMenu()}
                        </FilterSortContainer>
                    </div>
                </Row>
            </UserSearchContainer>
            <UsersBottomContainer>
                <UsersListContainer width={employeeById ? "50%" : "100%"}>
                    <Row
                        style={{
                            margin: 0,
                            padding: 0,
                            paddingLeft: 0,
                            paddingTop: 10,
                            alignItems: "center",
                            paddingBottom: 12,
                            borderBottom: "solid 1px var(--lightGrey)",
                            backgroundColor: "var(--lightGrey3)",
                        }}
                    >
                        <Col xs={employeeById ? 6 : 4}>
                            <TableHeaderLabel
                                onClick={() => updateSort(UserSortColumn.Name)}
                                className="flex justify-content-start align-items-center align-content-center"
                            >
                                <span>Name</span>
                                <SortIcon
                                    isAscending={
                                        sortDirection === SortDirection.Asc
                                    }
                                    isHidden={UserSortColumn.Name !== sortBy}
                                    src={
                                        require("../../images/icon-filter-down.svg")
                                            .default
                                    }
                                    alt="sort-icon"
                                />
                            </TableHeaderLabel>
                        </Col>
                        <Col xs={employeeById ? 6 : 4}>
                            <TableHeaderLabel
                                onClick={() => updateSort(UserSortColumn.Role)}
                            >
                                <span>Role</span>
                                <SortIcon
                                    isAscending={
                                        sortDirection === SortDirection.Asc
                                    }
                                    isHidden={UserSortColumn.Role !== sortBy}
                                    src={
                                        require("../../images/icon-filter-down.svg")
                                            .default
                                    }
                                    alt="sort-icon"
                                />
                            </TableHeaderLabel>
                        </Col>
                        {employeeById === null && (
                            <Col xs={4}>
                                <TableHeaderLabel
                                    onClick={() =>
                                        updateSort(UserSortColumn.Status)
                                    }
                                >
                                    Status
                                    <SortIcon
                                        isAscending={
                                            sortDirection === SortDirection.Asc
                                        }
                                        isHidden={
                                            UserSortColumn.Status !== sortBy
                                        }
                                        src={
                                            require("../../images/icon-filter-down.svg")
                                                .default
                                        }
                                        alt="sort-icon"
                                    />
                                </TableHeaderLabel>
                            </Col>
                        )}
                    </Row>

                    <UserTable ref={tableRef}>
                        {selectedUsersPage.value.map((x, i) => {
                            return (
                                <div
                                    key={`${x.employeeId}-userCellContainer`}
                                    style={{
                                        position: "relative",
                                        cursor: "pointer",
                                    }}
                                >
                                    {UserCell(x, i)}
                                </div>
                            );
                        })}
                    </UserTable>
                    <ReactPaginate
                        forcePage={currentPage}
                        className="react-paginate"
                        breakLabel="..."
                        nextLabel={
                            <div className="pagination-buttons flex align-items-center align-content-center">
                                Next
                                <img
                                    alt=""
                                    src={
                                        currentPage !==
                                            Math.ceil(
                                                selectedUsersPage["@odata.count"] /
                                                PAGE_SIZE
                                            ) -
                                            1
                                            ? rightEnabled
                                            : rightDisabled
                                    }
                                />
                            </div>
                        }
                        onPageChange={(page) => {
                            setCurrentPage(page.selected);
                        }}
                        pageRangeDisplayed={3}
                        pageCount={Math.ceil(
                            selectedUsersPage["@odata.count"] / PAGE_SIZE
                        )}
                        previousLabel={
                            <div className="pagination-buttons flex align-items-center align-content-center">
                                <img
                                    alt=""
                                    src={
                                        currentPage !== 0
                                            ? leftEnabled
                                            : leftDisabled
                                    }
                                />
                                Previous
                            </div>
                        }
                        renderOnZeroPageCount={null}
                    />
                </UsersListContainer>
                {employeeById && (
                    <div
                        style={{
                            width: "50%",
                            height: "100%",
                        }}
                    >
                        <UserProfile
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                        />
                    </div>
                )}
            </UsersBottomContainer>
        </div>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(Users as any);

import React from "react";
import Select from "react-dropdown-select";
import SignatureCanvas from 'react-signature-canvas'
import { SignatureMode } from "../../pages/evaluation-form/EvaluationForm";

interface Props {
    mode: SignatureMode,
    setMode: (mode: SignatureMode) => void;
    text: string,
    setText: (text: string) => void;
    canvas: any;
    isSubmitPressed,
    setIsSignatureValid,
    isSignatureValid
}

export const EditSignature: React.FC<Props> = (props) => {
    const {
        setMode, mode, setText, text, canvas, isSubmitPressed, setIsSignatureValid,
        isSignatureValid
    } = props;

    const options = [
        {
            value: SignatureMode.Text,
            label: SignatureMode[SignatureMode.Text]
        },
        {
            value: SignatureMode.Draw,
            label: SignatureMode[SignatureMode.Draw]
        }
    ];

    return (
        <div className="flex flex-col mt-2">
            <div>
                <Select values={[options[0]]} options={options} valueField="value" labelField="label"
                    onChange={(m) => setMode(m[0].value)} />
            </div>

            <div className="flex flex-col mt-2">
                <div
                    className={`rounded border p-3 pb-6 flex flex-col ${!isSignatureValid && isSubmitPressed ? "answer-required" : ""}`}>
                    <div className="flex flex-row ms-1 align-items-end p-0">
                        <button className="fs-6 border-0 bg-transparent">&#x2715;</button>
                        {mode === SignatureMode.Text &&
                            <input className="w-full mb-4 form-control fs-1 border-0 shadow-none"
                                style={{ fontFamily: "Allison" }} value={text}
                                onChange={e => {
                                    setText(e.target.value);
                                    setIsSignatureValid(e.target.value)
                                }}>
                            </input>
                        }
                        {mode === SignatureMode.Draw && <div className="w-full">
                            <SignatureCanvas ref={canvas}
                                onEnd={() => setIsSignatureValid(true)}
                                canvasProps={{
                                    className: 'w-full',
                                    width: undefined,
                                    height: undefined
                                }} /></div>}

                    </div>
                    <div className="border-top"></div>
                </div>
                {!isSignatureValid && isSubmitPressed &&
                    <span className="invalid-input">This is a required field</span>}
                <div className="mt-1 mb-3">
                    <button className="fs-6 bg-transparent text-primary border-0" onClick={
                        () => {
                            setText("");
                            canvas?.current?.clear();
                            setIsSignatureValid(false);
                        }
                    }>Clear
                    </button>
                </div>
            </div>
        </div>
    );
}

import Result from "./Result";
import Axios, { AxiosRequestConfig } from "axios";
import queryString from "query-string";
import { showErrors } from "../utils";
import SessionManager from "./session";
import * as Consts from "../consts";
import { ApiCall } from "../models/enums/ApiCall";
import { API_CALL_IDENTIFIER_PROPERTY } from "./axios/interceptors";
import { PageFilter } from "common/PageFilter";
import { PageQuery } from "common/PageQuery";
import { query } from "urlcat";

export interface IRequestOptions {
    url: string;
    data?: any;
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
    callType?: ApiCall;
    isBlob?: boolean;
    isUrlOverridden?: boolean;
    hideToastOnError?: boolean;
}

export interface ISendFormDataOptions {
    url: string;
    data: FormData;
    method: "POST" | "PUT" | "PATCH";
    callType?: ApiCall;
    hideToastOnError?: boolean;
}

/**
 * Represents base class of the API service.
 */
export abstract class ServiceBase {
    private _controller: string;

    constructor(controller: string) {
        this._controller = controller;
    }

    private buildUrl = (url: string) =>
        `/api/${Consts.API_VERSION}/${this._controller}/${url}`;

    /**
     * Make request with JSON data.
     * @param opts
     */
    protected async requestJson<T>(opts: IRequestOptions): Promise<Result<T>> {
        var axiosResult = null;
        var result = null;

        var processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${queryString.stringify(data)}`;
            }
            return url;
        };

        if (!opts.isUrlOverridden) {
            opts.url = this.buildUrl(opts.url);
        }

        var axiosRequestConfig: AxiosRequestConfig = {};
        const token = SessionManager.getToken();
        if (token) {
            axiosRequestConfig.headers = {
                Authorization: `Bearer ${token}`,
            };
        }

        if (opts.isBlob) {
            axiosRequestConfig.responseType = "blob";
        }

        axiosRequestConfig.params = {
            [API_CALL_IDENTIFIER_PROPERTY]:
                opts.callType || ApiCall.Unspecified,
        };

        try {
            switch (opts.method) {
                case "GET":
                    axiosResult = await Axios.get(
                        processQuery(opts.url, opts.data),
                        axiosRequestConfig
                    );
                    break;
                case "POST":
                    axiosResult = await Axios.post(
                        opts.url,
                        opts.data,
                        axiosRequestConfig
                    );
                    break;
                case "PUT":
                    axiosResult = await Axios.put(
                        opts.url,
                        opts.data,
                        axiosRequestConfig
                    );
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(
                        opts.url,
                        opts.data,
                        axiosRequestConfig
                    );
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(opts.url, {
                        ...axiosRequestConfig,
                        data: opts.data,
                    });
                    break;
            }

            result = new Result(
                axiosResult.data,
                axiosResult.headers,
                axiosResult.data.friendlyError,
                ...(typeof axiosResult.data.errors === "string"
                    ? [axiosResult.data.errors]
                    : axiosResult.data.errors || [])
            );
        } catch (error) {
            const data = error.response && error.response.data;
            if (data) {
                result = new Result(
                    data,
                    null,
                    data.friendlyError,
                    ...(typeof data.errors === "object"
                        ? Object.values(data.errors)
                        : typeof data.errors === "string"
                        ? [data.errors]
                        : data.errors ?? [])
                );
            } else {
                result = new Result(error, null, undefined, error.message);
            }
        }

        if (result.hasErrors && !opts.hideToastOnError) {
            showErrors(...result.errors);
        }

        return result;
    }

    protected async downloadFile(url: string, fileName: string) {
        var axiosRequestConfig: AxiosRequestConfig = {
            headers: {
                "Content-Type": "application/octet-stream",
            },
        };
        axiosRequestConfig.params = {
            [API_CALL_IDENTIFIER_PROPERTY]: ApiCall.Unspecified,
        };
        /* Axios.get(url, axiosRequestConfig)
      .then((res) => {
        fileDownload(res.data, "test");
      })
      .catch((error) => console.log(error));*/
    }

    /**
     * Allows you to send files to the server.
     * @param opts
     */
    protected async sendFormData<T>(
        opts: ISendFormDataOptions
    ): Promise<Result<T>> {
        let axiosResult = null;
        let result = null;

        opts.url = this.buildUrl(opts.url);

        const axiosOpts: AxiosRequestConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        const token = SessionManager.getToken();

        if (token) {
            axiosOpts.headers.Authorization = `Bearer ${token}`;
        }

        axiosOpts.params = {
            [API_CALL_IDENTIFIER_PROPERTY]:
                opts.callType || ApiCall.Unspecified,
        };

        try {
            switch (opts.method) {
                case "POST":
                    axiosResult = await Axios.post(
                        opts.url,
                        opts.data,
                        axiosOpts
                    );
                    break;
                case "PUT":
                    axiosResult = await Axios.put(
                        opts.url,
                        opts.data,
                        axiosOpts
                    );
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(
                        opts.url,
                        opts.data,
                        axiosOpts
                    );
                    break;
            }
            result = new Result(
                axiosResult.data,
                axiosResult.headers,
                axiosResult.data.friendlyError,
                ...(axiosResult.data.errors || [])
            );
        } catch (error) {
            result = new Result(null, null, null, error.message);
        }

        if (result.hasErrors && !opts.hideToastOnError) {
            showErrors(...result.errors);
        }

        return result;
    }

    protected createPageQuery(
        filterParam: string,
        { filterName, filterValue, isContains, skipQuotes, isIn }: PageFilter
    ) {
        if (isIn) {
            const encodedFilterValue = encodeURIComponent(
                ` in (${filterValue
                    .map((x) => (x === null ? "null" : `'${x}'`))
                    .join(", ")})`
            );
            filterParam += `${filterName}${encodedFilterValue}`;
        } else if (isContains) {
            const encodedFilterValue = encodeURIComponent(
                `'${filterValue.toLowerCase()}'`
            );
            filterParam += `contains(toLower(${filterName}),${encodedFilterValue})`;
        } else {
            const encodedFilterValue = skipQuotes
                ? encodeURIComponent(` eq ${filterValue}`)
                : encodeURIComponent(` eq '${filterValue}'`);
            filterParam += `${filterName}${encodedFilterValue}`;
        }

        return filterParam;
    }

    protected getPageQueryString<T>({
        pageSize,
        currentPage,
        by,
        direction,
    }: PageQuery<T>) {
        return query({
            count: pageSize,
            skip: currentPage * pageSize,
            by,
            direction,
        });
    }
}

import React from 'react'
import { CourseEvent } from '../../models/CourseEvent'
import {
  EventLocationAdditionalAddressContainer,
  EventLocationAdditionalAddressText,
  EventLocationAddressButton,
  EventLocationAddressContainer,
  EventLocationAndMapContainer,
  EventLocationAndMapInnerContainer,
  EventLocationContactTextContainer,
  EventLocationHeadingContainer,
  EventLocationImage,
  EventLocationImageContainer,
  EventLocationMapContainer,
  EventLocationPinContainer,
  EventLocationSection,
  EventLocationSectionContainer,
} from './styles/EventLocation'
import { SectionTitleContainer } from './styles/EventHighlights'
import { ReactComponent as IconMarker } from '../../images/marker.svg'
import MapWithAMarker from './MapWithAMarker'
import { Col, Row } from 'reactstrap'

interface EventLocationProps {
  courseEvent: CourseEvent
}

const EventLocation = (props: EventLocationProps) => {
  const { courseEvent } = props
  const lat = +courseEvent.locationLat
  const lng = +courseEvent.locationLong

  return (
    <section className='bg-primary-25 py-12'>
      <div className="container">
        <Row>
          <Col md={8}>
            <div>
              <EventLocationAndMapInnerContainer>
                <EventLocationHeadingContainer>
                  <SectionTitleContainer>Location</SectionTitleContainer>
                  <div className='flex flex-col gap-4 w-full'>
                    <EventLocationAddressContainer>
                      <EventLocationPinContainer>
                        <IconMarker style={{ width: 24, height: 24 }} />
                      </EventLocationPinContainer>
                      <a
                        href={`https://maps.google.com/?q=${lat},${lng}
`}
                        className='text-primary font-semibold text-lg'
                        target="_blank"
                        rel='noreferrer'
                      >
                        {courseEvent.locationVenue}
                      </a>
                    </EventLocationAddressContainer>
                    <EventLocationAdditionalAddressContainer>
                      <EventLocationAdditionalAddressText>
                        {courseEvent.locationStreatAddress} <br />{' '}
                        {courseEvent.locationCity}
                      </EventLocationAdditionalAddressText>
                    </EventLocationAdditionalAddressContainer>
                    <MapWithAMarker
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div className='max-w-full rounded-md w-full h-80' />}
                      longitude={lng}
                      latitude={lat}
                    />
                  </div>
                </EventLocationHeadingContainer>
              </EventLocationAndMapInnerContainer>
            </div>
          </Col>
          {courseEvent.locationImage &&
            <Col className='hidden md:block' md={4}>
              <img
                className='rounded h-full object-cover w-full'
                alt=""
                src={`data:image/png;base64,${courseEvent.locationImage}`}
              />
            </Col>
          }
        </Row>
      </div>
    </section>
  )
}

export default EventLocation

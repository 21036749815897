import styled, { css } from "styled-components";
import SearchIcon from "../../../images/icon-search.svg";
import { device } from "../../../styles/sizes/device";

interface HeaderIconProps {
    backgroundImage: string;
    width?: number;
    height: number;
    mobileWidth: number;
    mobileHeight: number;
    marginLeft: number;
    marginRight: number;
}

interface HeaderImgProps {
    width?: number;
    height: number;
    mobileWidth?: number;
    mobileHeight: number;
    marginLeft: number;
    marginRight: number;
}

interface HeaderIconContainerProps {
    paddingLeft: number;
    borderLeft: string;
}

interface SearchBarProps {
    searchIcon?: string;
}

interface MobileMenuProps {
    topBannerIsVisible: boolean;
    scrolled: boolean;
    showMobileMenu?: boolean;
}

interface ScrolledProp {
    scrolled: boolean;
    topBannerIsVisible: boolean;
}

export const HeaderIcon = styled.div<HeaderIconProps>`
    background-image: url(${(props) => props.backgroundImage});
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    background-repeat: no-repeat;
    background-size: ${(props) => props.width}px ${(props) => props.height}px;
    margin-left: ${(props) => props.marginLeft}px;
    margin-right: ${(props) => props.marginRight}px;
    cursor: pointer;
    @media (max-width: 767px) {
        width: ${(props) => props.mobileWidth}px;
        height: ${(props) => props.mobileHeight}px;
        background-size: ${(props) => props.mobileWidth}px
            ${(props) => props.mobileHeight}px;
    }
`;

export const HeaderImg = styled.img<HeaderImgProps>`
    width: ${(props) => (props.width ? `${props.width}px` : "auto")};
    height: ${(props) => props.height}px;
    margin-left: ${(props) => props.marginLeft}px;
    margin-right: ${(props) => props.marginRight}px;
    cursor: pointer;
    @media (max-width: 767px) {
        width: ${(props) =>
            props.mobileWidth ? `${props.mobileWidth}px` : "auto"};
        height: ${(props) => props.mobileHeight}px;
    }
`;

export const HeaderIconContainer = styled.div<HeaderIconContainerProps>`
    display: flex;
    align-items: center;
    padding-left: ${(props) => props.paddingLeft}px;
    border-left: ${(props) => props.borderLeft};
`;

export const SearchBar = styled.input<SearchBarProps>`
    height: 34px;
    width: 840px;
    max-width: 100%;
    margin-top: 3px;
    background-color: var(--white);
    outline: none;
    color: var(--gray-500, #667085);
    font-weight: 400;
    line-height: 24px;
    border-radius: 4px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    font-size: 16px;
    padding: 5px 15px 5px 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: auto;
    margin-right: 20px;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 10px center;
    &::placeholder {
        font-size: 16px;
        color: var(--gray-500, #667085);
    }
`;

export const LandingPageMobileMenu = styled.div<MobileMenuProps>`
    z-index: 100;
    top: 64px;
    position: fixed;
    background-color: white;
    padding-bottom: 15px;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    overflow: hidden;
`;

export const MobileNavMenu = styled.div<MobileMenuProps>`
    position: sticky;
    z-index: 100;
    height: 64px;
    width: 100%;
    background: ${(props) => (props.showMobileMenu ? "white" : "transparent")};

    transition: top 0.3s;

    display: none;

    top: ${(props) => {
        if (props.scrolled || !props.topBannerIsVisible) return "0px";
        return "192px";
    }};

    @media ${device.sm} {
        display: block;
        top: ${(props) => {
            if (props.scrolled || !props.topBannerIsVisible) return "0px";
            return "96px";
        }};
    }

    @media ${device.xs} {
        top: ${(props) => {
            if (props.scrolled || !props.topBannerIsVisible) return "0px";
            return "192px";
        }};
    }

    ${({ scrolled }) =>
        scrolled &&
        css`
            background: white;
        `}
`;

export const MobileNavMenuContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
`;

export const NavbarContainer = styled.div<ScrolledProp>`
    position: sticky;
    z-index: 100;
    width: 100%;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: transparent;
    transition: background 0.3s;

    display: flex;

    top: ${(props) => {
        if (props.scrolled || !props.topBannerIsVisible) return "0px";
        return "72px";
    }};

    @media ${device.sm} {
        display: none;
    }

    @media ${device.xs} {
        top: ${(props) => (props.topBannerIsVisible ? "250px" : "0px")};
    }

    ${({ scrolled }) =>
        scrolled &&
        css`
            background: white;
        `}
`;

export const MobileButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const BackgroundBlurOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.7;
    background: var(--gray-900, #101828);
    backdrop-filter: blur(5px); /* for browsers that support it */
    z-index: 99; /* should be below the menu but above everything else */
`;

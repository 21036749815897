import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const EventFaqSection = styled.section`
  display: flex;
  padding: 96px 0px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  border-bottom: 1px solid var(--gray-200);
  background: var(--white, #fff);
  @media ${device.xs} {
    padding: 48px 8px;
    flex-direction: column;
    gap: 48px;
  }
`;

export const EventFaqSectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
  @media ${device.xs} {
    flex-direction: column;
    gap: 48px;
  }
`;

export const EventFaqHeadingContentContainer = styled.div`
  display: flex;
  width: 448px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  @media ${device.xs} {
    width: 100%;
  }
`;

export const EventFaqHeadingDescriptionText = styled.p`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--gray-600);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media ${device.xs} {
    width: 100%;
  }
`;

export const EventFaqHeadingDescriptionLink = styled.a`
  text-decoration: underline !important;
  cursor: pointer;
`;

export const EventFaqContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
`;

export const EventFaqItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

export const EventFaqItemContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const EventFaqItemQuestionAndAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const EventFaqQuestionTextContainer = styled.h3`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--gray-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media ${device.xs} {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
  }
`;

export const EventFaqAnswerTextContainer = styled.p`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--gray-600);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const EventFaqIconContainer = styled.span``;

export const AccordionHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const AccordionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

export const AccordionIconContainer = styled.div`
  display: flex;
  padding-top: 2px;
  flex-direction: column;
  align-items: flex-start;
`;

export const EventFaqIcon = styled.svg``;

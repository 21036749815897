import Button from 'components/shared/Button';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import { CourseService } from 'services/CourseService';
import { join } from 'urlcat';
import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { PagedTable, PagedTableColumn } from 'components/shared/PagedTable';
import { PageQuery } from 'common/PageQuery';
import { CourseEntity } from 'models/entities/CourseEntitiy';
import { SortDirection } from 'common/SortDirection';

const columns: (PagedTableColumn<CourseEntity>)[] = [{
    key: "id",
    label: "Page",
    className: 'ps-0',
    sortable: true,
}, {
    key: "title",
    label: "Title",
    sortable: true,
}, {}, {}]

const Courses = () => {
    const { pathname } = useLocation();

    const getPaged = useCallback((args: PageQuery<CourseEntity>) => CourseService.GetPaged(args), []);

    return <>
        <Container className='py-4'>
            <Row>
                <Col>
                    <h3 className='mb-3'>Manage Courses</h3>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <Button className='mb-3' to={join(pathname, '/', "new")}>Create new</Button>
                </Col>
            </Row>

            <PagedTable
                pageSize={10}
                request={getPaged}
                columns={columns}
                defaultSort={["id", SortDirection.Desc]}
                renderRow={(({ id, slug, title, isHidden }) => <tr key={id} className={classNames('align-middle', { 'text-black-50': isHidden })}>
                    <td className='ps-0'>
                        {id}-{slug}{' '}
                        <a className={classNames({ 'text-decoration-line-through': isHidden })} href={`/learning-materials/${id}-${slug}`}>(link)</a>
                    </td>
                    <td>{title}</td>
                    <td className='text-center'><FontAwesomeIcon icon={isHidden ? faEyeSlash : faEye} /></td>
                    <td className='pe-0'>
                        <Button className='ms-auto' variant='secondary' to={join(pathname, '/', `${id}`)}>
                            Edit
                        </Button>
                    </td>
                </tr>)}
            />

        </Container>
    </>
}

export default Courses;

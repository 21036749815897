import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form"
import { Col, Row } from 'reactstrap';

import { Checkbox, DateTime, Select, SelectTimezone, TextInput } from 'components/form';
import Button from 'components/shared/Button';
import { CourseCategory } from 'models/enums/CourseCategory';
import { min, required } from 'validate';
import { useHistory } from 'react-router';
import { CourseFormValues } from 'models/CourseFormValues';

type Props = {
    handleSubmit(values: CourseFormValues);
    initialValues?: Partial<CourseFormValues>,
    handleCancel?(),
    actionLabel: string;
}

export default function CourseDetailsForm({
    handleSubmit,
    handleCancel,
    initialValues,
    actionLabel
}: Props) {
    const {
        register,
        control,
        handleSubmit: innerSubmit,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<CourseFormValues>({ defaultValues: initialValues });

    const [timezone, setTimezone] = useState<string>("Australia/Sydney");

    const { block } = useHistory();
    useEffect(() => {
        if (isDirty && !isSubmitting) {
            const unblock = block('You have unsaved changes.');
            return unblock;
        }
    }, [block, isDirty, isSubmitting]);

    return (
        <form onSubmit={innerSubmit(handleSubmit)}>
            <div className='py-3'>
                <h5>CPD Information</h5>
                <Row>
                    <Col>
                        <TextInput
                            label='Activity ID'
                            placeholder='Enter the activity Id'
                            id="activityId"
                            fieldError={errors.activityId}
                            type='number'
                            {...register("activityId", { valueAsNumber: true, validate: required() })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInput
                            label='Educational Activities'
                            placeholder='EA CPD Hours'
                            id="educationalActivitiesCpd"
                            type='number'
                            step="any"
                            fieldError={errors.educationalActivitiesCpd}
                            {...register("educationalActivitiesCpd", { valueAsNumber: true, required: true, validate: min(0) })}
                        />
                    </Col>
                    <Col>
                        <TextInput
                            label='Measuring Outcomes'
                            placeholder='MO CPD Hours'
                            id="measuringOutcomesCpd"
                            type='number'
                            step="any"
                            fieldError={errors.measuringOutcomesCpd}
                            {...register("measuringOutcomesCpd", { valueAsNumber: true, required: true, validate: min(0) })}
                        />
                    </Col>
                    <Col>
                        <TextInput
                            label='Reviewing Performance'
                            placeholder='RP CPD Hours'
                            id="reviewingPerformanceCpd"
                            type='number'
                            step="any"
                            fieldError={errors.reviewingPerformanceCpd}
                            {...register("reviewingPerformanceCpd", { valueAsNumber: true, required: true, validate: min(0) })}
                        />
                    </Col>
                </Row>
                <hr />
                <h5>Course Display</h5>
                <Row>
                    <Col>
                        <TextInput
                            label='Title'
                            placeholder='Enter the course title'
                            id="title"
                            fieldError={errors.title}
                            {...register("title", { validate: required() })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInput
                            label='Description'
                            placeholder='Enter the course description'
                            id="description"
                            type='textarea'
                            fieldError={errors.description}
                            {...register("description", { validate: required() })}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Select
                            label="Category"
                            placeholder="Select a category"
                            fieldError={errors.category}
                            {...register("category", { validate: required(), value: null })}
                        >
                            {Object.entries(CourseCategory).map(([k, v]) =>
                                <option value={v} key={v}>{k}</option>
                            )}
                        </Select>
                    </Col>

                    <Col>
                        <TextInput
                            label="Year"
                            placeholder='Year of Course'
                            {...register("year", { validate: required() })}
                            type='number'
                            fieldError={errors.year}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Checkbox
                            label="Only for 'Primary Practice Employees'"
                            id="onlyVisibleForPrimaryPracticeEmployees"
                            fieldError={errors.onlyVisibleForPrimaryPracticeEmployees}
                            {...register("onlyVisibleForPrimaryPracticeEmployees")}
                        />
                    </Col>
                    <Col>
                        <Checkbox
                            label="Hide for all users"
                            id="isHidden"
                            fieldError={errors.isHidden}
                            {...register("isHidden")}
                        />
                    </Col>
                </Row>
                <hr />

                <h5>Webinar (Optional)</h5>

                <Row>
                    <Col>
                        <DateTime
                            control={control}
                            name="webinarDateTime"
                            fieldError={errors.webinarDateTime}
                            label="Date and time"
                            dateFormat="DD/MM/YYYY"
                            timeFormat="h:mm A"
                            id="date"
                            displayTimeZone={timezone}
                        />
                    </Col>
                    <Col>
                        <SelectTimezone
                            value={timezone}
                            setTimezone={setTimezone}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <TextInput
                            label='Location'
                            id="webinarLocation"
                            fieldError={errors.webinarLocation}
                            {...register("webinarLocation")}
                        />
                    </Col>
                </Row>

                <hr />

                <Row className='justify-content-start'>
                    {handleCancel && <Col xs="auto">
                        <Button
                            type="button"
                            variant='secondary'
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Col>
                    }
                    <Col xs="auto">
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {actionLabel}
                        </Button>

                    </Col>
                </Row>
            </div>
        </form>
    )
}
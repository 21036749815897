import "./styles/LearningMaterialCard.scss";
import React, { MouseEventHandler, useCallback, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Course } from "models/Course";
import { Link, NavLink } from "react-router-dom";
import { Employee } from "models/Employee";
import {
    EventUsersAttendingText,
} from "./styles/LearningMaterials";
import { CourseCategoryFilterValue } from "common/CourseCategoryFilterValue";
import { CourseEvent } from "models/CourseEvent";
import { CourseOrEvent } from "models/enums/CourseOrEvent";
import iconCalendar from "images/icon-calendar.svg";
import iconMarker from "images/icon-marker.svg";
import iconUsers from "images/icon-users.svg";
import moment from "moment";
import { Card } from "components/reactstrap";
import classNames from "classnames";
import { CourseCategoryBadge } from "components/shared/badges/CourseCategoryBadge";
import { Badge } from "components/shared/Badge";

const DateFormat = new Intl.DateTimeFormat("en-AU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
});

interface Props {
    type: CourseOrEvent;
    course?: Course;
    event?: CourseEvent;
    startCourse: (courseId: number) => void;
    markCourseAsSubmitted: (courseId: number) => void;
    courseCompletionQrCode: string | null;
    fetchCourseCompletionFormQrCode: (courseId: number) => void;
    employee: Employee | null;
    hideCompletitionDate?: boolean;
    isExpanded?: boolean;
    expandImage?: boolean;
    isAdminUser: boolean;
    className?: string;
}

const getEventTimeString = (date: Date) => {
    if (new Date(date).getMinutes() === 0) {
        return moment(date).format("ha");
    }

    return moment(date).format("h:mma");
};

const getEventUsersAttendingText = (attending: number, capacity: number) => {
    if (attending >= capacity) {
        return (
            <EventUsersAttendingText isFullyBooked={true}>
                This course has reached capacity and you can no longer enrol
            </EventUsersAttendingText>
        );
    }

    if (attending >= capacity * 0.75) {
        return (
            <span>
                Users attending:{" "}
                <EventUsersAttendingText isMostlyBooked={true}>
                    {attending}/{capacity}
                </EventUsersAttendingText>
            </span>
        );
    }

    return (
        <span>
            Users attending:{" "}
            <EventUsersAttendingText isLightlyBooked={true}>
                {attending}/{capacity}
            </EventUsersAttendingText>
        </span>
    );
};

const footerClassName = "flex justify-between";

const LearningMaterialCard: React.FC<Props> = ({
    type,
    course,
    event,
    startCourse,
    employee,
    hideCompletitionDate,
    isExpanded,
    expandImage,
    isAdminUser,
    className
}: Props) => {
    const [modal, setModal] = useState(false);

    const isStarted = course?.startedAt != null;
    const isSubmitted = course?.submittedAt != null;
    const isCompleted = course?.approvedAt != null;
    const lastSlideReached = course?.lastSlideReachedAt != null;

    const getTitle = () => {
        return type === CourseOrEvent.Course ? course?.title : event?.title;
    };

    const getDescription = () => {
        return type === CourseOrEvent.Course
            ? course?.description
            : event?.description;
    };

    const getThumbnailSrc = (course: Course) => {
        return course?.thumbnailUrl ?? `data:image/png;base64,${course?.thumbnailBase64}`
    }

    const getCpd = () => {
        return type === CourseOrEvent.Course ? course?.cpd : event?.course.cpd;
    };

    const handleClick = () => {
        if (type === CourseOrEvent.Course && !isStarted) {
            startCourse(course.id);
        }
    };

    const clickMarkAsCompleted: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        e.bubbles = false;

        if (!lastSlideReached) {
            return;
        }

        setModal(true);
    }, [lastSlideReached]);

    return (
        <Card.Shell className={classNames("flex", className)}>
            <div className="h-100 flex-1 flex flex-col">
                <Link
                    to={type === CourseOrEvent.Course ? `/learning-materials/${course.id}-${course.slug}` : `/face-to-face-events/${event.id}`}
                    onClick={handleClick}
                    title={getTitle()}
                    className="flex-1 flex flex-col group"
                >
                    <div
                        className="h-44 w-full relative"
                    >
                        <img
                            src={type === CourseOrEvent.Course ? getThumbnailSrc(course) : getThumbnailSrc(event.course)}
                            alt=""
                            className="h-full w-full object-cover absolute inset-0"
                        />
                        {(course || event?.course)?.year !== moment().year() && <Badge className="absolute top-2 left-2 bg-white text-primary">{course?.year}</Badge>}
                    </div>
                    <Card.Body className="flex-1">
                        <div className="flex justify-between items-center">
                            <CourseCategoryBadge
                                category={type === CourseOrEvent.Course ? course.category : CourseCategoryFilterValue.Conferences}
                                className="grow-0 shrink-0"
                            />
                            <div className="text-primary font-semibold text-xs text-right">{getCpd()} CPD hours</div>
                        </div>
                        <h6 className="mt-2 group-hover:text-primary  line-clamp-2">
                            {getTitle()}
                        </h6>
                        <p className="mt-1 text-gray text-sm text-ellipsis line-clamp-2">
                            {getDescription()}
                        </p>

                        {type === CourseOrEvent.Event && (
                            <div className="space-y-1 mt-3 text-xs text-gray">
                                <div>
                                    <img
                                        src={iconCalendar}
                                        alt="Calendar icon"
                                        className="inline-block mr-2 w-5 h-5"
                                    />
                                    <span className="">
                                        {event.startDate ? <>
                                            {moment(event.startDate).format("D MMMM YYYY, ")}
                                            {getEventTimeString(event.startDate)}-
                                            {getEventTimeString(event.endDate)}
                                        </>
                                            : "Date TBD"}
                                    </span>
                                </div>
                                <div>
                                    <img
                                        src={iconMarker}
                                        alt="Map marker icon"
                                        className="inline-block mr-2 w-5 h-5"
                                    />
                                    <span className="">{event.locationVenue}</span>
                                </div>
                                {isAdminUser &&
                                    <div>
                                        <img
                                            src={iconUsers}
                                            alt="Users icon"
                                            className="inline-block mr-2 w-5 h-5"
                                        />
                                        <span className="">
                                            {getEventUsersAttendingText(
                                                event.capacity - event.availability,
                                                event.capacity
                                            )}
                                        </span>
                                    </div>
                                }
                            </div>
                        )}
                    </Card.Body>
                    <hr className="m-0" />
                    {type === CourseOrEvent.Course &&
                        !hideCompletitionDate &&
                        isSubmitted &&
                        !isCompleted && (
                            <Card.Body
                                className={footerClassName}
                            >
                                <span>
                                    Submitted{" "}
                                    {DateFormat.format(course.submittedAt)}
                                </span>
                                <img
                                    src={
                                        require("images/icon-submitted.svg")
                                            .default
                                    }
                                    alt="Course submitted"
                                />
                            </Card.Body>
                        )}
                    {type === CourseOrEvent.Course &&
                        !hideCompletitionDate &&
                        isCompleted && (
                            <Card.Body
                                className={footerClassName}
                            >
                                <span>
                                    Completed{" "}
                                    {DateFormat.format(course.approvedAt)}
                                </span>
                                <img
                                    src={
                                        require("images/icon-complete.svg")
                                            .default
                                    }
                                    alt="Course complete"
                                />
                            </Card.Body>
                        )}
                </Link>
                {type === CourseOrEvent.Event && isExpanded && (
                    event.startDate ? <Link to={`/face-to-face-events/${event.id}/book`} component={(a) => <Card.Body as="a" {...a}
                        disabled={event.availability <= 0} />}
                        className={classNames("hover:underline", footerClassName)}
                    >
                        Book your spot
                    </Link>
                        : <Card.Body className="text-base">This has been postponed.</Card.Body>
                )}
                {type === CourseOrEvent.Course &&
                    !hideCompletitionDate &&
                    !isSubmitted &&
                    !isCompleted && (
                        <Card.Body as="button"
                            onClick={clickMarkAsCompleted}
                            className={classNames("hover:underline", footerClassName)}
                        >
                            <span>Mark as completed</span>
                            <img
                                className="ml-auto"
                                src={
                                    lastSlideReached
                                        ? require("images/icon-circle.svg")
                                            .default
                                        : require("images/icon-circle-grey.svg")
                                            .default
                                }
                                alt="Mark as completed"
                            />
                        </Card.Body>
                    )}
            </div>
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
                centered={true}
                className="mark-as-completed-modal modal-dialog"
            >
                <div className="modal-header-icon-container">
                    <img
                        src={require("images/modal-circles.svg").default}
                        alt=""
                        aria-hidden
                    />
                    <img
                        className="modal-header-icon"
                        src={
                            require("images/icon-modal-tick.svg").default
                        }
                        alt=""
                        aria-hidden
                    />
                </div>
                <ModalHeader toggle={() => setModal(false)}></ModalHeader>
                <ModalBody>
                    <h5>Congratulations!</h5>

                    <div className="mt-3 flex mark-as-completed-modal-body-container">
                        Well done on completing this course!
                    </div>
                    <div className="mt-3 flex mark-as-completed-modal-body-container">
                        <span>
                            In order for this course to be recognised by the
                            RACGP, you will need to complete the{" "}
                            {
                                <NavLink
                                    to={`/evaluation-form?employee=${employee?.email}&learningmaterial=${course?.id}`}
                                >
                                    evaluation form
                                </NavLink>
                            }
                        </span>
                    </div>
                    <div className="mt-3 flex mark-as-completed-modal-body-container">
                        Your recognition of CPD hours will be uploaded within 30
                        days of completion.
                    </div>
                    <div className="mt-3">
                        <button
                            className="btn btn-primary col-12"
                            onClick={() => {
                                setModal(false);
                            }}
                        >
                            CONFIRM
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </Card.Shell>
    );
};

export default LearningMaterialCard;

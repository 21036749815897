import React from 'react'
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Libraries,
} from '@react-google-maps/api'

export interface MapWithAMarkerProps {
  longitude: number
  latitude: number
  loadingElement: React.ReactElement
  containerElement: React.ReactElement
}

const libraries: Libraries = ["geometry", "drawing", "places"]

const MapWithAMarker = (props: MapWithAMarkerProps) => {
  const apiKey = process.env.REACT_APP_MAPS_API_KEY;

  // `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
    libraries
  });

  return React.cloneElement(props.containerElement, {
  }, isLoaded
    ? <GoogleMap
      mapContainerClassName='h-100'
      zoom={15}
      center={{ lat: props.latitude, lng: props.longitude }}
    >
      <Marker position={{ lat: props.latitude, lng: props.longitude }} />
    </GoogleMap>
    : props.loadingElement);
};

export default MapWithAMarker

import React from "react";
import { TabContainer } from "./styles/NavTabContainer";

type Props = React.PropsWithChildren<{}>;
const NavTabContainer = (props: Props) => {
    return (
        <React.Fragment>
            <TabContainer>{props.children}</TabContainer>
        </React.Fragment>
    );
};

export default NavTabContainer;

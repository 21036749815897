import styled, { css } from "styled-components";
import { device } from "../../../styles/sizes/device";

export const EventRegisterSection = styled.div`
    display: flex;
    padding: 8px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
    @media ${device.xs} {
        justify-content: space-between;
    }
`;

export const EventRegisterSticky = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
`;

interface ScrolledProp {
    scrolled: boolean;
}

export const RegisterContainer = styled.div<ScrolledProp>`
    position: fixed;
    bottom: -54px;
    width: 100%;
    background: var(--primary-800);
    transition: bottom 0.3s;
    z-index: 100;

    ${({ scrolled }) =>
        scrolled &&
        css`
            bottom: 0;
        `}

    @media ${device.xs} {
        bottom: -75px;

        ${({ scrolled }) =>
            scrolled &&
            css`
                bottom: 0;
            `}
    }
`;

export const TimeContainer = styled.span`
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    @media ${device.xs} {
        display: none;
    }
`;

export const MobileTimeContainer = styled.span`
    display: none;
    @media ${device.xs} {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        display: block;
    }
`;

export const RegisterButton = styled.a`
    display: flex;
    padding: 8px 14px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const RegisterTextContainer = styled.div`
    color: var(--white, #fff);
    text-align: center;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
`;

export const RegisterButtonText = styled.span`
    color: var(--gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

import { Link } from "react-router-dom";
import styled from "styled-components";
import searchIcon from "../../../images/searchIcon.png";
import { UserCourseSubmissionStatus } from "../../../models/enums/UserCourseSubmissionStatus";

export const DescriptionLabel = styled.label`
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--black);
`;

export const SmallLabels = styled.div`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: #4d4d4d;
`;

interface HelpLabelProps {
    color?: string;
}

export const HelpLabel = styled.span<HelpLabelProps>`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: ${(props) => (props.color ? props.color : "#4d4d4d")};
`;

export const HelpLink = styled(Link)<HelpLabelProps>`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: ${(props) => (props.color ? props.color : "#4d4d4d")};
`;

export const Card = styled.div`
    background-color: var(--white);
    padding: 21px 29.5px 30px;
`;

interface SearchBarProps {
    searchIcon?: string;
    marginLeft?: string;
}

export const SearchBar = styled.input<SearchBarProps>`
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: 10px center;
`;

export const ResetFiltersButton = styled.span`
    color: var(--primaryActiveHoverColor);
    cursor: pointer;
`;

export interface FilterSortModalProps {
    top?: string;
    width?: string;
}

export const FilterSortModal = styled.div<FilterSortModalProps>`
    z-index: 999;
    display: block;
    position: absolute;
    float: right;
    width: ${(props) => props.width || "252px"};
    top: ${(props) => props.top || "45px"};
    right: 15px;
    min-height: 162px;
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

export const FilterSortContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 0px;
`;

export const FilterSortTitle = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-top: 11px;
    margin-left: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e3e50;
    margin-bottom: 11px;
`;

export const DeclineModal = styled.div`
    z-index: 999;
    display: block;
    position: absolute;
    float: right;
    width: 144px;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 5px;
    top: 35px;
    right: 0px;
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    max-height: 152px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

export interface FilterSortRowProps {
    height?: string;
}

export const AdminResponsesLeftContainer = styled.div`
    width: 354px;
    min-width: 354px;
    height: 100%;
    border-right: none !important;
`;

export const UserResponsesLeftContainer = styled.div`
    width: 354px;
    min-width: 354px;
    height: 100%;
    background-color: var(--lightGrey3);
    border-right: none !important;
    border-top: none !important;
`;

export const UserResponsesLeftContainerLink = styled.div`
    cursor: pointer;
    color: var(--gray-500, #667085);

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const ResponsesTitle = styled.div`
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    color: var(--black);
`;

export const ResponsesTitleCount = styled.span`
    color: var(--medGrey);
`;

interface EmployeeResponseListItemProps {
    isActive?: boolean;
}

export const EmployeeResponseListItem = styled.div<EmployeeResponseListItemProps>`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    background-color: ${(props) =>
        props.isActive ? "#E8F0F4" : "transparent"};
`;

export const ResponseInnerCardTitle = styled.div`
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: var(--black);
`;

export const ResponseCard = styled.div`
    background-color: var(--white);
    border-radius: 0 !important;
    border: none !important;
`;

interface ResponseItemDataGroupProps {
    isHidden?: boolean;
}

export const ResponseItemDataGroup = styled.div<ResponseItemDataGroupProps>`
    visibility: ${(props) => (props.isHidden ? "hidden" : "visible")};
`;

interface ResponseDataItemTitleProps {
    color?: string;
}

export const ResponseDataItemTitle = styled.div<ResponseDataItemTitleProps>`
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: ${(props) => props.color || "var(--darkGrey)"};
`;

export const ResponseDataItemValue = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;

export const AdminResponsesRightContainer = styled.div`
    background-color: var(--lightGrey3);
    border-left: none !important;
    border-top: none !important;
    padding-right: 132px !important;
`;

export const PdfLink = styled.a`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primaryButtonsLinksColor) !important;
`;

export const PdfImage = styled.img`
    width: 24px;
    height: 24px;
`;

export const AlertIcon = styled.img`
    width: 20px;
    height: 20px;
`;

export const MissingEvaluationFormAlertText = styled.span`
    line-height: 20px;
`;

export const ResponseItemBlockTitle = styled.div`
    border-radius: 4px;
    background: var(--primary-50, #fef1e9);
    color: var(--black);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
`;

export const QuestionText = styled.div`
    color: var(--gray-700, #344054);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

export const AnswerText = styled.div`
    color: var(--gray-600, #475467);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export interface CourseCategoryBadgeProps {
    color?: string;
    backgroundColor?: string;
}

export const CourseCategoryBadge = styled.div<CourseCategoryBadgeProps>`
    background-color: ${(props) => props.backgroundColor || "var(--white)"};
    color: ${(props) => props.color || "var(--black)"};
    height: 22px;
    padding: 5px 6px;
    border-radius: 6px !important;
    font-size: 12px;
    font-weight: 500;
`;

export const ResponseMissingMessageContainer = styled.div`
    width: 60%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--black);
`;

export const CertificateMissingMessageContainer = styled.div`
    width: 60%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--black);
`;

export const CertificateContainer = styled.div`
    background-color: var(--white);
    color: #13364d;
`;

export const CertificateStatement = styled.div`
    font-size: 28px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 20px;
`;

export const RacgpCpdProviderLogo = styled.img`
    width: 193px;
    margin-bottom: 20px;
`;

interface MyHealthLogoProps {
    marginBottom?: string;
}

export const MyHealthLogo = styled.img<MyHealthLogoProps>`
    width: 100px;
    margin-bottom: ${(props) => props.marginBottom || "40px"};
`;

export const EmployeeName = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
`;

export const RacgpNumber = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 40px;
`;

interface CertificateTextProps {
    marginTop?: string;
    marginBottom?: string;
}

export const CertificateText = styled.div<CertificateTextProps>`
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    margin-top: ${(props) => props.marginTop || "0"};
    margin-bottom: ${(props) => props.marginBottom || "20px"};
`;

export const CourseName = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    font-style: italic;
`;

export const ActivityId = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    font-style: italic;
`;

export const ApprovedAt = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 30px;
    font-style: italic;
`;

export const CpdTable = styled.div`
    background-color: #b2f1ea;
    padding: 6px;
`;

export const CpdInnerTableContainer = styled.div`
    background-color: var(--white);
    padding: 2px;
`;

export const CpdInnerTable = styled.div`
    background-color: #b2f1ea;
    padding: 6px;
`;

export const RacgpCpdApprovedActivityLogo = styled.img`
    width: 160px;
    margin: 6px;
`;

export const CpdTitle = styled.div`
    font-size: 6px;
    font-weight: 600;
    line-height: 9px;
    text-align: center;
    margin-bottom: 5px;
`;

export const CpdValue = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 9px;
    text-align: center;
    margin-bottom: 5px;
`;

export const CpdHoursLabel = styled.div`
    font-size: 6px;
    font-weight: 400;
    line-height: 9px;
    text-align: center;
`;

export const DownloadCertificateButtonText = styled.span`
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
`;

export const LearningJournalBackLink = styled.div`
    color: var(--primaryActiveHoverColor);
    text-decoration: underline;
`;

export const LearningMaterialCarouselImage = styled.img`
    width: 100%;
    max-height: 500px;
`;

interface UserCourseSubmissionStatusValueProps {
    status: UserCourseSubmissionStatus;
    isLarge?: boolean;
}

export const UserCourseSubmissionStatusValue = styled.span<UserCourseSubmissionStatusValueProps>`
    font-size: ${(props) => (props.isLarge ? "14px" : "12px")};
    line-height: ${(props) => (props.isLarge ? "24px" : "16px")};
    font-weight: 400;
    color: ${(props) =>
        props.status === UserCourseSubmissionStatus.PendingApproval
            ? "var(--primaryActiveHoverColor)"
            : props.status === UserCourseSubmissionStatus.Dismissed
            ? "#CB2424"
            : "var(--black)"};
`;

export const UserCourseSubmissionSubmissionDate = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--darkGrey);
`;

export const UserCourseSubmissionEmployeeName = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: var(--black);
`;

export const ResponsesMainContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

export const ApproveButton = styled.button`
    pointer-events: auto !important;
    background-color: var(--primaryButtonsLinksColor) !important;
    color: var(--white) !important;
    border-color: var(--primaryButtonsLinksColor) !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;

    &:disabled {
        cursor: not-allowed;
    }
`;

export const DismissButton = styled.button`
    pointer-events: auto !important;
    background-color: var(--white) !important;
    color: #cb2424 !important;
    border-color: #cb2424 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;

    &:disabled {
        background-color: var(--lightGrey) !important;
        border-color: var(--lightGrey) !important;
        color: var(--medGrey) !important;
        cursor: not-allowed;
    }
`;

export const DismissModal = styled.div`
    background-color: var(--white);
    padding: 24px;
    width: 400px;
`;

export const DismissModalTitle = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primaryHeadingsColor);
`;

export const DismissModalText = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    margin-top: 20px;
`;

interface DismissModalButtonProps {
    backgroundColor: string;
    color: string;
}

export const DismissModalButton = styled.button<DismissModalButtonProps>`
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    width: 175px;
    height: 32px;
    border: solid 1px var(--lightGrey);
    border-radius: 2px;
    padding-top: 23px;
    padding-bottom: 23px;
    border-radius: 4px;
`;

export const DismissModalRadioLabel = styled.label`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
`;

export const DismissModalTextareaLabel = styled.label`
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
`;

interface EventIconProps {
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
}

export const EventIcon = styled.img<EventIconProps>`
    width: 20px;
    height: 20px;
    margin-right: ${(props) => props.marginRight || 4}px;
    margin-left: ${(props) => props.marginLeft || 0}px;
    margin-top: ${(props) => props.marginTop || 0}px;
`;

interface EventDataItemProps {
    marginTop?: string;
}

export const EventDataItem = styled.span<EventDataItemProps>`
    font-size: 12px;
    line-height: 16px;
    margin-top: ${(props) => props.marginTop || 0};
`;

interface EventUsersAttendingTextProps {
    isFullyBooked?: boolean;
    isMostlyBooked?: boolean;
    isLightlyBooked?: boolean;
}

export const EventUsersAttendingText = styled.span<EventUsersAttendingTextProps>`
    color: ${(props) =>
        props.isFullyBooked
            ? "var(--errorRed)"
            : props.isMostlyBooked
            ? "var(--primaryActiveHoverColor)"
            : props.isLightlyBooked
            ? "#079455"
            : "inherit"};
    font-weight: ${(props) =>
        props.isMostlyBooked || props.isLightlyBooked ? 600 : 400};
`;

export const EventBookYourSpotButton = styled.button`
    margin-top: 16px;
    color: var(--white) !important;
    background-color: var(--primaryActiveHoverColor) !important;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--primaryActiveHoverColor);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 16px;

    font-weight: 600;
    line-height: 24px;

    &:disabled {
        background-color: #fbc39d !important;
        border: 1px solid #fbc39d;
        cursor: not-allowed;
    }
`;

interface LearningMaterialCardContainerProps {
    desktopWidth?: number;
    expandImage?: boolean;
    isExpanded?: boolean;
    disableHover?: boolean;
    isEvent?: boolean;
}

export const LearningMaterialCardContainer = styled.div<LearningMaterialCardContainerProps>`
    @media (min-width: 500px) {
        min-width: ${(props) => props.desktopWidth || 250}px !important;
        //max-width: ${(props) => props.desktopWidth || 250}px !important;

        > img {
            width: 100%;
            height: ${(props) =>
                props.expandImage && !props.isExpanded ? "110px" : "145px"};
            object-fit: cover;
        }
    }

    &:hover .card-title {
        color: ${(props) =>
            props.expandImage
                ? "var(--black)"
                : "var(--primaryActiveHoverColor)"};
    }

    & .title-text {
        height: ${(props) => (props.isEvent ? "auto" : "48px")};
    }

    & .description-text {
        height: ${(props) =>
            props.isEvent && !props.expandImage ? "72px" : "48px"};
    }
`;

export const UserResponsesTopBanner = styled.div`
    background-color: var(--white);
    display: flex;
    padding: 32px 132px;
    align-items: center;
    color: var(--gray-900, #101828);

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
`;

export const BookmarkIconContainer = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--success-100, #dcfae6);
`;

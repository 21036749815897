import { Context, ApplicationUser } from "./models";
import { KeyValuePair } from "../../models/KeyValuePair";
import moment from "moment";

const STORAGE_KEY = "auth";

/**
 * User's session context manager.
 */
class SessionManager {
  private static _isInitialized: boolean = false;
  private static _isFakeAuthorized: boolean = false;
  private static _context: Context = {};

  public static resetSession(): void {
    this._isInitialized = false;
    this._context = {};
  }

  public static initSession(sessionContext?: Context): void {
    if (this._isInitialized) {
      throw Error("SessionManager: already initialized.");
    }

    this._context = (sessionContext || {
      sessionData: {},
    }) as Context;

    this._isInitialized = true;

    const token = localStorage[STORAGE_KEY];
    this.setLogin(token, true);
  }

  private static throwIfNotInitialized() {
    if (!this._isInitialized) {
      throw Error(
        "SessionManager: you have to call 'SessionManager.initSession' for initialization."
      );
    }
  }

  // TODO: Remove this when database is implemented and token is generated
  public static setFakeAuthentication() {
    this._isFakeAuthorized = true;
  }

  public static getSessionContext(): Context {
    this.throwIfNotInitialized();
    return this._context;
  }

  public static getUser(): ApplicationUser {
    let context = this.getSessionContext();
    if (context) {
      return context.sessionData.applicationUser;
    }
    throw Error("SessionManager: current session was not initialized.");
  }

  public static getToken(): string {
    let context = this.getSessionContext();
    if (context) {
      return context.sessionData.token;
    }
    throw Error("SessionManager: current session was not initialized.");
  }

  public static setLogin(token: string, persist: boolean): ApplicationUser {
    const user = this.getUserFromToken(token);
    if (!user) {
      this.removeLogin();
      return null;
    }

    this.setUser(user, token);
    if (persist) {
      localStorage[STORAGE_KEY] = token;
    }

    return user;
  }

  public static removeLogin() {
    localStorage.removeItem(STORAGE_KEY);
    this.setUser(null, null);
  }

  private static setUser(user: ApplicationUser, token: string) {
    let context = this.getSessionContext();
    context.sessionData.applicationUser = user;
    context.sessionData.token = token;
  }

  public static get isAuthenticated(): boolean {
    return !!this.getUser() || this._isFakeAuthorized;
  }

  public static get hasElevatedPermission(): boolean {
    const token = this._context?.sessionData?.token;

    if (!token) {
      return false;
    }

    const dictionary = this.parseJwt(token);
    const roles = dictionary["role"];

    return typeof roles === "string" ? false : roles.length > 1;
  }

  private static getUserFromToken(token: string) {
    if (!token) {
      return null;
    }
    const payload = this.parseJwt(token);
    const expiry = payload["exp"];
    const expiryDate = moment.unix(parseInt(expiry));
    if (!expiryDate.isAfter(moment())) {
      return null;
    }
    const user = {
      userName: payload["unique_name"],
      email: payload["email"],
    } as ApplicationUser;
    return user;
  }

  private static parseJwt = (token): KeyValuePair<string, string> => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };
}

export default SessionManager;
export * from "./models";

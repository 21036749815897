import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { Disclosure, Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { SidePanel } from 'components/SidePanel';
import { Card, Container } from 'components/reactstrap';
import { isAdmin } from 'models/admin/UserRoles'
import { IApplicationState } from 'store'
import * as dashboard from 'store/dashboardStore'

import { ReactComponent as DashboardSvg } from "images/icon-dashboard.svg";
import { ReactComponent as Chevron } from "images/chevron-down.svg";
import { ReactComponent as CoursesSvg } from "images/icon-courses.svg";
import { ReactComponent as SupportSvg } from "images/icon-help.svg";
import { ReactComponent as MyLearningJournalSvg } from "images/icon-my-learning-journal.svg";
import { ReactComponent as UsersSvg } from "images/icon-users.svg";
import { ReactComponent as SettingsSvg } from "images/icon-nav-settings.svg";
import { ReactComponent as BellSvg } from "images/icon-bell.svg";
import { ReactComponent as HamburgerIcon } from "images/icon-hamburger.svg";
import { ReactComponent as LogoutSvg } from "images/icon-logout.svg";
import AcademyLogoImage from 'images/academy-logo.svg'

import UserMenu from '../dashboard/UserMenu';
import { CourseCategoryFilterValue } from 'common/CourseCategoryFilterValue';

interface HeaderProps {
  hideLogo?: boolean
  hideSearchBar?: boolean
  hideAccountInfo?: boolean
  hideAcademyLogo?: boolean
  showNavMenu?: boolean
}


enum Visibility {
  OnlyAdmin,
  OnlyUser,
  Both,
}

interface MenuItemBase {
  name: string;
  visibility: Visibility;
  svgComponent: any;
  disabled?: boolean;
}

type MenuItemParent = {
  children: MenuItem[];
} & MenuItemBase

type MenuItemChild = {
  path: string;
} & MenuItemBase

type MenuItem = MenuItemParent | MenuItemChild;


type Props = HeaderProps &
  dashboard.DashboardState &
  typeof dashboard.actionCreators


const menuItems: MenuItem[] = [
  {
    name: "Dashboard",
    path: "/dashboard",
    visibility: Visibility.OnlyAdmin,
    svgComponent: DashboardSvg,
  },
  {
    name: "Calendar",
    path: "/calendar",
    visibility: Visibility.Both,
    svgComponent: CoursesSvg,
    disabled: true,
  },
  {
    name: "My Learning Journal",
    visibility: Visibility.Both,
    svgComponent: MyLearningJournalSvg,
    children: [
      {
        name: "Courses",
        path: "/learning-materials",
        visibility: Visibility.Both,
        svgComponent: null,
      },
      {
        name: "Webinars",
        path: `/learning-materials?category=${CourseCategoryFilterValue.Webinars}`,
        visibility: Visibility.Both,
        svgComponent: null,
      },
      {
        name: "Conferences",
        path: `/learning-materials?category=${CourseCategoryFilterValue.Conferences}`,
        visibility: Visibility.Both,
        svgComponent: null,
      },
      {
        name: "Bookmarks",
        path: "#",
        visibility: Visibility.Both,
        svgComponent: null,
        disabled: true,
      },
      {
        name: "2023",
        path: "/learning-materials/2023",
        visibility: Visibility.Both,
        svgComponent: null,
      },
    ],
  },
  {
    name: "Users",
    visibility: Visibility.OnlyAdmin,
    svgComponent: UsersSvg,
    children: [
      {
        name: "Doctors",
        path: "/doctors",
        visibility: Visibility.OnlyAdmin,
        svgComponent: null,
      },
      {
        name: "Nurses",
        path: "/nurses",
        visibility: Visibility.OnlyAdmin,
        svgComponent: null,
      },
      {
        name: "Users",
        path: "/users",
        visibility: Visibility.OnlyAdmin,
        svgComponent: null,
      },
      {
        name: "Permissions",
        path: "#",
        visibility: Visibility.OnlyAdmin,
        svgComponent: null,
        disabled: true,
      },
    ],
  },
  {
    name: "Settings",
    visibility: Visibility.Both,
    svgComponent: SettingsSvg,
    children: [
      {
        name: "View Profile",
        path: "#",
        visibility: Visibility.Both,
        svgComponent: null,
        disabled: true,
      },
      {
        name: "Edit Details",
        path: "/myaccount",
        visibility: Visibility.Both,
        svgComponent: null,
      },
      {
        name: "Reports and Analytics",
        path: "#",
        visibility: Visibility.Both,
        svgComponent: null,
        disabled: true,
      },
      {
        name: "Integrations",
        path: "#",
        visibility: Visibility.Both,
        svgComponent: null,
        disabled: true,
      },
      {
        name: "Notification Settings",
        path: "#",
        visibility: Visibility.Both,
        svgComponent: null,
        disabled: true,
      },
      {
        name: "Manage Courses",
        path: "/admin/courses",
        visibility: Visibility.OnlyAdmin,
        svgComponent: null,
        disabled: false,
      },
    ],
  },
];

const linkClassName = "p-2 mx- flex align-content-center font-semibold text-gray-600 hover:text-primary"

type RenderMenuItemProps = {
  onClick?();
} & MenuItem;

const RenderMenuItem = ({ onClick, ...menuItem }: RenderMenuItemProps) => {
  if (menuItem.disabled) {
    return <span key={menuItem.name} className={classNames(linkClassName, "text-gray-600/25 pointer-events-none")}>
      <div >
        {menuItem.name}
      </div>
    </span>
  }
  if ('children' in menuItem) {
    return <Popover className="z-10" key={menuItem.name}>
      <Popover.Button className={classNames(linkClassName, "ui-open:text-primary")}>
        <div>{menuItem.name}</div>
      </Popover.Button>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel>
          {({ close }) =>
            <Card.Shell className="absolute left-1/2 -translate-x-1/2 z-20 mt-1 w-80 origin-top focus:outline-none">
              {menuItem.children.map((m) => <RenderMenuItem key={m.name} onClick={close} {...m} />)}
            </Card.Shell>
          }
        </Popover.Panel>
      </Transition>
    </Popover>
  }
  if ('path' in menuItem) {
    return <NavLink
      key={menuItem.name}
      to={menuItem.path}
      className={linkClassName}
      onClick={onClick}
    >
      <div>
        {menuItem.name}
      </div>
    </NavLink>
  }
}

const sidePanelLinkClassName = "block group w-full py-2 px-3 flex align-content-center font-semibold text-gray-600 hover:text-primary"

type RenderSidePanelMenuItemProps = {
  onClick?();
} & MenuItem;

const RenderSidePanelMenuItem = ({ onClick, ...menuItem }: RenderSidePanelMenuItemProps) => {
  const icon = (className?: string) => menuItem.svgComponent
    ? <menuItem.svgComponent className={classNames("inline-block w-6 h-6 mr-2 text-gray-500 group-hover:text-primary", className)} />
    : <div className='inline-block w-6 h-6 mr-2 text-gray-500' />

  if (menuItem.disabled) {
    return <div key={menuItem.name} className={classNames(sidePanelLinkClassName, "text-opacity-25 pointer-events-none")}>
      {icon("text-opacity-25")}
      <div >
        {menuItem.name}
      </div>
    </div>
  }
  if ('children' in menuItem) {
    return <Disclosure as="div" key={menuItem.name}>
      {({ open }) => <>
        <Disclosure.Button className={classNames(sidePanelLinkClassName)}>
          {icon()}
          <div>{menuItem.name}</div>
          <Chevron className={classNames('inline-block w-6 h-6 ml-auto text-gray-500', { '-scale-y-100': open })} />
        </Disclosure.Button>
        <Transition
          as={React.Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel>
            <div className="space-y-1 focus:outline-none">
              {menuItem.children.map((m) => <RenderSidePanelMenuItem key={m.name} onClick={onClick} {...m} />)}
            </div>
          </Disclosure.Panel>
        </Transition>
      </>}
    </Disclosure>
  }
  if ('path' in menuItem) {
    return <NavLink
      onClick={onClick}
      key={menuItem.name}
      to={menuItem.path}
      className={sidePanelLinkClassName}
    >
      {icon()}
      <div>
        {menuItem.name}
      </div>
    </NavLink>
  }
}

function filterMenuItem({ visibility }: MenuItem, isAdmin: boolean) {
  switch (visibility) {
    case Visibility.OnlyAdmin:
      return isAdmin;
    case Visibility.OnlyUser:
      return !isAdmin;
    default:
      return true;
  }
}

function reduceMenuItemArray(menuItems: MenuItem[], isAdmin: boolean): MenuItem[] {
  return menuItems.reduce((arr, menuItem) => {
    if (!filterMenuItem(menuItem, isAdmin)) {
      return arr;
    }
    if ('children' in menuItem) {
      arr.push({ ...menuItem, children: reduceMenuItemArray(menuItem.children, isAdmin) });
    } else {
      arr.push(menuItem);
    }
    return arr;
  }, [])
}

const Header: React.FC<Props> = ({
  currentEmployee,
  updateProfessionHeader,
  logoutUser,
  professionHeader,
  hideLogo,
  hideAcademyLogo,
  hideAccountInfo,
}) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false)

  React.useEffect(() => {
    let profHeader = 'GP'
    if (currentEmployee && currentEmployee.professionType) {
      switch (currentEmployee.professionType) {
        case 'General Practitioner':
          profHeader = 'GP'
          break
        case 'Registrar':
          profHeader = 'Registrar'
          break
        case 'Nurse':
          profHeader = 'Nurse'
          break
      }
    }
    updateProfessionHeader(profHeader)
  }, [currentEmployee, updateProfessionHeader]);

  let isAdminUser =
    currentEmployee != null &&
    currentEmployee.userRoles != null &&
    isAdmin(currentEmployee.userRoles.role);

  const activeMenuItems = React.useMemo(() => reduceMenuItemArray(menuItems, isAdminUser), [isAdminUser]);

  const closeSidepanel = useCallback(() => setShowOffcanvas(false), [])
  const doLogout = useCallback(() => {
    logoutUser();
    closeSidepanel();
  }, [closeSidepanel, logoutUser])

  return (
    <>
      <nav
        className={"absolute z-20 w-full top-0 h-12 sm:h-16 md:h-20 bg-white flex-nowrap border-b-2 border-gray-200 py-2"}
      >
        <Container className='h-full flex flex-row' style={{ alignItems: 'normal' }}>
          <Link className="block mr-8" to="/">
            <img src={AcademyLogoImage}
              className='h-full w-auto' alt='MyHealth Academy' />
          </Link>
          <div className='hidden lg:flex flex-row flex-1'>
            <div className='h-full flex flex-row items-center space-x-4'>
              {activeMenuItems.map((m) => <RenderMenuItem key={m.name} {...m} />)}
            </div>

            <div className='h-full flex flex-row items-center space-x-4 ml-auto'>
              <NavLink to="/help" className="block hover:text-primary p-2">
                <SupportSvg className="w-6 h-6" />
              </NavLink>
              <NavLink to="#" className="hidden p-2">
                <BellSvg className="w-6 h-6" />
              </NavLink>
              <UserMenu />
            </div>
          </div>

          <div className="flex items-center ml-auto lg:hidden space-x-1 sm:space-x-2">
            <NavLink to="#" className="hidden p-2">
              <BellSvg className="w-6 h-6" />
            </NavLink>
            <button className="p-2 -mr-2 rounded-md" onClick={() => setShowOffcanvas(!showOffcanvas)}>
              <span className="sr-only">Open menu</span>
              <HamburgerIcon className="w-6 h-6" />
            </button>
          </div>
        </Container>
      </nav>
      <SidePanel open={showOffcanvas} setOpen={setShowOffcanvas}>
        <div className='h-100 flex flex-col'>
          <div className='px-4 mt-1 sm:px-6'>
            <Link onClick={closeSidepanel} className="inline-block p-2 -ml-2" to="/">
              <img src={AcademyLogoImage}
                className='max-h-10 w-auto' alt='MyHealth Academy' />
            </Link>
          </div>
          <div className='relative overflow-y-auto flex-1 flex flex-col px-2 mt-2 py-2 space-y-1 justify-start'>
            {activeMenuItems.map((m) => <RenderSidePanelMenuItem key={m.name} onClick={closeSidepanel} {...m} />)}
            <div className='flex-1'></div>
            <RenderSidePanelMenuItem path='/help' svgComponent={SupportSvg} name='Support' onClick={closeSidepanel} visibility={Visibility.Both} />
          </div>
          <div className='border-top'>
            <div className='flex flex-row'>
              <Link onClick={closeSidepanel} to="/myaccount" className='flex items-center overflow-hidden flex-row flex-1 p-3 space-x-4 hover:bg-gray-200'>
                <div
                  className={classNames(
                    'bg-gray-100 w-10 h-10 rounded-full flex-shrink-0',
                    'flex items-center justify-center select-none text-gray-700',
                  )}
                >
                  {currentEmployee ? `${currentEmployee?.firstName[0].toUpperCase()}${currentEmployee?.lastName[0].toUpperCase()}` : '...'}
                </div>
                <div className='break-words overflow-hidden text-gray-700'>
                  <span className='font-semibold block overflow-hidden whitespace-nowrap text-ellipsis'>{currentEmployee ? `${currentEmployee.firstName} ${currentEmployee.lastName}` : '...'}</span>
                  <span className='block overflow-hidden whitespace-nowrap text-ellipsis'>{currentEmployee && currentEmployee.email}</span>
                </div>
              </Link>
              <button onClick={doLogout} className='p-3 flex flex-row items-center hover:bg-gray-200'>
                <LogoutSvg className="w-6 h-6 text-gray-700" />
              </button>
            </div>
          </div>
        </div>
      </SidePanel>
      <div className='pt-12 sm:pt-16 md:pt-20'></div>
    </>
  )
}

export default connect(
  (state: IApplicationState) => ({
    ...state.dashboard,
  }),
  {
    ...dashboard.actionCreators,
  },
)(Header as any) as any
import React from 'react'
import {
  EventHighlightsSection,
  CenteredSectionTitleContainer,
  EventHighlightsSectionContainer,
  EventHighlightsCardContainer,
  CardImageContainer,
  CardTitleContainer,
  CardDescriptionContainer,
  EventHighlightsCardsContainer,
  CardTextContainer,
} from './styles/EventHighlights'
import { CourseEvent } from '../../models/CourseEvent'
import BookIcon from '../../images/book-open.svg'
import HandIcon from '../../images/hand.svg'
import MessageIcon from '../../images/message-chat-circle.svg'
import './styles/Event.scss';

interface EventHighlightsProps {
  courseEvent: CourseEvent
}

function Highlight({ title, description, Icon }) {
  return title ?
    <EventHighlightsCardContainer>
      <CardImageContainer>
        <img src={Icon} style={{ height: 24, width: 24 }} alt={title} />
      </CardImageContainer>
      <CardTextContainer>
        <CardTitleContainer>
          {title}
        </CardTitleContainer>
        {description &&
          <CardDescriptionContainer>
            {description}
          </CardDescriptionContainer>
        }
      </CardTextContainer>
    </EventHighlightsCardContainer> : <></>
}

const EventHighlights = (props: EventHighlightsProps) => {
  const { courseEvent } = props

  if (!courseEvent.highlightOneTitle && !courseEvent.highlightTwoTitle && !courseEvent.highlightThreeTitle) {
    return null;
  }

  return (
    <EventHighlightsSection>
      <div className="container">
        <EventHighlightsSectionContainer>
          <CenteredSectionTitleContainer>Highlights</CenteredSectionTitleContainer>
          <EventHighlightsCardsContainer>
            <Highlight
              title={courseEvent.highlightOneTitle}
              description={courseEvent.highlightOneDescription}
              Icon={BookIcon}
            />
            <Highlight
              title={courseEvent.highlightTwoTitle}
              description={courseEvent.highlightTwoDescription}
              Icon={MessageIcon}
            />
            <Highlight
              title={courseEvent.highlightThreeTitle}
              description={courseEvent.highlightThreeDescription}
              Icon={HandIcon}
            />
          </EventHighlightsCardsContainer>
        </EventHighlightsSectionContainer>
      </div>
    </EventHighlightsSection>
  )
}

export default EventHighlights

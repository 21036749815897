import React from "react";
import {
    Menu,
    Transition
} from '@headlessui/react';
import classNames from "classnames";

import { ReactComponent as ChevronDown } from 'images/chevron-down.svg';
import { ReactComponent as IconTick } from 'images/icon-tick.svg';

interface Props {
    options: any[];
    selectedValue: any;
    onSelected: (value: any) => void;
    labelSelector?: (option: any) => string;
    keySelector?: (option: any) => string | number;
    className?: string;
    dropdownHeader?: string;
}

export const FilterDropDown: React.FC<Props> = (props: Props) => {
    const {
        options,
        selectedValue,
        onSelected,
        labelSelector,
        keySelector,
        className,
        dropdownHeader,
    } = props;

    return (
        <Menu as="div" className={classNames("relative inline-block text-left", className)}>
            <div>
                <Menu.Button className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-4 py-2 text-base font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {labelSelector ? labelSelector(selectedValue) : selectedValue}
                    <ChevronDown className="-mr-1 h-5 w-5 text-gray-400" />
                </Menu.Button>
            </div>

            <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {dropdownHeader &&
                            <span
                                className="text-sm font-normal px-2 text-gray"
                            >{dropdownHeader}</span>
                        }
                        {options.map((o) => {
                            const isSelected = keySelector
                                ? keySelector(o) === keySelector(selectedValue)
                                : o === selectedValue;
                            return <Menu.Item
                                key={(keySelector
                                    ? keySelector(o) : o) ?? "default"}
                            >
                                {({ active, disabled }) => (
                                    <button
                                        key={keySelector
                                            ? keySelector(o) : o}
                                        className={classNames('w-full flex px-4 py-2 text-sm font-medium text-gray-700',
                                            {
                                                'bg-gray-50 text-gray-900': active,
                                                'text-gray-200': disabled
                                            }
                                        )}
                                        onClick={() => onSelected(o)}
                                        disabled={disabled}
                                    >
                                        {labelSelector ? labelSelector(o) : o}
                                        {isSelected && (
                                            <IconTick
                                                className="ms-auto inline h-5 w-5"
                                            />
                                        )}

                                    </button>
                                )}
                            </Menu.Item>
                        })}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
};

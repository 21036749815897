import React, { useCallback } from 'react';
import { Select, SelectProps } from './Select';

type Props = {
    setTimezone(tz: string);
    value: string;
} & Omit<SelectProps, "onChange">

export const SelectTimezone = ({ setTimezone, ...props }: Props) => {
    const onChange = useCallback((e) => { setTimezone(e.target.value) }, [setTimezone])

    return <Select
        label="Timezone"
        id="timezone"
        placeholder="Select a Timezone"
        onChange={onChange}
        {...props}
    >
        <option
            key="Australia/Sydney"
            value="Australia/Sydney"
        >
            Australia/Sydney
        </option>
        <option
            key="Australia/ACT"
            value="Australia/ACT"
        >
            Australia/ACT
        </option>
        <option
            key="Australia/Adelaide"
            value="Australia/Adelaide"
        >
            Australia/Adelaide
        </option>
        <option
            key="Australia/Brisbane"
            value="Australia/Brisbane"
        >
            Australia/Brisbane
        </option>
        <option
            key="Australia/Broken_Hill"
            value="Australia/Broken_Hill"
        >
            Australia/Broken_Hill
        </option>
        <option
            key="Australia/Canberra"
            value="Australia/Canberra"
        >
            Australia/Canberra
        </option>
        <option
            key="Australia/Currie"
            value="Australia/Currie"
        >
            Australia/Currie
        </option>
        <option
            key="Australia/Darwin"
            value="Australia/Darwin"
        >
            Australia/Darwin
        </option>
        <option
            key="Australia/Eucla"
            value="Australia/Eucla"
        >
            Australia/Eucla
        </option>
        <option
            key="Australia/Hobart"
            value="Australia/Hobart"
        >
            Australia/Hobart
        </option>
        <option
            key="Australia/LHI"
            value="Australia/LHI"
        >
            Australia/LHI
        </option>
        <option
            key="Australia/Lindeman"
            value="Australia/Lindeman"
        >
            Australia/Lindeman
        </option>
        <option
            key="Australia/Lord_Howe"
            value="Australia/Lord_Howe"
        >
            Australia/Lord_Howe
        </option>
        <option
            key="Australia/Melbourne"
            value="Australia/Melbourne"
        >
            Australia/Melbourne
        </option>
        <option
            key="Australia/NSW"
            value="Australia/NSW"
        >
            Australia/NSW
        </option>
        <option
            key="Australia/North"
            value="Australia/North"
        >
            Australia/North
        </option>
        <option
            key="Australia/Perth"
            value="Australia/Perth"
        >
            Australia/Perth
        </option>
        <option
            key="Australia/Queensland"
            value="Australia/Queensland"
        >
            Australia/Queensland
        </option>
        <option
            key="Australia/South"
            value="Australia/South"
        >
            Australia/South
        </option>
        <option
            key="Australia/Tasmania"
            value="Australia/Tasmania"
        >
            Australia/Tasmania
        </option>
        <option
            key="Australia/Victoria"
            value="Australia/Victoria"
        >
            Australia/Victoria
        </option>
        <option
            key="Australia/West"
            value="Australia/West"
        >
            Australia/West
        </option>
        <option
            key="Australia/Yancowinna"
            value="Australia/Yancowinna"
        >
            Australia/Yancowinna
        </option>
    </Select>
}
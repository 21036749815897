import React from 'react';
import { Moment } from 'moment';
import { Controller, ControllerProps } from 'react-hook-form';
import ReactDateTime, { DatetimepickerProps } from "react-datetime";

import { InputDecorator, InputDecoratorProps } from './InputDecorator';

type DateTimeProps<F> =
    & InputDecoratorProps
    & Omit<ControllerProps<F>, "render">
    & Omit<DatetimepickerProps, "onChange" | "name" | "value">

export const DateTime = <F,>({ className, hint, label, error, id, fieldError, name, control, rules, ...datePickerProps }: DateTimeProps<F>) => {
    return <InputDecorator
        id={id}
        className={className}
        error={error}
        label={label}
        hint={hint}
        fieldError={fieldError}
    >
        <Controller
            rules={rules}
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
                <ReactDateTime
                    onChange={onChange}
                    value={value as Moment}
                    onClose={onBlur}
                    {...datePickerProps}
                />
            )}
        />
    </InputDecorator>
};

import React, { useCallback } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { CreateCourse } from 'models/CreateCourse';
import { CourseService } from 'services/CourseService';
import { useHistory, useLocation } from 'react-router';
import Button from 'components/shared/Button';
import { toast } from 'react-toastify';
import CourseDetailsForm from 'components/admin/CourseDetailsForm';
import moment from 'moment';

export const CreateCoursePage = () => {
    const history = useHistory();
    const { pathname } = useLocation();

    const backPath = `${pathname.replace("/new", ``)}`

    const onSubmit = useCallback(async (createCourse: CreateCourse) => {
        try {
            const createdCourse = await CourseService.Create(createCourse);

            if (!createdCourse.hasErrors) {
                toast(`Created ${createdCourse.value.title}`)
                history.push(`${backPath}/${createdCourse.value.id}`);
            }
        } catch {

        }
    }, [backPath, history]);

    return <Container className='py-4'>
        <Row>
            <Col>
                <h3 className='mb-3'>Create Course</h3>
            </Col>
            <Col className='d-flex justify-content-end'>
                <Button variant='secondary' className='mb-3' to={backPath}>Back</Button>
            </Col>
        </Row>

        <CourseDetailsForm
            handleSubmit={onSubmit}
            actionLabel='Create'
            initialValues={{
                year: moment().year()
            }}
        />
    </Container>
}
import React, { useEffect } from "react";

import Button from "components/shared/Button";
import { StyledSVG } from "components/dashboard/styles/Common";
import iconFileCheck from "images/icon-file-check.svg";
import { CourseUserResponse } from "models/CourseUserResponse";
import { Employee } from "models/Employee";
import { UpdateUserCourseAnswerPayload } from "models/UpdateUserCourseAnswerPayload";
import { UserCourseCompletionStatus } from "models/enums/UserCourseCompletionStatus";
import { ReactComponent as IconBookmarkCheck } from "images/bookmark-check.svg";

import {
    UserResponsesLeftContainer,
    AdminResponsesRightContainer,
    ResponsesMainContainer,
    UserResponsesLeftContainerLink,
    UserResponsesTopBanner,
    BookmarkIconContainer,
    ResponseMissingMessageContainer,
} from "../styles/LearningMaterials";
import LearningMaterialResponses from "./LearningMaterialResponses";
import "./styles/LearningMaterialDetails.scss";
import { Col, Row } from "reactstrap";
import { UserCourseSubmissionStatus } from "models/enums/UserCourseSubmissionStatus";
import { useLocation } from "react-router";
import urlcat from "urlcat";
import { UserTab } from ".";

interface LearningMaterialUserResponsesProps {
    courseId: number;
    courseTitle: string;
    courseUserResponses: CourseUserResponse;
    currentEmployee: Employee;
    fetchCourseResponses: (courseId: number) => void;
    updateUserCourseAnswer: (
        userCourseAnswerId: string,
        payload: UpdateUserCourseAnswerPayload
    ) => any;
    scrollToBlock: (blockTitle: string) => void;
}

const LearningMaterialUserResponses: React.FC<
    LearningMaterialUserResponsesProps
> = (props: LearningMaterialUserResponsesProps) => {
    const {
        courseId,
        courseTitle,
        courseUserResponses,
        currentEmployee,
        fetchCourseResponses,
        updateUserCourseAnswer,
        scrollToBlock,
    } = props;

    const { pathname } = useLocation();

    useEffect(() => {
        if (currentEmployee != null) {
            fetchCourseResponses(courseId);
        }
    }, [courseId, currentEmployee, fetchCourseResponses]);

    if (!courseUserResponses?.submissionDate && courseUserResponses?.completionStatus !== UserCourseCompletionStatus.Completed) {
        return <div className="flex flex-col align-items-center pt-5">
            <div className="pt-5 mb-4">
                <StyledSVG src={iconFileCheck} size="40px" />
            </div>
            <ResponseMissingMessageContainer>
                When you complete the course content, your responses will be recorded here.
                <br />
                <br />
                Once your responses have been recorded, you will be able to edit them until your submission has been approved by our CPD Admin Team.
            </ResponseMissingMessageContainer>
        </div>
    }

    return (
        <>
            <UserResponsesTopBanner className="flex justify-between align-items-center w-full">
                <div>{courseTitle}</div>
                <div className="flex align-items-center">
                    <BookmarkIconContainer className="me-2">
                        <IconBookmarkCheck />
                    </BookmarkIconContainer>
                    <Button
                        variant="primary"
                        className="enrolled-button"
                        disabled={true}
                    >
                        Enrolled
                    </Button>
                </div>
            </UserResponsesTopBanner>
            <ResponsesMainContainer className="flex">
                <UserResponsesLeftContainer className="card h-100 p-3 overflow-auto">
                    <div className="flex justify-content-center p-3">
                        <div></div>
                        <div>
                            {courseUserResponses != null &&
                                courseUserResponses.blocks?.map(
                                    (block, index) => (
                                        <UserResponsesLeftContainerLink
                                            key={index}
                                            className="mb-3"
                                            onClick={() =>
                                                scrollToBlock(block.blockTitle)
                                            }
                                        >
                                            {block.blockTitle}
                                        </UserResponsesLeftContainerLink>
                                    )
                                )}
                        </div>
                        <div></div>
                    </div>
                </UserResponsesLeftContainer>
                <AdminResponsesRightContainer className="flex-grow-1 card p-3 flex flex-col h-100">
                    {courseUserResponses && (
                        <LearningMaterialResponses
                            updateUserCourseAnswer={async (
                                courseQuestionId: string,
                                payload: UpdateUserCourseAnswerPayload
                            ) => {
                                await updateUserCourseAnswer(
                                    courseQuestionId,
                                    payload
                                );

                                fetchCourseResponses(courseId);
                            }}
                            responseItem={courseUserResponses}
                            isAdminUser={false}
                        />
                    )}
                    {courseUserResponses.submissionStatus === null &&
                        <Row>
                            <Col className="mt-4" xs={12}>
                                <Button
                                    className="ms-auto"
                                    to={urlcat(
                                        pathname,
                                        {
                                            "activeTab": UserTab.Evaluation
                                        })}
                                >Continue to evaluation</Button>
                            </Col>
                        </Row>
                    }
                </AdminResponsesRightContainer>
            </ResponsesMainContainer>
        </>
    );
};

export default LearningMaterialUserResponses;

import "./styles/DoctorDetailsModal.scss";
import React, { useState } from "react";
import { Button, Modal, Nav, NavItem, NavLink } from "reactstrap";
import { Employee } from "../../models/Employee";
import DoctorCourses from "./DoctorCourses";
import DoctorCertificates from "./DoctorCertificates";
import { ContextMenu } from "../../components/shared/ContextMenu";
import DoctorDetails from "./DoctorDetails";

enum DoctorDetailsTab {
    Profile,
    Courses,
    Certificates,
}

interface Props {
    employee: Employee;
    onClose: () => void;
    onRmeoveUser: (employeeId: number) => Promise<void>;
    onMarkUserAsActiveOrInactive: (employeeId: number) => Promise<void>;
    onShowRevokeInviteModal: () => void;
    allowedProfessionalTypes: string[];
}

export const DoctorDetailsModal: React.FC<Props> = (props: Props) => {
    const { employee } = props;
    const [selectedTabIndex, setSelectedTabIndex] = useState(
        DoctorDetailsTab.Profile
    );
    const [canEditEmployeeDetails, setCanEditEmployeeDetails] = useState(false);
    const [
        isMarkUserAsInactiveModalOpened,
        setIsMarkUserAsInactiveModalOpened,
    ] = useState(false);
    const [isRemoveUserModalOpened, setIsRemoveUserModalOpened] =
        useState(false);
    const [newState, setNewState] = useState("");

    React.useEffect(() => {
        setCanEditEmployeeDetails(false);
        setNewState(employee.isActive ? "Inactive" : "Active");
    }, [employee]);

    return (
        <React.Fragment>
            <Modal
                className="mark-user-as-inactive-modal"
                isOpen={isMarkUserAsInactiveModalOpened}
                toggle={() => setIsMarkUserAsInactiveModalOpened(false)}
                centered={true}
            >
                <div className="p-3">
                    <h5>Mark user as {newState}</h5>
                    <p>
                        Are you sure you want to mark this user as {newState}?
                    </p>

                    <div className="row mt-3 mx-0">
                        <Button
                            color="secondary"
                            className="col me-3"
                            onClick={() =>
                                setIsMarkUserAsInactiveModalOpened(false)
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            color="danger"
                            className="col"
                            onClick={async () => {
                                await props.onMarkUserAsActiveOrInactive(
                                    employee.id
                                );
                                setIsMarkUserAsInactiveModalOpened(false);
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="mark-user-as-inactive-modal"
                isOpen={isRemoveUserModalOpened}
                toggle={() => setIsRemoveUserModalOpened(false)}
                centered={true}
            >
                <div className="p-3">
                    <h5>Remove user</h5>
                    <p>Are you sure you want to remove this user?</p>

                    <div className="row mt-3 mx-0">
                        <Button
                            color="secondary"
                            className="col me-3"
                            onClick={() => setIsRemoveUserModalOpened(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="danger"
                            className="col"
                            onClick={async () => {
                                await props.onRmeoveUser(employee.id);
                                setIsRemoveUserModalOpened(false);
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className="dashboard-modal flex">
                <div className="flex flex-col flex-grow-1">
                    <div className="p-3 pb-0">
                        <div className="flex justify-content-end">
                            <Button
                                className="p-0 m-0 bg-none border-0"
                                style={{ height: 0 }}
                                onClick={props.onClose}
                            >
                                x
                            </Button>
                        </div>
                        <div className="flex items-center">
                            <h3 className="py-0 d-inline-flex my-0">
                                {employee.firstName} {employee.lastName}
                            </h3>
                            <div className="ml-2">
                                <ContextMenu
                                    menuitems={[
                                        {
                                            id: 0,
                                            label: "Edit user",
                                            onAction: (_) => {
                                                setCanEditEmployeeDetails(true);
                                                setSelectedTabIndex(
                                                    DoctorDetailsTab.Profile
                                                );
                                            },
                                        },

                                        employee.isInvitePending
                                            ? {
                                                id: 1,
                                                label: "Revoke Invite",
                                                className: "text-danger",
                                                onAction: (_) =>
                                                    props.onShowRevokeInviteModal(),
                                            }
                                            : {
                                                id: 1,
                                                label: `Mark user as ${newState}`,
                                                className: "text-danger",
                                                onAction: (_) =>
                                                    setIsMarkUserAsInactiveModalOpened(
                                                        true
                                                    ),
                                            },

                                        {
                                            id: 3,
                                            label: "Remove user",
                                            className: "text-danger",
                                            onAction: (_) =>
                                                setIsRemoveUserModalOpened(
                                                    true
                                                ),
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <span className="text-gray text-sm">
                            {employee.professionType}
                        </span>
                    </div>
                    <hr className="my-0" />
                    <Nav tabs>
                        <NavItem
                            className={
                                selectedTabIndex === DoctorDetailsTab.Profile
                                    ? "category-nav-tab active"
                                    : "category-nav-tab"
                            }
                        >
                            <NavLink
                                role="button"
                                className={
                                    selectedTabIndex ===
                                        DoctorDetailsTab.Profile
                                        ? "active"
                                        : ""
                                }
                                onClick={() =>
                                    setSelectedTabIndex(
                                        DoctorDetailsTab.Profile
                                    )
                                }
                            >
                                Profile
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className={
                                selectedTabIndex === DoctorDetailsTab.Courses
                                    ? "category-nav-tab active"
                                    : "category-nav-tab"
                            }
                        >
                            <NavLink
                                role="button"
                                className={
                                    selectedTabIndex ===
                                        DoctorDetailsTab.Courses
                                        ? "active"
                                        : ""
                                }
                                onClick={() =>
                                    setSelectedTabIndex(
                                        DoctorDetailsTab.Courses
                                    )
                                }
                            >
                                Courses
                            </NavLink>
                        </NavItem>
                        <NavItem
                            className={
                                selectedTabIndex ===
                                    DoctorDetailsTab.Certificates
                                    ? "category-nav-tab active"
                                    : "category-nav-tab"
                            }
                        >
                            <NavLink
                                role="button"
                                className={
                                    selectedTabIndex ===
                                        DoctorDetailsTab.Certificates
                                        ? "active"
                                        : ""
                                }
                                onClick={() =>
                                    setSelectedTabIndex(
                                        DoctorDetailsTab.Certificates
                                    )
                                }
                            >
                                Certificates{" "}
                                <div className="badge rounded-pill ms-1">
                                    {
                                        employee.courses.filter(
                                            (c) => c.approvedAt != null
                                        ).length
                                    }
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    {selectedTabIndex === DoctorDetailsTab.Profile && (
                        <DoctorDetails
                            employee={employee}
                            canEdit={canEditEmployeeDetails}
                            onCloseEdit={() => setCanEditEmployeeDetails(false)}
                            allowedProfessionalTypes={
                                props.allowedProfessionalTypes
                            }
                        />
                    )}
                    {selectedTabIndex === DoctorDetailsTab.Courses && (
                        <DoctorCourses employee={employee} />
                    )}
                    {selectedTabIndex === DoctorDetailsTab.Certificates && (
                        <DoctorCertificates employee={employee} />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

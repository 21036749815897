import ReactGA from "react-ga4";
const InitializeGoogleAnalytics = () => {
    // Initialize GA4 - Add measurement ID
    ReactGA.initialize(process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID, {

    });
};

const TrackGoogleAnalyticsEvent = (
    category: string,
    action: string,
    label: string
) => {
    console.log("GA event:", category, ":", action, ":", label);
    // Send GA4 Event
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
};

export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent };
import "./styles/UploadSubmissionFormAndButton.scss";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as dashboard from "../../store/dashboardStore";
import { Button, Col, Modal, Row } from "reactstrap";
import { PersonalDetailsForm } from "../auth/PersonalDetailsForm";
import { PersonalDetails } from "../../models/auth/PersonalDetails";
import { RoleType } from "../../models/admin/UserRoles";
import { ProfessionType } from "../../models/enums/ProfessionType";

interface Props {
    onInvitedUser: () => Promise<void>;
}

type PropsType = Props &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const InviteUserFormAndButton: React.FC<PropsType> = (props: PropsType) => {
    const formRef = useRef<any>();

    const { inviteEmployee } = props;

    const [isModalOpened, setIsModalOpened] = useState(false);

    const inviteUser = async (personalDetails: PersonalDetails) => {
        if (
            await inviteEmployee({
                ...personalDetails,
                role: RoleType.USER,
                professionType: "Nurse",
            })
        ) {
            setIsModalOpened(false);
            await props.onInvitedUser();
        }
    };

    return (
        <>
            {isModalOpened && (
                <Modal
                    className="upload-submission-modal"
                    isOpen={isModalOpened}
                    centered
                >
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col sm={4} className="bg-light p-3 pt-5">
                                <h5>Invite a Nurse</h5>
                                <p className="description">
                                    Enter the details of the nurse you would
                                    like to invite to the portal. We’ll send
                                    them an email for their account.
                                </p>
                            </Col>
                            <Col sm={8} className="p-0">
                                <div className="p-3">
                                    <PersonalDetailsForm
                                        canEdit={true}
                                        hideTitle
                                        onContinue={inviteUser}
                                        allowedProfessionTypes={[
                                            ProfessionType.Nurse,
                                        ]}
                                    />
                                </div>

                                <hr />

                                <div className="flex justify-content-end p-3 pt-0">
                                    <Button
                                        color="secondary"
                                        className="me-3"
                                        onClick={() => {
                                            setIsModalOpened(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={false}
                                        onClick={() =>
                                            formRef.current.handleSubmit()
                                        }
                                    >
                                        SEND INVITE
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}

            <Button
                className="btn btn-primary"
                onClick={() => setIsModalOpened(true)}
            >
                INVITE A USER
            </Button>
        </>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(InviteUserFormAndButton as any) as React.FC<Props>;

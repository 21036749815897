import styled from "styled-components";
import { UserCourseSubmissionStatus } from "../../../models/enums/UserCourseSubmissionStatus";

interface SortableColumnTextProps {
    isSorted: boolean;
}

export const SortableColumnText = styled.span<SortableColumnTextProps>`
    cursor: pointer;
    font-weight: ${(props) => (props.isSorted ? 600 : 400)};
`;

interface UserCourseSubmissionStatusCellValueProps {
    status: UserCourseSubmissionStatus;
}

export const UserCourseSubmissionStatusCellValue = styled.span<UserCourseSubmissionStatusCellValueProps>`
    color: ${(props) =>
        props.status === UserCourseSubmissionStatus.PendingApproval
            ? "var(--primaryActiveHoverColor)"
            : props.status === UserCourseSubmissionStatus.Dismissed
                ? "#CB2424"
                : "var(--black)"};
    var(--white)-space: nowrap;
`;

export const ViewResponsesLink = styled.span`
    color: var(--primaryButtonsLinksColor);
    var(--white)-space: nowrap;
`;

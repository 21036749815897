import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "reactstrap";
import _ from "lodash";

import { ContextMenu } from "components/shared/ContextMenu";
import { SearchBar } from "components/dashboard/styles/Common";
import { FilterDropDown } from "components/shared/FilterDropDown";
import { UserCourseSubmissionStatus } from "models/enums/UserCourseSubmissionStatus";
import { UserCourseSubmissionDismissReason } from "models/enums/UserCourseSubmissionDismissReason";
import { CourseUserResponse } from "models/CourseUserResponse";
import { Employee } from "models/Employee";
import { UpdateUserCourseAnswerPayload } from "models/UpdateUserCourseAnswerPayload";

import { AdminTab } from ".";
import LearningMaterialResponses from "./LearningMaterialResponses";
import "./styles/LearningMaterialDetails.scss";

import {
    AdminResponsesLeftContainer,
    AdminResponsesRightContainer,
    EmployeeResponseListItem,
    ResponsesTitle,
    ResponsesTitleCount,
    UserCourseSubmissionStatusValue,
    UserCourseSubmissionSubmissionDate,
    UserCourseSubmissionEmployeeName,
    ResponsesMainContainer,
    DismissModal,
    DismissModalTitle,
    DismissModalText,
    DismissModalRadioLabel,
    DismissModalTextareaLabel,
    DismissModalButton,
} from "../styles/LearningMaterials";

interface LearningMaterialAdminResponsesProps {
    courseId: number;
    courseResponses: CourseUserResponse[];
    selectedAdminTab: AdminTab;
    selectedEmployeeIndex: number;
    currentEmployee: Employee;
    isAdminUser: boolean;
    fetchCourseResponses: (
        courseId: number,
        filter: string,
        sort: string,
        status: UserCourseSubmissionStatus | null
    ) => void;
    downloadCourseResponsesCsvExport: (
        courseId: number,
        responsesFilter: string,
        responsesSort: string,
        employeeId?: number
    ) => void;
    downloadEvaluationFormSubmissionDocument: (
        evaluationFormId: number
    ) => void;
    approveEvaluationForm: (id: number, isReapprove?: boolean) => void;
    dismissEvaluationForm: (
        id: number,
        reason: UserCourseSubmissionDismissReason,
        comment: string
    ) => void;
    updateUserCourseAnswer: (
        userCourseAnswerId: string,
        userId: number,
        payload: UpdateUserCourseAnswerPayload
    ) => any;
}

const SubmissionDateFormat = new Intl.DateTimeFormat("en-AU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
});

const statusFilterItems: (UserCourseSubmissionStatus | null)[] = [null].concat(
    Object.keys(UserCourseSubmissionStatus)
        .filter((v) => isNaN(Number(v)))
        .map((x) => UserCourseSubmissionStatus[x])
);

const getSubmissionStatusText = (
    status: UserCourseSubmissionStatus
): string => {
    switch (status) {
        case UserCourseSubmissionStatus.PendingApproval:
            return "Pending Approval";
        default:
            return UserCourseSubmissionStatus[status].toString();
    }
};

export enum ResponsesSortType {
    Alphabetical = "Alphabetical",
    DateAdded = "Date Added",
}

const sortItems = [ResponsesSortType.Alphabetical, ResponsesSortType.DateAdded];

const LearningMaterialAdminResponses: React.FC<
    LearningMaterialAdminResponsesProps
> = (props: LearningMaterialAdminResponsesProps) => {
    const {
        courseId,
        courseResponses,
        selectedAdminTab,
        selectedEmployeeIndex,
        currentEmployee,
        isAdminUser,
        fetchCourseResponses,
        downloadCourseResponsesCsvExport,
        downloadEvaluationFormSubmissionDocument,
        approveEvaluationForm,
        dismissEvaluationForm,
        updateUserCourseAnswer,
    } = props;

    const history = useHistory();
    const location = useLocation();

    const [responsesFilter, setResponsesFilter] = useState("");
    const [responsesSort, setResponsesSort] = useState(
        ResponsesSortType.DateAdded
    );
    const [responsesStatusFilter, setResponsesStatusFilter] =
        useState<UserCourseSubmissionStatus | null>(null);

    const [dismissReason, setDismissReason] =
        useState<UserCourseSubmissionDismissReason>(
            UserCourseSubmissionDismissReason.InsufficientResponses
        );
    const [dismissComment, setDismissComment] = useState("");

    const [idToDismiss, setIdToDismiss] = useState<number>(NaN);

    const dismissModalDoctorName =
        courseResponses.find((x) => x.evaluationFormId === idToDismiss)
            ?.employeeFullName ?? "";

    const debouncedResults = useMemo(() => {
        return _.debounce((e: string) => {
            setResponsesFilter(e);
        }, 500);
    }, []);

    useEffect(() => {
        if (currentEmployee != null && isAdminUser) {
            fetchCourseResponses(
                courseId,
                responsesFilter,
                responsesSort,
                responsesStatusFilter
            );
        }
    }, [
        courseId,
        currentEmployee,
        fetchCourseResponses,
        isAdminUser,
        responsesFilter,
        responsesSort,
        responsesStatusFilter,
    ]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const selectedResponseItem = isNaN(selectedEmployeeIndex)
        ? null
        : courseResponses[selectedEmployeeIndex];

    return (
        <React.Fragment>
            <Modal
                isOpen={!isNaN(idToDismiss)}
                toggle={() => setIdToDismiss(NaN)}
                centered={true}
                modalClassName="dismiss-modal"
            >
                <DismissModal>
                    <DismissModalTitle>
                        Dismiss Course Attendance
                    </DismissModalTitle>
                    <DismissModalText>
                        Are you sure you want to dismiss{" "}
                        {dismissModalDoctorName}'s course attendance? Please
                        provide a reason below.
                    </DismissModalText>
                    <div className="ms-0 mt-2 mb-2 ps-0">
                        <input
                            type={"radio"}
                            value={
                                UserCourseSubmissionDismissReason.InsufficientResponses
                            }
                            name="insufficient-responses"
                            id={
                                Object.keys(UserCourseSubmissionDismissReason)[
                                UserCourseSubmissionDismissReason
                                    .InsufficientResponses
                                ]
                            }
                            onChange={() =>
                                setDismissReason(
                                    UserCourseSubmissionDismissReason.InsufficientResponses
                                )
                            }
                            checked={
                                dismissReason ===
                                UserCourseSubmissionDismissReason.InsufficientResponses
                            }
                        />
                        <DismissModalRadioLabel
                            className="ms-2"
                            htmlFor="insufficient-responses"
                        >
                            Insufficient Responses
                        </DismissModalRadioLabel>
                    </div>
                    <div className="ms-0 mt-2 mb-2 ps-0">
                        <input
                            type={"radio"}
                            value={
                                UserCourseSubmissionDismissReason.InsufficientSubmissionForm
                            }
                            id="insufficient-submission-form"
                            onChange={() =>
                                setDismissReason(
                                    UserCourseSubmissionDismissReason.InsufficientSubmissionForm
                                )
                            }
                            checked={
                                dismissReason ===
                                UserCourseSubmissionDismissReason.InsufficientSubmissionForm
                            }
                        />
                        <DismissModalRadioLabel
                            className="ms-2"
                            htmlFor="insufficient-submission-form"
                        >
                            Insufficient Submission Form
                        </DismissModalRadioLabel>
                    </div>
                    <div className="ms-0 mt-2 ps-0">
                        <DismissModalTextareaLabel>
                            Add comment (optional)
                        </DismissModalTextareaLabel>
                        <textarea
                            className="form-control"
                            value={dismissComment}
                            onChange={(e) => setDismissComment(e.target.value)}
                        />
                    </div>
                    <div className="ms-0 mt-2 ps-0 flex justify-between">
                        <DismissModalButton
                            className="flex align-items-center justify-content-center"
                            backgroundColor="var(--white)"
                            color="var(--darkGrey)"
                            style={{ marginRight: 10 }}
                            onClick={() => setIdToDismiss(NaN)}
                        >
                            Cancel
                        </DismissModalButton>
                        <DismissModalButton
                            className="flex align-items-center justify-content-center"
                            backgroundColor="#CB2424"
                            color="var(--white)"
                            style={{ border: "none" }}
                            onClick={() => {
                                dismissEvaluationForm(
                                    idToDismiss,
                                    dismissReason,
                                    dismissComment
                                );
                                setIdToDismiss(NaN);
                                setDismissReason(
                                    UserCourseSubmissionDismissReason.InsufficientResponses
                                );
                                setDismissComment("");
                            }}
                        >
                            Dismiss
                        </DismissModalButton>
                    </div>
                </DismissModal>
            </Modal>
            <ResponsesMainContainer className="flex">
                <AdminResponsesLeftContainer className="card h-100 overflow-auto">
                    <div className="flex justify-between align-items-center p-3">
                        <ResponsesTitle>
                            Responses{" "}
                            <ResponsesTitleCount>
                                ({courseResponses.length})
                            </ResponsesTitleCount>
                        </ResponsesTitle>
                        <ContextMenu
                            rightAligned={true}
                            menuitems={[
                                {
                                    id: 0,
                                    label: "Export CSV",
                                    onAction: () =>
                                        downloadCourseResponsesCsvExport(
                                            courseId,
                                            responsesFilter,
                                            responsesSort
                                        ),
                                },
                            ]}
                            icon={
                                require("images/icon-more-vertical.svg")
                                    .default
                            }
                        />
                    </div>
                    <div className="px-3">
                        <SearchBar
                            marginLeft={"0"}
                            placeholder="Search"
                            placeholderBackgroundPosition="10px center"
                            padding="10px 15px 10px 40px"
                            onChange={(e) => {
                                debouncedResults(e.target.value);
                            }}
                        />
                    </div>
                    <div className="px-2 mb-2 flex justify-between">
                        <FilterDropDown
                            className="sort-by-dropdown"
                            options={statusFilterItems}
                            onSelected={(x) => {
                                setResponsesStatusFilter(x);
                            }}
                            selectedValue={responsesStatusFilter}
                            labelSelector={(x) =>
                                x != null
                                    ? getSubmissionStatusText(x)
                                    : "All Statuses"
                            }
                        />

                        <FilterDropDown
                            className="sort-by-dropdown"
                            options={sortItems}
                            onSelected={setResponsesSort}
                            selectedValue={responsesSort}
                            dropdownHeader="Sort by"
                        />
                    </div>
                    <div>
                        {courseResponses.map((courseResponse, index) => {
                            return (
                                <EmployeeResponseListItem
                                    className="p-3"
                                    key={index}
                                    onClick={() =>
                                        history.replace({
                                            pathname: location.pathname,
                                            search: new URLSearchParams({
                                                activeTab: selectedAdminTab,
                                                employeeId:
                                                    selectedResponseItem !==
                                                        null &&
                                                        selectedResponseItem.employeeId ===
                                                        courseResponse.employeeId
                                                        ? null
                                                        : courseResponse.employeeId.toString(),
                                            }).toString(),
                                        })
                                    }
                                    isActive={selectedEmployeeIndex === index}
                                >
                                    {
                                        <div className="flex justify-between">
                                            <div>
                                                <UserCourseSubmissionSubmissionDate>
                                                    {SubmissionDateFormat.format(
                                                        courseResponse.submissionDate
                                                    )}
                                                </UserCourseSubmissionSubmissionDate>
                                                <UserCourseSubmissionEmployeeName>
                                                    {
                                                        courseResponse.employeeFullName
                                                    }
                                                </UserCourseSubmissionEmployeeName>
                                            </div>
                                            <UserCourseSubmissionStatusValue
                                                status={
                                                    UserCourseSubmissionStatus[
                                                    courseResponse.submissionStatus.toString() as keyof typeof UserCourseSubmissionStatus
                                                    ]
                                                }
                                            >
                                                {getSubmissionStatusText(
                                                    UserCourseSubmissionStatus[
                                                    courseResponse.submissionStatus.toString() as keyof typeof UserCourseSubmissionStatus
                                                    ]
                                                )}
                                            </UserCourseSubmissionStatusValue>
                                        </div>
                                    }
                                </EmployeeResponseListItem>
                            );
                        })}
                    </div>
                </AdminResponsesLeftContainer>
                <AdminResponsesRightContainer className="flex-grow-1 card p-3 flex flex-col h-100">
                    {selectedResponseItem && (
                        <LearningMaterialResponses
                            updateUserCourseAnswer={async (
                                courseQuestionId: string,
                                payload: UpdateUserCourseAnswerPayload
                            ) => {
                                await updateUserCourseAnswer(
                                    courseQuestionId,
                                    selectedResponseItem.userId,
                                    payload
                                );

                                fetchCourseResponses(
                                    courseId,
                                    responsesFilter,
                                    responsesSort,
                                    responsesStatusFilter
                                );
                            }}
                            responseItem={selectedResponseItem}
                            isAdminUser={isAdminUser}
                            approveEvaluationForm={(isReapprove) =>
                                approveEvaluationForm(
                                    selectedResponseItem.evaluationFormId,
                                    isReapprove
                                )
                            }
                            setIdToDismiss={(id) => setIdToDismiss(id)}
                            downloadCourseResponsesCsvExport={() =>
                                downloadCourseResponsesCsvExport(
                                    courseId,
                                    responsesFilter,
                                    responsesSort,
                                    selectedResponseItem.employeeId
                                )
                            }
                            downloadEvaluationFormSubmissionDocument={() =>
                                downloadEvaluationFormSubmissionDocument(
                                    selectedResponseItem.evaluationFormId
                                )
                            }
                        />
                    )}
                </AdminResponsesRightContainer>
            </ResponsesMainContainer>
        </React.Fragment>
    );
};

export default LearningMaterialAdminResponses;

import {
    validateMobile,
    validateEmail,
} from "components/helper/EmployeeHelper";

export const required =
    (message = "This is a required field") =>
    (value) =>
        value ? undefined : message;

export const email =
    (message = "Enter a valid email") =>
    (value) =>
        validateEmail(`${value}`)?.length ? undefined : message;

export const mobile =
    (message = "Enter a valid mobile number") =>
    (value) =>
        validateMobile(value) ? undefined : message;

export const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce(
            (error, validator) => error || validator(value),
            undefined
        );

export const min =
    (floor: number, message = "Too low") =>
    (value) =>
        value ?? true
            ? Number(value) >= Number(floor)
                ? undefined
                : message
            : undefined;

export const max =
    (ceil: number, message = "Too high") =>
    (value) =>
        value ?? true
            ? Number(value) <= Number(ceil)
                ? undefined
                : message
            : undefined;

export const maxLength =
    (max: number, errorMessage = "Too long") =>
    (value) =>
        typeof value === "string" || Array.isArray(value)
            ? value.length > max
                ? errorMessage
                : undefined
            : undefined;

export const minLength =
    (min: number, errorMessage = "Too short") =>
    (value) =>
        typeof value === "string" || Array.isArray(value)
            ? value.length < min
                ? errorMessage
                : undefined
            : undefined;

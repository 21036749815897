import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Col, Modal, Row } from "reactstrap";

import { UserSortColumn } from "common/UserSortColumn";
import { PageFilter } from "common/PageFilter";
import { PAGE_SIZE } from "consts";
import { useDebounce } from "hooks";
import { UserCourseSubmissionService } from "services/UserCourseSubmissionService";
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";
import { showDefault, showErrors } from "utils";

import "./styles/UploadSubmissionFormAndButton.scss";
import { UploadSubmissionForm, UploadSubmissionFormValues } from "./forms/UploadSubmissionForm";
import Button from "components/shared/Button";

interface Props {
    employeeId?: number;
}

type PropsType = Props &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const UploadSubmission: React.FC<PropsType> = ({
    fetchAllUsersPage,
    fetchEmployeeById,
    allUsersPage,
    employeeById,
    employeeId
}: PropsType) => {

    const formRef = useRef<HTMLFormElement>();

    const [isModalOpened, setIsModalOpened] = useState(false);

    const [userPage, setUserPage] = useState(0);
    const [searchInputValue, setSearchInputValue] = useState("");

    const debouncedSearch = useDebounce((e: string) => {
        setSearchInputValue(e);
        setUserPage(0);
    }, 500, []);

    const uploadSubmission = useCallback(async ({
        file,
        userCourseId
    }: UploadSubmissionFormValues) => {
        const formData = new FormData();
        formData.append("attachments", file);

        console.log(file, formData);

        const response =
            await UserCourseSubmissionService.UploadEvaluationFormSubmission(
                userCourseId,
                formData
            );
        if (response && !response.hasErrors) {
            showDefault("Sucessfully uploaded submission form.");
            setIsModalOpened(false);
        } else {
            showErrors("Failed to upload submission form.");
        }
    }, []);

    const onCancel = useCallback(() => {
        setIsModalOpened(false);
    }, []);

    useEffect(() => {
        if (employeeId) {
            fetchEmployeeById(employeeId);
        }
    }, [employeeId, fetchEmployeeById]);

    useEffect(() => {
        const filter: PageFilter[] = [];

        if (searchInputValue) {
            const childLevelFilters: PageFilter[] = [];
            childLevelFilters.push({
                filterName: "firstName",
                filterValue: searchInputValue,
                isContains: true,
            });
            childLevelFilters.push({
                filterName: "lastName",
                filterValue: searchInputValue,
                isContains: true,
            });
            filter.push({
                filterName: "",
                filterValue: "",
                isContains: false,
                childLevelFilters: childLevelFilters,
            });
        }

        fetchAllUsersPage(PAGE_SIZE, userPage, filter, UserSortColumn.Name);
    }, [userPage, searchInputValue, fetchAllUsersPage]);

    return (
        <>
            {isModalOpened && (
                <Modal
                    className=""
                    size="lg"
                    isOpen={isModalOpened}
                    centered
                >
                    <Row>
                        <Col
                            lg={4}
                            className="border-end p-4"
                        >
                            <img
                                className="modal-header-icon mb-2"
                                src={
                                    require("images/modal-check-square-broken.svg")
                                        .default
                                }
                                alt=""
                            />
                            <br />
                            <p>
                                Upload Submission
                            </p>
                            <p>
                                Please upload the course form completed by the user.
                            </p>

                            <p>
                                Once the submission is uploaded, the course will
                                be automatically marked as completed.
                            </p>
                        </Col>

                        <Col lg={8} className="ps-lg-0">
                            <UploadSubmissionForm
                                handleSubmit={uploadSubmission}
                                searchUsers={debouncedSearch}
                                formRef={formRef}
                                allUsersPage={allUsersPage}
                                onCancel={onCancel}
                                initialEmployee={employeeById}
                            />
                        </Col>
                    </Row>
                </Modal>
            )}
            <div>
                <Button
                    variant="primary"
                    onClick={() => setIsModalOpened(true)}
                >
                    Upload submission
                </Button>
            </div>
        </>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(UploadSubmission as any) as React.FC<Props>;

import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { Container } from "components/reactstrap";
import { ContactUsService } from "services/ContactUsService";

import { ContactForm, ContactFormValues } from "./forms/ContactForm";

export const HelpScreen: React.FC = () => {
    const submit = useCallback(async ({ subject, body, attachments }: ContactFormValues) => {
        const formData = new FormData();
        formData.append("subject", subject);
        formData.append("body", body);

        if (Array.isArray(attachments)) {
            for (const file of attachments) {
                formData.append("attachments", file);
            }
        }
        try {
            console.log({ subject, body, formData });
            const result = await ContactUsService.SendContactUsRequest(formData);
            console.log(result);
            if (result.hasErrors) {
                toast.error(result.friendlyError ?? result.errors);
            } else {
                toast.success("Your message was sent successfully.");
            }
        } catch {
            toast.error("Something went wrong.")
        }
    }, []);

    return (
        <Container className="py-3">
            <div className="prose max-w-none">
                <h1>Contact Us</h1>
                <p>
                    If you have any questions, just reach out to us and we&#8217;ll respond as soon as we can.
                </p>
                <p>
                    If it&#8217;s urgent, please call {" "}
                    <a href="tel:+61281972822" className="text-primary">
                        (02) 8197 2822
                    </a>
                    {", "}
                    <strong className="text-primary">9am - 5pm</strong>
                    {" "}
                    on Mon-Fri.
                </p>
            </div>

            <div className="lg:w-3/5">
                <ContactForm
                    handleSubmit={submit}
                />
            </div>
        </Container >
    );
};

import * as React from "react";
import AppRoute from "./components/shared/AppRoute";
import { Switch } from "react-router-dom";
import Signin from "./pages/auth/Signin";
import VerifyMobile from "./pages/auth/VerifyMobile";
import { GuestLeftSideLayout } from "./layouts/GuestLeftSideLayout";
import { GuestLayout } from "./layouts/GuestLayout";
import Signup from "./pages/auth/Signup";
import SignupComplete from "./pages/auth/SignupComplete";
import LearningMaterials from "./pages/learning-materials/LearningMaterials";
import LearningMaterialDetails from "./pages/learning-materials/details";
import DoctorAccount from "./pages/DoctorAccount";
import { Help } from "./pages/Help";
import EvaluationFormPage from "./pages/evaluation-form/EvaluationFormPage";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./components/dashboard/Users";
import AuthorizedLayout from "./layouts/AuthorizedLayout";
import Home from "./pages/Home";
import { DoctorsScreen } from "./pages/doctors/DoctorsScreen";
import { NursesScreen } from "./pages/doctors/Nurses";
import FinalizeSignup from "./pages/auth/FinalizeSignup";
import RegisterSessionAttendance from "./pages/register-session-attendance/RegisterSessionAttendance";
import { LandingPage } from "./pages/landing-page/LandingPage";
import NewLandingPage from "./pages/landing-page/NewLandingPage";
import { PrivacyPolicy } from "./pages/landing-page/PrivacyPolicy";
import FaceToFaceEvents from "./pages/face-to-face-events/FaceToFaceEvents";
import FaceToFaceEventDetails from "./pages/face-to-face-events/FaceToFaceEventDetails";
import FaceToFaceEventBooking from "./pages/face-to-face-events/FaceToFaceEventBooking";
import { HangfireContainer } from "./pages/hangfire/HangfireContainer";
import EmptyLayout from "./layouts/EmptyLayout";
import GuestLandingPageLayout from "./layouts/GuestLandingPageLayout";
import UpcomingEvent from "./pages/landing-page/UpcomingEvent";
import Courses from "pages/admin/courses";
import UpdateCoursePage from "pages/admin/courses/Update";
import { CreateCoursePage } from "pages/admin/courses/Create";
import UpcomingEventGroup from "pages/landing-page/UpcomingEventGroup";

export const routes = (
    <Switch>
        <AppRoute
            layout={GuestLandingPageLayout}
            layoutProps={{ hideHeader: false, hideWrapper: true }}
            exact
            path="/home"
            component={NewLandingPage}
        />
        <AppRoute
            layout={GuestLandingPageLayout}
            layoutProps={{ hideHeader: false }}
            exact
            path="/privacy-policy"
            component={PrivacyPolicy}
        />
        <AppRoute
            layout={GuestLandingPageLayout}
            layoutProps={{ hideHeader: false, hideWrapper: true }}
            exact
            path="/event/:id"
            component={UpcomingEvent}
        />
        <AppRoute
            layout={GuestLandingPageLayout}
            layoutProps={{ hideHeader: false, hideWrapper: true }}
            exact
            path="/event-group/:id"
            component={UpcomingEventGroup}
        />
        <AppRoute
            layout={GuestLayout}
            layoutProps={{ hideHeader: true }}
            exact
            path="/auth/signin"
            component={Signin}
        />
        <AppRoute
            layout={GuestLayout}
            layoutProps={{ hideHeader: true }}
            exact
            path="/auth/verify"
            component={VerifyMobile}
        />
        <AppRoute
            layout={GuestLayout}
            layoutProps={{ hideHeader: true }}
            exact
            path="/auth/signup/success"
            component={SignupComplete}
        />
        <AppRoute
            layout={GuestLayout}
            layoutProps={{ hideHeader: true }}
            exact
            path="/auth/signup"
            component={Signup}
        />
        <AppRoute
            layout={GuestLayout}
            layoutProps={{ hideHeader: true }}
            exact
            path="/auth/signup/finalize"
            component={FinalizeSignup}
        />
        <AppRoute
            layout={GuestLayout}
            layoutProps={{ hideHeader: true }}
            exact
            path="/evaluation-form"
            component={EvaluationFormPage}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/learning-materials"
            component={LearningMaterials}
        />
        <AppRoute
            layout={AuthorizedLayout}
            path="/learning-materials/:year(\d*)"
            component={LearningMaterials}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/learning-materials/:id-:slug"
            component={LearningMaterialDetails}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/face-to-face-events"
            component={FaceToFaceEvents}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/face-to-face-events/:id"
            component={FaceToFaceEventDetails}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/face-to-face-events/:id/book"
            component={FaceToFaceEventBooking}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/doctors/:id?"
            component={DoctorsScreen}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/nurses/:id?"
            component={NursesScreen}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/dashboard"
            component={Dashboard}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/users"
            component={Users}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/myaccount"
            component={DoctorAccount}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/admin/courses/new"
            component={CreateCoursePage}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/admin/courses/:id"
            component={UpdateCoursePage}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/admin/courses"
            component={Courses}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/help"
            component={Help}
        />
        <AppRoute
            layout={AuthorizedLayout}
            exact
            path="/register-session-attendance/:token"
            component={RegisterSessionAttendance}
        />
        <AppRoute
            layout={EmptyLayout}
            exact
            path="/hangfire"
            component={HangfireContainer}
        />
        <AppRoute
            layout={AuthorizedLayout}
            path="/"
            component={Home}
        />
    </Switch>
);

import "./GuestLayout.scss";
import * as React from "react";
import Header from "../components/shared/Header";

export const GuestLayout = (props: React.PropsWithChildren<{ hideHeader?: boolean }>) => {
    return (
        <React.Fragment>
            <div className="main">
                {!props.hideHeader && <Header hideSearchBar hideAccountInfo hideLogo hideAcademyLogo />}
                {props.children}
            </div>
        </React.Fragment>
    );
};

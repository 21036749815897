import React from 'react';
import { useForm } from "react-hook-form"

import { TextInput } from 'components/form';
import Button from 'components/shared/Button';
import { composeValidators, email, required } from 'validate';

import { SigninForm } from '../Signin';

type Props = {
    handleSubmit(values: SigninForm);
    initialValues?: Partial<SigninForm>,
    isLoading?: boolean;
}

export default function InviteUserForm({
    handleSubmit,
    initialValues,
    isLoading
}: Props) {
    const {
        register,
        handleSubmit: innerSubmit,
        formState: { errors },
    } = useForm<SigninForm>({ defaultValues: initialValues });

    return (
        <form onSubmit={innerSubmit(handleSubmit)}>
            <TextInput
                type="email"
                label='Email'
                placeholder='Enter your email'
                id="email"
                fieldError={errors.email}
                {...register("email", { validate: composeValidators(required(), email()) })}
            />
            <TextInput
                label='Last Name'
                placeholder='Enter your last name'
                id="last_name"
                fieldError={errors.lastName}
                {...register("lastName", { validate: required() })}
            />

            <div className="mt-4">
                <Button disabled={isLoading} className='m-auto' type="submit">
                    {isLoading ? "Logging you in..." : "Login"}
                </Button>
            </div>
        </form>
    )
}
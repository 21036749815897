import React from 'react'
import { CourseEvent } from '../../models/CourseEvent'
import EventSchedule from './EventSchedule'
import EventSpeakers from './EventSpeakers'
import EventSponsor from './EventSponsor'
import { EventDetailsSectionContainer, EventSpeakersAndSponsorContainer } from './styles/EventDetails'

interface EventDetailsProps {
  courseEvent: CourseEvent
}

const EventDetails = (props: EventDetailsProps) => {
  const { courseEvent } = props

  return (
    <section className='bg-primary-25 py-12'>
      <div className="container">
        <EventDetailsSectionContainer>
          <EventSchedule courseEvent={courseEvent} />

          <EventSpeakersAndSponsorContainer>
            <EventSpeakers courseEvent={courseEvent} />
            <EventSponsor courseEvent={courseEvent} />
          </EventSpeakersAndSponsorContainer>
        </EventDetailsSectionContainer>
      </div>
    </section>
  )
}

export default EventDetails

import { Link } from "react-router-dom";
import styled from "styled-components";
import searchIcon from "../../../images/searchIcon.png";
import { UserCourseSubmissionStatus } from "../../../models/enums/UserCourseSubmissionStatus";

export const DescriptionLabel = styled.label`
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--black);
`;

export const SmallLabels = styled.div`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: #4d4d4d;
`;

interface HelpLabelProps {
    color?: string;
}

export const HelpLabel = styled.span<HelpLabelProps>`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: ${(props) => (props.color ? props.color : "#4d4d4d")};
`;

export const HelpLink = styled(Link)<HelpLabelProps>`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: ${(props) => (props.color ? props.color : "#4d4d4d")};
`;

export const Card = styled.div`
    background-color: var(--white);
    padding: 21px 29.5px 30px;
`;

interface SearchBarProps {
    searchIcon?: string;
    marginLeft?: string;
}

export const SearchBar = styled.input<SearchBarProps>`
    height: 44px;
    max-width: 400px;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 25px;
    border: solid 1px var(--lightGrey);
    background-color: var(--white);

    font-size: 14px;
    padding: 10px 15px 10px 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: ${(props) => props.marginLeft || "auto"};
    margin-right: 20px;
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: 10px center;
`;

export const ResetFiltersButton = styled.span`
    color: var(--primaryActiveHoverColor);
    cursor: pointer;
`;

export interface FilterSortModalProps {
    top?: string;
    width?: string;
}

export const FilterSortModal = styled.div<FilterSortModalProps>`
    z-index: 999;
    display: block;
    position: absolute;
    float: right;
    width: ${(props) => props.width || "252px"};
    top: ${(props) => props.top || "45px"};
    right: 15px;
    min-height: 162px;
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

export const FilterSortContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 0px;
`;

export const FilterSortTitle = styled.div`
    font-size: 13px;
    font-weight: 600;
    margin-top: 11px;
    margin-left: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1e3e50;
    margin-bottom: 11px;
`;

export const FilterSortImageContainer = styled.div`
    width: 55px;
    padding-left: 15px;
    padding-right: 15px;
`;

export const DeclineModal = styled.div`
    z-index: 999;
    display: block;
    position: absolute;
    float: right;
    width: 144px;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 5px;
    top: 35px;
    right: 0px;
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    max-height: 152px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

export interface FilterSortRowProps {
    height?: string;
}

export const FilterSortRow = styled.div<FilterSortRowProps>`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    height: ${(props) => props.height || "24px"};
    margin-bottom: 10px;
`;

interface FilterSortItemProps {
    color?: string;
}

export const FilterSortItem = styled.div<FilterSortItemProps>`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-left: 5px;
    color: ${(props) => props.color || "var(--black)"};
    &:hover {
        color: #ef4623;
    }
    text-overflow: ellipsis;
    var(--white)-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 80px);
`;

export const AdminResponsesLeftContainer = styled.div`
    width: 354px;
    min-width: 354px;
    height: 100%;
`;

export const ResponsesTitle = styled.div`
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    color: var(--black);
`;

export const ResponsesTitleCount = styled.span`
    color: var(--medGrey);
`;

interface EmployeeResponseListItemProps {
    isActive?: boolean;
}

export const EmployeeResponseListItem = styled.div<EmployeeResponseListItemProps>`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    background-color: ${(props) =>
        props.isActive ? "#E8F0F4" : "transparent"};
`;

export const ResponseInnerCardTitle = styled.div`
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: var(--black);
`;

export const ResponseCard = styled.div`
    background-color: var(--white);
    border-radius: 0 !important;
    border: none !important;
`;

interface ResponseItemDataGroupProps {
    isHidden?: boolean;
}

export const ResponseItemDataGroup = styled.div<ResponseItemDataGroupProps>`
    visibility: ${(props) => (props.isHidden ? "hidden" : "visible")};
`;

interface ResponseDataItemTitleProps {
    color?: string;
}

export const ResponseDataItemTitle = styled.div<ResponseDataItemTitleProps>`
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: ${(props) => props.color || "var(--darkGrey)"};
`;

export const ResponseDataItemValue = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;

export const AdminResponsesRightContainer = styled.div`
    background-color: var(--lightGrey3);
`;

export const PdfLink = styled.a`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primaryButtonsLinksColor) !important;
`;

export const PdfImage = styled.img`
    width: 24px;
    height: 24px;
`;

export const AlertIcon = styled.img`
    width: 20px;
    height: 20px;
`;

export const MissingEvaluationFormAlertText = styled.span`
    line-height: 20px;
`;

export const ResponseItemBlockTitle = styled.div`
    background-color: var(--lightGrey3);
    color: var(--black);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
`;

export const QuestionText = styled.div`
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: var(--darkGrey);
`;

export const AnswerText = styled.div`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--black);
`;

export interface CourseCategoryBadgeProps {
    color?: string;
    backgroundColor?: string;
}

export const CourseCategoryBadge = styled.div<CourseCategoryBadgeProps>`
    background-color: ${(props) => props.backgroundColor || "var(--white)"};
    color: ${(props) => props.color || "var(--black)"};
`;

export const CertificateMissingMessageContainer = styled.div`
    width: 60%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-jheight: 24px;
    color: var(--black);
`;

export const CertificateContainer = styled.div`
    background-color: var(--white);
    color: #13364d;
`;

export const CertificateStatement = styled.div`
    font-size: 28px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 20px;
`;

export const RacgpCpdProviderLogo = styled.img`
    width: 193px;
    margin-bottom: 20px;
`;

interface MyHealthLogoProps {
    marginBottom?: string;
}

export const MyHealthLogo = styled.img<MyHealthLogoProps>`
    width: 100px;
    margin-bottom: ${(props) => props.marginBottom || "40px"};
`;

export const EmployeeName = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
`;

export const RacgpNumber = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 40px;
`;

interface CertificateTextProps {
    marginTop?: string;
    marginBottom?: string;
}

export const CertificateText = styled.div<CertificateTextProps>`
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    margin-top: ${(props) => props.marginTop || "0"};
    margin-bottom: ${(props) => props.marginBottom || "20px"};
`;

export const CourseName = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    font-style: italic;
`;

export const ActivityId = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    font-style: italic;
`;

export const ApprovedAt = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 30px;
    font-style: italic;
`;

export const CpdTable = styled.div`
    background-color: #b2f1ea;
    padding: 6px;
`;

export const CpdInnerTableContainer = styled.div`
    background-color: var(--white);
    padding: 2px;
`;

export const CpdInnerTable = styled.div`
    background-color: #b2f1ea;
    padding: 6px;
`;

export const RacgpCpdApprovedActivityLogo = styled.img`
    width: 160px;
    margin: 6px;
`;

export const CpdTitle = styled.div`
    font-size: 6px;
    font-weight: 600;
    line-height: 9px;
    text-align: center;
    margin-bottom: 5px;
`;

export const CpdValue = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 9px;
    text-align: center;
    margin-bottom: 5px;
`;

export const CpdHoursLabel = styled.div`
    font-size: 6px;
    font-weight: 400;
    line-height: 9px;
    text-align: center;
`;

export const DownloadCertificateButtonText = styled.span`
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
`;

export const FaceToFaceEventsBackLink = styled.div`
    &:hover {
        * {
            color: var(--primaryActiveHoverColor);
        }
    }
`;

export const LearningMaterialCarouselImage = styled.img`
    width: 100%;
    max-height: 500px;
`;

interface UserCourseSubmissionStatusValueProps {
    status: UserCourseSubmissionStatus;
    isLarge?: boolean;
}

export const UserCourseSubmissionStatusValue = styled.span<UserCourseSubmissionStatusValueProps>`
    font-size: ${(props) => (props.isLarge ? "14px" : "12px")};
    line-height: ${(props) => (props.isLarge ? "24px" : "16px")};
    font-weight: 400;
    color: ${(props) =>
        props.status === UserCourseSubmissionStatus.PendingApproval
            ? "var(--primaryActiveHoverColor)"
            : props.status === UserCourseSubmissionStatus.Dismissed
            ? "#CB2424"
            : "var(--black)"};
`;

export const UserCourseSubmissionSubmissionDate = styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--darkGrey);
`;

export const UserCourseSubmissionEmployeeName = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: var(--black);
`;

export const AdminResponsesMainContainer = styled.div`
    height: calc(100vh - 174px);
`;

export const ApproveButton = styled.button`
    pointer-events: auto !important;
    background-color: var(--primaryButtonsLinksColor) !important;
    color: var(--white) !important;
    border-color: var(--primaryButtonsLinksColor) !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;

    &:disabled {
        cursor: not-allowed;
    }
`;

export const DismissButton = styled.button`
    pointer-events: auto !important;
    background-color: var(--white) !important;
    color: #cb2424 !important;
    border-color: #cb2424 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;

    &:disabled {
        background-color: var(--lightGrey) !important;
        border-color: var(--lightGrey) !important;
        color: var(--medGrey) !important;
        cursor: not-allowed;
    }
`;

export const DismissModal = styled.div`
    background-color: var(--white);
    padding: 24px;
    width: 400px;
`;

export const DismissModalTitle = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primaryHeadingsColor);
`;

export const DismissModalText = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    margin-top: 20px;
`;

interface DismissModalButtonProps {
    backgroundColor: string;
    color: string;
}

export const DismissModalButton = styled.button<DismissModalButtonProps>`
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    width: 175px;
    height: 32px;
    border: solid 1px var(--lightGrey);
    border-radius: 2px;
    padding-top: 23px;
    padding-bottom: 23px;
    border-radius: 4px;
`;

export const DismissModalRadioLabel = styled.label`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
`;

export const DismissModalTextareaLabel = styled.label`
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
`;

export const CourseEventDetailsTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--black);
`;

export const CourseEventDetailsText = styled.p`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--darkGrey);
`;

export const CourseEventDetailsList = styled.ul`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--darkGrey);
`;

export const CourseNoteText = styled.p`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--gray-400);
`;

export const CourseEventDetailsContainer = styled.div`
    background-color: #f9fafb;
    @media (min-width: 768px) {
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 138px;
        padding-right: 138px;
    }
`;

export const CourseEventDetailsScheduleItemTitle = styled.div`
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: var(--darkGrey);
`;

export const CourseEventDetailsSponsorLogo = styled.img`
    margin-top: 24px;
    max-width: 300px;
`;

export const EventSpeakersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 12px;
    padding-bottom: 12px;
    gap: 12px;
`;

export const CourseEventSpeakerContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const EventSpeakerImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const EventSpeakerImage = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 200px;
`;

export const EventSpeakerTextContainer = styled.div`
    flex: 1 0 0;
`;

export const EventSpeakerName = styled.h4`
    color: var(--secondary-dark-grey, #666);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
`;

export const EventSpeakerTopic = styled.p`
    color: var(--secondary-dark-grey, #666);

    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
`;

import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    RouteComponentProps,
    useHistory,
    useLocation,
    withRouter,
} from "react-router-dom";
import { Card } from "reactstrap";

import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";
import { compose } from "redux";
import { isAdmin } from "models/admin/UserRoles";
import NavTabContainer from "components/shared/NavTabContainer";
import NavTabItem from "components/shared/NavTabItem";

import LearningMaterialAdminResponses from "./LearningMaterialAdminResponses";
import LearningMaterialEvaluation from "./LearningMaterialEvaluation";
import LearningMaterialSessionAttendances from "./LearningMaterialSessionAttendances";
import LearningMaterialUserResponses from "./LearningMaterialUserResponses";
import LearningMaterialUserCertificate from "./LearningMaterialUserCertificate";
import ScormCourse from "./ScormCourse";
import "./styles/LearningMaterialDetails.scss";

interface OwnRouteProps {
    id?: string;
}

type Props = RouteComponentProps<OwnRouteProps> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

export enum AdminTab {
    Content = "content",
    Responses = "responses",
    ActivityAttendance = "activity-attendance",
}

export enum UserTab {
    CourseDetails = "course-details",
    Content = "content",
    ReviewResponses = "review-responses",
    Evaluation = "evaluation",
    Certificate = "certificate",
}

const scrollToUserResponsesBlock = (blockTitle: string) => {
    document
        .getElementById(`response-block-${blockTitle}`)
        .scrollIntoView({ behavior: "smooth" });
};

const LearningMaterialDetailsTabItem = ({ tab, title, selectedTab }: { tab: UserTab | AdminTab, selectedTab: string, title: string }) => {
    const history = useHistory();
    const location = useLocation();

    return <NavTabItem
        isSelected={selectedTab === tab}
        onClick={() =>
            history.replace({
                pathname: location.pathname,
                search: new URLSearchParams({
                    activeTab: tab,
                }).toString(),
            })
        }
    >
        {title}
    </NavTabItem>
}

const LearningMaterialDetails: React.FC<Props> = (props: Props) => {
    const {
        courseDetails,
        fetchCourseDetails,
        currentEmployee,
        courseResponses,
        ownCourseResponses,
        fetchCourseResponses,
        userCourseCertificateDetails,
        requestCountLoader,
        fetchUserCourseCertificateDetails,
        downloadEvaluationFormSubmissionDocument,
        downloadCourseResponsesCsvExport,
        downloadUserCourseCertificateExport,
        fetchCourseSessionLinks,
        fetchCourseSessions,
        courseSessions,
        dismissEvaluationForm,
        approveEvaluationForm,
        fetchOwnCourseResponses,
        updateUserCourseAnswer,
        updateOwnUserCourseAnswer,
    } = props;

    const courseId = parseInt(props.match.params.id);

    const query = new URLSearchParams(props.location.search);

    const tabFromQuery = query.get("activeTab");
    const employeeIdFromQuery = query.get("employeeId");

    const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(NaN);

    const isAdminUser =
        currentEmployee?.userRoles?.role &&
        isAdmin(currentEmployee.userRoles.role);

    const [selectedTab, setSelectedTab] = useState(
        tabFromQuery || isAdminUser ? AdminTab.Content : UserTab.Content
    );

    useEffect(() => {
        if (isAdminUser) {
            if (Object.values(AdminTab).indexOf(tabFromQuery as AdminTab) >= 0) {
                setSelectedTab(tabFromQuery as AdminTab);
            }
        } else {
            if (Object.values(UserTab).indexOf(tabFromQuery as UserTab) >= 0) {
                setSelectedTab(tabFromQuery as UserTab);
            }
        }
    }, [tabFromQuery, isAdminUser]);

    useEffect(() => {
        const parsedEmployeeIdFromQuery = parseInt(employeeIdFromQuery);

        if (isNaN(parsedEmployeeIdFromQuery)) {
            setSelectedEmployeeIndex(NaN);
        } else {
            const index = courseResponses.findIndex(
                (x) => x.employeeId === parsedEmployeeIdFromQuery
            );
            if (index !== -1) {
                setSelectedEmployeeIndex(index);
            }
        }
    }, [courseResponses, employeeIdFromQuery]);

    const getCourse = useCallback(() => {
        fetchCourseDetails(courseId);
    }, [courseId, fetchCourseDetails]);

    useEffect(() => {
        getCourse();
    }, [getCourse, selectedTab]);

    useEffect(() => {
        if (isAdminUser) {
            fetchCourseSessionLinks(courseId);
            fetchCourseSessions(courseId);
        } else {
            fetchUserCourseCertificateDetails(courseId);
            fetchOwnCourseResponses(courseId);
        }
    }, [courseId, fetchCourseSessionLinks, fetchCourseSessions, fetchUserCourseCertificateDetails, fetchOwnCourseResponses, isAdminUser]);

    return (
        <React.Fragment>
            <div className="w-full h-full flex flex-col overflow-auto">
                {isAdminUser && (
                    <div className="flex justify-content-center align-items-center learning-materials-admin-tabs-container">
                        <NavTabContainer>
                            <LearningMaterialDetailsTabItem selectedTab={selectedTab} tab={AdminTab.Content} title="Content" />
                            <LearningMaterialDetailsTabItem selectedTab={selectedTab} tab={AdminTab.Responses} title="Responses" />
                            {courseSessions && courseSessions.length > 0 && (
                                <LearningMaterialDetailsTabItem selectedTab={selectedTab} tab={AdminTab.ActivityAttendance} title="Activity Attendance" />
                            )}
                        </NavTabContainer>
                    </div>
                )}
                {!isAdminUser && (
                    <div className="flex justify-content-center align-items-center learning-materials-admin-tabs-container">
                        <NavTabContainer>
                            <LearningMaterialDetailsTabItem selectedTab={selectedTab} tab={UserTab.Content} title="Content" />
                            <LearningMaterialDetailsTabItem selectedTab={selectedTab} tab={UserTab.ReviewResponses} title="Review Responses" />
                            <LearningMaterialDetailsTabItem selectedTab={selectedTab} tab={UserTab.Evaluation} title="Evaluation" />
                            <LearningMaterialDetailsTabItem selectedTab={selectedTab} tab={UserTab.Certificate} title="Certificate" />
                        </NavTabContainer>
                    </div>
                )}
                <Card
                    className={`learning-materials-admin-tab-content-container flex-grow-1 overflow-auto ${!isAdminUser && selectedTab === UserTab.Certificate
                        ? "certificate-container"
                        : ""
                        }`}
                >
                    {((isAdminUser && selectedTab === AdminTab.Content) ||
                        (!isAdminUser && selectedTab === UserTab.Content))
                        && (
                            <ScormCourse />
                        )}
                    {isAdminUser && (<>
                        {selectedTab === AdminTab.Responses && (
                            <LearningMaterialAdminResponses
                                courseId={courseId}
                                courseResponses={courseResponses}
                                fetchCourseResponses={fetchCourseResponses}
                                approveEvaluationForm={approveEvaluationForm}
                                currentEmployee={currentEmployee}
                                dismissEvaluationForm={dismissEvaluationForm}
                                downloadCourseResponsesCsvExport={
                                    downloadCourseResponsesCsvExport
                                }
                                downloadEvaluationFormSubmissionDocument={
                                    downloadEvaluationFormSubmissionDocument
                                }
                                isAdminUser={isAdminUser}
                                selectedAdminTab={selectedTab}
                                selectedEmployeeIndex={selectedEmployeeIndex}
                                updateUserCourseAnswer={updateUserCourseAnswer}
                            />
                        )}
                        {selectedTab === AdminTab.ActivityAttendance && (
                            <LearningMaterialSessionAttendances
                                courseId={courseId}
                            />
                        )}
                    </>)}
                    {!isAdminUser && (<>
                        {selectedTab === UserTab.ReviewResponses && (
                            <LearningMaterialUserResponses
                                courseId={courseId}
                                courseTitle={courseDetails?.title ?? ""}
                                courseUserResponses={ownCourseResponses}
                                currentEmployee={currentEmployee}
                                fetchCourseResponses={fetchOwnCourseResponses}
                                updateUserCourseAnswer={
                                    updateOwnUserCourseAnswer
                                }
                                scrollToBlock={scrollToUserResponsesBlock}
                            />
                        )}
                        {selectedTab === UserTab.Evaluation && (
                            <LearningMaterialEvaluation
                                course={courseDetails}
                                currentEmployee={currentEmployee}
                                refreshCourseState={getCourse}
                            />
                        )}
                        {selectedTab === UserTab.Certificate && (
                            <LearningMaterialUserCertificate
                                courseId={courseId}
                                userCourseCertificateDetails={
                                    userCourseCertificateDetails
                                }
                                downloadUserCourseCertificateExport={
                                    downloadUserCourseCertificateExport
                                }
                                requestCountLoader={requestCountLoader}
                            />
                        )}
                    </>)}
                </Card>
            </div>
        </React.Fragment>
    );
};

export default compose(
    withRouter,
    connect(
        (state: IApplicationState) => ({
            ...state.dashboard,
        }),
        { ...dashboard.actionCreators }
    )
)(LearningMaterialDetails as any);

import React from "react";
import * as dashboard from "../store/dashboardStore";
import { Redirect, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IApplicationState } from "../store";
import { isAdmin } from "../models/admin/UserRoles";

type Props = RouteComponentProps<{}> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const Home: React.FC<Props> = (props: Props) => {
    if (props.currentEmployee != null) {
        if (props.currentEmployee.userRoles != null && isAdmin(props.currentEmployee.userRoles.role)) {
            return <Redirect to="/dashboard" />;
        }
        return <Redirect to="/learning-materials" />;
    }

    return <></>;
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(Home as any);

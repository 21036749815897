import styled from "styled-components";

interface InputEntryProps {
    margin?: string;
    isValid?: boolean;
    inputSize: string;
    fontSize?: string;
    padding?: string;
    isFilled?: boolean;
}

export const InputEntry = styled.input<InputEntryProps>`
    width: ${(props) => props.inputSize};
    height: ${(props) => props.inputSize};
    margin: ${(props) => props.margin || "0.417vw 1.736vw 2.083vw 2.778vw"};
    border: solid 1px ${(props) => (props.isValid ? (props.isFilled ? "#F47721" : "#e0e0e0") : "#ef4623")};
    padding: ${(props) => props.padding};
    background-color: var(--white);
    font-size: ${(props) => props.fontSize || "2.5vw"};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    color: #F47721;
    outline-color: #F47721;
    padding-left: 22px;
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
    &:focus-visible, &:active, &:focus, &:focus-within {
        border: solid 1px #F47721;
    }
`;

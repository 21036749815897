import React, { useCallback } from 'react';
import classNames from 'classnames';

interface Props<T> {
    tabs: T[];
    selected: T;
    onChange(tab: T);
    keySelector(tab: T): React.Key;
    labelSelector(tab: T): string;
    className?: string;
    disableSmDropdown?: boolean;
}

export const Tabs = <T,>({
    tabs,
    selected,
    onChange,
    keySelector,
    labelSelector,
    className,
    disableSmDropdown
}: Props<T>) => {

    const onChangeSelect: React.ChangeEventHandler<HTMLSelectElement> = useCallback((e) => {
        const tabPicked = tabs.find(t => `${keySelector(t)}` === e.target.value);
        console.log(tabPicked, e.target.value);
        if (tabPicked) {
            onChange(tabPicked);
        }
    }, [keySelector, onChange, tabs])

    return (
        <div className={className}>
            <div className={classNames({ "sm:hidden": !disableSmDropdown, "hidden": disableSmDropdown })}>
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500"
                    value={keySelector(selected)}
                    onChange={onChangeSelect}
                >
                    {tabs.map((tab) => (
                        <option value={keySelector(tab)} key={keySelector(tab)}>{labelSelector(tab)}</option>
                    ))}
                </select>
            </div>
            <div className={classNames({ "hidden sm:block": !disableSmDropdown })}>
                <nav className="flex space-x-1" aria-label="Tabs">
                    {tabs.map((tab) => {
                        const isSelected = keySelector(selected) === keySelector(tab)
                        return <button
                            key={keySelector(tab)}
                            className={classNames(
                                isSelected ? 'bg-primary-100 text-primary-700' : 'text-gray-500 hover:text-gray-700',
                                'rounded-md px-4 py-2 text-base font-semibold whitespace-nowrap'
                            )}
                            aria-current={isSelected ? 'page' : undefined}
                            onClick={() => onChange(tab)}
                        >
                            {labelSelector(tab)}
                        </button>
                    })}
                </nav>
            </div>
        </div>
    )
}
import React from 'react';
import { useForm } from "react-hook-form"
import { Col, Row } from 'reactstrap';

import { Select, TextInput } from 'components/form';
import Button from 'components/shared/Button';
import { RoleType } from 'models/admin/UserRoles';
import { InviteEmployeePayload } from 'models/InviteEmployeePayload';
import { composeValidators, email, mobile, required } from 'validate';

import { mapEnumToOption } from '../styles/StyledDropdown';

type Props = {
    handleSubmit(values: InviteEmployeePayload);
    initialValues?: Partial<InviteEmployeePayload>,
    handleCancel?()
}

export default function InviteUserForm({
    handleSubmit,
    handleCancel,
    initialValues,
}: Props) {
    const {
        register,
        handleSubmit: innerSubmit,
        formState: { errors },
    } = useForm<InviteEmployeePayload>({ defaultValues: initialValues });

    return (
        <form onSubmit={innerSubmit(handleSubmit)}>
            <div className='p-3'>
                <Row>
                    <Col>
                        <TextInput
                            label='First Name'
                            placeholder='Enter your first name'
                            id="first_name"
                            fieldError={errors.firstName}
                            {...register("firstName", { validate: required() })}
                        />
                    </Col>
                    <Col>
                        <TextInput
                            label='Last Name'
                            placeholder='Enter your last name'
                            id="last_name"
                            fieldError={errors.lastName}
                            {...register("lastName", { validate: required() })}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <TextInput
                            type="email"
                            label='Email'
                            placeholder='Enter your email'
                            id="email"
                            fieldError={errors.email}
                            {...register("email", { validate: composeValidators(required(), email()) })}
                        />
                    </Col>
                    <Col>
                        <TextInput
                            type="tel"
                            label="Mobile Number"
                            placeholder="Enter your mobile number"
                            id="contactNumber"
                            fieldError={errors.phoneNumber}
                            {...register("phoneNumber", { validate: composeValidators(required(), mobile()) })}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Select
                            id="userRole"
                            label="Role"
                            placeholder="Select a role"
                            {...register("role", { validate: required() })}
                        >
                            {mapEnumToOption(RoleType).map((p) => (
                                <option value={p.value} key={p.value}>
                                    {p.label}
                                </option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </div>

            <hr />

            <div className='p-3'>
                <Row className='justify-content-end'>
                    <Col xs="auto">
                        {handleCancel && <Button
                            type="button"
                            variant='secondary'
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        }
                    </Col>
                    <Col xs="auto">
                        <Button
                            type="submit"
                        >
                            Send Invite
                        </Button>

                    </Col>
                </Row>
            </div>
        </form>
    )
}
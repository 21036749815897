import React, { useEffect } from "react";
import "./styles/PrivacyPolicy.scss";
import Footer from "../../components/shared/Footer";
import { HeaderDesktopHr, HeaderMobileHr } from "./styles/LandingPage";

export const PrivacyPolicy: React.FC = (props) => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }, []);

    return (
        <React.Fragment>
            <HeaderDesktopHr className="hidden sm:block" />
            <HeaderMobileHr className="block sm:hidden" />
            <div className="privacy-policy-container">
                <p className="title">Privacy Policy</p>
                <div>
                    <p className="paragraph-title">INTRODUCTION</p>
                    <p>
                        Myhealth Medical Group Pty Ltd ACN 160 321 693 manages medical centres on behalf of the medical
                        practitioners and other health service providers (Healthcare Practitioners) who operate their
                        practice from them. We provide the administrative and non-medical services that those Healthcare
                        Practitioners need to provide you with medical or other health services (Services).
                    </p>
                    <p>
                        Myhealth Medical Group Pty Ltd ACN 160 321 693 and its subsidiaries and related bodies corporate
                        (“Our”, “Us” and “We”) are committed to protecting the privacy of personal information provided
                        to
                        Us and to handling your personal information in a responsible manner in accordance with the
                        Privacy
                        Act 1988 (Cth), the Australian Privacy Principles and relevant State and Territory privacy
                        legislation (referred to as privacy legislation).
                    </p>
                    <p className="paragraph-title">COLLECTION</p>
                    <p>
                        We generally collect information that is necessary and relevant to enable Us to provide you with
                        access to medical care, treatment, and to manage Our practice.
                    </p>
                    <p>
                        This information may include your name, address, date of birth, gender, health information,
                        family
                        history, credit card and direct debit details and contact details and any other information to
                        assist Us in providing you access to Services.
                    </p>
                    <p>
                        This information may be stored by your treating Healthcare Practitioner and/or by Us at the
                        medical
                        centre where you are treated or electronically on Our central electronic database of medical
                        records
                        and/or in hand written medical records.
                    </p>
                    <p>
                        We may hold your personal information on Our central electronic database to allow each of Our
                        clinics to access your personal information and provide you with appropriate medical care.
                    </p>
                    We collect information in various ways, such as over the phone or in writing, in person (including
                    patient registration forms), through Our website or over the internet or email if you transact with
                    Us
                    online. We may also collect information via mobile applications.
                    <p>
                        This information may be collected by medical and non-medical staff.
                    </p>
                    <p>
                        Wherever practicable your treating Healthcare Practitioner and/or We will only collect
                        information
                        from you personally (or as relevant your guardian or legal representative). However, your
                        treating
                        Healthcare Practitioner or We may also need to collect personal information from other health
                        providers, such as treating specialists, radiologists, pathologists, hospitals and other health
                        care
                        providers, to enable Us to provide you access to Services.
                    </p>
                    <p>
                        In emergency situations We may also need to collect information from your relatives or friends.
                        We
                        may be required by law to retain medical records for certain periods of time depending on your
                        age
                        at the time We provide services.
                    </p>
                    <p>
                        If a person applies for a position with Us, We may collect, that information from that person in
                        order to consider their application.
                    </p>
                    <p className="paragraph-title">USE AND DISCLOSURE</p>
                    <p>
                        We will treat your personal information as strictly private and confidential.
                    </p>
                    <p>
                        We will only use or disclose your personal information for purposes directly related to your
                        care
                        and treatment, or in ways that you would reasonably expect that We may use it for your ongoing
                        care
                        and treatment or as otherwise required or permitted by law, including to provide medical
                        services or
                        to provide to you reminders.
                    </p>
                    <p>
                        We may use online platforms and mobile apps such as Medinet and Mypractice and platforms which
                        provide e-scripts to provide online health care to our patients. Your personal information may
                        be
                        shared with these platforms if you or we use those platforms to provide services to you. If We
                        use
                        these platforms, they are subject to their privacy policies. Some of these platforms may use
                        people
                        who are located overseas.
                    </p>
                    <p>
                        We may contact you as part of your ongoing care, for example, in relation to appointments and
                        patient check-ups.
                    </p>
                    <p>
                        We may disclose your blood test results to your specialist or disclose your personal information
                        for
                        the purpose of requesting x-rays.
                    </p>
                    <p>
                        There are other circumstances where We may be permitted or required by law to use or disclose
                        your
                        personal information including for quality assurance, billing and management purposes. For
                        example,
                        We may disclose your personal information to Medicare, Police, insurers, solicitors, government
                        regulatory bodies, tribunals, courts of law, hospitals, or debt collection agents.
                    </p>
                    <p>
                        We may use and disclosed de-identified, aggregated data for management, quality assurance,
                        marketing
                        and administrative purposes.
                    </p>
                    <p>
                        We may also from time to time provide de-identified aggregated statistical data to third parties
                        for
                        research purposes.
                    </p>
                    <p>
                        We may have CCTV installed in the common areas at Our centres, such as the reception area. We
                        may
                        collect, use and disclose your personal information in the CCTV footage for security, management
                        and
                        quality assurance purposes.
                    </p>
                    <p>
                        In some cases We may disclose your personal information to Our other medical centres in order to
                        provide services to you or for quality assurance or management purposes.
                    </p>
                    <p>
                        We may disclose information about you to Our contractors to carry out activities on Our behalf,
                        such
                        as an IT service provider, administrative services, solicitor or debt collection agent. We
                        impose
                        security and confidentiality requirements on how they handle your personal information. Our
                        contractors are required to retain your information confidential and not to use personal
                        information
                        about you for any purpose except for those activities We have asked them to perform.
                    </p>
                    <p>
                        We do not use sensitive information (such as health information) for marketing purposes without
                        your
                        prior written consent.
                    </p>
                    <p>
                        We may disclose de-identified data, including demographic data, to the relevant Primary Health
                        Network.
                    </p>
                    <p>
                        Our medical centres may disclose your personal information and sensitive information to the
                        Myhealth
                        Group to allow all of Our medical centres to access this information and provide medical
                        services to
                        you.
                    </p>
                    <p className="paragraph-title">DATA QUALITY AND SECURITY</p>
                    <p>
                        We will take reasonable steps to ensure that your personal information is accurate, compete, up
                        to
                        date and relevant. For this purpose Our staff may ask you to confirm that your contact details
                        are
                        correct when you attend a consultation. We request that you let Us know if any of the
                        information We
                        hold about you is incorrect or out of date.
                    </p>
                    <p>
                        Personal information that We hold is protected from unauthorised access by:• securing Our
                        premises•
                        placing passwords and varying access levels on databases containing your personal information to
                        limit access to authorised personnel on a need to know basis and protect electronic information
                        from
                        unauthorised interference, access, modification and disclosure.• ensuring that Our service
                        providers
                        and contractors have in place appropriate privacy protection arrangements.
                    </p>
                    <p>
                        Our staff and service providers have been informed of the importance We place on protecting your
                        privacy and their role and obligations in helping Us to do so.
                    </p>
                    <p>
                        Although We will endeavour to protect your personal information, We are unable to guarantee that
                        any
                        information you transmit to Us over the internet is 100% secure. Any information you transmit to
                        Us
                        over the internet is conducted at your own risk.
                    </p>
                    <p className="paragraph-title">CORRECTIONS</p>
                    <p>
                        If you believe that the personal information We have about you is not accurate, complete or
                        up-to-date, you are entitled to seek the correction of your personal information by contacting
                        Us in
                        writing (see details below).
                    </p>
                    <p className="paragraph-title">ACCESS</p>
                    <p>
                        Subject to any legal restrictions, you are entitled to request access to your personal
                        information
                        We hold about you. We request that you send your request in writing to Us and We will respond to
                        it
                        within a reasonable time.
                    </p>
                    <p>
                        There may be a fee for the administrative costs of retrieving and providing you with copies of
                        your
                        medical records.
                    </p>
                    <p>
                        We may deny access to your medical records in certain circumstances permitted by law, for
                        example,
                        if disclosure may cause a serious threat to your health or safety. We will always tell you why
                        access is denied and the options you have to respond to Our decision.
                    </p>
                    <p className="paragraph-title">ACQUISITION OF ADDITIONAL MEDICAL CENTRES</p>
                    <p>
                        When the Myhealth group acquires new medical centres, your personal information may be
                        transferred
                        to the new Myhealth Medical Centre. We may collect, hold, use and disclose your personal
                        information
                        that is transferred to Us by medical centres that join the Myhealth group. Any personal
                        information
                        that We collect from newly acquired medical centres will be managed in accordance with this
                        privacy
                        policy.
                    </p>
                    <p>
                        In Victoria, when We acquire new medical centres the medical centre will notify patients
                        currently
                        receiving treatment in writing that the medical centre will be sold. In addition there will be a
                        notice placed at the medical centre and published in the local newspaper. The notice will state
                        whether the medical centre will retain your medical records, transfer them to you, or transfer
                        them
                        to the new Myhealth Medical Centre. In response to such a notice you may request that your
                        medical
                        records be transferred to yourself or to the new Myhealth Medical Centre.
                    </p>
                    <p className="paragraph-title">COOKIES</p>
                    <p>
                        Our website uses cookies to provide a better browsing experience. If you prefer not to have
                        cookies
                        collected, you can disable this option in your browser settings.
                    </p>
                    <p className="paragraph-title">GOOGLE ANALYTICS, API ETC.</p>
                    <p>
                        We use products such as Google Analytics to analyse the audience statistics of Our website to
                        improve Our content. No personal information is collected from Google Analytics.
                    </p>
                    <p>
                        We collect your personal information from Google application programming interfaces (APIs). We
                        use
                        Google APIs, namely Google Maps, in conjunction with Our QR codes to verify your address when
                        filling out digital patient registration forms.
                    </p>
                    <p className="paragraph-title">COMPLAINTS</p>
                    <p>
                        If you have a complaint about the privacy of your personal information (including any breach of
                        the
                        Australian Privacy Principles or an applicable registered APP code), or you would like further
                        information on Our privacy policy, or you need to correct your personal information, We request
                        that
                        you contact Us in writing at the following address:
                    </p>
                    <p>
                        Myhealth Medical Group Att: General Counsel Suite 203/12 Help Street Chatswood NSW 2067
                    </p>
                    <p>
                        Upon receipt of a complaint We will consider the details and attempt to resolve it in accordance
                        with Our complaints handling procedures.If you are dissatisfied with Our handling of a complaint
                        or
                        the outcome you may make an application to the Australian Information Commissioner (see
                        http://www.oaic.gov.au/privacy/privacy-complaints) or the Privacy Commissioner in your State or
                        Territory.
                    </p>
                    <p className="paragraph-title">OVERSEAS DISCLOSURE OF PERSONAL INFORMATION</p>
                    <p>
                        We may disclose your personal information to one of Our overseas service providers located in
                        the
                        Philippines and will require that service provider to retain that information as confidential
                        information.
                    </p>
                    <p className="paragraph-title">GENERAL</p>
                    <p>
                        We may amend or replace this privacy policy from time to time in which case a copy of the
                        amended
                        privacy policy will be published on our website.
                    </p>
                    <p>
                        If an individual does not provide their personal information to Us, We may not be able to
                        provide
                        our
                        services to them..
                    </p>
                    <p>
                        Last Updated July 2022.
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
};

import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const EventPricingSection = styled.section`
    background: var(--orange-50);
    padding: 96px 0px 96px 0px;
    flex-direction: column;
    align-items: center;
    @media ${device.xs} {
        padding: 48px 8px;
    }
`;

export const EventPricingSectionContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 64px;
    @media ${device.xs} {
        gap: 32px;
    }
`;

export const EventPricingMainContainer = styled.div`
    display: flex;
    padding: 0px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    @media ${device.xs} {
        gap: 48px;
        padding: 0px 0px;
        align-items: center;
    }
`;

export const EventPricingCardsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    @media ${device.xs} {
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
`;

export const EventPricingCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid var(--gray-200);
    background: var(--white, #fff);
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
        0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    @media ${device.xs} {
        gap: 16px;
        width: 100%;
    }
`;

export const CardHeaderContainer = styled.div`
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-bottom: 1px solid var(--gray-200);
    @media ${device.xs} {
        padding: 24px 24px 32px 24px;
    }
`;

export const CardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    @media ${device.xs} {
    }
`;

export const CardHeadingAndBadgeContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    @media ${device.xs} {
    }
`;

export const CardHeadingText = styled.h4`
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    color: var(--gray-600);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    @media ${device.xs} {
    }
`;

export const CardHeadingBadgeContainer = styled.div`
    display: flex;
    padding: 4px 12px;
    align-items: center;
    border-radius: 16px;
    border: 0px solid var(--success-200);
    background: var(--success-50);
`;

export const CardHeadingBadgeText = styled.span`
    color: var(--success-700);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    @media ${device.xs} {
    }
`;

export const CardPriceContainer = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 4px;
    align-self: stretch;
    @media ${device.xs} {
    }
`;

export const CardPriceText = styled.span`
    color: var(--gray-900);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: -0.96px;
    @media ${device.xs} {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.72px;
    }
`;

export const CardPriceAdditionalInfoContainer = styled.div`
    display: flex;
    padding-bottom: 0px;
    align-items: flex-start;
    @media ${device.xs} {
    }
`;

export const CardPriceAdditionalInfoText = styled.span`
    color: var(--primary-700);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
`;

export const CardFooterTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
`;

export const CardFooterText = styled.span`
    color: var(--gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const RegisterNowContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    @media ${device.xs} {
        flex-direction: column;
        gap: 16px;
    }
`;

export const RegisterNowTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
`;

export const RegisterNowText = styled.span`
    color: var(--gray-900);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    @media ${device.xs} {
        font-size: 18px;
        line-height: 28px;
    }
`;

export const RegisterNowTextHighlighted = styled.span`
    color: var(--primary-600);
`;

export const RegisterNowButton = styled.a`
    color: var(--white) !important;
    cursor: pointer;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--primary-600);
    background: var(--primary-600);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    @media ${device.xs} {
    }
`;

export const RegisterNowButtonText = styled.span`
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import * as dashboard from "../../store/dashboardStore";
import { Button, Col, Container, Label, Modal, Row, FormGroup, Input } from "reactstrap";
import { CourseService } from "../../services/CourseService";
import { showDefault, showErrors } from "../../utils";
import { EditSignature } from "../../components/shared/EditSignature";
import { SignatureMode } from "../evaluation-form/EvaluationForm";
import SignatureCanvas from "react-signature-canvas";
import { CourseSessionLink } from "../../models/CourseSessionLink";
import { RouteComponentProps } from "react-router";
import { CourseSessionLinkDetails } from "../../models/CourseSessionLinkDetails";

interface IProps {
}

type Props = IProps & dashboard.DashboardState & typeof dashboard.actionCreators & RouteComponentProps<{ token: string }>;

const RegisterSessionAttendance: React.FC<Props> = (props: Props) => {
    const {
        currentEmployee,
        history
    } = props;

    const [sessionLinkDetails, setSessionLinkDetails] = useState<CourseSessionLinkDetails>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isSucceded, setIsSucceded] = useState(false);
    const [isRedirectAfterMessageDialog, setIsRedirectAfterMessageDialog] = useState(false);
    const [isSubmitPressed, setIsSubmitPressed] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [isSignatureValid, setIsSignatureValid] = useState(false);
    const [signatureMode, setSignatureMode] = useState(SignatureMode.Text);
    const [signatureText, setSignatureText] = useState<string>("");
    const signatureCanvas = useRef<SignatureCanvas>();

    useEffect(() => {
        CourseService.GetSessionLinkDetails(props.match.params.token).then(r => {
            if (r.hasErrors) {
                setErrorMessage(r.errors[0]);
                setIsRedirectAfterMessageDialog(true);
                return;
            }

            setSessionLinkDetails(r.value);
        });
    }, []);

    const submitForm = async (signatureBase64: string, signatureText: string) => {
        var r = await CourseService.LogUserAttendanceByToken(props.match.params.token, signatureText, signatureBase64, isApproved);
        if (r.hasErrors) {
            setErrorMessage(r.errors[0]);
            setIsRedirectAfterMessageDialog(false);
            return;
        }

        setIsSucceded(true);
    };

    return (
        <>
            {
                errorMessage &&
                <Modal className="course-modal" isOpen={!!errorMessage} centered>
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>Error</h5>
                                <p className="description">{errorMessage}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-3">
                                <Button className="w-full" color="primary" onClick={_ => {
                                    setErrorMessage(undefined);
                                    if (isRedirectAfterMessageDialog) {
                                        history.push("/");
                                    }
                                }}>OK</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }

            {
                isSucceded &&
                <Modal className="course-modal" isOpen={!!isSucceded} centered>
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>Thank you!</h5>
                                <p className="description">Your attendance has been recorded. Thank you for participating!</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-3">
                                <Button className="w-full" color="primary" onClick={_ => {
                                    history.push("/");
                                }}>DONE</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            }

            {
                currentEmployee && sessionLinkDetails &&

                <Container className="evaluation-form-container mt-5">
                    <Row>
                        <Col xs="12">
                            <Row>
                                <h4 className="text-center font-weight-bold">
                                    Clinical Meeting Series Member Attendance Form
                                </h4>
                                <h4 className="text-center font-weight-bold mt-3 mb-0">
                                    {sessionLinkDetails.sessionName}
                                </h4>
                                <span className="text-center">
                                    ({sessionLinkDetails.courseName}, Session No. #{sessionLinkDetails.sessionNumber}, {sessionLinkDetails.date.format('DD/MM/YYYY hh:mm a')})
                                </span>
                            </Row>
                            <Row className="mt-5">
                                <Col md={6}>
                                    <strong>Name:</strong>&nbsp;{currentEmployee.firstName}&nbsp;{currentEmployee.lastName}
                                </Col>
                                <Col md={6}>
                                    <strong>RACGP No.:</strong>&nbsp;{currentEmployee?.racgpNumber}
                                </Col>
                            </Row>


                            <Row className="mt-4">
                                <strong className="p-0">Signature</strong>
                                <EditSignature
                                    mode={signatureMode}
                                    setMode={setSignatureMode}
                                    canvas={signatureCanvas}
                                    text={signatureText}
                                    setText={setSignatureText}
                                    isSubmitPressed={isSubmitPressed}
                                    setIsSignatureValid={(isValid) => {
                                        setIsSignatureValid(isValid);
                                    }}
                                    isSignatureValid={isSignatureValid}
                                />
                            </Row>

                            <Row>
                                <FormGroup check>
                                    <Input type="checkbox" name="checkbox"
                                        // value={isApproved}
                                        onChange={(e) => { setIsApproved(e.target.value === 'false') }} />
                                    <Label check for="checkbox">By submitting this form, you acknowledge that you attended <span className="font-weight-bold">{sessionLinkDetails.sessionName} of {sessionLinkDetails.courseName}</span></Label>
                                </FormGroup>
                            </Row>

                            <Row className="mt-3">
                                <Button
                                    className="btn btn-primary evaluation-form-button"
                                    disabled={isSubmitPressed || !isApproved}
                                    type="button"
                                    onClick={(_) => {
                                        setIsSubmitPressed(true);
                                        if (
                                            signatureMode === SignatureMode.Text
                                        ) {
                                            submitForm(null, signatureText);
                                        } else {
                                            submitForm(
                                                signatureCanvas.current.toDataURL(),
                                                null
                                            );
                                        }
                                    }}
                                >
                                    SUBMIT
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );
}

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(RegisterSessionAttendance as any) as React.FC<IProps>;

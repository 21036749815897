import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { ContextMenu } from "../../components/shared/ContextMenu";
import { CourseSessionLink } from "../../models/CourseSessionLink";
import { CourseSession } from "../../models/CourseSession";
import activeLink from '../../images/link-active.svg';
import inactiveLink from '../../images/link-inactive.svg';
import LearningMaterialSessionAttendanceTable from "./LearningMaterialSessionAttendanceTable";
import * as dashboard from "../../store/dashboardStore";
import { IApplicationState } from "../../store";
import LogUserAttendanceButtonAndModal from "./LogUserAttendanceButtonAndModal";
import ExportCsvButtonAndModal from "./ExportCsv";
import DeactivateLinkButtonAndModal from "./DeactivateLinkButtonAndModal";

interface IProps {
    courseId: number;
    session: CourseSession;
    sessionLink: CourseSessionLink;
    onClose: () => void;
}

type Props = IProps &
    dashboard.DashboardState & typeof dashboard.actionCreators;

const LearningMaterialSessionDetailsModal: React.FC<Props> = (props: Props) => {
    const {
        courseId,
        session,
        sessionLink,
        onClose,
        courseSessionAttendances
    } = props;

    const [isLogUserModalOpened, setIsLoUserModalOpened] = useState(false);
    const [isExportCsvModalOpened, setIsExportCsvModalOpened] = useState(false);
    const [isDeactivateLinkModalOpened, setIsDeactivateLinkModalOpened] = useState(false);

    return (
        <>
            <LogUserAttendanceButtonAndModal courseId={courseId} sessionLinkId={sessionLink.id} isModalOpened={isLogUserModalOpened} onClose={() => setIsLoUserModalOpened(false)} hideButton />
            <ExportCsvButtonAndModal courseId={courseId} sessionLinkId={sessionLink.id} isModalOpened={isExportCsvModalOpened} onClose={() => setIsExportCsvModalOpened(false)} hideButton />
            <DeactivateLinkButtonAndModal courseId={courseId} sessionId={session.id} sessionLinkId={sessionLink.id} sessionName={session.sessionName} isModalOpened={isDeactivateLinkModalOpened} onClose={() => setIsDeactivateLinkModalOpened(false)} hideButton />

            <div className="dashboard-modal flex">
                <div className="flex flex-col flex-grow-1">
                    <div className="p-3 pb-0">
                        <div className="flex justify-content-end">
                            <Button className="p-0 m-0 bg-none border-0" style={{ height: 0 }} onClick={onClose}>x</Button>
                        </div>
                        <div className="flex justify-between">
                            <h3 className="py-0 d-inline-flex">{session.sessionName}</h3>
                            <div className="flex me-4">
                                <span className={`me-3 text-status-badge ${sessionLink.status.toLowerCase()}`}>
                                    {sessionLink.status}
                                </span>
                                <span className="me-3" role={sessionLink.status == 'Active' ? 'button' : undefined} onClick={_ => { navigator.clipboard.writeText(`${sessionLink.registrationUrl}`) }}>
                                    <img alt="Copy" title="Copy" src={sessionLink.status == 'Active' ? activeLink : inactiveLink} />
                                </span>
                                <ContextMenu rightAligned={true} menuitems={[
                                    { id: 1, label: 'Log User', onAction: _ => setIsLoUserModalOpened(true) },
                                    { id: 2, label: 'Export CSV', onAction: _ => setIsExportCsvModalOpened(true) },
                                    { id: 3, className: sessionLink.status == 'Active' ? 'text-danger' : 'text-light-notification', label: 'Deactivate', onAction: _ => sessionLink.status == 'Active' && setIsDeactivateLinkModalOpened(true) }
                                ]} />
                            </div>
                        </div>
                        <span className="text-gray-500">{courseSessionAttendances?.length || 0} user(s) have participated in this activity</span>
                    </div>

                    <LearningMaterialSessionAttendanceTable courseId={courseId} sessionLinkId={sessionLink.id} />
                </div>
            </div>
        </>
    );
}

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(LearningMaterialSessionDetailsModal as any) as React.FC<IProps>;
import styled from "styled-components";

export const HeaderDesktopHr = styled.hr`
  margin-left: -250px;
  width: 100vw;
  color: #EAECF0;
`;

export const HeaderMobileHr = styled.hr`
  margin-left: -25px;
  width: 100vw;
  color: #EAECF0;
`;
import React, { useState } from "react";
import { connect } from "react-redux";
import { SearchBar } from "../../components/dashboard/styles/Common";
import { Employee } from "../../models/Employee";
import { FilterDropDown } from "../../components/shared/FilterDropDown";
import { ContextMenu } from "../../components/shared/ContextMenu";
import UploadSubmissionFormAndButton from "./UploadSubmission";
import * as dashboard from "../../store/dashboardStore";
import { IApplicationState } from "../../store";

const DateFormat = new Intl.DateTimeFormat("en-AU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
});

interface IProps {
    employee: Employee;
}

type Props = IProps &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const DoctorCourses: React.FC<Props> = (props: Props) => {
    const courseFilterItems = [
        {
            label: "All Courses",
            value: "AllCourses",
        },
        {
            label: "All Completed Courses",
            value: "AllCompletedCourses",
        },
        {
            label: "All Incomplete Courses",
            value: "AllIncompleteCourses",
        },
    ];

    const {
        employee,
        downloadEvaluationFormSubmissionDocument,
        approveEvaluationForm,
        fetchAllUsers,
    } = props;

    const [searchText, setSearchText] = useState("");
    const [selectedCourseFilter, setSelectedCourseFilter] = useState(
        courseFilterItems[0]
    );

    const courseFilter = (course) => {
        if (!course) {
            return false;
        }

        return (
            (!searchText ||
                course?.title?.toLowerCase()?.indexOf(searchText) >= 0) &&
            (selectedCourseFilter?.value == "AllCourses" ||
                (selectedCourseFilter?.value == "AllCompletedCourses" &&
                    course.approvedAt) ||
                (selectedCourseFilter?.value == "AllIncompleteCourses" &&
                    !course.approvedAt))
        );
    };

    return (
        <React.Fragment>
            <div className="p-3 flex justify-between">
                <h5 className="p-0 m-0 align-self-center">Courses</h5>
                <UploadSubmissionFormAndButton employeeId={employee.id} />
            </div>
            <hr className="m-0" />
            <div className="p-3 flex justify-between">
                <SearchBar
                    className="mx-0"
                    value={searchText}
                    placeholder="Search"
                    onChange={(e) =>
                        setSearchText(e?.target?.value?.trim()?.toLowerCase())
                    }
                    style={{
                        backgroundPosition: "10px center",
                        width: "250px",
                        paddingLeft: "40px",
                        marginLeft: 0,
                        marginTop: "1px",
                        marginBottom: "1px",
                    }}
                />
                <FilterDropDown
                    options={courseFilterItems}
                    onSelected={setSelectedCourseFilter}
                    selectedValue={selectedCourseFilter}
                    labelSelector={(o) => o.label}
                    keySelector={(o) => o.value}
                />
            </div>
            <div className="flex-grow-1 overflow-auto">
                <table className="table table-hover doctors-table w-full mb-0">
                    <thead>
                        <tr className="p-2">
                            <th className="table-cell">Course Name</th>
                            <th className="table-cell">Submission</th>
                            <th className="table-cell">Date</th>
                            <th className="table-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {employee?.courses
                            ?.filter(courseFilter)
                            ?.map((course) => {
                                return (
                                    <tr key={course.id} className="p-2">
                                        <td className="approval-table-cell">
                                            {course.title}
                                        </td>
                                        <td className="approval-table-cell">
                                            {course.submittedAt &&
                                                course.evaluationFormId && (
                                                    <a
                                                        href="#"
                                                        title="evaluation-form"
                                                        className="evaluation-form-link flex align-items-center align-content-center"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            downloadEvaluationFormSubmissionDocument(
                                                                course.evaluationFormId
                                                            );
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                require("../../images/icon-pdf.svg")
                                                                    .default
                                                            }
                                                            alt="pdf-icon"
                                                        />
                                                        <span>Form.pdf</span>
                                                    </a>
                                                )}
                                        </td>
                                        <td className="approval-table-cell">
                                            {course.approvedAt &&
                                                DateFormat.format(
                                                    course.approvedAt
                                                )}
                                        </td>
                                        <td className="approval-table-cell">
                                            {course.submittedAt && (
                                                <ContextMenu
                                                    menuitems={[
                                                        ...(course.submittedAt &&
                                                            !course.approvedAt
                                                            ? [
                                                                {
                                                                    id: 0,
                                                                    label: "Mark as Completed",
                                                                    context:
                                                                        course,
                                                                    onAction:
                                                                        async (
                                                                            c
                                                                        ) => {
                                                                            await approveEvaluationForm(
                                                                                c.id
                                                                            );
                                                                            await fetchAllUsers();
                                                                        },
                                                                },
                                                            ]
                                                            : []),
                                                        ...(course.submittedAt
                                                            ? [
                                                                {
                                                                    id: 1,
                                                                    label: "Download",
                                                                    context:
                                                                        course,
                                                                    onAction:
                                                                        (c) =>
                                                                            downloadEvaluationFormSubmissionDocument(
                                                                                c.evaluationFormId
                                                                            ),
                                                                },
                                                            ]
                                                            : []),
                                                    ]}
                                                    icon={
                                                        require("../../images/icon-more-vertical.svg")
                                                            .default
                                                    }
                                                />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(DoctorCourses as any) as React.FC<IProps>;

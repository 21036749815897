import styled from "styled-components";

export const TitleLabel = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 8px;
    color: var(--darkGrey);
`;

interface SortingLabelProps {
    fontWeight?: number;
}

export const SortingLabel = styled.div<SortingLabelProps>`
    font-weight: ${(props) => props.fontWeight || 600};
    font-size: 16px;
    line-height: 24px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    color: var(--primaryButtonsLinksColor);
    margin-left: auto;
    margin-right: 5px;
    cursor: pointer;
`;

export interface UserMenuModalProps {
    marginTop?: string;
    right?: string;
    height?: string;
    paddingTop?: string;
    position?: string;
}

export const UserMenuModal = styled.div<UserMenuModalProps>`
    z-index: 999;
    display: block;
    position: ${(props) => props.position || "absolute"};
    float: right;
    width: 202px;
    height: ${(props) => props.height || "115px"};
    margin-top: ${(props) => props.marginTop || "-40px"};
    right: ${(props) => props.right || "10px"};
    background: var(--white);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding-top: ${(props) => props.paddingTop || "19px"};
    padding-left: 15px;
    padding-bottom: 20px;
`;

export interface EmployeesContainerProps {
    minimizeLayout: boolean;
}

export const EmployeesContainer = styled.div<EmployeesContainerProps>`
    display: flex;
    flex-direction: column;
    padding-top: 18px;
    padding-left: ${(props) => !props.minimizeLayout && "20px"};
    border: ${(props) =>
        props.minimizeLayout ? "1px solid var(--lightGrey)" : "none"};
    border-top: none;
    height: ${(props) => (props.minimizeLayout ? "78vh" : "70vh")};
    cursor: default;
    border-left: none;
    padding-right: ${(props) => (!props.minimizeLayout ? "20px" : "0")};
`;

export interface EmployeesTableProps {
    minimizeLayout?: boolean;
}

export const EmployeesTable = styled.div<EmployeesTableProps>`
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border: ${(props) =>
        props.minimizeLayout ? "none" : "1px solid var(--lightGrey)"};
    border-top: 1px solid var(--lightGrey);
    overflow-y: auto;
    flex-grow: ${(props) => (props.minimizeLayout ? "1" : "0")};
    padding-bottom: ${(props) => (props.minimizeLayout ? "70px" : "0")};
`;

interface AddNewCellProps {
    minimizeLayout?: boolean;
    showBottomBorder: boolean;
}

export const AddNewCell = styled.div<AddNewCellProps>`
    height: ${(props) => (props.minimizeLayout ? "57px" : "78.5px")};
    padding: ${(props) =>
        props.minimizeLayout ? "17px 0 15px 0" : "27.3px 0 27.3px 0"};
    border: solid 1px var(--lightGrey);
    border-left: ${(props) =>
        props.minimizeLayout ? "none" : "solid 1px var(--lightGrey)"};
    border-right: ${(props) =>
        props.minimizeLayout ? "none" : "solid 1px var(--lightGrey)"};
    border-bottom: ${(props) =>
        props.showBottomBorder ? "solid 1px var(--lightGrey)" : "none"};
`;

export const AddNewEmployee = styled.div`
    color: #f56007;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
`;

import React from 'react'
import { WelcomeAndAboutSection } from './styles/LandingPage'
import Welcome from './Welcome'
import About from './About'

const WelcomeAndAbout = () => {
  return <WelcomeAndAboutSection>
    <Welcome/>
    <About />
  </WelcomeAndAboutSection>
}

export default WelcomeAndAbout

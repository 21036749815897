import React from 'react'
import { CourseEvent, CourseEventBookingUrl } from '../../models/CourseEvent'
import { CenteredSectionTitleContainer } from './styles/EventHighlights'
import {
  CardContentContainer,
  CardFooterText,
  CardFooterTextContainer,
  CardHeaderContainer,
  CardHeadingAndBadgeContainer,
  CardHeadingBadgeContainer,
  CardHeadingBadgeText,
  CardHeadingText,
  CardPriceAdditionalInfoContainer,
  CardPriceAdditionalInfoText,
  CardPriceContainer,
  CardPriceText,
  EventPricingCardContainer,
  EventPricingCardsContainer,
  EventPricingMainContainer,
  EventPricingSection,
  EventPricingSectionContainer,
  RegisterNowButton,
  RegisterNowButtonText,
  RegisterNowContainer,
  RegisterNowText,
  RegisterNowTextContainer,
  RegisterNowTextHighlighted,
} from './styles/EventPricing'

interface EventPricingProps {
  courseEvent: CourseEvent
}

const EventPricing = (props: EventPricingProps) => {
  const { courseEvent } = props

  const renderPricingCard = (
    headerTitle: string,
    badge: string | null,
    price: number,
    priceSubText: string | null,
    footerText: string,
    index: number,
  ) => {
    return (
      <EventPricingCardContainer key={`event-pricing-card-${index}`}>
        <CardHeaderContainer>
          <CardContentContainer>
            <CardHeadingAndBadgeContainer>
              <CardHeadingText>{headerTitle}</CardHeadingText>
              {badge && (
                <CardHeadingBadgeContainer>
                  <CardHeadingBadgeText>{badge}</CardHeadingBadgeText>
                </CardHeadingBadgeContainer>
              )}
            </CardHeadingAndBadgeContainer>
            <CardPriceContainer>
              <CardPriceText>${price}</CardPriceText>
              {priceSubText && (
                <CardPriceAdditionalInfoContainer>
                  <CardPriceAdditionalInfoText>
                    ({priceSubText})
                  </CardPriceAdditionalInfoText>
                </CardPriceAdditionalInfoContainer>
              )}
            </CardPriceContainer>
            <CardFooterTextContainer>
              <CardFooterText>{footerText}</CardFooterText>
            </CardFooterTextContainer>
          </CardContentContainer>
        </CardHeaderContainer>
      </EventPricingCardContainer>
    )
  }

  if (!courseEvent.courseEventPricingItems?.length) {
    return null;
  }

  return (
    <section className='bg-primary-25 py-12'>
      <div className="container">
        <EventPricingSectionContainer>
          <CenteredSectionTitleContainer>
            Pricing and discounts
          </CenteredSectionTitleContainer>
          <EventPricingMainContainer>
            <EventPricingCardsContainer>
              {courseEvent.courseEventPricingItems.map((priceItem, index) => {
                return renderPricingCard(
                  priceItem.title,
                  priceItem.isEarlyBird === true ? 'Early-bird' : null,
                  priceItem.price,
                  priceItem.priceSubText,
                  priceItem.alternativePriceInformation,
                  index
                )
              })}
            </EventPricingCardsContainer>
          </EventPricingMainContainer>
          <RegisterNowContainer>
            <RegisterNowTextContainer>
              <RegisterNowText>
                Register now and{' '}
                <RegisterNowTextHighlighted>
                  learn all about the skin!
                </RegisterNowTextHighlighted>
              </RegisterNowText>
            </RegisterNowTextContainer>
            <RegisterNowButton
              href={CourseEventBookingUrl(courseEvent)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RegisterNowButtonText>Register now</RegisterNowButtonText>
            </RegisterNowButton>
          </RegisterNowContainer>
        </EventPricingSectionContainer>
      </div>
    </section>
  )
}

export default EventPricing

import React, { useEffect, useState } from 'react'

import { useWindowScroll } from 'react-use'
import { CourseEvent } from '../../models/CourseEvent'
import moment from 'moment'
import {
  TopBannerActionsContainer,
  TopBannerContainer,
  TopBannerContentContainer,
  TopBannerIconAndTextContainer,
  TopBannerIconContainer,
  TopBannerInnerContainer,
  TopBannerPrimaryTextContainer,
  TopBannerSecondaryTextContainer,
  TopBannerTextContainer,
} from './styles/TopBanner'
import Button from '../shared/Button'
import StarsIcon from '../../images/landing-page/stars-02.svg'
import { PaddingContainer } from './styles/General'

interface TopBannerProps {
  event: CourseEvent
}

const TopBanner = (props: TopBannerProps) => {
  const { y: pageYOffset } = useWindowScroll()
  const [scrolled, setScrolled] = useState<boolean>(false)

  const { event } = props

  useEffect(() => {
    if (pageYOffset > 50) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }, [pageYOffset])

  return (
    <TopBannerContainer scrolled={scrolled}>
      <div className="container">
        <PaddingContainer>
          <TopBannerInnerContainer>
            <TopBannerContentContainer>
              <TopBannerIconAndTextContainer>
                <TopBannerIconContainer>
                  <img src={StarsIcon} style={{ height: 20, width: 20 }} />
                </TopBannerIconContainer>
                <TopBannerTextContainer>
                  <TopBannerPrimaryTextContainer>
                    {event.title}{' '}
                    <TopBannerSecondaryTextContainer>
                      {event.description}
                    </TopBannerSecondaryTextContainer>
                  </TopBannerPrimaryTextContainer>
                </TopBannerTextContainer>
              </TopBannerIconAndTextContainer>
              <TopBannerActionsContainer>
                <Button variant="secondary" to={'/event/' + event.id}>
                  Find out more
                </Button>
              </TopBannerActionsContainer>
            </TopBannerContentContainer>
          </TopBannerInnerContainer>
        </PaddingContainer>
      </div>
    </TopBannerContainer>
  )
}

export default TopBanner

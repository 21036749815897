import React from 'react'

import { CourseEvent } from '../../models/CourseEvent'
import {
  EventSpeakerContentContainer,
  EventSpeakerHeadingAndDescriptionContainer,
  EventSpeakersContainer,
} from './styles/EventDetails'
import EventSpeaker from './EventSpeaker'


interface EventSpeakersProps {
  courseEvent: CourseEvent
}

const EventSpeakers = (props: EventSpeakersProps) => {
  const { courseEvent } = props

  if (!courseEvent.courseEventSpeakers || !courseEvent.courseEventSpeakers.length) {
    return null;
  }

  return (
    <EventSpeakerContentContainer>
      <EventSpeakerHeadingAndDescriptionContainer>
        <h2>Speakers</h2>
        <EventSpeakersContainer>
          {courseEvent.courseEventSpeakers.map((speaker) =>
            <EventSpeaker key={speaker.id} speaker={speaker} />,
          )}
        </EventSpeakersContainer>
      </EventSpeakerHeadingAndDescriptionContainer>
    </EventSpeakerContentContainer>
  )
}

export default EventSpeakers

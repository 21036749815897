import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { IApplicationState } from ".";
import { Practice } from "../models/Practice";
import { NavBarItemData } from "../models/NavBarItemData";
import { EmployeeService } from "../services/EmployeeService";
import { PracticeService } from "../services/PracticeService";
import { DocumentService } from "../services/DocumentService";
import { PaygradeService } from "../services/PaygradeService";
import { ShiftAdjustmentService } from "../services/ShiftAdjustmentService";
import { ClockInOutService } from "../services/ClockInOutService";
import { CourseService } from "../services/CourseService";
import { Document } from "../models/Document";
import { Employee } from "../models/Employee";
import { ClockInOut } from "../models/ClockInOut";
import { RoleFilterValue, UserRoles } from "../models/admin/UserRoles";
import { AuthService } from "../services/AuthService";
import SessionManager from "../core/session";
import { ClockInOutPair } from "../models/ClockInOutPair";
import {
    GetTwoWeeksPeriodStart,
    TwoWeeksAfter,
    TwoWeeksBefore,
} from "../components/helper/DateHelper";
import moment from "moment";
import { TimeSheetItem } from "../models/TimeSheetItem";
import { ShiftAdjustment } from "../models/ShiftAdjustment";
import { PayGrade } from "../models/PayGrade";
import { UserService } from "../services/UsersService";
import { Course } from "../models/Course";
import { UserCourseSubmission } from "../models/UserCourseSubmission";
import { UserCourseSubmissionService } from "../services/UserCourseSubmissionService";
import { CourseDetails } from "../models/CourseDetails";
import { UserPayload } from "../models/UserPayload";
import { InviteEmployeePayload } from "../models/InviteEmployeePayload";
import { SortDirection } from "../common/SortDirection";
import { ODataPageResult } from "../common/ODataPageResult";
import { PageFilter } from "../common/PageFilter";
import { CourseUserResponse } from "../models/CourseUserResponse";
import { CourseCategoryFilterValue } from "../common/CourseCategoryFilterValue";
import { UserCourseCertificateDetails } from "../models/UserCourseCertificateDetails";
import Result from "../core/Result";
import { CourseSession } from "../models/CourseSession";
import { CourseSessionLink } from "../models/CourseSessionLink";
import { CourseSessionAttendance } from "../models/CourseSessionAttendance";
import { UserCourseSubmissionSort } from "../models/enums/UserCourseSubmissionSort";
import { UserCourseSubmissionStatus } from "../models/enums/UserCourseSubmissionStatus";
import { UserCourseSubmissionDismissReason } from "../models/enums/UserCourseSubmissionDismissReason";
import { CourseEvent } from "../models/CourseEvent";
import { CourseBanner } from "../models/CourseBanner";
import { UpdateUserCourseAnswerPayload } from "../models/UpdateUserCourseAnswerPayload";
import { CpdType } from "common/CpdType";
import { UsersPageListItem } from "models/UsersPageListItem";

export enum PhoneChangeVerificationState {
    NONE,
    CODE_REQUESTED,
    CODE_RECEIVED,
    REQUEST_FAILED,
}

export enum PhoneChangeCodeVerificationState {
    NONE,
    CODE_SENT,
    REQUEST_SUCCESS,
    REQUEST_FAILED,
}

export enum DocumentUploadState {
    NONE,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    REQUEST_FAILED,
}

export enum TimeSheetUpdateState {
    NONE,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    REQUEST_FAILED,
}

export enum AddClockInOutState {
    NONE,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    REQUEST_FAILED,
}

export enum EmployeeNavigationItems {
    PROFILE,
    CLOCK_IN_HISTORY,
    TIMESHEETS,
    DOCUMENTS,
    EDIT_PROFILE,
    NONE,
}

export enum UpdateState {
    NONE,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    REQUEST_FAILED,
}

// Declare an interface of the store's state.
export interface DashboardState {
    allUsers: Employee[];
    allUsersPage: ODataPageResult<UsersPageListItem>;
    adminUsersPage: ODataPageResult<UsersPageListItem>;
    superAdminUsersPage: ODataPageResult<UsersPageListItem>;
    practices: Practice[];
    navBarItems: NavBarItemData[];
    selectedNavBarItem: string;
    selectedState: string;
    selectedPractice?: Practice;
    selectedEmployee?: Employee;
    isEditingEmployee: boolean;
    selectedEmployeeDocuments: Document[];
    employees: Employee[];
    professionTypes: string[];
    clockInQueryStartDate: Date;
    clockInQueryEndDate: Date;
    selectedClockInOuts: ClockInOut[];
    isLoading: boolean;
    requestCountLoader: number;
    isCustomDateRange: boolean;
    currentEmployee: Employee;
    employeeById: Employee;
    deletedEmployee?: Employee;
    userRoles: UserRoles;
    toastMessage: string | undefined;
    selectedClockInOutPairs: ClockInOutPair[];
    phoneChangeVerificationState: PhoneChangeVerificationState;
    phoneChangeCodeVerificationState: PhoneChangeCodeVerificationState;
    showAddClockInOutModal: boolean;
    showMarkEmployeeModal: boolean;
    showRevokeInviteModal: boolean;
    showDeleteEmployeeModal: boolean;
    documentUploadState: DocumentUploadState;
    timeSheetUpdateState: TimeSheetUpdateState;
    documentDeleteState: UpdateState;
    clockInOutUpdateState: AddClockInOutState;
    timeSheetItems: TimeSheetItem[];
    customTimeSheets: TimeSheetItem[];
    isEditProfileSelected: boolean;
    paygrades: PayGrade[];
    selectedEmployeeNavigationItem: EmployeeNavigationItems;
    selectedUser: Employee;
    allDocuments: Document[];
    incompleteClockinOutPairs: TimeSheetItem;
    adminOvertimeNotifications: ClockInOutPair[];
    allTimeSheetClockInOutPairs: ClockInOutPair[];
    employeeSearchResult: Employee[];
    allCourses: Course[];
    landingPageCourses: Course[];
    notStartedCourses: Course[];
    submittedCourses: Course[];
    completedCourses: Course[];
    courseCompletionFormQrCode: string | null;
    userCourseSubmissions: UserCourseSubmission[];
    courseDetails: CourseDetails | null;
    courseResponses: CourseUserResponse[];
    ownCourseResponses: CourseUserResponse | null;
    userCourseCertificateDetails: UserCourseCertificateDetails | null;
    courseSessions: CourseSession[];
    courseSessionLinks: CourseSessionLink[];
    courseSessionAttendances: CourseSessionAttendance[];
    courseBanners: CourseBanner[];
    professionHeader: string;
    courseEventsToBeDisplayedInCoursesPage: CourseEvent[];
    upcomingCourseEvents: CourseEvent[];
    allUpcomingCourseEvents: CourseEvent[];
    pastCourseEvents: CourseEvent[];
    selectedCourseEvent: CourseEvent | null;
}

const dateRangeStart = GetTwoWeeksPeriodStart(new Date()).toDate();
const dateRangeEnd = moment(dateRangeStart).add(13, "days").toDate();

// Create the slice.
const slice = createSlice({
    name: "dashboard",
    initialState: {
        practices: [],
        currentEmployee: null,
        employeeById: null,
        deletedEmployee: null,
        phoneChangeVerificationState: PhoneChangeVerificationState.NONE,
        phoneChangeCodeVerificationState: PhoneChangeCodeVerificationState.NONE,
        selectedEmployeeDocuments: [],
        documentUploadState: DocumentUploadState.NONE,
        timeSheetUpdateState: TimeSheetUpdateState.NONE,
        clockInOutUpdateState: AddClockInOutState.NONE,
        selectedNavBarItem: null,
        selectedState: "",
        selectedEmployee: null,
        selectedPractice: null,
        isLoading: false,
        requestCountLoader: 0,
        isEditingEmployee: false,
        isCustomDateRange: false,
        selectedClockInOutPairs: [],
        selectedClockInOuts: [],
        clockInQueryStartDate: dateRangeStart,
        clockInQueryEndDate: dateRangeEnd,
        employees: [],
        professionTypes: [],
        allUsers: [],
        allUsersPage: { "@odata.count": 0, value: [] },
        adminUsersPage: { "@odata.count": 0, value: [] },
        superAdminUsersPage: { "@odata.count": 0, value: [] },
        userRoles: {} as UserRoles,
        toastMessage: undefined,
        showAddClockInOutModal: false,
        showMarkEmployeeModal: false,
        showRevokeInviteModal: false,
        showDeleteEmployeeModal: false,
        timeSheetItems: [],
        customTimeSheets: [],
        isEditProfileSelected: false,
        paygrades: [],
        selectedUser: null,
        allDocuments: [],
        selectedEmployeeNavigationItem: EmployeeNavigationItems.PROFILE,
        adminOvertimeNotifications: [],
        incompleteClockinOutPairs: {} as TimeSheetItem,
        allTimeSheetClockInOutPairs: [],
        employeeSearchResult: [],
        allCourses: [],
        landingPageCourses: [],
        notStartedCourses: [],
        submittedCourses: [],
        completedCourses: [],
        courseCompletionFormQrCode: null,
        userCourseSubmissions: [],
        courseDetails: null,
        courseResponses: [],
        userCourseCertificateDetails: null,
        courseSessions: [],
        courseSessionLinks: [],
        courseSessionAttendances: [],
        courseBanners: [],
        professionHeader: "GP",
        courseEventsToBeDisplayedInCoursesPage: [],
        upcomingCourseEvents: [],
        allUpcomingCourseEvents: [],
        pastCourseEvents: [],
        selectedCourseEvent: null,
    } as DashboardState,
    reducers: {
        setAllTimeSheetClockInOutPairs: (
            state,
            action: PayloadAction<ClockInOutPair[]>
        ) => {
            state.allTimeSheetClockInOutPairs = action.payload;
        },
        setEmployeeSearchResult: (state, action: PayloadAction<Employee[]>) => {
            state.employeeSearchResult = action.payload;
        },
        setProfessionTypes: (state, action: PayloadAction<string[]>) => {
            state.professionTypes = action.payload;
        },
        setCustomTimeSheets: (
            state,
            action: PayloadAction<TimeSheetItem[]>
        ) => {
            state.customTimeSheets = action.payload;
        },
        setAdminOvertimeNotifications: (
            state,
            action: PayloadAction<ClockInOutPair[]>
        ) => {
            state.adminOvertimeNotifications = action.payload;
        },
        setAllDocuments: (state, action: PayloadAction<Document[]>) => {
            state.allDocuments = action.payload;
        },
        setIncompleteClockInOutPairs: (
            state,
            action: PayloadAction<TimeSheetItem>
        ) => {
            state.incompleteClockinOutPairs = action.payload;
        },
        setDocumentDeleteState: (state, action: PayloadAction<UpdateState>) => {
            state.documentDeleteState = action.payload;
        },
        setSelectedUser: (state, action: PayloadAction<Employee>) => {
            if (
                action.payload &&
                state.selectedUser?.id === (action.payload as Employee).id
            ) {
                state.selectedUser = null;
            } else {
                state.selectedUser = action.payload;
            }
        },
        setSelectedEmployeeNavigationItem: (
            state,
            action: PayloadAction<EmployeeNavigationItems>
        ) => {
            state.selectedEmployeeNavigationItem = action.payload;
        },
        setPayGrades: (state, action: PayloadAction<PayGrade[]>) => {
            state.paygrades = action.payload;
        },
        setAllUsers: (state, action: PayloadAction<Employee[]>) => {
            state.allUsers = action.payload;
            if (state.selectedUser != null) {
                const userFromResponse = action.payload.find(
                    (x) => state.selectedUser.id === x.id
                );
                if (userFromResponse != null) {
                    state.selectedUser = { ...userFromResponse };
                }
            }
        },
        setAllUsersPage: (
            state,
            action: PayloadAction<ODataPageResult<UsersPageListItem>>
        ) => {
            state.allUsersPage = action.payload;
        },
        setAdminUsersPage: (
            state,
            action: PayloadAction<ODataPageResult<UsersPageListItem>>
        ) => {
            state.adminUsersPage = action.payload;
        },
        setSuperAdminUsersPage: (
            state,
            action: PayloadAction<ODataPageResult<UsersPageListItem>>
        ) => {
            state.superAdminUsersPage = action.payload;
        },
        setIsEditProfileSelected: (state, action: PayloadAction<boolean>) => {
            state.isEditProfileSelected = action.payload;
        },
        setTimeSheetUpdateState: (
            state,
            action: PayloadAction<TimeSheetUpdateState>
        ) => {
            state.timeSheetUpdateState = action.payload;
        },
        setAddClockInOutUpdateState: (
            state,
            action: PayloadAction<AddClockInOutState>
        ) => {
            state.clockInOutUpdateState = action.payload;
        },
        setShowMarkEmployeeModal: (state, action: PayloadAction<boolean>) => {
            state.showMarkEmployeeModal = action.payload;
        },
        setShowRevokeInviteModal: (state, action: PayloadAction<boolean>) => {
            state.showRevokeInviteModal = action.payload;
        },
        setShowDeleteEmployeeModal: (state, action: PayloadAction<boolean>) => {
            state.showDeleteEmployeeModal = action.payload;
        },
        setShowAddClockInOutModal: (state, action: PayloadAction<boolean>) => {
            state.showAddClockInOutModal = action.payload;
        },
        setIsEditingEmployee: (state, action: PayloadAction<boolean>) => {
            state.isEditingEmployee = action.payload;
        },
        setPractices: (state, action: PayloadAction<Practice[]>) => {
            state.practices = action.payload.sort((a, b) =>
                a.name.localeCompare(b.name)
            );
            state.isLoading = false;
        },
        setPhoneChangeVerificationState: (
            state,
            action: PayloadAction<PhoneChangeVerificationState>
        ) => {
            state.phoneChangeVerificationState = action.payload;
        },
        setPhoneChangeCodeVerificationState: (
            state,
            action: PayloadAction<PhoneChangeCodeVerificationState>
        ) => {
            state.phoneChangeCodeVerificationState = action.payload;
        },
        setUserRoles: (state, action: PayloadAction<UserRoles>) => {
            state.userRoles = action.payload;
        },
        setCurrentEmployee: (state, action: PayloadAction<Employee>) => {
            state.currentEmployee = action.payload;
        },
        setEmployeeById: (state, action: PayloadAction<Employee>) => {
            state.employeeById = action.payload;
        },
        setDeletedEmployee: (state, action: PayloadAction<Employee>) => {
            state.deletedEmployee = action.payload;
        },
        setCurrentEmployeeByUserPayload: (
            state,
            action: PayloadAction<UserPayload>
        ) => {
            state.currentEmployee = {
                ...state.currentEmployee,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                phoneNumber: action.payload.contactNumber,
                userRoles: {
                    ...state.currentEmployee.userRoles,
                    role: action.payload.role,
                },
            };
        },
        setPracticeEmployees: (state, action: PayloadAction<any>) => {
            state.practices = state.practices.map((p) =>
                p.id === action.payload.practiceId
                    ? {
                          ...p,
                          employees: action.payload.employees.map((e) => {
                              return {
                                  ...e,
                                  clockInOuts: p.clockInOuts?.filter(
                                      (c) => c.employeeId === e.id
                                  ),
                              };
                          }),
                      }
                    : p
            );
            if (
                state.selectedPractice &&
                state.selectedPractice.id === action.payload.practiceId
            ) {
                state.selectedPractice.employees = action.payload.employees.map(
                    (e) => {
                        return {
                            ...e,
                            clockInOuts: action.payload.employees.filter(
                                (x) => x.id === e.id
                            )[0].clockInOuts,
                        };
                    }
                );
            }
            state.isLoading = false;
        },
        setSelectedState: (state, action: PayloadAction<string>) => {
            state.selectedState = action.payload;
            state.isLoading = false;
        },
        setDocumentUploadState: (
            state,
            action: PayloadAction<DocumentUploadState>
        ) => {
            state.documentUploadState = action.payload;
        },
        setSelectedNavBarItem: (state, action: PayloadAction<string>) => {
            state.selectedNavBarItem = action.payload;
            state.isLoading = false;
        },
        setPreviousDateRangePeriod: (state, action: PayloadAction) => {
            state.clockInQueryStartDate = state.isCustomDateRange
                ? dateRangeStart
                : TwoWeeksBefore(state.clockInQueryStartDate);
            state.clockInQueryEndDate = state.isCustomDateRange
                ? dateRangeEnd
                : TwoWeeksBefore(state.clockInQueryEndDate);
            state.isCustomDateRange = false;
        },
        setNextDateRangePeriod: (state, action: PayloadAction) => {
            state.clockInQueryStartDate = state.isCustomDateRange
                ? dateRangeStart
                : TwoWeeksAfter(state.clockInQueryStartDate);
            state.clockInQueryEndDate = state.isCustomDateRange
                ? dateRangeEnd
                : TwoWeeksAfter(state.clockInQueryEndDate);
            state.isCustomDateRange = false;
        },
        setSelectedPractice: (state, action: PayloadAction<Practice>) => {
            state.selectedPractice = action.payload;
            state.isLoading = false;
        },
        setSelectedEmployee: (state, action: PayloadAction<Employee>) => {
            if (action.payload === null) {
                state.selectedEmployee = action.payload;
            } else if (
                !state.selectedEmployee ||
                state.selectedEmployee.id !== (action.payload as Employee).id
            ) {
                state.selectedEmployee = action.payload;
                state.selectedClockInOuts = [];
            } else {
                state.selectedEmployee = null;
                state.selectedClockInOuts = [];
            }
            state.selectedClockInOutPairs = [];
            state.selectedEmployeeDocuments = [];
            state.timeSheetItems = [];
            state.isLoading = false;
        },
        setUpdatedEmployee: (state, action: PayloadAction<Employee>) => {
            state.selectedEmployee = action.payload;
            state.employeeById = action.payload;
        },
        setUpdatedUser: (state, action: PayloadAction<UserPayload>) => {
            if (state.selectedUser) {
                state.selectedUser = {
                    ...state.selectedUser,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    phoneNumber: action.payload.contactNumber,
                    userRoles: {
                        ...state.selectedUser.userRoles,
                        role: action.payload.role,
                    },
                };
            }
            if (state.selectedEmployee) {
                state.selectedEmployee = {
                    ...state.selectedEmployee,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    phoneNumber: action.payload.contactNumber,
                    userRoles: {
                        ...state.selectedEmployee.userRoles,
                        role: action.payload.role,
                    },
                };
            }
            if (state.employeeById) {
                state.employeeById = {
                    ...state.employeeById,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    phoneNumber: action.payload.contactNumber,
                    userRoles: {
                        ...state.employeeById.userRoles,
                        role: action.payload.role,
                    },
                };
            }

            state.allUsers = state.allUsers.map((x) =>
                x.id === action.payload.id ? { ...state.selectedUser } : x
            );

            state.toastMessage = "Successfully updated user.";
        },
        clearEmployeeById: (state, action: {}) => {
            state.employeeById = null;
        },
        setToastMessage: (state, action: PayloadAction<string>) => {
            state.toastMessage = action.payload;
        },
        clearToastMessage: (state, action: {}) => {
            state.toastMessage = undefined;
        },
        clearDeletedEmployee: (state, action: {}) => {
            state.deletedEmployee = null;
        },
        setClockInQueryStartDate: (state, action: PayloadAction<Date>) => {
            state.clockInQueryStartDate = action.payload;
            state.isCustomDateRange = true;
            state.isLoading = false;
        },
        setClockInQueryEndDate: (state, action: PayloadAction<Date>) => {
            state.clockInQueryEndDate = action.payload;
            state.isCustomDateRange = true;
            state.isLoading = false;
        },
        setClockInOuts: (state, action: PayloadAction<ClockInOut[]>) => {
            state.selectedClockInOuts = action.payload;
            state.isLoading = false;
        },
        setClockInOutsForAllPractices: (
            state,
            action: PayloadAction<ClockInOut[]>
        ) => {
            state.practices = state.practices.map((p) => {
                return {
                    ...p,
                    clockInOuts: action.payload.filter(
                        (c) => c.practiceId === p.id
                    ),
                };
            });
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        increaseRequestCountLoader: (state, action: {}) => {
            state.requestCountLoader++;
        },
        decreaseRequestCountLoader: (state, action: {}) => {
            state.requestCountLoader--;
        },
        setSelectedClockInOutPairs: (
            state,
            action: PayloadAction<ClockInOutPair[]>
        ) => {
            state.selectedClockInOutPairs = action.payload;
        },
        setSelectedEmployeeDocuments: (
            state,
            action: PayloadAction<Document[]>
        ) => {
            state.selectedEmployeeDocuments = action.payload;
        },
        setTimeSheetItems: (state, action: PayloadAction<TimeSheetItem[]>) => {
            state.timeSheetItems = action.payload;
        },
        setAllCourses: (state, action: PayloadAction<Course[]>) => {
            state.allCourses = action.payload;
        },
        setAllLandingPageCourses: (state, action: PayloadAction<Course[]>) => {
            state.landingPageCourses = action.payload;
        },
        setNotStartedCourses: (state, action: PayloadAction<Course[]>) => {
            state.notStartedCourses = action.payload;
        },
        setSubmittedCourses: (state, action: PayloadAction<Course[]>) => {
            state.submittedCourses = action.payload;
        },
        setCompletedCourses: (state, action: PayloadAction<Course[]>) => {
            state.completedCourses = action.payload;
        },
        setCourseStarted: (state, action: PayloadAction<number>) => {
            state.allCourses = state.allCourses.map((x) => {
                return x.id === action.payload
                    ? {
                          ...x,
                          startedAt: new Date(),
                      }
                    : x;
            });

            state.notStartedCourses = state.notStartedCourses.filter(
                (x) => x.id !== action.payload
            );
        },
        setCourseSubmitted: (state, action: PayloadAction<number>) => {
            state.allCourses = state.allCourses.map((x) => {
                return x.id === action.payload
                    ? {
                          ...x,
                          submittedAt: new Date(),
                      }
                    : x;
            });

            state.submittedCourses = state.submittedCourses.concat([
                { ...state.allCourses.find((x) => x.id === action.payload) },
            ]);
        },
        setCourseCompletionFormQrCode: (
            state,
            action: PayloadAction<string | null>
        ) => {
            state.courseCompletionFormQrCode = action.payload;
        },
        setUserCourseSubmissions: (
            state,
            action: PayloadAction<UserCourseSubmission[]>
        ) => {
            state.userCourseSubmissions = action.payload;
        },
        setCourseBanners: (state, action: PayloadAction<CourseBanner[]>) => {
            state.courseBanners = action.payload;
        },
        setProfessionHeader: (state, action: PayloadAction<string>) => {
            state.professionHeader = action.payload;
        },
        setCourseDetails: (
            state,
            action: PayloadAction<CourseDetails | null>
        ) => {
            state.courseDetails = action.payload;
        },
        setCourseResponses: (
            state,
            action: PayloadAction<CourseUserResponse[]>
        ) => {
            state.courseResponses = action.payload;
        },
        setOwnCourseResponses: (
            state,
            action: PayloadAction<CourseUserResponse | null>
        ) => {
            state.ownCourseResponses = action.payload;
        },
        setUserCourseCertificateDetails: (
            state,
            action: PayloadAction<UserCourseCertificateDetails>
        ) => {
            state.userCourseCertificateDetails = action.payload || null;
        },
        setCourseSessions: (state, action: PayloadAction<CourseSession[]>) => {
            state.courseSessions = action.payload || null;
        },
        setCourseSessionLinks: (
            state,
            action: PayloadAction<CourseSessionLink[]>
        ) => {
            state.courseSessionLinks = action.payload || null;
        },
        setCourseSessionAttendances: (
            state,
            action: PayloadAction<CourseSessionAttendance[]>
        ) => {
            state.courseSessionAttendances = action.payload || null;
        },
        setCourseEventsToBeDisplayedInCoursesPage: (
            state,
            action: PayloadAction<CourseEvent[]>
        ) => {
            state.courseEventsToBeDisplayedInCoursesPage = action.payload || [];
        },
        setUpcomingCourseEvents: (
            state,
            action: PayloadAction<CourseEvent[]>
        ) => {
            state.upcomingCourseEvents = action.payload || [];
        },
        setAllUpcomingCourseEvents: (
            state,
            action: PayloadAction<CourseEvent[]>
        ) => {
            state.allUpcomingCourseEvents = action.payload || [];
        },
        setPastCourseEvents: (state, action: PayloadAction<CourseEvent[]>) => {
            state.pastCourseEvents = action.payload || [];
        },
        setSelectedCourseEvent: (state, action: PayloadAction<CourseEvent>) => {
            state.selectedCourseEvent = action.payload || null;
        },
    },
});

// Export reducer from the slice.
export const { reducer } = slice;

// Define action creators.
export const actionCreators = {
    requestPhoneNumberChangeValidation:
        (phoneNumber: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(
                slice.actions.setPhoneChangeVerificationState(
                    PhoneChangeVerificationState.CODE_REQUESTED
                )
            );
            const response = await AuthService.RequestCode(phoneNumber);
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setPhoneChangeVerificationState(
                        PhoneChangeVerificationState.CODE_RECEIVED
                    )
                );
            } else {
                dispatch(
                    slice.actions.setPhoneChangeVerificationState(
                        PhoneChangeVerificationState.REQUEST_FAILED
                    )
                );
            }
        },

    validatePhoneNumberChange:
        (phoneNumber: string, employeeId: number, code: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(
                slice.actions.setPhoneChangeCodeVerificationState(
                    PhoneChangeCodeVerificationState.CODE_SENT
                )
            );
            const response = await AuthService.ValidateCodeForMobileChange(
                phoneNumber,
                employeeId,
                code
            );
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setPhoneChangeCodeVerificationState(
                        PhoneChangeCodeVerificationState.REQUEST_SUCCESS
                    )
                );
                SessionManager.setLogin(response.value.token, true);
            } else {
                dispatch(
                    slice.actions.setPhoneChangeCodeVerificationState(
                        PhoneChangeCodeVerificationState.REQUEST_FAILED
                    )
                );
            }
        },
    addEmployee:
        (employee: Employee, practiceId: number, generateContract: boolean) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await EmployeeService.AddEmployee(
                employee,
                practiceId,
                generateContract
            );
            if (!result.hasErrors) {
                const response =
                    await PracticeService.fetchPracticeEmployeesById(
                        practiceId
                    );
                if (!response.hasErrors) {
                    const responseWithPracticeId = {
                        practiceId: practiceId,
                        employees: response.value,
                    };
                    dispatch(
                        slice.actions.setPracticeEmployees(
                            responseWithPracticeId
                        )
                    );
                }
            }
        },
    setCustomTimesheetItems:
        (items: TimeSheetItem[]) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setCustomTimeSheets(items));
        },
    inviteEmployee:
        (inviteEmployeePayload: InviteEmployeePayload) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await UserService.InviteEmployee(
                inviteEmployeePayload
            );
            if (!result.hasErrors) {
                dispatch(
                    slice.actions.setToastMessage(
                        "Successfully invited employee."
                    )
                );

                const response = await UserService.FetchAllUsers();
                if (!response.hasErrors) {
                    dispatch(slice.actions.setAllUsers(response.value));
                }

                return true;
            } else {
                dispatch(
                    slice.actions.setToastMessage("Invite employee failed.")
                );
            }

            return false;
        },
    logoutUser:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            SessionManager.removeLogin();
            dispatch(slice.actions.setCurrentEmployee(null));
        },
    resendInvite:
        (employee: Employee) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await UserService.ResendInvite(employee);
            if (!result.hasErrors) {
                const response = await UserService.FetchAllUsers();
                if (!response.hasErrors) {
                    dispatch(slice.actions.setAllUsers(response.value));
                }
            }
        },
    fetchDeletedEmployee:
        (email: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchDeletedEmployee(email);
            if (!response.hasErrors) {
                dispatch(slice.actions.setDeletedEmployee(response.value));
            } else {
                SessionManager.removeLogin();
            }
        },
    fetchEmployee:
        (email: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchEmployee(email);
            if (!response.hasErrors) {
                dispatch(slice.actions.setCurrentEmployee(response.value));
            } else {
                SessionManager.removeLogin();
            }
        },
    fetchEmployeeById:
        (id: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchEmployeeById(id);
            if (!response.hasErrors) {
                dispatch(slice.actions.setEmployeeById(response.value));
            } else {
                SessionManager.removeLogin();
            }
        },
    clearEmployeeById:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.clearEmployeeById());
        },
    fetchCurrentEmployee:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchCurrentEmployee();
            if (!response.hasErrors) {
                dispatch(slice.actions.setCurrentEmployee(response.value));
            } else {
                SessionManager.removeLogin();
            }
        },
    fetchAllUsers:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await UserService.FetchAllUsers();
            if (!response.hasErrors) {
                dispatch(slice.actions.setAllUsers(response.value));
            }
        },
    fetchAllUsersPage:
        (
            pageSize: number,
            currentPage: number,
            filter: PageFilter[],
            sortBy: string,
            sortDirection?: SortDirection
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await UserService.FetchUsersPage(
                pageSize,
                currentPage,
                filter.slice(),
                sortBy,
                sortDirection
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setAllUsersPage(response.value));
            }
            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchAdminUsersPage:
        (
            pageSize: number,
            currentPage: number,
            filter: PageFilter[],
            sortBy: string,
            sortDirection?: SortDirection
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const adminFilter = filter.slice();
            adminFilter.push({
                filterName: "userRole",
                filterValue: RoleFilterValue.ADMIN,
                isContains: false,
            });
            const response = await UserService.FetchUsersPage(
                pageSize,
                currentPage,
                adminFilter,
                sortBy,
                sortDirection
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setAdminUsersPage(response.value));
            }
            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchSuperAdminUsersPage:
        (
            pageSize: number,
            currentPage: number,
            filter: PageFilter[],
            sortBy: string,
            sortDirection?: SortDirection
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const superAdminFilter = filter.slice();
            superAdminFilter.push({
                filterName: "userRole",
                filterValue: RoleFilterValue.SUPER_ADMIN,
                isContains: false,
            });
            const response = await UserService.FetchUsersPage(
                pageSize,
                currentPage,
                superAdminFilter,
                sortBy,
                sortDirection
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setSuperAdminUsersPage(response.value));
            }
            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchEmployeeRoles:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchEmployeeRoles();
            if (!response.hasErrors) {
                dispatch(slice.actions.setUserRoles(response.value));
            }
        },
    fetchUser:
        (token: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {},
    updatePractice:
        (practice: Practice) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            await PracticeService.UpdatePractice(practice);
        },
    updateCurrentEmployee:
        (employee: Employee) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.UpdateEmployee(employee);
            if (!response.hasErrors) {
                dispatch(slice.actions.setCurrentEmployee(employee));
            }
        },
    setSelectedEmployeeFromSearchableEmployees:
        (employee: Employee) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchPracticesForEmployee(
                employee.id
            );
            if (!response.hasErrors) {
                if (response.value.length > 0) {
                    const practice = response.value[0];
                    dispatch(slice.actions.setSelectedNavBarItem("practices"));
                    dispatch(slice.actions.setSelectedState(practice.state));
                    dispatch(slice.actions.setSelectedPractice(practice));
                    dispatch(slice.actions.setSelectedEmployee(null));
                    dispatch(slice.actions.setSelectedEmployee(employee));
                }
            }
        },
    updateEmployee:
        (employee: Employee) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.UpdateEmployee(employee);
            if (!response.hasErrors) {
                dispatch(slice.actions.setUpdatedEmployee(employee));
                dispatch(
                    slice.actions.setSelectedPractice({
                        ...getState().dashboard.selectedPractice,
                        employees:
                            getState().dashboard.selectedPractice?.employees?.map(
                                (x) => {
                                    return x.id === employee.id ? employee : x;
                                }
                            ),
                    })
                );
            }
        },
    updateUser:
        (userPayload: UserPayload) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await UserService.UpdateUser(userPayload);
            if (!response.hasErrors) {
                dispatch(slice.actions.setUpdatedUser(userPayload));
                if (
                    getState().dashboard.currentEmployee.id === userPayload.id
                ) {
                    dispatch(
                        slice.actions.setCurrentEmployeeByUserPayload(
                            userPayload
                        )
                    );
                }
            } else {
                dispatch(slice.actions.setToastMessage("Update user failed"));
            }
        },
    clearToastMessage:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.clearToastMessage());
        },
    clearDeletedEmployee:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.clearDeletedEmployee());
        },
    fetchPracticeEmployees:
        (practice: Practice) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await PracticeService.fetchPracticeEmployees(
                practice
            );
            if (!response.hasErrors) {
                const responseWithPracticeId = {
                    practiceId: practice.id,
                    employees: response.value,
                };
                dispatch(
                    slice.actions.setPracticeEmployees(responseWithPracticeId)
                );
            }
        },
    fetchPractices:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const practices = await PracticeService.FetchPractices();
            if (!practices.hasErrors) {
                dispatch(slice.actions.setPractices(practices.value));
                if (getState().dashboard.selectedPractice) {
                    const newSelectedPractice = practices.value.filter(
                        (p) => p.id === getState().dashboard.selectedPractice.id
                    )?.[0];
                    newSelectedPractice &&
                        dispatch(
                            slice.actions.setSelectedPractice(
                                newSelectedPractice
                            )
                        );
                }
            }
        },
    setSelectedState:
        (state: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setSelectedState(state));
        },
    setShowAddClockInOutModal:
        (value: boolean) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setShowAddClockInOutModal(value));
        },
    setShowMarkEmployeeModal:
        (value: boolean) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setShowMarkEmployeeModal(value));
        },
    setShowRevokeInviteModal:
        (value: boolean) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setShowRevokeInviteModal(value));
        },
    setShowDeleteEmployeeModal:
        (value: boolean) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setShowDeleteEmployeeModal(value));
        },
    setSelectedNavBarItem:
        (item: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setSelectedEmployee(null));
            dispatch(slice.actions.setSelectedPractice(null));
            dispatch(slice.actions.setSelectedNavBarItem(item));
        },
    setSelectedPractice:
        (practice: Practice) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setSelectedEmployee(null));
            dispatch(slice.actions.setSelectedPractice(practice));
        },
    setSelectedEmployee:
        (employee: Employee) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setSelectedEmployee(employee));
        },
    setClockInQueryStartDate:
        (date: Date) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setClockInQueryStartDate(date));
        },
    setClockInQueryEndDate:
        (date: Date) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setClockInQueryEndDate(date));
        },
    setNextDateRangePeriod:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setNextDateRangePeriod());
        },
    setPreviousDateRangePeriod:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setPreviousDateRangePeriod());
        },
    setPhoneChangeVerificationState:
        (state: PhoneChangeVerificationState) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setPhoneChangeVerificationState(state));
        },
    setPhoneChangeCodeVerificationState:
        (state: PhoneChangeCodeVerificationState) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setPhoneChangeCodeVerificationState(state));
        },
    fetchClockInOutsForDate:
        (date: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await ClockInOutService.GetClockInOutsForDate(date);
            if (!result.hasErrors) {
                dispatch(
                    slice.actions.setClockInOutsForAllPractices(result.value)
                );
            }
        },
    addClockInOuts:
        (employeeId: number, practiceId: number, clockInOut: ClockInOut) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(
                slice.actions.setAddClockInOutUpdateState(
                    AddClockInOutState.REQUEST_SUCCESS
                )
            );

            const result = await EmployeeService.AddClockInOut(
                employeeId,
                practiceId,
                clockInOut
            );
            if (!result.hasErrors) {
                dispatch(
                    slice.actions.setAddClockInOutUpdateState(
                        AddClockInOutState.REQUEST_SUCCESS
                    )
                );
            } else {
                dispatch(
                    slice.actions.setAddClockInOutUpdateState(
                        AddClockInOutState.REQUEST_FAILED
                    )
                );
            }
        },
    fetchClockInOuts:
        (
            employee: Employee,
            practice: Practice,
            startDate: string,
            endDate: string
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await EmployeeService.GetClockInOuts(
                employee.id,
                startDate,
                endDate,
                practice.id
            );
            if (!result.hasErrors) {
                dispatch(slice.actions.setClockInOuts(result.value));
            }
        },
    deleteEmployee:
        (employeeId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await EmployeeService.DeleteEmployee(employeeId);
            if (!result.hasErrors) {
                alert("Employee successfully deleted");

                dispatch(slice.actions.setSelectedEmployee(null));
                const fetchResponse =
                    await PracticeService.fetchPracticeEmployees(
                        getState().dashboard.selectedPractice
                    );
                if (!fetchResponse.hasErrors) {
                    const responseWithPracticeId = {
                        practiceId: getState().dashboard.selectedPractice.id,
                        employees: fetchResponse.value,
                    };
                    dispatch(
                        slice.actions.setPracticeEmployees(
                            responseWithPracticeId
                        )
                    );
                }
            }
        },
    revokeInvite:
        (employeeId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await UserService.RevokeInvite(employeeId);
            if (!result.hasErrors) {
                alert("Invitation successfully revoked");

                dispatch(slice.actions.setSelectedUser(null));
                const fetchResponse = await UserService.FetchAllUsers();
                if (!fetchResponse.hasErrors) {
                    dispatch(slice.actions.setAllUsers(fetchResponse.value));
                }
            }
        },
    deleteUser:
        (employeeId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await EmployeeService.DeleteEmployee(employeeId);
            if (!result.hasErrors) {
                dispatch(slice.actions.setSelectedUser(null));
                dispatch(slice.actions.setEmployeeById(null));
                dispatch(
                    slice.actions.setToastMessage("Successfully deleted user.")
                );

                const fetchResponse = await UserService.FetchAllUsers();
                if (!fetchResponse.hasErrors) {
                    dispatch(slice.actions.setAllUsers(fetchResponse.value));
                }
                return true;
            }

            return false;
        },
    toggleUserActiveFlag:
        (employeeId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await EmployeeService.ToggleUserActiveFlag(
                employeeId
            );
            if (!result.hasErrors) {
                const fetchResponse = await UserService.FetchAllUsers();
                dispatch(
                    slice.actions.setEmployeeById({
                        ...getState().dashboard.employeeById,
                        isActive: !getState().dashboard.employeeById.isActive,
                    })
                );
                if (!fetchResponse.hasErrors) {
                    dispatch(slice.actions.setAllUsers(fetchResponse.value));
                }

                return true;
            }

            return false;
        },
    fetchDocumentsForEmployee:
        (employeeId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.GetDocuments(employeeId);
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setSelectedEmployeeDocuments(response.value)
                );
            }
        },
    fetchClockInOutPairs:
        (
            employee: Employee,
            practice: Practice,
            startDate: string,
            endDate: string
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await EmployeeService.GetClockInOutPairs(
                employee.id,
                startDate,
                endDate,
                practice.id
            );
            if (!result.hasErrors) {
                dispatch(
                    slice.actions.setSelectedClockInOutPairs(result.value)
                );
            }
        },
    setSelectedUser:
        (user: Employee) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setSelectedUser(user));
        },
    setDocumentUploadState:
        (state: DocumentUploadState) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setDocumentUploadState(state));
        },
    getDocumentPresignedUrl:
        (documentId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const result = await DocumentService.GetPresignedUrl(documentId);
            if (!result.hasErrors) {
                dispatch(
                    slice.actions.setSelectedEmployeeDocuments([
                        ...getState().dashboard.selectedEmployeeDocuments.map(
                            (x) => {
                                if (x.id === documentId) {
                                    return result.value;
                                }
                                return x;
                            }
                        ),
                    ])
                );
                dispatch(
                    slice.actions.setAllDocuments([
                        ...getState().dashboard.allDocuments.map((x) => {
                            if (x.id === documentId) {
                                return result.value;
                            }
                            return x;
                        }),
                    ])
                );
            }
        },
    fetchPayGrades:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await PaygradeService.FetchPaygrades();
            if (!response.hasErrors) {
                dispatch(slice.actions.setPayGrades(response.value));
            }
        },
    fetchTimeSheets:
        (employeeId: number, pageNum: number, pageSize: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchTimesheets(
                employeeId,
                pageNum,
                pageSize
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setTimeSheetItems(response.value));
            }
        },
    fetchCustomTimeSheets:
        (
            employeeId: number,
            practiceId: number,
            startDate: string,
            endDate: string
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchCustomTimesheets(
                employeeId,
                practiceId,
                startDate,
                endDate
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setCustomTimeSheets(response.value));
            }
        },
    fetchAllTimeSheets:
        (employeeId: number, startDate: string, endDate: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await ClockInOutService.FetchAllTimeSheets(
                employeeId,
                startDate,
                endDate
            );
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setAllTimeSheetClockInOutPairs(response.value)
                );
            }
        },
    deleteDocument:
        (documentId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            slice.actions.setDocumentDeleteState(UpdateState.REQUEST_SENT);
            const response = await DocumentService.DeleteDocument(documentId);
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setDocumentDeleteState(
                        UpdateState.REQUEST_SUCCESS
                    )
                );
            } else {
                dispatch(
                    slice.actions.setDocumentDeleteState(
                        UpdateState.REQUEST_FAILED
                    )
                );
            }
        },
    setDocumentDeleteState:
        (state: UpdateState) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setDocumentDeleteState(state));
        },
    addShiftAdjustments:
        (shiftAdjustments: ShiftAdjustment[]) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(
                slice.actions.setTimeSheetUpdateState(
                    TimeSheetUpdateState.REQUEST_SENT
                )
            );
            const response = await ShiftAdjustmentService.AddShiftAdjustments(
                shiftAdjustments
            );
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setTimeSheetUpdateState(
                        TimeSheetUpdateState.REQUEST_SUCCESS
                    )
                );
            } else {
                dispatch(
                    slice.actions.setTimeSheetUpdateState(
                        TimeSheetUpdateState.REQUEST_FAILED
                    )
                );
            }
        },
    updateShiftAdjustment:
        (shiftAdjustment: ShiftAdjustment) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(
                slice.actions.setTimeSheetUpdateState(
                    TimeSheetUpdateState.REQUEST_SENT
                )
            );
            const response = await ShiftAdjustmentService.UpdateShiftAdjustment(
                shiftAdjustment
            );
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setTimeSheetUpdateState(
                        TimeSheetUpdateState.REQUEST_SUCCESS
                    )
                );
            } else {
                dispatch(
                    slice.actions.setTimeSheetUpdateState(
                        TimeSheetUpdateState.REQUEST_FAILED
                    )
                );
            }
        },
    setTimeSheetUpdateState:
        (state: TimeSheetUpdateState) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setTimeSheetUpdateState(state));
        },
    fetchAllDocuments:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await DocumentService.FetchAllDocuments();
            if (!response.hasErrors) {
                dispatch(slice.actions.setAllDocuments(response.value));
            }
        },
    searchEmployees:
        (searchText: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.SearchEmployees(searchText);
            if (!response.hasErrors) {
                dispatch(slice.actions.setEmployeeSearchResult(response.value));
            }
        },
    fetchProfessionTypes:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await EmployeeService.FetchProfessionTypes();
            if (!response.hasErrors) {
                dispatch(slice.actions.setProfessionTypes(response.value));
            }
        },
    setEmployeeSearchResult:
        (employees: []) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setEmployeeSearchResult(employees));
        },
    fetchAdminNotifications:
        (employeeId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response =
                await ShiftAdjustmentService.FetchAdminNotifications(
                    employeeId
                );
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setAdminOvertimeNotifications(response.value)
                );
            }
        },
    setIsEditProfileSelected:
        (value: boolean) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setIsEditProfileSelected(value));
        },
    setClockInOutUpdateState:
        (value: AddClockInOutState) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setAddClockInOutUpdateState(value));
        },
    setSelectedEmployeeNavigationItem:
        (value: EmployeeNavigationItems) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setSelectedEmployeeNavigationItem(value));
        },
    fetchIncompleteClockInOutPairs:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response =
                await ClockInOutService.GetIncompleteClockInOutPairs();
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setIncompleteClockInOutPairs(response.value)
                );
            }
        },
    uploadDocument:
        (document: FormData) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(
                slice.actions.setDocumentUploadState(
                    DocumentUploadState.REQUEST_SENT
                )
            );
            const response = await DocumentService.UploadDocument(document);
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setDocumentUploadState(
                        DocumentUploadState.REQUEST_SUCCESS
                    )
                );
            } else {
                dispatch(
                    slice.actions.setDocumentUploadState(
                        DocumentUploadState.REQUEST_FAILED
                    )
                );
            }
        },
    updateDocument:
        (document: Document) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(
                slice.actions.setDocumentUploadState(
                    DocumentUploadState.REQUEST_SENT
                )
            );
            const response = await DocumentService.UpdateDocument(document);
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setDocumentUploadState(
                        DocumentUploadState.REQUEST_SUCCESS
                    )
                );
            } else {
                dispatch(
                    slice.actions.setDocumentUploadState(
                        DocumentUploadState.REQUEST_FAILED
                    )
                );
            }
        },
    downloadFile:
        (url: string, file: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            await DocumentService.DownloadFile(url, file);
        },
    fetchAllCourses:
        (
            filter: string,
            sort: string,
            categoryFilterValue: CourseCategoryFilterValue,
            cpd: CpdType,
            adminViewAs: string | null,
            year?: number
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetAll(
                filter,
                sort,
                categoryFilterValue,
                cpd,
                adminViewAs,
                year
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setAllCourses(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchAllLandingPageCourses:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetAllLandingPage();
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setAllLandingPageCourses(response.value)
                );
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchNotStartedCourses:
        (
            filter: string,
            sort: string,
            categoryFilterValue: CourseCategoryFilterValue,
            cpd: CpdType,
            adminViewAs: string | null,
            year?: number
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetNotStarted(
                filter,
                sort,
                categoryFilterValue,
                cpd,
                adminViewAs,
                year
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setNotStartedCourses(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchSubmittedCourses:
        (
            filter: string,
            sort: string,
            categoryFilterValue: CourseCategoryFilterValue,
            cpd: CpdType,
            adminViewAs: string | null,
            year?: number
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetSubmitted(
                filter,
                sort,
                categoryFilterValue,
                cpd,
                adminViewAs,
                year
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setSubmittedCourses(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchCompletedCourses:
        (
            filter: string,
            sort: string,
            categoryFilterValue: CourseCategoryFilterValue,
            cpd: CpdType,
            adminViewAs: string | null,
            year?: number
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetCompleted(
                filter,
                sort,
                categoryFilterValue,
                cpd,
                adminViewAs,
                year
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setCompletedCourses(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    startCourse:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setLoading(true));
            await CourseService.StartCourse(courseId);
            dispatch(slice.actions.setCourseStarted(courseId));
            dispatch(slice.actions.setLoading(false));
        },
    markCourseAsSubmitted:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setLoading(true));
            await CourseService.MarkCourseAsSubmitted(courseId);
            dispatch(slice.actions.setCourseSubmitted(courseId));
            dispatch(slice.actions.setLoading(false));
        },
    fetchCourseCompletionFormQrCode:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setLoading(true));
            dispatch(slice.actions.setCourseCompletionFormQrCode(null));
            const imageBlob = (
                await CourseService.GetCourseCompletionFormQrCode(courseId)
            ).value;

            const imageObjectUrl = URL.createObjectURL(imageBlob);
            dispatch(
                slice.actions.setCourseCompletionFormQrCode(imageObjectUrl)
            );
            dispatch(slice.actions.setLoading(false));
        },
    fetchUserCourseSubmissions:
        (
            filter: string,
            courseId: number | null,
            status: UserCourseSubmissionStatus | null,
            sort: UserCourseSubmissionSort,
            isAscending: boolean
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response =
                await UserCourseSubmissionService.FetchAllSubmissions(
                    filter,
                    courseId,
                    status,
                    sort,
                    isAscending
                );
            dispatch(slice.actions.decreaseRequestCountLoader());
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setUserCourseSubmissions(response.value)
                );
            }
        },
    fetchCourseBanners:
        () => async (dispatch: Dispatch, getState: () => IApplicationState) => {
            const response = await CourseService.FetchBanners();
            if (!response.hasErrors) {
                dispatch(slice.actions.setCourseBanners(response.value));
            }
        },
    updateProfessionHeader:
        (professionHeader: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setProfessionHeader(professionHeader));
        },
    approveEvaluationForm:
        (id: number, isReapprove?: boolean) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await UserCourseSubmissionService.Approve(
                id,
                isReapprove
            );
            dispatch(slice.actions.decreaseRequestCountLoader());

            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setUserCourseSubmissions(
                        getState().dashboard.userCourseSubmissions.map((x) =>
                            x.id !== id
                                ? { ...x }
                                : {
                                      ...x,
                                      status: UserCourseSubmissionStatus.Approved,
                                  }
                        )
                    )
                );
                dispatch(
                    slice.actions.setCourseResponses(
                        getState().dashboard.courseResponses.map((x) =>
                            x.evaluationFormId !== id
                                ? { ...x }
                                : {
                                      ...x,
                                      status: UserCourseSubmissionStatus[
                                          UserCourseSubmissionStatus.Approved
                                      ].toString() as any,
                                  }
                        )
                    )
                );
            }
        },
    dismissEvaluationForm:
        (
            id: number,
            reason: UserCourseSubmissionDismissReason,
            comment: string
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await UserCourseSubmissionService.Dismiss(
                id,
                reason,
                comment
            );
            dispatch(slice.actions.decreaseRequestCountLoader());

            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setUserCourseSubmissions(
                        getState().dashboard.userCourseSubmissions.map((x) =>
                            x.id !== id
                                ? { ...x }
                                : {
                                      ...x,
                                      status: UserCourseSubmissionStatus[
                                          UserCourseSubmissionStatus.Dismissed
                                      ].toString() as any,
                                      dismissComment: comment,
                                      dismissReason:
                                          UserCourseSubmissionDismissReason[
                                              reason
                                          ].toString() as any,
                                  }
                        )
                    )
                );
                dispatch(
                    slice.actions.setCourseResponses(
                        getState().dashboard.courseResponses.map((x) =>
                            x.evaluationFormId !== id
                                ? { ...x }
                                : {
                                      ...x,
                                      submissionStatus:
                                          UserCourseSubmissionStatus[
                                              UserCourseSubmissionStatus
                                                  .Dismissed
                                          ].toString() as any,
                                      dismissComment: comment,
                                      dismissReason:
                                          UserCourseSubmissionDismissReason[
                                              reason
                                          ].toString() as any,
                                  }
                        )
                    )
                );
            }
        },
    fetchCourseDetails:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setCourseDetails(null));
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetDetails(courseId);
            if (!response.hasErrors) {
                dispatch(slice.actions.setCourseDetails(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchCourseResponses:
        (
            courseId: number,
            filter: string,
            sort: string,
            status: UserCourseSubmissionStatus | null
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setCourseResponses([]));
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetCourseResponses(
                courseId,
                filter,
                sort,
                status
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setCourseResponses(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchOwnCourseResponses:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.setCourseResponses([]));
            dispatch(slice.actions.increaseRequestCountLoader());
            dispatch(slice.actions.setOwnCourseResponses(null));
            const response = await CourseService.GetOwnCourseResponses(
                courseId
            );

            if (!response.hasErrors) {
                dispatch(slice.actions.setOwnCourseResponses(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchUserCourseCertificateDetails:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetCertificateDetails(
                courseId
            );
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setUserCourseCertificateDetails(
                        response.value
                    )
                );
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchCourseSessions:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetSessions(courseId);
            if (!response.hasErrors) {
                dispatch(slice.actions.setCourseSessions(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchCourseSessionLinks:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetSessionLinks(courseId);
            if (!response.hasErrors) {
                dispatch(slice.actions.setCourseSessionLinks(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchCourseSessionAttendances:
        (courseId: number, sessionLinkId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetSessionLinkAttendances(
                courseId,
                sessionLinkId
            );
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setCourseSessionAttendances(response.value)
                );
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchCourseEventsToBeDisplayedInCoursesPage:
        (filter: string, sort: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response =
                await CourseService.GetCourseEventsToBeDisplayedInCoursesPage(
                    filter,
                    sort
                );
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setCourseEventsToBeDisplayedInCoursesPage(
                        response.value
                    )
                );
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchUpcomingCourseEvents:
        (filter: string, sort: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetUpcomingEvents(
                filter,
                sort
            );
            if (!response.hasErrors) {
                dispatch(slice.actions.setUpcomingCourseEvents(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchAllUpcomingCourseEvents:
        (sort: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetAllUpcomingEvents(sort);
            if (!response.hasErrors) {
                dispatch(
                    slice.actions.setAllUpcomingCourseEvents(response.value)
                );
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchPastCourseEvents:
        (filter: string, sort: string) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.GetPastEvents(filter, sort);
            if (!response.hasErrors) {
                dispatch(slice.actions.setPastCourseEvents(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    fetchCourseEvent:
        (id: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            dispatch(slice.actions.setSelectedCourseEvent(null));
            const response = await CourseService.GetEventById(id);
            if (!response.hasErrors) {
                dispatch(slice.actions.setSelectedCourseEvent(response.value));
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    updateOwnUserCourseAnswer:
        (courseQuestionId: string, payload: UpdateUserCourseAnswerPayload) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.UpdateOwnUserCourseAnswer(
                courseQuestionId,
                payload
            );
            if (!response.hasErrors) {
                //TODO load answers again
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    updateUserCourseAnswer:
        (
            courseQuestionId: string,
            userId: number,
            payload: UpdateUserCourseAnswerPayload
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());
            const response = await CourseService.UpdateUserCourseAnswer(
                courseQuestionId,
                userId,
                payload
            );
            if (!response.hasErrors) {
                //TODO load answers again
            }

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    downloadSessionAttendancesCsv:
        (courseId: number, sessionLinkId?: number, employeeId?: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());

            const documentBlob =
                await CourseService.DownloadSessionAttendancesCsv(
                    courseId,
                    sessionLinkId,
                    employeeId
                );
            const fileName = `Session_Attendances_Report_${
                sessionLinkId || 0
            }.csv`;
            downloadBlob(documentBlob, fileName);

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    downloadSessionAttendancePdf:
        (courseId: number, sessionLinkId: number, employeeId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());

            const documentBlob =
                await CourseService.DownloadSessionAttendancePdf(
                    courseId,
                    sessionLinkId,
                    employeeId
                );
            const fileName = `Session_Attendances_${sessionLinkId}_${employeeId}.pdf`;
            downloadBlob(documentBlob, fileName);

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    downloadEvaluationFormSubmissionDocument:
        (id: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());

            const documentBlob =
                await UserCourseSubmissionService.DownloadSubmission(id);
            const fileName = `Evaluation_Form_Submission_${id}.pdf`;
            downloadBlob(documentBlob, fileName);

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    downloadCourseResponsesCsvExport:
        (
            courseId: number,
            filter: string,
            sort: string,
            employeeId: number | null = null
        ) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());

            const documentBlob =
                await CourseService.DownloadCourseResponsesCsvExport(
                    courseId,
                    filter,
                    sort,
                    employeeId
                );
            const fileName = `Course_Responses_${courseId}.csv`;
            downloadBlob(documentBlob, fileName);

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    downloadUserCourseCertificateExport:
        (courseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());

            const documentBlob =
                await CourseService.DownloadUserCourseCertificateExport(
                    courseId
                );
            const fileName = `Course_Certificate_${courseId}.pdf`;
            downloadBlob(documentBlob, fileName);

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
    downloadUserCourseCertificateExportAdmin:
        (userCourseId: number) =>
        async (dispatch: Dispatch, getState: () => IApplicationState) => {
            dispatch(slice.actions.increaseRequestCountLoader());

            const documentBlob =
                await CourseService.DownloadUserCourseCertificateExportAdmin(
                    userCourseId
                );
            const fileName = `Course_Certificate_${userCourseId}.pdf`;
            downloadBlob(documentBlob, fileName);

            dispatch(slice.actions.decreaseRequestCountLoader());
        },
};

const downloadBlob = (documentBlob: Result<Blob>, defaultFileName: string) => {
    if (!documentBlob.hasErrors) {
        let fileName = defaultFileName.toString();
        if (
            documentBlob.headers &&
            documentBlob.headers["content-disposition"]
        ) {
            fileName = (documentBlob.headers["content-disposition"] as string)
                ?.split(";")
                ?.map((x) => x.trim())
                ?.find((x) => x.startsWith("filename="))
                ?.substring(9);
        }

        const documentObjectUrl = URL.createObjectURL(documentBlob.value);
        const anchor = document.createElement("a");
        anchor.target = "_blank";
        anchor.href = documentObjectUrl;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
};

import "./styles/FilterDropDown.scss";
import React, { useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

export interface MenuItemProp {
    id: string | number;
    label?: string;
    child?: any;
    labelColor?: string;
    className?: string;
    context?: any;
    onAction?: (context: any) => void;
}

interface Props {
    menuitems: MenuItemProp[];
    className?: string;
    icon?: any;
    dropdownMenuend?: boolean;
    rightAligned?: boolean;
}

export const ContextMenu: React.FC<Props> = (props: Props) => {
    const { menuitems, className, icon, dropdownMenuend, rightAligned } = props;
    const [isOpened, setIsOpened] = useState(false);

    return (
        <Dropdown
            className={`context-dropdown-menu ${className ?? ""}`}
            isOpen={isOpened}

            toggle={() => setIsOpened((p) => !p)}
        >
            <DropdownToggle className="toggle-span" tag="span" role="button">
                <img
                    role="button"
                    src={icon ?? require("../../images/icon-more.svg").default}
                    alt="more-icon"
                />
            </DropdownToggle>
            <DropdownMenu
                style={{
                    zIndex: 9999,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                right={rightAligned === true}
            >
                {menuitems.map((o) => (
                    <DropdownItem
                        key={o.id}
                        className={o.className}
                        onClick={() => o.onAction && o.onAction(o.context)}
                        style={{ color: o.labelColor || "default" }}
                    >
                        {o.label || o.child}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

import { Link } from "react-router-dom";
import styled from "styled-components";

export const DescriptionLabel = styled.label`
    margin-bottom: 5px;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: var(--black);
`;

export const SmallLabels = styled.div`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: #4d4d4d;
`;

export const HelpLabels = styled.div`
    font-size: 13px;
    line-height: 1.23;
    color: #4d4d4d;
    line-height: 2em;
`;

interface HelpLabelProps {
    color?: string;
}

export const HelpLabel = styled.span<HelpLabelProps>`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: ${(props) => (props.color ? props.color : "#4d4d4d")};
`;

export const HelpLink = styled(Link) <HelpLabelProps>`
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: ${(props) => (props.color ? props.color : "#4d4d4d")} !important;
`;

export const Card = styled.div`
    background-color: var(--white);
    padding: 21px 29.5px 30px;
`;

export const SigninAlert = styled.div`
    background: #cb24240d;
    border-radius: 10px;
    padding: 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #cb2424;
`;

export const InputExplanation = styled.span`
    display: block;
`;

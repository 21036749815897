import React, { forwardRef } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';

type Props = {
    label: string;
    invalid?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>,
    "type"
>

export const Radio = forwardRef<HTMLInputElement, Props>(({ invalid, className, label, id, ...inputProps }: Props, ref) => {
    return <FormGroup check className={className}>
        <Input {...inputProps} type="radio" invalid={invalid} id={id} innerRef={ref} />
        <Label check htmlFor={id}>{label}</Label>
    </FormGroup>;
});
import styled from "styled-components";
import { device } from "../../styles/sizes/device";
import { Link } from "react-router-dom";

export interface PageContentProps {
  topBannerIsVisible: boolean;
}

export const PageContent = styled.div<PageContentProps>`
//   padding-top: ${(props) => (props.topBannerIsVisible ? "72px" : "0px")};

//   @media ${device.sm} {
//     padding-top: ${(props) => (props.topBannerIsVisible ? "120px" : "0px")};
//   }

//   @media ${device.xs} {
//     padding-top: ${(props) => (props.topBannerIsVisible ? "194px" : "0px")};
//   }
`;

import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const EventHighlightsSection = styled.section`
    background: var(--orange-25);
    padding: 96px 0px 96px 0px;
    @media ${device.xs} {
        padding: 48px 16px 48px 16px;
    }
`;

export const CenteredSectionTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--gray-900);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    @media ${device.xs} {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const SectionTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    @media ${device.xs} {
        font-size: 24px;
        line-height: 32px;
    }
`;

export const EventHighlightsSectionContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 64px;
    @media ${device.xs} {
        gap: 48px;
    }
`;

export const EventHighlightsCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;
    align-self: stretch;
    @media ${device.xs} {
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
    }
`;

export const EventHighlightsCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    @media ${device.xs} {
        gap: 16px;
    }
`;

export const CardImageContainer = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 8px solid #fef1e9;
    background: #fddbc4;
    @media ${device.xs} {
        width: 40px;
        height: 40px;
        border: 6px solid #fef1e9;
    }
`;

export const ImageContainer = styled.svg`
    font-size: 48px;
`;

export const CardTextContainer = styled.div``;

export const CardTitleContainer = styled.h1`
    color: var(--gray-900);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    @media ${device.xs} {
        font-size: 18px;
        line-height: 28px;
    }
`;

export const CardDescriptionContainer = styled.p`
    color: var(--gray-600);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media ${device.xs} {
        font-size: 16px;
        line-height: 24px;
    }
`;

export enum CourseCategoryFilterValue {
    All = "All",
    ClinicalAudits = "ClinicalAudits",
    Webinars = "Webinars",
    MeetingSeries = "MeetingSeries",
    Meetings = "Meetings",
    Conferences = "Conferences",
    Workshops = "Workshops",
    RecordedWebinars = "RecordedWebinars",
}

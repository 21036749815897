import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { Practice } from "../models/Practice";
import { Employee } from "../models/Employee";
import { ClockInOut } from "../models/ClockInOut";

class PracticeServiceClass extends ServiceBase {
  private static _practiceService: PracticeServiceClass;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): PracticeServiceClass {
    return (
      this._practiceService || (this._practiceService = new this("practices"))
    );
  }

  public async FetchPractices(): Promise<Result<Practice[]>> {
    const result = await super.requestJson<Practice[]>({
      method: "GET",
      url: "",
      callType: ApiCall.GetPractices,
      data: {},
    });

    return result;
  }

  public async fetchPracticeEmployees(
    practice: Practice
  ): Promise<Result<Employee[]>> {
    const result = await super.requestJson<Employee[]>({
      method: "GET",
      url: `${practice.id}/employees`,
      callType: ApiCall.fetchPracticeEmployees,
      data: {},
    });

    return result;
  }

  public async fetchPracticeEmployeesById(
    practiceId: number
  ): Promise<Result<Employee[]>> {
    const result = await super.requestJson<Employee[]>({
      method: "GET",
      url: `${practiceId}/employees`,
      callType: ApiCall.fetchPracticeEmployees,
      data: {},
    });

    return result;
  }

  public async UpdatePractice(practice: Practice): Promise<Result<{}>> {
    const result = await super.requestJson<Practice[]>({
      method: "PUT",
      url: `practice`,
      callType: ApiCall.UpdatePractice,
      data: {
        ...practice,
      },
    });

    return result;
  }
}

export const PracticeService = PracticeServiceClass.Instance;

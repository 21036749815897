import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { ClockInOut } from "../models/ClockInOut";
import { ClockInOutPair } from "../models/ClockInOutPair";
import { TimeSheetItem } from "../models/TimeSheetItem";

class ClockInOutServiceClass extends ServiceBase {
  private static _clockInOutService: ClockInOutServiceClass;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): ClockInOutServiceClass {
    return (
      this._clockInOutService ||
      (this._clockInOutService = new this("clockinouts"))
    );
  }

  public async GetClockInOutsForDate(
    date: string
  ): Promise<Result<ClockInOut[]>> {
    const result = await super.requestJson<ClockInOut[]>({
      method: "GET",
      url: ``,
      callType: ApiCall.GetClockInOutsForDate,
      data: {
        date: date,
      },
    });

    return result;
  }

  public async FetchAllTimeSheets(
    employeeId: number,
    startDate: string,
    endDate: string
  ): Promise<Result<ClockInOutPair[]>> {
    const result = await super.requestJson<ClockInOutPair[]>({
      method: "GET",
      url: `timesheets`,
      callType: ApiCall.FetchAllTimeSheets,
      data: {
        adminId: employeeId,
        startDate: startDate,
        endDate: endDate,
      },
    });

    return result;
  }

  public async GetIncompleteClockInOutPairs(): Promise<Result<TimeSheetItem>> {
    const result = await super.requestJson<TimeSheetItem>({
      method: "GET",
      url: `incomplete`,
      callType: ApiCall.GetIncompleteClockInOutPairs,
    });

    return result;
  }
}

export const ClockInOutService = ClockInOutServiceClass.Instance;

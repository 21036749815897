import React, { forwardRef } from 'react';
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';
import { InputDecorator, InputDecoratorProps } from './InputDecorator';

type TextInputProps = {
    type?: InputType;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>,
    "type"
> & InputDecoratorProps

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ className, hint, label, error, id, fieldError, ...inputProps }, ref) => {
    return <InputDecorator
        id={id}
        className={className}
        error={error}
        label={label}
        hint={hint}
        fieldError={fieldError}
    >
        <Input
            invalid={!!(error || fieldError)}
            {...inputProps}
            id={id}
            innerRef={ref} />
    </InputDecorator>
});

import React from "react";
import { HelpLabels, HelpLabel } from "../../pages/auth/styles/Signin";
import mailIcon from "../../images/icon-mail.svg";

export const HelpText = () => {
    return (
        <HelpLabels className="flex justify-between">
            <HelpLabel>
                © Myhealth 2024
            </HelpLabel>
            <a href="mailto:CPD@myhealth.net.au">
                <HelpLabel>
                    <img src={mailIcon} alt="" className="inline" /> CPD@myhealth.net.au
                </HelpLabel>
            </a>
        </HelpLabels>
    );
};

import React, { useState } from "react";
import Header from "../../components/shared/Header";
import { RouteComponentProps } from "react-router";
import { EmployeeService } from "../../services/EmployeeService";
import { Employee } from "../../models/Employee";
import { EvaluationForm } from "./EvaluationForm";
import { Container } from "reactstrap";
import { EvaluationFormData } from "../../models/EvaluationFormData";
import { CourseService } from "../../services/CourseService";
import { CourseDetails } from "../../models/CourseDetails";

const EvaluationFormPage: React.FC<RouteComponentProps> = (
    props: RouteComponentProps
) => {
    const [course, setCourse] = useState<CourseDetails | undefined>();
    const [employee, setEmployee] = useState<Employee | undefined>();
    const [evaluationFormData, setEvaluationFormData] = useState<
        EvaluationFormData | undefined
    >();
    const [showErrorPage, setShowErrorPage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    var employeeEmail = new URLSearchParams(props.location.search).get(
        "employee"
    );

    var learningMaterialId = new URLSearchParams(props.location.search).get(
        "learningmaterial"
    );

    const fetchEmployee = async () => {
        var result = await EmployeeService.FetchEmployee(employeeEmail);
        if (!result.hasErrors && result.value) {
            setEmployee(result.value);
            return result.value;
        } else {
            setShowErrorPage(true);
        }

        return undefined;
    };

    const fetchCourse = async () => {
        var result = await CourseService.GetDetails(+learningMaterialId);
        if (!result.hasErrors && result.value) {
            setCourse(result.value);
            return result.value;
        } else {
            setShowErrorPage(true);
        }

        return undefined;
    };

    const fetchFormData = async () => {
        var result = await EmployeeService.FetchEvaluationFormData(
            employeeEmail,
            +learningMaterialId,
            true
        );
        if (!result.hasErrors) {
            setEvaluationFormData(result.value);
            setIsDataLoaded(true);
        } else {
            if (!result.friendlyError) {
                setShowErrorPage(true);
            } else {
                setErrorMessage(result.friendlyError);
            }
        }

        return undefined;
    };

    React.useEffect(() => {
        fetchCourse();
        fetchFormData();
        fetchEmployee();
    }, []);

    const saveEvaluationFormData = async (formContent) => {
        var learningMaterialId = new URLSearchParams(props.location.search).get(
            "learningmaterial"
        );

        var result = await EmployeeService.SaveEvaluationFormData(
            employeeEmail,
            +learningMaterialId,
            formContent
        );
        if (!result.hasErrors) {
            setShowSuccessMessage(true);
        }
    };

    return (
        <React.Fragment>
            <Header hideSearchBar hideAccountInfo />
            <Container className="evaluation-form-container mt-5">
                {showErrorPage && (
                    <h3 className="text-center">Failed to load data :(</h3>
                )}

                {errorMessage && (
                    <h3 className="text-center">{errorMessage}</h3>
                )}

                {!showErrorPage && !errorMessage && showSuccessMessage && (
                    <h3 className="text-center">
                        You have sent the form successfully!
                    </h3>
                )}

                {isDataLoaded &&
                    !showErrorPage &&
                    !errorMessage &&
                    !showSuccessMessage && (
                        <EvaluationForm
                            employee={employee}
                            course={course}
                            onSubmit={saveEvaluationFormData}
                            evaluationForm={evaluationFormData}
                        />
                    )}
            </Container>
        </React.Fragment>
    );
};

export default EvaluationFormPage;

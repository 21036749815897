import React from 'react'

import { CourseEvent } from '../../models/CourseEvent'
import { SectionTitleContainer } from './styles/EventHighlights'
import {
  EventSponsorContentContainer,
  EventSponsorDescriptionContainer,
  EventSponsorHeadingAndDescriptionContainer,
  EventSponsorImageContainer,
} from './styles/EventDetails'

interface EventSponsorProps {
  courseEvent: CourseEvent
}

const EventSponsor = (props: EventSponsorProps) => {
  const { courseEvent } = props

  if (courseEvent.sponsorLogoBase64 == null) {
    return null;
  }

  return (
    <EventSponsorContentContainer>
      <EventSponsorHeadingAndDescriptionContainer>
        <SectionTitleContainer>Sponsor</SectionTitleContainer>
        <EventSponsorDescriptionContainer>
          Myhealth would like to thank our sponsor for this event
        </EventSponsorDescriptionContainer>
      </EventSponsorHeadingAndDescriptionContainer>
      <EventSponsorImageContainer
        src={`data:image/png;base64,${courseEvent.sponsorLogoBase64}`}
      />
    </EventSponsorContentContainer>
  )
}

export default EventSponsor

import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

import { AdvancedSelect as UncontrolledSelect, AdvancedSelectProps } from './advanced-select';
import { InputDecorator, InputDecoratorProps } from './InputDecorator';

type Props<V, O, F> =
    & AdvancedSelectProps<V, O>
    & InputDecoratorProps
    & Omit<ControllerProps<F>, "render">


export function AdvancedSelect<TValue, TOption, FormValues>({ className, hint, label, error, id, fieldError, name, control, rules, ...props }: Props<TValue, TOption, FormValues>) {
    return <InputDecorator
        id={id}
        className={className}
        error={error}
        label={label}
        hint={hint}
        fieldError={fieldError}
    >
        <Controller
            rules={rules}
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
                <UncontrolledSelect
                    {...props}
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched/blur
                    value={value}
                />
            )}
        />
    </InputDecorator>
}
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Modal, ModalHeader, Row } from "reactstrap";

import { PAGE_SIZE } from "consts";
import { UserSortColumn } from "common/UserSortColumn";
import { PageFilter } from "common/PageFilter";
import { CourseSessionLink } from "models/CourseSessionLink";
import { UsersPageListItem } from "models/UsersPageListItem";
import { CourseService } from "services/CourseService";
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";

import { LogUserAttendanceForm, LogUserAttendanceFormValues } from "./forms/LogUserAttendanceForm";
import "./styles/GenerateLinkButtonAndModal.scss";

interface Props {
    courseId: number;
    sessionLinkId?: number;
    hideButton?: boolean;
    isModalOpened?: boolean;
    onClose?: () => void;
}

type PropsType = Props &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const LogUserAttendanceButtonAndModal: React.FC<PropsType> = (
    props: PropsType
) => {
    const {
        courseId,
        hideButton,
        isModalOpened,
        onClose,
        courseSessionLinks,
        fetchAllUsersPage,
        allUsersPage,
        fetchCourseSessions,
        fetchCourseSessionLinks,
        fetchCourseSessionAttendances
    } = props;

    const formRef = useRef<any>();

    const [isLogUserModalOpened, setIsLoUserModalOpened] = useState(false);
    const [logUserErrorMessage, setLogUserErrorMessage] = useState("");
    const [currentUsers, setCurrentUsers] = useState<UsersPageListItem[]>([]);

    const [modalInfo, setModalInfo] = useState<{ name: string, sessionLink: CourseSessionLink }>()

    const close = useCallback(() => {
        setIsLoUserModalOpened(false);
        onClose && onClose();
    }, [onClose]);

    const logUserAttendance = useCallback(async ({ sessionLinkId,
        employeeId }: LogUserAttendanceFormValues) => {
        const response = await CourseService.LogUserAttendance(
            courseId,
            0,
            sessionLinkId,
            employeeId
        );
        if (response && !response.hasErrors) {
            console.log({ employeeId, currentUsers });
            const selectedUser = currentUsers.find(
                (u) => u.employeeId === employeeId
            );
            setModalInfo(
                {
                    name: `${selectedUser.firstName} ${selectedUser.lastName}`,
                    sessionLink: courseSessionLinks.find(l => l.id === sessionLinkId)
                }
            );
            close();
            fetchCourseSessions(courseId);
            fetchCourseSessionLinks(courseId);
            if (sessionLinkId) {
                fetchCourseSessionAttendances(
                    courseId,
                    sessionLinkId
                );
            }
        } else {
            setLogUserErrorMessage(response.errors[0]);
        }
    }, [close, courseId, courseSessionLinks, currentUsers, fetchCourseSessionAttendances, fetchCourseSessionLinks, fetchCourseSessions]);

    const searchUsers = useCallback((name: string) => {
        const filter: PageFilter[] = [];

        if (name) {
            const childLevelFilters: PageFilter[] = [];
            childLevelFilters.push({
                filterName: "firstName",
                filterValue: name,
                isContains: true,
            });
            childLevelFilters.push({
                filterName: "lastName",
                filterValue: name,
                isContains: true,
            });
            filter.push({
                filterName: "",
                filterValue: "",
                isContains: false,
                childLevelFilters: childLevelFilters,
            });
        }

        fetchAllUsersPage(PAGE_SIZE, 0, filter, UserSortColumn.Name);
    }, [fetchAllUsersPage]);

    useEffect(() => {
        if (isModalOpened === true || isModalOpened === false) {
            setIsLoUserModalOpened(isModalOpened);
        }
    }, [isModalOpened]);

    useEffect(() => {
        setCurrentUsers(cu =>
            cu
                .slice()
                .concat(
                    allUsersPage.value
                        .filter(
                            (x) =>
                                cu
                                    .map((y) => y.employeeId)
                                    .indexOf(x.employeeId) === -1
                        )
                        .slice()
                )
        );
    }, [allUsersPage]);

    return (
        <>
            {isLogUserModalOpened && (
                <Modal
                    className="course-modal"
                    isOpen={isLogUserModalOpened}
                    centered
                    size="md"
                >
                    <div className="modal-header-icon-container">
                        <img
                            alt=""
                            src={
                                require("images/modal-circles.svg")
                                    .default
                            }
                        />
                        <img
                            alt=""
                            className="modal-header-icon"
                            src={
                                require("images/modal-check-circle-broken.svg")
                                    .default
                            }
                        />
                    </div>
                    <ModalHeader toggle={() => close()}></ModalHeader>
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>Log User Attendance</h5>
                                <p className="description">
                                    Log the attendance of a user for a session.
                                </p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-0">
                                <LogUserAttendanceForm
                                    allUsersPage={allUsersPage}
                                    courseSessionLinks={courseSessionLinks}
                                    logUserAttendance={logUserAttendance}
                                    searchUsers={searchUsers}
                                    formRef={formRef}
                                    onCancel={close}
                                />
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}

            {logUserErrorMessage && (
                <Modal
                    className="course-modal"
                    isOpen={!!logUserErrorMessage}
                    centered
                >
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>Error</h5>
                                <p className="description">
                                    {logUserErrorMessage}
                                </p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-3">
                                <Button
                                    className="w-full"
                                    color="primary"
                                    onClick={(_) =>
                                        setLogUserErrorMessage(undefined)
                                    }
                                >
                                    Ok
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}

            {modalInfo && (
                <Modal
                    className="course-modal"
                    isOpen
                    centered
                >
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>Attendance Logged!</h5>
                                <p className="description">
                                    The attendance for {modalInfo.name} has successfully been logged for{" "}
                                    {modalInfo.sessionLink?.sessionLinkName}
                                    .
                                </p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-3">
                                <Button
                                    className="w-full"
                                    color="primary"
                                    onClick={(_) => {
                                        setModalInfo(null);
                                    }}
                                >
                                    Done
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}

            {!hideButton && (
                <Button
                    color="secondary"
                    className="me-1"
                    onClick={() => setIsLoUserModalOpened(true)}
                >
                    Log user attendance
                </Button>
            )}
        </>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(LogUserAttendanceButtonAndModal as any) as React.FC<Props>;

import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { Practice } from "../models/Practice";
import { Employee } from "../models/Employee";
import { ClockInOut } from "../models/ClockInOut";
import { UserRoles } from "../models/admin/UserRoles";
import { ClockInOutPair } from "../models/ClockInOutPair";
import { Document } from "../models/Document";
import { TimeSheetItem } from "../models/TimeSheetItem";
import { EvaluationFormData } from "../models/EvaluationFormData";

class EmployeeServiceClass extends ServiceBase {
    private static _employeeService: EmployeeServiceClass;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): EmployeeServiceClass {
        return (
            this._employeeService ||
            (this._employeeService = new this("employees"))
        );
    }

    public async GetDocuments(employeeId: number): Promise<Result<Document[]>> {
        const result = await super.requestJson<Document[]>({
            method: "GET",
            url: `${employeeId}/documents`,
            callType: ApiCall.GetDocuments,
        });

        return result;
    }

    public async GetClockInOuts(
        employeeId: number,
        startDate: string,
        endDate: string,
        practiceId: number
    ): Promise<Result<ClockInOut[]>> {
        const result = await super.requestJson<ClockInOut[]>({
            method: "GET",
            url: `${employeeId}/practices/${practiceId}/clockinouts`,
            callType: ApiCall.GetClockInOuts,
            data: {
                employeeId: employeeId,
                practiceId: practiceId,
                startDate: startDate,
                endDate: endDate,
            },
        });

        return result;
    }

    public async FetchTimesheets(
        employeeId: number,
        pageNum: number,
        pageSize: number
    ): Promise<Result<TimeSheetItem[]>> {
        const result = await super.requestJson<TimeSheetItem[]>({
            method: "GET",
            url: `${employeeId}/timesheets`,
            callType: ApiCall.FetchTimeSheets,
            data: {
                employeeId: employeeId,
                pageNum: pageNum,
                pageSize: pageSize,
            },
        });

        return result;
    }

    public async FetchPracticesForEmployee(
        employeeId: number
    ): Promise<Result<Practice[]>> {
        const result = await super.requestJson<Practice[]>({
            method: "GET",
            url: `${employeeId}/practices`,
            callType: ApiCall.FetchPracticesForEmployee,
            data: {
                employeeId: employeeId,
            },
        });
        return result;
    }

    public async FetchCustomTimesheets(
        employeeId: number,
        practiceId: number,
        startDate: string,
        endDate: string
    ): Promise<Result<TimeSheetItem[]>> {
        const result = await super.requestJson<TimeSheetItem[]>({
            method: "GET",
            url: `${employeeId}/customtimesheets/${practiceId}`,
            callType: ApiCall.FetchTimeSheets,
            data: {
                employeeId: employeeId,
                startDate: startDate,
                endDate: endDate,
            },
        });

        return result;
    }

    public async AddClockInOut(
        employeeId: number,
        practiceId: number,
        clockInOut: ClockInOut
    ): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "POST",
            url: `${employeeId}/practices/${practiceId}/manualclockinouts`,
            callType: ApiCall.AddClockInOut,
            data: {
                ...clockInOut,
            },
        });

        return result;
    }

    public async DeleteEmployee(employeeId: number): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "DELETE",
            url: `${employeeId}`,
            callType: ApiCall.DeleteEmployee,
        });

        return result;
    }

    public async ToggleUserActiveFlag(
        employeeId: number
    ): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "POST",
            url: `${employeeId}/toggleactiveflag`,
            callType: ApiCall.ToggleUserActiveFlag,
        });

        return result;
    }

    public async GetClockInOutPairs(
        employeeId: number,
        startDate: string,
        endDate: string,
        practiceId: number
    ): Promise<Result<ClockInOutPair[]>> {
        const result = await super.requestJson<ClockInOutPair[]>({
            method: "GET",
            url: `${employeeId}/practices/${practiceId}/clockinoutpairs`,
            callType: ApiCall.GetClockInOutPairs,
            data: {
                employeeId: employeeId,
                practiceId: practiceId,
                startDate: startDate,
                endDate: endDate,
            },
        });

        return result;
    }

    public async AddEmployee(
        employee: Employee,
        practiceId: number,
        generateContract: boolean
    ): Promise<Result<{}>> {
        const result = await super.requestJson<{}>({
            method: "POST",
            url: `?practiceid=${practiceId}&generateContract=${generateContract}`,
            callType: ApiCall.AddEmployee,
            data: {
                ...employee,
            },
        });

        return result;
    }

    public async FetchEmployeeRoles(): Promise<Result<UserRoles>> {
        const result = await super.requestJson<UserRoles>({
            method: "GET",
            url: "roles",
            callType: ApiCall.FetchEmployeeRoles,
        });

        return result;
    }

    public async UpdateEmployee(employee: Employee): Promise<Result<{}>> {
        const result = await super.requestJson<Practice[]>({
            method: "PUT",
            url: `employee`,
            callType: ApiCall.UpdateEmployee,
            data: {
                ...employee,
            },
        });

        return result;
    }

    public async FetchEmployee(email: string): Promise<Result<Employee>> {
        const result = await super.requestJson<Employee>({
            method: "GET",
            url: `?email=${email}`,
            callType: ApiCall.FetchEmployee,
        });

        return result;
    }

    public async FetchEmployeeById(id: number): Promise<Result<Employee>> {
        const result = await super.requestJson<Employee>({
            method: "GET",
            url: `${id}`,
            callType: ApiCall.FetchEmployee,
        });

        return result;
    }

    public async FetchDeletedEmployee(email: string): Promise<Result<Employee>> {
        const result = await super.requestJson<Employee>({
            method: "GET",
            url: `deleted?email=${email}`,
            callType: ApiCall.FetchEmployee,
        });

        return result;
    }

    public async FetchCurrentEmployee(): Promise<Result<Employee>> {
        const result = await super.requestJson<Employee>({
            method: "GET",
            url: `current`,
            callType: ApiCall.FetchEmployee,
        });

        return result;
    }

    public async FetchEvaluationFormData(
        employeeEmail: string,
        learningMaterialId: number,
        hideToastOnError?: boolean
    ): Promise<Result<EvaluationFormData>> {
        const result = await super.requestJson<EvaluationFormData>({
            method: "GET",
            url: `learningmaterial?employeeEmail=${employeeEmail}&learningMaterialId=${learningMaterialId}`,
            callType: ApiCall.FetchEvaluationFormData,
            hideToastOnError: hideToastOnError
        });

        return result;
    }

    public async SaveEvaluationFormData(
        employeeEmail: string,
        learningMaterialId: number,
        formContent: any
    ): Promise<Result<void>> {
        const result = await super.requestJson<void>({
            method: "POST",
            url: `learningmaterial?employeeEmail=${employeeEmail}&learningMaterialId=${learningMaterialId}`,
            callType: ApiCall.SaveEvaluationFormData,
            data: {
                ...formContent,
            },
        });

        return result;
    }

    public async SearchEmployees(
        searchText: string
    ): Promise<Result<Employee[]>> {
        const result = await super.requestJson<Employee[]>({
            method: "GET",
            url: `search/?searchText=${searchText}`,
            callType: ApiCall.SearchEmployees,
        });

        return result;
    }

    public async FetchProfessionTypes(): Promise<Result<string[]>> {
        const result = await super.requestJson<string[]>({
            method: "GET",
            url: `professiontypes`,
            callType: ApiCall.SearchEmployees,
        });

        return result;
    }
}

export const EmployeeService = EmployeeServiceClass.Instance;

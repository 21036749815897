import React from 'react';
import { CourseCategoryFilterValue } from "common/CourseCategoryFilterValue";
import { Badge } from "../Badge";
import classNames from 'classnames';

function getClassName(categoryFilterValue: CourseCategoryFilterValue) {
    switch (categoryFilterValue) {
        case CourseCategoryFilterValue.ClinicalAudits:
            return "bg-purple-50 text-purple-700";
        case CourseCategoryFilterValue.Webinars:
            return "bg-blue-50 text-blue-700";
        case CourseCategoryFilterValue.MeetingSeries:
            return "bg-pink-50 text-pink-700";
        case CourseCategoryFilterValue.Meetings:
            return "bg-pink-50 text-pink-700";
        case CourseCategoryFilterValue.Conferences:
            return "bg-primary-50 text-primary-700";
        case CourseCategoryFilterValue.Workshops:
            return "bg-green-50 text-green-700";
        case CourseCategoryFilterValue.RecordedWebinars:
            return "bg-blue-50 text-blue-700";
        default:
            return "";
    }
}

function getLabel(categoryFilterValue: CourseCategoryFilterValue) {
    switch (categoryFilterValue) {
        case CourseCategoryFilterValue.ClinicalAudits:
            return "Clinical Audits";
        case CourseCategoryFilterValue.Webinars:
            return "Webinars";
        case CourseCategoryFilterValue.MeetingSeries:
            return "Meeting Series";
        case CourseCategoryFilterValue.Meetings:
            return "Meetings";
        case CourseCategoryFilterValue.Conferences:
            return "Conferences";
        case CourseCategoryFilterValue.Workshops:
            return "Workshops";
        case CourseCategoryFilterValue.RecordedWebinars:
            return "Recorded Webinars";
        default:
            return "";
    }
};

type Props = {
    category: CourseCategoryFilterValue
} & React.HTMLAttributes<HTMLDivElement>

export const CourseCategoryBadge = ({ className, category, ...props }: Props) => {
    return <Badge className={classNames(getClassName(category), className)} {...props}>
        {getLabel(category)}
    </Badge>
}
import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { EventLinkTree } from "models/EventLinkTree";

class EventLinkTreeServiceClass extends ServiceBase {
    private static _eventLinkTreeService: EventLinkTreeServiceClass;

    private constructor(controllerName: string) {
        super(controllerName);
    }

    public static get Instance(): EventLinkTreeServiceClass {
        return (
            this._eventLinkTreeService ||
            (this._eventLinkTreeService = new this("eventlinktree"))
        );
    }

    public async get(id: number): Promise<Result<EventLinkTree>> {
        const result = await super.requestJson<EventLinkTree>({
            method: "GET",
            url: `${id}`,
        });

        return result;
    }
}

export const EventLinkTreeService = EventLinkTreeServiceClass.Instance;

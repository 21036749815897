import styled from "styled-components";

export const Title = styled.h3`
    padding: 0;

    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: var(--black);
`;

export const TitleLabel = styled.label`
    padding: 0;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--darkGrey);
`;

export const SmallLabels = styled.div`
    position: relative;
    top: 144px;
    font-size: 0.903vw;
    line-height: 1.23;
    text-align: center;
    color: #4d4d4d;
`;

export const ResendContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 40px;
    width: 100%;
    position: relative;
`;

export const ResendButtonContainer = styled.span`
    display: flex;
    justify-content: center;
    height: 40px;
    position: relative;
    color: var(--primaryButtonsLinksColor);
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    cursor: pointer;
`;

interface ResendLabelProps {
    color?: string;
}

export const ResendLabel = styled.div<ResendLabelProps>`
    height: 16px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: ${(props) => (props.color ? props.color : "#4d4d4d")};
`;

export const Card = styled.div`
    background-color: var(--white);
    padding: 1.458vw 2.049vw 2.083vw;
    width: 26.7vw;
`;

interface ButtonProps {
    type: string;
}

const ButtonBackgroundColor = (type: string) => {
    switch (type) {
        case "var(--white)":
            return "var(--white)";
        case "blue":
            return "#1b6893";
    }
};

export const ErrorLabel = styled.div`
    height: 15px;
    margin-top: 4.5px;
    margin-bottom: 25.5px;

    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ef4623;
`;

const ButtonBorder = (type: string) => {
    switch (type) {
        case "var(--white)":
            return "solid 1px; var(--lightGrey)";
        case "blue":
            return "none";
    }
};

export const MediumButton = styled.button<ButtonProps>`
  width: 12.153vw;
  height: 2.222vw
  margin: 2.083vw 0.694vw 0 0;
  padding: 0.486vw 0.972vw 0.625vw;
  border: ${(props) => ButtonBorder(props.type)}
  background-color: ${(props) => ButtonBackgroundColor(props.type)};
`;

interface ModalProps {
    show: boolean;
}

export const Modal = styled.div<ModalProps>`
    z-index: 999;
    display: ${(props) => (props.show ? "flex" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
`;

export const ModalTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
`;

export const ModalLabel = styled.div`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #4d4d4d;
    margin-top: 7px;
`;

export const ModalCard = styled.div`
    width: 400px;
    height: 153px;
    background-color: var(--white);
    padding: 20px;
`;

import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const EventRacpgSection = styled.section`
  background: var(--base-white);
  padding: 96px 0px 96px 0px;
  @media ${device.xs} {
    padding: 48px 16px 48px 16px;
  }
`;

export const EventRacpgSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 96px;
  @media ${device.xs} {
    gap: 48px;
    flex-direction: column;
  }
`;

export const EventRacpgImageContentContainer = styled.div`
  flex-shrink: 0;
  @media ${device.xs} {
  }
`;

export const EventRacpgImageWrapper = styled.img`
  width: 500px;
  flex-shrink: 0;
  @media ${device.xs} {
    height: 228.899px;
    width: 100%;
  }
`;

export const EventRacpgContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  @media ${device.xs} {
    gap: 16px;
  }
`;

export const EventRacpgTitleContainer = styled.h1`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #101828;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  @media ${device.xs} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const EventRacpgDescriptionContainer = styled.p`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1 0 0;

  color: var(--gray-600);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const EventRacpgTitleOrangeContainer = styled.span`
  color: var(--primary-orange);
  display: inline;
`;

export const CpdTable = styled.div`
  background-color: #b2f1ea;
  padding: 6px;
`;

export const CpdNoteText = styled.p`
  padding-top: 1em;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--gray-400);
`;

export const CpdInnerTableContainer = styled.div`
  background-color: var(--white);
  padding: 4px;
`;

export const CpdInnerTable = styled.div`
  background-color: #b2f1ea;
  padding: 6px;
`;

export const RacgpCpdApprovedActivityLogo = styled.img`
  width: 470px;
  flex-shrink: 0;
  margin: 6px;
  @media ${device.xs} {
    width: 100%;
  }
`;

export const CpdItem = styled.div`
  margin: 0.75em;
  margin-bottom: 1em;
`;

export const CpdTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  text-align: center;
`;

export const CpdValue = styled.div`
  font-size: 30px;
  font-weight: 900;
  line-height: 26px;
  text-align: center;
  margin: 13px;
  margin-bottom: 16px;
`;

export const CpdHoursLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

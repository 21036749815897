import "./styles/ExistingUserModal.scss";
import React from "react";
import { Button, Col, Modal, ModalHeader, Row } from "reactstrap";

interface Props {
    onClose: () => void;
    onSingin: () => void;
}

export const ExistingUserModal: React.FC<Props> = (props: Props) => {
    return (
        <Modal className="existing-user-modal" isOpen={true} centered size="md">
            <div className="modal-header-icon-container">
                <img
                    src={
                        require("../../images/modal-circles.svg")
                            .default
                    }
                />
                <img
                    className="modal-header-icon"
                    src={
                        require("../../images/modal-user-red-square.svg")
                            .default
                    }
                />
            </div>

            <ModalHeader toggle={props.onClose}>
            </ModalHeader>

            <div className="modal-body p-0">
                <Row className="m-0">
                    <Col className="p-4 pb-0">
                        <h5>You already have an account</h5>
                        <p className="description">
                            The email and mobile number is already associated with an account. Please tap below to log in instead.
                        </p>
                    </Col>
                </Row>
                <Row className="m-0">
                    <Col className="p-3">
                        <Row className="m-0 button-container">
                            <Col className="ps-0">
                                <Button className="w-full" color="secondary" onClick={props.onClose}>Cancel</Button>
                            </Col>
                            <Col className="pe-0">
                                <Button className="w-full" color="primary" onClick={props.onSingin}>Log in</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div >
        </Modal >
    );
};

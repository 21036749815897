import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const CourseCarouselSection = styled.section`
    @media ${device.xs} {
        padding-left: 16px;
    }
`;

export const CourseCarouselWrapper = styled.div`
    padding-bottom: 150px;
    @media ${device.xs} {
        padding-bottom: 48px;
    }
`;

export const CourseCarouselContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
    padding-top: 32px;
    overflow: visible;

    @media ${device.xs} {
    }
`;

export const CourseCardWrapper = styled.div`
    width: 280px;
`;

export const CourseCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid var(--gray-200, #eaecf0);
    background: var(--base-white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
`;

export const CourseCardImageContainer = styled.div``;

export const CourseCardImage = styled.img`
    width: 100%;
    object-fit: cover;
`;

export const CourseCardContent = styled.div`
    display: flex;
    padding: 16px 16px 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
`;

export const CourseCardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
`;

export const CourseCardBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    height: 135px;
`;

export const CourseCardTitle = styled.div`
    color: var(--gray-900, #101828);

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
`;

export const CourseCardDescription = styled.div`
    height: 40px;
    align-self: stretch;
    overflow: hidden;
    color: var(--gray-600, #475467);
    text-overflow: ellipsis;
    whitespace: nowrap;
    /* Text sm/Regular */

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

export const BadgeContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const BadgeWrapper = styled.div<CourseCategoryBadgeProps>`
    display: flex;
    padding: 2px 10px;
    align-items: center;
    border-radius: 16px;
    border: 0px solid var(--pink-200, #fcceee);
    background-color: ${(props) => props.backgroundColor || "var(--white)"};
    mix-blend-mode: multiply;
`;

export interface CourseCategoryBadgeProps {
    color?: string;
    backgroundColor?: string;
}

export const BadgeText = styled.span<CourseCategoryBadgeProps>`
    color: ${(props) => props.color || "var(--black)"};
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
`;

export const CpdContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const CpdIconContainer = styled.img``;

export const CpdHours = styled.span`
    color: var(--gray-900, #101828);
    /* Text xs/Semibold */

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
`;

export const CpdText = styled.span`
    color: var(--gray-500, #667085);
    /* Text xs/Semibold */

    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
`;

export const SwiperButtonsContainer = styled.div`
    display: none;
    align-items: flex-start;
    padding-top: 32px;
    gap: 16px;

    @media ${device.md} {
        display: flex;
        padding-left: 16px;
    }
`;

export const SwiperButtonContainer = styled.button`
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 28px;
    border: 1px solid var(--gray-200, #eaecf0);
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
`;

export const SwiperIconContainer = styled.img`
    width: 20px;
    height: 20px;
`;

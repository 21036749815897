import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "reactstrap";

import { PersonalDetails } from "models/auth/PersonalDetails";
import { Select, TextInput } from "components/form";
import { composeValidators, email, mobile, required } from "validate";

import "./styles/PersonalDetails.scss";
import Button from "components/shared/Button";

type Props = {
    personalDetails?: PersonalDetails;
    onContinue?: (personalDetails: PersonalDetails) => Promise<void>;
    showContinueButton?: boolean;
    isLoading?: boolean;
    hideTitle?: boolean;
    canEdit: boolean;
    allowedProfessionTypes: string[];
};

const genders = [
    { id: "Female", text: "Female" },
    { id: "Male", text: "Male" },
    { id: "NonBinary", text: "Non binary" },
    { id: "PreferNotTosay", text: "Prefer not to say" },
];

export type PersonalDetailsFormRef = {
    submit(): Promise<void>;
    reset(): void;
}

export const PersonalDetailsForm = forwardRef(({
    personalDetails,
    canEdit,
    allowedProfessionTypes,
    onContinue,
    hideTitle,
    isLoading,
    showContinueButton,
}: Props, ref: React.Ref<PersonalDetailsFormRef>) => {

    const {
        register,
        handleSubmit: innerSubmit,
        formState: { errors },
        reset,
    } = useForm<PersonalDetails>({ defaultValues: personalDetails });

    useImperativeHandle(ref, () => {
        return {
            submit: onContinue && innerSubmit(onContinue),
            reset
        }
    }, [innerSubmit, onContinue, reset]);

    useEffect(() => {
        reset(personalDetails);
    }, [personalDetails, reset]);

    return (
        <React.Fragment>
            {!hideTitle && (
                <div className="text-center">
                    <h2>
                        Personal details<br />
                        <small className="text-muted fs-6">All fields are required unless marked optional</small>
                    </h2>
                </div>
            )}

            {canEdit && (
                <form
                    className="personal-details-form mt-3"
                    onSubmit={onContinue && innerSubmit(onContinue)}
                >
                    <Row>
                        <Col>
                            <TextInput
                                label="First name"
                                id="firstName"
                                placeholder="Enter your first name"
                                {...register("firstName", { validate: required() })}
                                fieldError={errors.firstName}
                            />
                        </Col>
                        <Col>
                            <TextInput
                                label="Last name"
                                id="lastname"
                                placeholder="Enter your last name"
                                {...register("lastName", { validate: required() })}
                                fieldError={errors.lastName}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <TextInput
                                type="email"
                                label="Email"
                                id="email"
                                placeholder="Enter your email"
                                {...register("email", { validate: composeValidators(required(), email()) })}
                                fieldError={errors.email}
                            />
                        </Col>
                        <Col>
                            <TextInput
                                type="tel"
                                label="Contact number"
                                id="phoneNumber"
                                placeholder="Enter your contact number"
                                {...register("phoneNumber", { validate: composeValidators(required(), mobile()) })}
                                fieldError={errors.phoneNumber}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Select
                                label="Gender"
                                id="gender"
                                placeholder="Select a gender"
                                {...register("gender", { validate: required(), value: "" })}
                                fieldError={errors.gender}
                            >
                                {genders.map((g) => (
                                    <option key={g.id} value={g.id}>
                                        {g.text}
                                    </option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <Select
                                label="Profession type"
                                id="professionType"
                                placeholder="Select a profession type"
                                {...register("professionType", { validate: required(), value: "" })}
                                fieldError={errors.professionType}
                            >
                                {allowedProfessionTypes.map((p) => (
                                    <option key={p} value={p}>{p}</option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    {showContinueButton && (
                        <div className="mt-4">
                            <Button
                                className="w-full"
                                type="submit"
                                disabled={isLoading}
                            >
                                Continue
                            </Button>
                        </div>
                    )}
                </form>
            )}

            {!canEdit && (
                <>
                    <Row>
                        <Col>
                            <dt>First name</dt>
                            <dd>{personalDetails.firstName || "\u00A0"}</dd>
                        </Col>
                        <Col>
                            <dt>Last name</dt>
                            <dd>{personalDetails.lastName || "\u00A0"}</dd>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <dt>Email</dt>
                            <dd>{personalDetails.email || "\u00A0"}</dd>
                        </Col>
                        <Col>
                            <dt>Contact number</dt>
                            <dd>{personalDetails.phoneNumber || "\u00A0"}</dd>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <dt>Gender</dt>
                            <dd>{personalDetails.gender || "\u00A0"}</dd>
                        </Col>
                        <Col>
                            <dt>Profession type</dt>
                            <dd>{personalDetails.professionType || "\u00A0"}</dd>
                        </Col>
                    </Row>
                </>
            )}
        </React.Fragment>
    );
});

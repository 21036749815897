import React from 'react'
import { Link } from 'react-router-dom'

import iconRacgp from '../../images/icon-racgp-cpd-provider.svg'
import welcomeMockup from '../../images/landing-page/phone_web_mockup.png'
import {
  WelcomeContainer,
  WelcomeImage,
  WelcomeImageContainer,
  WelcomeLogoContainer,
  WelcomeSubtitleText,
  WelcomeTextContainer,
  WelcomeTitleContainer,
  WelcomeTitleText,
} from './styles/LandingPage'
import Button from '../shared/Button'

const Welcome = () => {
  return (
    <div className='container'>
        <WelcomeContainer>
          <WelcomeTextContainer>
            <WelcomeLogoContainer src={iconRacgp} />
            <WelcomeTitleContainer>
              <WelcomeTitleText>Welcome to Myhealth Academy</WelcomeTitleText>
              <WelcomeSubtitleText>
                Supporting the professional development of GPs and those who
                work in primary care
              </WelcomeSubtitleText>
            </WelcomeTitleContainer>
            <Button variant="primary" to="/auth/signin">
              Get started
            </Button>
          </WelcomeTextContainer>
          <WelcomeImageContainer>
            <WelcomeImage src={welcomeMockup} />
          </WelcomeImageContainer>
        </WelcomeContainer>
    </div>
  )
}

export default Welcome

import { IApplicationState } from "../../store";
import { AnyAction, Store } from "redux";
import Axios, { AxiosResponse } from "axios";
import { Dictionary } from "../Dictionary";
import { ApiCall } from "../../models/enums/ApiCall";
import { KeyValuePair } from "../../models/KeyValuePair";
import * as loadingStore from "../../store/loadingStore";

export const API_CALL_IDENTIFIER_PROPERTY = "apiCall";
const API_CALL_GUID_PROPERTY = "apiCallGuid";

export const configureInterceptors = (
  store: Store<IApplicationState, AnyAction>
) => {
  Axios.interceptors.request.use((request) => {
    const identifier = request.params[API_CALL_IDENTIFIER_PROPERTY] as ApiCall;
    const guid = uuidv4();
    loadingStore.actionCreators.addLoading(guid, identifier)(
      store.dispatch,
      store.getState
    );
    request.params[API_CALL_GUID_PROPERTY] = guid;
    return request;
  });

  Axios.interceptors.response.use(
    (response) => {
      const guid = response.config.params[API_CALL_GUID_PROPERTY] as string;
      loadingStore.actionCreators.removeLoading(guid)(
        store.dispatch,
        store.getState
      );
      return response;
    },
    (error) => {
      console.log(error);
      const guid = error.config.params[API_CALL_GUID_PROPERTY] as string;
      loadingStore.actionCreators.removeLoading(guid)(
        store.dispatch,
        store.getState
      );
      return Promise.reject(error);
    }
  );
};

const uuidv4 = () => {
  return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

import "./styles/SignupProgress.scss";
import React from "react";
import { Col, Row } from "reactstrap";
import { Container } from "components/reactstrap";
import { SignupProgressItem } from "./SignupProgressItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ProfessionalDetails from "./ProfessionalDetails";
import * as dashboard from "../../store/dashboardStore";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import SessionManager from "../../core/session";
import { Redirect } from "react-router";

interface IProps { }

type Props = IProps &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const FinalizeSignup: React.FC<Props> = (props: Props) => {
    const {
        currentEmployee,
        fetchEmployee,
        fetchEmployeeRoles,
        updateCurrentEmployee,
    } = props;

    const isLoading = false;

    React.useEffect(() => {
        const user = SessionManager.getUser();
        if (currentEmployee === null && user && user.userName && user.email) {
            fetchEmployee(user.email);
        }
    }, [currentEmployee, fetchEmployee]);

    React.useEffect(() => {
        const user = SessionManager.getUser();
        if (currentEmployee != null && user && user.userName && user.email) {
            fetchEmployeeRoles();
        }
    }, [currentEmployee, fetchEmployeeRoles]);

    const handleProfessionalDetailsSave = async (professionalDetails) => {
        await updateCurrentEmployee({
            ...currentEmployee,
            ...professionalDetails,
        });
    };

    if (!currentEmployee) {
        return <></>;
    }

    if (currentEmployee?.ahpraNumber) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <div className="leftHalf">
                <div className="academy-logo"></div>

                <div className="progress-container">
                    <Row>
                        <Col>
                            <SignupProgressItem
                                title="Professional details"
                                subTitle="Please provide your RACGP and AHPRA Number"
                                isActive={true}
                                isReady={false}
                            ></SignupProgressItem>
                        </Col>
                    </Row>
                </div>

                <span className="copyright">© Myhealth 2024</span>
                <div className="email">
                    <FontAwesomeIcon icon={faEnvelope} /> &nbsp;
                    <a href="mailto:CPD@myhealth.net.au">CPD@myhealth.net.au</a>
                </div>
            </div>

            <div className="rightHalf">
                <Container className="flex vh-100 sign-up-container">
                    <Row className="justify-content-center h-100">
                        <Col
                            xs={12}
                            md={6}
                            className="align-self-center justify-self-center"
                            style={{ maxWidth: "375px" }}
                        >
                            <Row>
                                <Col>
                                    <div>
                                        <div>
                                            <ProfessionalDetails
                                                isLoading={isLoading}
                                                onNext={
                                                    handleProfessionalDetailsSave
                                                }
                                                canEdit
                                                showButtons
                                                isDeletedUser={false}
                                                userEmail={
                                                    props?.currentEmployee
                                                        ?.email
                                                }
                                                professionalDetails={
                                                    props.currentEmployee
                                                }
                                                professionType={
                                                    props.currentEmployee
                                                        ?.professionType
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(FinalizeSignup as any);

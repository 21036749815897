import React, { useEffect } from 'react'
import Footer from '../../components/shared/Footer'
import { HeaderDesktopHr, HeaderMobileHr } from './styles/LandingPage'
import { RouteComponentProps } from 'react-router'
import * as dashboard from '../../store/dashboardStore'
import { connect } from 'react-redux'
import { IApplicationState } from '../../store'
import EventHeader from '../../components/events/EventHeader'
import EventRacgp from '../../components/events/EventRacgp'
import EventHighlights from '../../components/events/EventHighlights'
import EventDetails from '../../components/events/EventDetails'
import EventLocation from '../../components/events/EventLocation'
import EventPricing from '../../components/events/EventPricing'
import EventFaq from '../../components/events/EventFaq'
import EventRegister from '../../components/events/EventRegister'

interface OwnRouteProps {
  id: string
}

type Props = RouteComponentProps<OwnRouteProps> &
  dashboard.DashboardState & {} & typeof dashboard.actionCreators
const UpcomingEvent: React.FC<Props> = (props: Props) => {
  const courseEventId = parseInt(props.match.params.id)
  const { fetchCourseEvent, selectedCourseEvent } = props

  useEffect(() => {
    fetchCourseEvent(courseEventId)
  }, [courseEventId, fetchCourseEvent])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [])

  if (!selectedCourseEvent) {
    return null
  }

  return (
    <React.Fragment>
      <div className="event-page-container flex flex-col">
        <EventHeader courseEvent={selectedCourseEvent!} />
        <EventRacgp courseEvent={selectedCourseEvent!} />
        <EventHighlights courseEvent={selectedCourseEvent!} />
        <EventDetails courseEvent={selectedCourseEvent!} />
        <EventLocation courseEvent={selectedCourseEvent!} />
        <EventPricing courseEvent={selectedCourseEvent!} />
        <EventFaq courseEvent={selectedCourseEvent!} />
        <EventRegister courseEvent={selectedCourseEvent!} />
      </div>
    </React.Fragment>
  )
}

export default connect(
  (state: IApplicationState) => ({
    ...state.dashboard,
  }),
  { ...dashboard.actionCreators },
)(UpcomingEvent as any)

import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import { Employee } from "models/Employee";
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";

import { ContextMenu } from "../shared/ContextMenu";

export interface UserCardProps {
    employee: Employee;
    onEditUser: () => void;
}

type Props = RouteComponentProps<{}> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators &
    UserCardProps;

export const UserCard: React.FC<Props> = (props: Props) => {
    const {
        setShowMarkEmployeeModal,
        setShowRevokeInviteModal,
        setShowDeleteEmployeeModal,
        employee,
    } = props;

    return (
        <div className="container mt-4">
            <div
                className="flex flex-row align-items-center"
            >
                <h4 className="m-0 me-2">
                    {`${employee?.firstName} ${employee?.lastName}`}
                </h4>
                {employee &&
                    <ContextMenu
                        menuitems={[
                            {
                                id: 0,
                                label: "Edit user",
                                context: employee,
                                onAction: (c) => {
                                    props.onEditUser();
                                },
                            },
                            {
                                id: 1,
                                label: `${employee.isInvitePending
                                    ? "Revoke Invite"
                                    : employee.isActive
                                        ? "Mark user as Inactive"
                                        : "Mark user as Active"
                                    } `,
                                labelColor: "#CB2424",
                                context: employee,
                                onAction: (c) => {
                                    employee.isInvitePending
                                        ? setShowRevokeInviteModal(true)
                                        : setShowMarkEmployeeModal(true);
                                },
                            },
                            {
                                id: 2,
                                label: "Remove user",
                                labelColor: "#CB2424",
                                context: employee,
                                onAction: (c) => {
                                    setShowDeleteEmployeeModal(true);
                                },
                            },
                        ]}
                        icon={require("images/icon-more.svg").default}
                    />
                }
            </div>
            <h6 className="mt-2">
                {employee?.professionType && employee.professionType}
                {!employee?.professionType && <span>Not set</span>}
            </h6>
        </div>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(UserCard as any);

export enum RoleType {
    SUPER_ADMIN = "Super User",
    ADMIN = "Admin",
    USER = "User",
}

export enum RoleFilterValue {
    SUPER_ADMIN = "SuperUser",
    ADMIN = "Admin",
    USER = "User",
}

export enum ClinicAccessType {
    ALL_CLINICS = "All clinics",
    SELECT_CLINICS = "Selected clinics",
}

export interface UserRoles {
    role: RoleType;
    clinicAccess: ClinicAccessType;
}

export const roleString = (type: RoleType) => {
    if (type === RoleType.SUPER_ADMIN) {
        return "Super Admin";
    } else return type as string;
};

export const isAdmin = (type: RoleType) => {
    return type === RoleType.SUPER_ADMIN || type === RoleType.ADMIN;
};

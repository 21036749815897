import React from 'react'
import { CourseEvent } from '../../models/CourseEvent'
import {
  EventFaqAnswerTextContainer,
  EventFaqContentContainer,
  EventFaqHeadingContentContainer,
  EventFaqHeadingDescriptionText,
  EventFaqItemContainer,
  EventFaqItemContentContainer,
  EventFaqItemQuestionAndAnswerContainer,
  EventFaqQuestionTextContainer,
  EventFaqSection,
  EventFaqSectionContainer,
  EventFaqHeadingDescriptionLink,
  AccordionHeaderContainer,
  AccordionTextContainer,
  AccordionIconContainer,
} from './styles/EventFaq'
import { SectionTitleContainer } from './styles/EventHighlights'
import { ReactComponent as MinusCircle } from '../../images/minus-circle.svg'
import { ReactComponent as PlusCircle } from '../../images/plus-circle.svg'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

interface EventFrequentlyAskedQuestionsProps {
  courseEvent: CourseEvent
}

const EventFaq = (props: EventFrequentlyAskedQuestionsProps) => {
  const { courseEvent } = props

  const renderFaqItem = (
    question: string,
    answer: string,
    data: Record<string, string | number>
  ) => {

    const transformedQuestion = question.replaceAll(/{{([^}}]+)}}/g, (_, capture) => `${data[capture]}`);
    const transformedAnswer = answer.replaceAll(/{{([^}}]+)}}/g, (_, capture) => `${data[capture]}`);

    return (
      <EventFaqItemContainer>
        <EventFaqItemContentContainer>
          <EventFaqItemQuestionAndAnswerContainer>
            <AccordionItem style={{ width: '100%' }}>
              <EventFaqQuestionTextContainer>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <AccordionHeaderContainer>
                      <AccordionTextContainer>
                        {transformedQuestion}{' '}
                      </AccordionTextContainer>
                      <AccordionIconContainer>
                        <AccordionItemState>
                          {({ expanded }) =>
                            expanded ? (
                              <MinusCircle style={{ width: 24, height: 24 }} />
                            ) : (
                              <PlusCircle style={{ width: 24, height: 24 }} />
                            )
                          }
                        </AccordionItemState>
                      </AccordionIconContainer>
                    </AccordionHeaderContainer>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </EventFaqQuestionTextContainer>
              <AccordionItemPanel>
                <EventFaqAnswerTextContainer>
                  {transformedAnswer}
                </EventFaqAnswerTextContainer>
              </AccordionItemPanel>
            </AccordionItem>
          </EventFaqItemQuestionAndAnswerContainer>
        </EventFaqItemContentContainer>
      </EventFaqItemContainer>
    )
  }

  const hours =
    courseEvent.course.educationalActivitiesCpd +
    courseEvent.course.measuringOutcomesCpd +
    courseEvent.course.reviewingPerformanceCpd

  return (
    <EventFaqSection>
      <div className="container">
        <EventFaqSectionContainer>
          <EventFaqHeadingContentContainer>
            <SectionTitleContainer>FAQs</SectionTitleContainer>
            <EventFaqHeadingDescriptionText>
              If you can’t find the answers you’re looking for, contact us at{' '}
              <EventFaqHeadingDescriptionLink href="mailto:CPD@myhealth.net.au.">
                CPD@myhealth.net.au.
              </EventFaqHeadingDescriptionLink>
            </EventFaqHeadingDescriptionText>
          </EventFaqHeadingContentContainer>
          <EventFaqContentContainer>
            <Accordion allowZeroExpanded allowMultipleExpanded style={{ width: '100%' }}>
              <EventFaqContentContainer>
                {courseEvent.courseEventFaqs.map(({ id, question, answer }) =>
                  <React.Fragment key={id}>
                    {renderFaqItem(question, answer, {
                      total_hours: hours,
                      educational_activities_hours: courseEvent.course.educationalActivitiesCpd,
                      measuring_outcomes_hours: courseEvent.course.measuringOutcomesCpd,
                      reviewing_performance_hours: courseEvent.course.reviewingPerformanceCpd,
                    })}
                  </React.Fragment>)}
              </EventFaqContentContainer>
            </Accordion>
          </EventFaqContentContainer>
        </EventFaqSectionContainer>
      </div>
    </EventFaqSection>
  )
}

export default EventFaq

import React from 'react'
import { Link } from 'react-router-dom'
import CourseCarousel from './CourseCarousel'
import {
  RegisterSection,
  RegisterSectionContainer,
  RegisterSectionTitleContainer,
  RegisterSectionTitleContainerContent,
  RegisterSectionTitleText,
  RegisterSectionTitleTextHighlighted,
} from './styles/Register'
import Button from '../shared/Button'
import { Course } from '../../models/Course'

export interface RegisterProps {
  courses: Course[]
}

const Register = (props: RegisterProps) => {
  return (
    <>
      <RegisterSection>
        <div className="container">
          <RegisterSectionContainer>
            <RegisterSectionTitleContainer>
              <RegisterSectionTitleContainerContent>
                <RegisterSectionTitleText>
                  Register now to get access to our library of CPD courses,{' '}
                  <br />
                  activities and training.{' '}
                  <RegisterSectionTitleTextHighlighted>
                    Supporting you to achieve your 'work joy balance'.
                  </RegisterSectionTitleTextHighlighted>
                </RegisterSectionTitleText>
              </RegisterSectionTitleContainerContent>
            </RegisterSectionTitleContainer>
            <Button to="/auth/signin">Get started</Button>
          </RegisterSectionContainer>
        </div>
        <CourseCarousel courses={props.courses} />
      </RegisterSection>
    </>
  )
}

export default Register

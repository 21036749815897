import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import urlcat from "urlcat";
import { Col, Row, Spinner } from "reactstrap";
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";

import SessionManager from "core/session";
import Button from "components/shared/Button";
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";
import { useScormApi } from "scorm/hooks/useScormApi";

import { UserTab } from ".";

type Props = dashboard.DashboardState;

const ScormCourse: React.FC<Props> = (props: Props) => {
    const { courseDetails, currentEmployee } = props;

    const { pathname } = useLocation();
    const [courseCompletedToast, setToast] = useState<React.ReactText>();

    const onCourseCompleted = useCallback(() => {
        setToast(
            toast(({ closeToast }) => <>
                <p>In order for this course to be recognised, you will need to complete the  <strong>evaluation form</strong>.</p>
                <Row>
                    <Col xs="auto" className="mt-2">
                        <Button variant="secondary" onClick={closeToast} to={urlcat(
                            pathname,
                            {
                                "activeTab": UserTab.ReviewResponses
                            })}>Review Responses</Button>
                    </Col>
                    <Col xs="auto" className="mt-2">
                        <Button onClick={closeToast} to={urlcat(
                            pathname,
                            {
                                "activeTab": UserTab.Evaluation
                            })}>Evaluation form</Button>
                    </Col>
                </Row>
            </>, {
                autoClose: false,
                type: "info",
                closeOnClick: false
            })
        );
    }, [pathname]);

    // dismiss toast when navigating away
    useEffect(() => {
        return () => {
            if (courseCompletedToast) {
                toast.dismiss(courseCompletedToast);
                setToast(undefined);
            }
        }
    }, [courseCompletedToast]);

    const { isLoaded: isScormApiMounted } = useScormApi({ courseDetails, currentEmployee, onCourseCompleted });


    const iframeUrl = React.useMemo(() => {
        if (!courseDetails || !currentEmployee) {
            return;
        }
        const baseUrl = `${courseDetails.baseUrl}/index.html`;

        let iframeUrl = urlcat(
            `${baseUrl}`,
            {
                "evaluation-form-href": `${courseDetails.baseUrlForEvaluationForm
                    }/evaluation-form?employee=${currentEmployee.email.toLowerCase()}&learningmaterial=${courseDetails.id}`,
                "learning-material-id": courseDetails.id,
                "api-url": `${courseDetails.baseUrlForEvaluationForm}/api/v2/courses/`,
                token: SessionManager.getToken() || "",
            });
        return iframeUrl;
    }, [courseDetails, currentEmployee]);

    return iframeUrl && isScormApiMounted ?
        <iframe
            src={iframeUrl}
            title={courseDetails.title}
            style={{ height: "100%" }}
        />
        : <div className="m-auto"><Spinner color="primary" /></div>;
}

export default compose(
    connect(
        (state: IApplicationState) => ({
            ...state.dashboard,
        })
    )
)(ScormCourse);
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import SessionManager from "../../core/session";

const HangfireCookieName = "HangFireCookie";

export const HangfireContainer: React.FC = () => {
    const [cookies, setCookie] = useCookies([]);
    const [cookieSet, setCookieSet] = useState(false);

    useEffect(() => {
        setCookie(HangfireCookieName, SessionManager.getToken() || "", {
            path: "/",
        });
        setCookieSet(true);
    }, []);

    return cookieSet ? (
        <iframe
            src="/hangfire-internal"
            title="Hangfire"
            className="w-full h-100"
            frameBorder="0"
        />
    ) : (
        <></>
    );
};

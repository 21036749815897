import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import { Col, Modal, ModalHeader, Row } from "reactstrap";

import copyIcon from "images/icon-copy.svg";
import { CourseSessionLink } from "models/CourseSessionLink";
import { CourseService } from "services/CourseService";
import { IApplicationState } from "store";
import * as dashboard from "store/dashboardStore";

import { GenerateSessionLinkForm } from "./forms/GenerateSessionLinkForm";
import "./styles/GenerateLinkButtonAndModal.scss";
import Button from "components/shared/Button";
import classNames from "classnames";

interface Props {
    courseId: number;
    className?: string;
}

type PropsType = Props &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const GenerateLinkButtonAndModal: React.FC<PropsType> = (props: PropsType) => {

    const { courseId, courseSessions, practices, courseSessionLinks, fetchCourseSessions, fetchCourseSessionLinks } = props;
    const formRef = useRef<any>();

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [newRegistrationUrl, setNewRegistrationUrl] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const generateLink = useCallback(async ({
        date,
        sessionId,
        practiceId,
        state,
        sessionLinkName
    }: CourseSessionLink) => {
        const response = await CourseService.CreateSessionLink(courseId, {
            sessionId,
            practiceId,
            state,
            sessionLinkName,
            date: date.utc(),
        });

        if (response && !response.hasErrors) {
            setIsModalOpened(false);
            setNewRegistrationUrl(response.value);
            fetchCourseSessions(courseId);
            fetchCourseSessionLinks(courseId);
        } else {
            setErrorMessage(
                "An error has occurred generating the link. Please try again."
            );
        }
    }, [courseId, fetchCourseSessionLinks, fetchCourseSessions]);

    const onCancel = useCallback(() => {
        setIsModalOpened(false);
    }, [])

    return (
        <>
            {errorMessage && (
                <Modal
                    className="course-modal"
                    isOpen={!!errorMessage}
                    centered
                >
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>Error</h5>
                                <p className="description">{errorMessage}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-3">
                                <Button
                                    className="w-full"
                                    variant="primary"
                                    onClick={(_) => setErrorMessage(undefined)}
                                >
                                    OK
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}

            {newRegistrationUrl && (
                <Modal
                    className="course-modal"
                    isOpen={!!newRegistrationUrl}
                    centered
                >
                    <div className="modal-body p-0">
                        <Row className="m-0">
                            <Col className="p-4 pb-0">
                                <h5>All set. Now share the link!</h5>
                                <p className="description">
                                    Copy the link below and share it with the
                                    attendees after the session is completed.
                                </p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-4 pt-2 pb-0 position-relative">
                                <input
                                    className="form-control"
                                    type="text"
                                    value={newRegistrationUrl}
                                />
                                <span
                                    className="copy-reg-url"
                                    role="button"
                                    onClick={(_) =>
                                        navigator.clipboard.writeText(
                                            newRegistrationUrl
                                        )
                                    }
                                >
                                    <img alt="" src={copyIcon} />
                                    &nbsp;Copy
                                </span>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-4 pt-0 pb-2 text-gray-500">
                                The invite link expires in 24 hours.
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="p-3">
                                <Button
                                    className="w-full"
                                    variant="primary"
                                    onClick={(_) =>
                                        setNewRegistrationUrl(undefined)
                                    }
                                >
                                    DONE
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}

            {isModalOpened && (
                <Modal className="course-modal" isOpen={isModalOpened} centered size="md">
                    <div className="modal-header-icon-container">
                        <img
                            alt=""
                            src={
                                require("images/modal-circles.svg")
                                    .default
                            }
                        />
                        <img
                            alt=""
                            className="modal-header-icon"
                            src={
                                require("images/modal-link.svg")
                                    .default
                            }
                        />
                    </div>
                    <ModalHeader
                        toggle={() => {
                            setIsModalOpened(false);
                        }}
                    ></ModalHeader>

                    <div className="modal-body p-4">
                        <h5>Generate Session Attendance Link</h5>
                        <p className="description">
                            Generate an attendance link for an upcoming
                            session and track participation. Please note
                            that the link will expire 24 hours after the
                            session’s scheduled start time.
                        </p>
                        <GenerateSessionLinkForm
                            practices={practices}
                            courseSessions={courseSessions}
                            courseSessionLinks={courseSessionLinks}
                            generateLink={generateLink}
                            formRef={formRef}
                            onCancel={onCancel}
                        />
                    </div>
                </Modal>
            )}

            <Button className={classNames(props.className)} variant="primary" onClick={() => setIsModalOpened(true)}>
                <img
                    alt=""
                    className="mb-1"
                    src={require('images/icon-plus.svg').default}
                />&nbsp;Generate link
            </Button>
        </>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(GenerateLinkButtonAndModal as any) as React.FC<Props>;

import React from "react";
import {
    SmallLabels,
    HelpLabel,
    HelpLink,
} from "../../pages/auth/styles/Signin";

export const LinkToSigninText = () => {
    return (
        <SmallLabels>
            <HelpLabel>
                Already have an account?{" "}
                <HelpLink
                    color="var(--primaryButtonsLinksColor)"
                    to={"/auth/signin"}
                >
                    Log in
                </HelpLink>
            </HelpLabel>
        </SmallLabels>
    );
};

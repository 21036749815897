import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const AboutSection = styled.section`
    display: flex;
    padding: clamp(48px, 4vw, 96px) 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;

    @media ${device.xs} {
        padding: 48px 16px;
        gap: 48px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    padding: 0px 120px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    @media ${device.xl} {
        padding: 0px 110px;
    }

    @media ${device.lg} {
        padding: 0px 50px;
    }

    @media ${device.md} {
        padding: 0px 25px;
    }

    @media ${device.sm} {
        padding: 0px 25px;
    }

    @media ${device.xs} {
        padding: 0px 16px;
    }
`;

export const TitleContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    align-self: stretch;
`;

export const TitleContainerContentHeadingAndSub = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
`;

export const TitleContainerContentHeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`;

export const TitleContainerContentHeadingText = styled.h1`
    color: var(--gray-900, #101828);
    text-align: center;

    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */

    max-width: clamp(500px, 50vw, 900px);

    @media ${device.xs} {
        max-width: 350px;
        font-size: 24px;
        line-height: 32px; /* 133.333% */
    }
`;

export const TitleContainerContentSubHeadingText = styled.p`
    color: var(--gray-600, #475467);
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    align-self: stretch;

    @media ${device.xs} {
        font-size: 18px;
        line-height: 28px; /* 155.556% */
    }
`;

export const AboutCardsContainer = styled.div`
    display: flex;
    padding: 0px 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    @media ${device.md} {
        flex-direction: column;
        padding: 0px 16px;
        gap: 40px;
    }

    @media ${device.sm} {
        flex-direction: column;
        padding: 0px 16px;
        gap: 40px;
    }

    @media ${device.xs} {
        flex-direction: column;
        padding: 0px 16px;
        gap: 40px;
    }
`;

export const AboutCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    @media ${device.xs} {
        gap: 16px;
    }
`;

export const CardImageContainer = styled.div`
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 8px solid var(--primary-50);
    background: var(--primary-100);
    @media ${device.xs} {
        width: 40px;
        height: 40px;
        padding: 10px;
        border: 6px solid var(--primary-50);
    }
`;

export const ImageContainer = styled.svg`
    font-size: 48px;
`;

export const CardTextContainer = styled.div``;

export const CardTitleContainer = styled.h1`
    color: var(--gray-900);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    @media ${device.xs} {
        font-size: 18px;
        line-height: 28px;
    }
`;

export const CardDescriptionContainer = styled.p`
    color: var(--gray-600);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    @media ${device.xs} {
        font-size: 16px;
        line-height: 24px;
    }
`;

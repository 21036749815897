import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { SigninResult } from "../models/auth/SigninResult";
import { ApiCall } from "../models/enums/ApiCall";
import { SignupUser } from "../models/auth/SignupUser";
import {UserStatus} from "../common/UserStatus";

class AuthServiceClass extends ServiceBase {
  private static _authService: AuthServiceClass;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): AuthServiceClass {
    return this._authService || (this._authService = new this("auth"));
  }

  public async Signin(
    email: string,
    lastName: string
  ): Promise<Result<SigninResult>> {
    const result = await super.requestJson<SigninResult>({
      method: "POST",
      url: "signin",
      data: {
        email,
        lastName,
      },
      callType: ApiCall.AuthSignin,
    });

    return result;
  }

  public async Signup(
    user: SignupUser
  ): Promise<Result<SigninResult>> {
    const result = await super.requestJson<SigninResult>({
      method: "POST",
      url: "signup",
      data: {
        ...user.personalDetails,
        ...user.professionalDetails,
        code: user.code
      },
      callType: ApiCall.AuthSignin,
    });

    return result;
  }

  public async ValidateIsNewUser(
    email: string,
    phoneNumber: string
  ): Promise<Result<UserStatus>> {
    const result = await super.requestJson<UserStatus>({
      method: "GET",
      url: "validateisnewuser",
      data: {
        email,
        phoneNumber
      },
      callType: ApiCall.AuthValidateIsNewUser,
    });
    return result;
  }

  public async VerifyMobile(
    email: string,
    lastName: string,
    code: string
  ): Promise<Result<SigninResult>> {
    const result = await super.requestJson<SigninResult>({
      method: "POST",
      url: "verifyphone",
      data: {
        email,
        lastName,
        code: code,
      },
      callType: ApiCall.AuthVerifyMobile,
    });
    return result;
  }

  public async VerifyMobileNumber(
    phoneNumber: string,
    code: string
  ): Promise<Result<SigninResult>> {
    const result = await super.requestJson<SigninResult>({
      method: "POST",
      url: "verifyphonenumber",
      data: {
        phoneNumber,
        code: code,
      },
      callType: ApiCall.AuthVerifyMobileNumber,
    });
    return result;
  }

  public async RequestCode(phone: string): Promise<Result<{}>> {
    const result = await super.requestJson<{}>({
      method: "POST",
      url: "requestcode",
      data: {
        phoneNumber: phone,
      },
      callType: ApiCall.AuthRequestCode,
    });

    return result;
  }

  public async ValidateCodeForMobileChange(
    phone: string,
    employeeId: number,
    code: string
  ): Promise<Result<SigninResult>> {
    const result = await super.requestJson<SigninResult>({
      method: "POST",
      url: "validatemobilechange",
      data: {
        phoneNumber: phone,
        employeeId: employeeId,
        code: code,
      },
      callType: ApiCall.AuthValidateCode,
    });

    return result;
  }

  public async ResendCode(
    email: string,
    lastName: string
  ): Promise<Result<SigninResult>> {
    const result = await super.requestJson<SigninResult>({
      method: "POST",
      url: "ResendCode",
      data: {
        email,
        lastName,
      },
      callType: ApiCall.AuthResendCode,
    });

    return result;
  }
}
export const AuthService = AuthServiceClass.Instance;

import classNames from "classnames";
import React, { useCallback, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import { Controller, ControllerProps } from 'react-hook-form';

import { InputDecorator, InputDecoratorProps } from "./InputDecorator";
import DocumentFileCell from "components/dashboard/DocumentFileCell";

export type FileDragAndDropProps<F> = {
    className?: string;
    accept?: string;
    multiple?: boolean;
}
    & InputDecoratorProps
    & Omit<ControllerProps<F>, "render">

export const FileInput = <FormValues,>(props: FileDragAndDropProps<FormValues>) => {
    const {
        className,
        accept,
        multiple,
        hint = "Click here to upload or drag and drop.",
        label,
        error,
        id,
        fieldError,
        name,
        control,
        rules
    } = props;
    const [onDragHover, setOnDragHover] = useState(false);

    const fileInputRef = useRef(null);
    const dragAreaRef = useRef(null);

    const onFileInputChange = useCallback((value, onChange) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        files.length > 0 && onChange(multiple ? [...(value || []), ...Array.from(files)] : files[0]);
    }, [multiple]);

    const handleFilesDrag = useCallback((value, onChange) => (files: FileList) => {
        files.length > 0 && onChange(multiple ? [...(value || []), ...Array.from(files)] : files[0]);
    }, [multiple]);

    const onTargetClick = () => {
        fileInputRef.current.click();
    };

    return (
        <InputDecorator
            id={id}
            className={className}
            error={error}
            label={label}
            fieldError={fieldError}
        >
            <Controller
                rules={rules}
                name={name}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                    <div ref={dragAreaRef}>
                        <input
                            onChange={onFileInputChange(value, onChange)}
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            accept={accept}
                            multiple={multiple}
                        />
                        <FileDrop
                            onTargetClick={onTargetClick}
                            onDragOver={() => setOnDragHover(true)}
                            onDragLeave={() => setOnDragHover(false)}
                            onDrop={handleFilesDrag(value, onChange)}
                        >
                            <div style={{ cursor: "pointer" }} className={classNames("py-4 px-2 border", { "bg-light": !onDragHover })}>
                                {hint}
                            </div>
                        </FileDrop>
                        {value &&
                            <div>
                                {[...(Array.isArray(value) ? (value as File[]) : [value])].map((file: File, i) => (
                                    <DocumentFileCell
                                        key={`${file.webkitRelativePath}${file.name}${file.size}`}
                                        file={file as File}
                                        onRemoveFile={(file) => {
                                            if (Array.isArray(value)) {
                                                (value as File[]).splice(i, 1);
                                                onChange(value);
                                            } else {
                                                onChange(undefined);
                                            }
                                        }}
                                    />
                                ))}
                            </div>}
                    </div>
                )}
            />
        </InputDecorator>
    );
};

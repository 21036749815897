import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { required } from 'validate';

import { AdvancedSelect, Select } from 'components/form';
import { FileInput } from 'components/form/FileInput';
import Button from 'components/shared/Button';
import { Employee } from 'models/Employee';
import { ODataPageResult } from 'common/ODataPageResult';
import { EmployeeService } from 'services/EmployeeService';
import { UsersPageListItem } from 'models/UsersPageListItem';

type Props = {
    handleSubmit(values: UploadSubmissionFormValues): unknown;
    allUsersPage: ODataPageResult<UsersPageListItem>;
    searchUsers(name: string): unknown;
    formRef?: MutableRefObject<HTMLFormElement>;
    initialEmployee?: Employee,
    onCancel?();
}

export type UploadSubmissionFormValues = {
    employeeId: number;
    userCourseId: number;
    file: File;
}

export const UploadSubmissionForm = ({
    formRef,
    searchUsers,
    handleSubmit,
    allUsersPage,
    onCancel,
    initialEmployee,
}: Props) => {

    const {
        handleSubmit: innerSubmit,
        formState: { errors, },
        control,
        register,
        watch,
        resetField
    } = useForm<UploadSubmissionFormValues>({ defaultValues: { employeeId: initialEmployee?.id } });

    const employeeId = watch("employeeId");
    const [userCourses, setUserCourses] = useState(initialEmployee?.courses);

    const changeEmployee = useCallback(async (id) => {
        const res = await EmployeeService.FetchEmployeeById(id);
        setUserCourses(res.value.courses);
    }, [])

    useEffect(() => {
        if (employeeId) {
            changeEmployee(employeeId);
            resetField("userCourseId", { defaultValue: null });
        }
    }, [changeEmployee, employeeId, resetField]);


    return <form
        ref={formRef}
        onSubmit={innerSubmit(handleSubmit)}
    >
        <div className="p-3">
            <Row>
                <Col>
                    <AdvancedSelect
                        menuPosition="fixed"
                        label="Doctor name"
                        placeholder="Select a doctor"
                        id="userId"
                        onInputChange={searchUsers}
                        isSearchable={true}
                        getOptionLabel={(p) => `${p.firstName} ${p.lastName}`}
                        getOptionValue={(p) => p?.employeeId}
                        options={allUsersPage.value}
                        control={control}
                        name="employeeId"
                        fieldError={errors.employeeId}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <Select
                        label="Course name"
                        id="userCourseId"
                        placeholder="Select a course"
                        disabled={!userCourses?.length}
                        {...register("userCourseId", { validate: required(), value: null })}
                        fieldError={errors.userCourseId}
                    >
                        {userCourses?.map(
                            (c) => (
                                <option
                                    key={c.id}
                                    value={c.id}
                                >
                                    {c.title}
                                </option>
                            )
                        )}
                    </Select>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FileInput
                        label="Upload submission form"
                        control={control}
                        name="file"
                        className="drag-and-drop"
                        rules={{ validate: required() }}
                        fieldError={errors.file}
                    />
                </Col>
            </Row>
        </div>

        <div className="p-3 d-grid gap-2 lg:flex justify-content-lg-end">
            {onCancel && <Button
                className="me-lg-2 order-2 order-lg-1"
                variant="secondary"
                onClick={onCancel}
            >
                Cancel
            </Button>}

            <Button
                className="order-1 order-lg-2"
                type="submit"
            >
                Upload submission
            </Button>
        </div>
    </form>
}
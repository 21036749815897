import React, { useCallback, useMemo } from 'react'
import Select, { Props } from 'react-select'

export type AdvancedSelectProps<TValue, TOption> = {
    onChange?(value: TValue): void;
    getOptionValue(option: TOption): TValue;
    value?: TValue;
    options: TOption[];
} & Omit<Props<TOption>, "onChange" | "getOptionValue" | "value" | "options">

export const AdvancedSelect = <TValue extends string = string, TOption = TValue>({ onChange, value, getOptionValue, ...props }: AdvancedSelectProps<TValue, TOption>) => {
    const innerOnChange = useCallback((o: TOption) => {
        onChange(getOptionValue(o));
        return o;
    }, [getOptionValue, onChange]);

    const innerValue = useMemo(() => {
        return props.options.find(o => getOptionValue(o) === value) || '';
    }, [props.options, getOptionValue, value]);

    return <Select value={innerValue} onChange={innerOnChange} getOptionValue={getOptionValue} {...props} />
}
import React, { useState } from "react";
import { Alert, Col, Container, FormGroup, Row } from "reactstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { TrackGoogleAnalyticsEvent } from "google-analytics";

import { UserStatus } from "common/UserStatus";
import { LinkToSigninText } from "components/shared/LinkToSigninText";
import { ProfessionType } from "models/enums/ProfessionType";
import { PersonalDetails } from "models/auth/PersonalDetails";
import { ProfessionalDetails as ProfessionalDetailsModel } from "models/auth/ProfessionalDetails";
import { SignupUser } from "models/auth/SignupUser";
import { IApplicationState } from "store";
import * as auth from "store/authStore";
import { AuthService } from "services/AuthService";
import academyLogoImage from "images/academy-logo.svg";

import { SignupProgress } from "./SignupProgress";
import { VerifyMobileForm } from "./VerifyMobileForm";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import ProfessionalDetails from "./ProfessionalDetails";
import { ExistingUserModal } from "./ExistingUserModal";
import { FormContainer, MobileLoginLogo } from "./styles/SignUp";
import "./styles/Signup.scss";

export enum SignupStatus {
    PersonalDetails,
    Verify,
    ProfessionalDetails,
    Failed,
    Successful,
    NavigateToSingin,
}

type Props = RouteComponentProps<{}> &
    auth.AuthState &
    typeof auth.actionCreators;

const Signup: React.FC<Props> = (props: Props) => {
    const [userEmail, setUserEmail] = useState<string>();
    const [signupStatus, setSignupStatus] = useState(
        SignupStatus.PersonalDetails
    );
    const [signupUser, setSignupUser] = useState<SignupUser>({});
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [showExistingUser, setShowExistingUser] = useState(false);
    const [isDeletedUser, setIsDeletedUser] = useState(false);
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const referrer = query.get('referrer');

    if (signupStatus === SignupStatus.Successful) {

        if (referrer === 'qrcode') {
            TrackGoogleAnalyticsEvent("Signup", "Form Submission", "QR Code");
        }

        return <Redirect to="/auth/signup/success" />;
    }

    if (signupStatus === SignupStatus.NavigateToSingin) {

        return <Redirect to="/auth/signin" />;
    }

    const handlePersonalDetailsChange = async (
        personalDetails: PersonalDetails
    ) => {
        var validNewUserResponse = await AuthService.ValidateIsNewUser(
            personalDetails.email,
            personalDetails.phoneNumber
        );

        if (validNewUserResponse.hasErrors) {
            setErrors(validNewUserResponse.errors);
            return;
        }
        setUserEmail(personalDetails.email);
        if (validNewUserResponse.value === UserStatus.Registered) {
            setShowExistingUser(true);
            return;
        } else if (validNewUserResponse.value === UserStatus.Deleted) {
            setIsDeletedUser(true);
        }

        setSignupUser({ personalDetails, ...signupUser });

        setLoading(true);
        const response = await AuthService.RequestCode(
            personalDetails.phoneNumber
        );
        setLoading(false);

        if (response.hasErrors) {
            setErrors(response.errors);
            return;
        }

        setSignupStatus(SignupStatus.Verify);
    };

    const handleVerifyMobileCode = (code: string) => {
        setSignupUser({ code, ...signupUser });
        setSignupStatus(SignupStatus.ProfessionalDetails);
    };

    const handleProfessionalDetailsChange = async (
        professionalDetails: ProfessionalDetailsModel
    ) => {
        const newSingupUser = { professionalDetails, ...signupUser };
        setSignupUser(newSingupUser);

        setLoading(true);
        const response = await AuthService.Signup(newSingupUser);
        setLoading(false);

        if (response.hasErrors) {
            setErrors(response.errors);
            return;
        }

        setSignupStatus(SignupStatus.Successful);
    };

    return (
        <React.Fragment>
            <div className="block md:hidden">
                <MobileLoginLogo src={academyLogoImage} />
            </div>
            {showExistingUser && (
                <ExistingUserModal
                    onClose={() => setShowExistingUser(false)}
                    onSingin={() =>
                        setSignupStatus(SignupStatus.NavigateToSingin)
                    }
                />
            )}

            <SignupProgress signupStatus={signupStatus}></SignupProgress>

            <div className="rightHalf signup sign-up-main-container">
                <Container className="flex vh-100 sign-up-container">
                    <Row className="flex flex-grow-1 justify-content-center align-items-center">
                        <Col
                            xs={12}
                            md={6}
                            className="align-self-center justify-self-center justify-content-center flex flex-col"
                            style={{ maxWidth: "375px" }}
                        >
                            <Row>
                                <Col>
                                    <div>
                                        <FormContainer>
                                            {signupStatus ===
                                                SignupStatus.Failed && (
                                                    <FormGroup>
                                                        <Alert
                                                            color="danger"
                                                            className="alert-outline mt-5"
                                                        >
                                                            <div className="alert-icon">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faExclamationTriangle
                                                                    }
                                                                    fixedWidth
                                                                />
                                                            </div>
                                                            <div className="alert-message">
                                                                <strong>
                                                                    Sign up failed.
                                                                </strong>{" "}
                                                                Please fix the
                                                                following error(s)
                                                                and try again:
                                                                <ul>
                                                                    {errors.map(
                                                                        (e, i) => (
                                                                            <li
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                {e}
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </Alert>
                                                    </FormGroup>
                                                )}

                                            {signupStatus ===
                                                SignupStatus.PersonalDetails && (
                                                    <PersonalDetailsForm
                                                        isLoading={isLoading}
                                                        onContinue={
                                                            handlePersonalDetailsChange
                                                        }
                                                        canEdit
                                                        showContinueButton
                                                        allowedProfessionTypes={[
                                                            ProfessionType.GeneralPractitioner,
                                                            ProfessionType.Registrar,
                                                        ]}
                                                    />
                                                )}

                                            {signupStatus ===
                                                SignupStatus.Verify && (
                                                    <VerifyMobileForm
                                                        phoneNumber={
                                                            signupUser
                                                                .personalDetails
                                                                .phoneNumber
                                                        }
                                                        onBack={() => {
                                                            setSignupStatus(
                                                                SignupStatus.PersonalDetails
                                                            );
                                                        }}
                                                        onNext={
                                                            handleVerifyMobileCode
                                                        }
                                                    />
                                                )}

                                            {signupStatus ===
                                                SignupStatus.ProfessionalDetails && (
                                                    <ProfessionalDetails
                                                        isLoading={isLoading}
                                                        onNext={
                                                            handleProfessionalDetailsChange
                                                        }
                                                        canEdit
                                                        showButtons
                                                        isDeletedUser={
                                                            isDeletedUser
                                                        }
                                                        userEmail={userEmail}
                                                        professionType={
                                                            signupUser
                                                                .personalDetails
                                                                ?.professionType ||
                                                            ""
                                                        }
                                                    />
                                                )}
                                        </FormContainer>
                                    </div>
                                </Col>
                            </Row>
                            {signupStatus === SignupStatus.PersonalDetails && (
                                <Row className="pt-4">
                                    <Col>
                                        <LinkToSigninText />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.auth,
    }),
    { ...auth.actionCreators }
)(Signup as any);

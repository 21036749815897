import * as React from "react";

type Props = React.PropsWithChildren<{}>;

const App = (props: Props) => {
    React.useEffect(() => {
        !document.querySelector(".splash") ||
            document.querySelector(".splash").classList.remove("active");
    }, []);
    return <React.Fragment>{props.children}</React.Fragment>;
};

export default App;

import styled from "styled-components";
import { device } from "../../../styles/sizes/device";
import { Link } from "react-router-dom";

export const PaddingContainer = styled.div`

  @media ${device.md} {
    padding: 0px;
  }

  @media ${device.sm} {
    padding: 0px;
  }

  @media ${device.xs} {
    padding: 0px;
  }
`;

export const CarouselContainer = styled.div`
  width: 1440px;
  margin: 0 auto;
`

import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';

import { Radio, Select, TextInput, DateTime, InputDecorator } from 'components/form';
import Button from 'components/shared/Button';
import { CourseSession } from 'models/CourseSession';
import { CourseSessionLink } from 'models/CourseSessionLink';
import { Practice } from 'models/Practice';
import { composeValidators, required } from 'validate';

type Props = {
    practices: Practice[];
    courseSessions: CourseSession[];
    courseSessionLinks?: CourseSessionLink[]
    generateLink(values: CourseSessionLink);
    formRef?: MutableRefObject<HTMLFormElement>;
    onCancel?();
}

const states = ["NSW", "VIC", "QLD", "WA", "SA", "TAS"];

export const GenerateSessionLinkForm = ({
    practices,
    courseSessions,
    courseSessionLinks,
    generateLink,
    formRef,
    onCancel
}: Props) => {

    const validateUniqueLinkName = useCallback((value: string) => {
        if (courseSessionLinks.some(link => link.sessionLinkName === value)) {
            return "The session name you entered already exists.";
        }
    }, [courseSessionLinks])

    const {
        register,
        control,
        handleSubmit: innerSubmit,
        formState: { errors },
        resetField
    } = useForm<CourseSessionLink>();

    const [practiceOrState, setPracticeOrState] = useState<"practice" | "state">("state");
    const [timezone, setTimezone] = useState<string>("Australia/Sydney");

    useEffect(() => {
        switch (practiceOrState) {
            case "practice":
                resetField("state");
                break;
            case "state":
                resetField("practiceId");
                break;
        }
    }, [practiceOrState, resetField])

    return <form
        ref={formRef}
        onSubmit={innerSubmit(generateLink)}
    >
        <Row>
            <Col xs="auto">
                <InputDecorator label="Category">
                    <Radio
                        name="practiceOrState"
                        value="state"
                        checked={practiceOrState === "state"}
                        label="State"
                        id={`practiceOrState.state`}
                        onChange={(e) => setPracticeOrState("state")}
                    />
                    <Radio
                        name="practiceOrState"
                        value="practice"
                        checked={practiceOrState === "practice"}
                        label="Clinic"
                        id={`practiceOrState.practice`}
                        onChange={(e) => setPracticeOrState("practice")}
                    />
                </InputDecorator>
            </Col>
            <Col>
                {practiceOrState === "practice" &&
                    <Select
                        label="Clinic"
                        id="practiceId"
                        placeholder="Select a clinic"
                        {...register("practiceId", { validate: required(), value: null })}
                        fieldError={errors.practiceId}
                    >
                        {practices?.map((practice) => (
                            <option key={practice.id} value={practice.id}>
                                {practice.name}
                            </option>
                        ))}
                    </Select>
                }
                {practiceOrState === "state" &&
                    <Select
                        label="State"
                        id="state"
                        placeholder="Select a state"
                        {...register("state", { validate: required(), value: "" })}
                        fieldError={errors.state}
                    >
                        {states?.map((state) => (
                            <option key={state} value={state}>
                                {state}
                            </option>
                        ))}
                    </Select>
                }
            </Col>
        </Row>
        <Row>
            <Col>

                <Select
                    label="Session type"
                    id="sessionId"
                    placeholder='Select a session type'
                    {...register("sessionId", { validate: required() })}
                    fieldError={errors.sessionId}
                >
                    {[...courseSessions].sort((a, b) => a.sessionName < b.sessionName ? -1 : 1).map(
                        (s) => (
                            <option
                                key={s.id}
                                value={s.id}
                            >
                                {
                                    s.sessionName
                                }
                            </option>
                        )
                    )}
                </Select>
            </Col>
        </Row>

        <Row>
            <Col>
                <TextInput
                    label="Session name"
                    id="sessionLinkName"
                    placeholder="Enter a name for the session"
                    hint="Example: Clinic/State Session 1"
                    {...register("sessionLinkName", { validate: composeValidators(required(), validateUniqueLinkName) })}
                    fieldError={errors.sessionLinkName}
                />
            </Col>
        </Row>

        <Row>
            <Col>
                <DateTime
                    label="Date and time"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="h:mm A"
                    id="date"
                    displayTimeZone={timezone}
                    name="date"
                    control={control}
                    error={!!errors.date}
                    errorMessage={errors.date?.message}
                />
            </Col>
            <Col>
                <Select
                    label="Timezone"
                    id="timezone"
                    placeholder="Select a Timezone"
                    onChange={e => setTimezone(e.target.value)}
                    value={timezone}
                >
                    <option
                        key="Australia/Sydney"
                        value="Australia/Sydney"
                    >
                        Australia/Sydney
                    </option>
                    <option
                        key="Australia/ACT"
                        value="Australia/ACT"
                    >
                        Australia/ACT
                    </option>
                    <option
                        key="Australia/Adelaide"
                        value="Australia/Adelaide"
                    >
                        Australia/Adelaide
                    </option>
                    <option
                        key="Australia/Brisbane"
                        value="Australia/Brisbane"
                    >
                        Australia/Brisbane
                    </option>
                    <option
                        key="Australia/Broken_Hill"
                        value="Australia/Broken_Hill"
                    >
                        Australia/Broken_Hill
                    </option>
                    <option
                        key="Australia/Canberra"
                        value="Australia/Canberra"
                    >
                        Australia/Canberra
                    </option>
                    <option
                        key="Australia/Currie"
                        value="Australia/Currie"
                    >
                        Australia/Currie
                    </option>
                    <option
                        key="Australia/Darwin"
                        value="Australia/Darwin"
                    >
                        Australia/Darwin
                    </option>
                    <option
                        key="Australia/Eucla"
                        value="Australia/Eucla"
                    >
                        Australia/Eucla
                    </option>
                    <option
                        key="Australia/Hobart"
                        value="Australia/Hobart"
                    >
                        Australia/Hobart
                    </option>
                    <option
                        key="Australia/LHI"
                        value="Australia/LHI"
                    >
                        Australia/LHI
                    </option>
                    <option
                        key="Australia/Lindeman"
                        value="Australia/Lindeman"
                    >
                        Australia/Lindeman
                    </option>
                    <option
                        key="Australia/Lord_Howe"
                        value="Australia/Lord_Howe"
                    >
                        Australia/Lord_Howe
                    </option>
                    <option
                        key="Australia/Melbourne"
                        value="Australia/Melbourne"
                    >
                        Australia/Melbourne
                    </option>
                    <option
                        key="Australia/NSW"
                        value="Australia/NSW"
                    >
                        Australia/NSW
                    </option>
                    <option
                        key="Australia/North"
                        value="Australia/North"
                    >
                        Australia/North
                    </option>
                    <option
                        key="Australia/Perth"
                        value="Australia/Perth"
                    >
                        Australia/Perth
                    </option>
                    <option
                        key="Australia/Queensland"
                        value="Australia/Queensland"
                    >
                        Australia/Queensland
                    </option>
                    <option
                        key="Australia/South"
                        value="Australia/South"
                    >
                        Australia/South
                    </option>
                    <option
                        key="Australia/Tasmania"
                        value="Australia/Tasmania"
                    >
                        Australia/Tasmania
                    </option>
                    <option
                        key="Australia/Victoria"
                        value="Australia/Victoria"
                    >
                        Australia/Victoria
                    </option>
                    <option
                        key="Australia/West"
                        value="Australia/West"
                    >
                        Australia/West
                    </option>
                    <option
                        key="Australia/Yancowinna"
                        value="Australia/Yancowinna"
                    >
                        Australia/Yancowinna
                    </option>
                </Select>
            </Col>
        </Row>

        <Row className="mt-3">
            {onCancel &&
                <Col>
                    <Button
                        className='w-full'
                        variant="secondary"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </Col>
            }
            <Col>
                <Button
                    type="submit"
                    className='w-full'
                >
                    Generate link
                </Button>
            </Col>
        </Row>
    </form>
}
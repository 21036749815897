import { Employee } from "./Employee";

export enum ReviewStatus {
  PENDING,
  DELETED,
  APPROVED,
  DECLINED,
  EDITED,
}

export enum EmployeeStatus {
  PENDING,
  ACCEPTED,
  DECLINED,
}

export enum DeclineReason {
  UNAVAILABLE,
  SHIFT_CLASH,
  SHIFT_NOT_WORTHWHILE,
  OTHER,
}

export const DeclineReasonToString = (reason: DeclineReason): string => {
  switch (reason) {
    case DeclineReason.UNAVAILABLE: return "Unavailable"
    case DeclineReason.SHIFT_CLASH: return "Shift clash"
    case DeclineReason.SHIFT_NOT_WORTHWHILE: return "Shift not worthwhile"
    case DeclineReason.OTHER: return "Other"
  }
}

export const EmployeeStatusToString = (status: EmployeeStatus) => {
  switch (status) {
    case EmployeeStatus.PENDING:
      return "Pending";
    case EmployeeStatus.ACCEPTED:
      return "Accepted";
    case EmployeeStatus.DECLINED:
      return "Declined";
  }
};

export const ReviewStatusToString = (status: ReviewStatus) => {
  switch (status) {
    case ReviewStatus.PENDING:
      return "Pending";
    case ReviewStatus.APPROVED:
      return "Approved";
    case ReviewStatus.DECLINED:
      return "Declined";
    case ReviewStatus.EDITED:
      return "Edited";
    case ReviewStatus.DELETED:
      return "Deleted";
  }
};

export interface ShiftAdjustment {
  id: number;
  shiftStart: string;
  shiftEnd: string;
  adjustedClockIn: string;
  adjustedClockOut: string;
  adjustedBySuperUser: boolean;
  note?: string;
  reviewer: Employee;
  reviewStatus: ReviewStatus;
  employeeStatus: EmployeeStatus;
  reviewedAt: Date;
  employeeResponseAt: Date;
  declineReason: DeclineReason;
  declineOtherReasonNote: string;
  clockInOutPairId: number;
}

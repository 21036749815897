import "./styles/LearningMaterials.scss";
import * as dashboard from "../../store/dashboardStore";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    TabContent,
    TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { RouteComponentProps, useParams } from "react-router";
import LearningMaterialCard from "./LearningMaterialCard";
import {
    ResetFiltersButton,
    SearchBar,
} from "./styles/LearningMaterials";
import _ from "lodash";
import { Course } from "../../models/Course";
import { Employee } from "../../models/Employee";
import { isAdmin } from "../../models/admin/UserRoles";
import { CourseCategoryFilterValue } from "../../common/CourseCategoryFilterValue";
import { CourseOrEvent } from "../../models/enums/CourseOrEvent";
import { CourseEvent } from "../../models/CourseEvent";
import { FilterDropDown } from "../../components/shared/FilterDropDown";
import { CpdType } from "common/CpdType";
import { Container, Tabs } from "components/reactstrap";
import { LearningMaterialCarousel } from "./LearningMaterialsCarousel";
import Button from "components/shared/Button";
import { SidePanel } from "components/SidePanel";
import { Radio } from "components/form";
import { ReactComponent as IconClose } from "images/icon-close.svg";
import { useQuery } from "hooks";

const tabs = [
    { label: "All", key: 0 },
    { label: "Not Started", key: 1 },
    { label: "Submitted", key: 2 },
    { label: "Completed", key: 3 }
]
const keySelector = t => t.key;
const labelSelector = t => t.label;

type SortOptions = "dateAdded" | "title"

type LearningMaterialsQuery = {
    sort?: SortOptions,
    category?: keyof typeof CourseCategoryFilterValue,
    cpd?: keyof typeof CpdType
};

type Props = RouteComponentProps<{}> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const LearningMaterials: React.FC<Props> = (props: Props) => {
    const { year } = useParams<{ year?: string }>();
    console.log(year);

    const [{
        sort = "dateAdded", category, cpd
    }, setQueryString] = useQuery<LearningMaterialsQuery>();

    const cpdType = CpdType[cpd];
    const categoryFilterValue = CourseCategoryFilterValue[category] ?? CourseCategoryFilterValue.All;
    const setSort = useCallback((sort: SortOptions) => { setQueryString({ sort }) }, [setQueryString]);
    const setCpdType = useCallback((cpd: CpdType) => { setQueryString({ cpd }) }, [setQueryString]);
    const setCategoryFilterValue = useCallback((category: CourseCategoryFilterValue) => { setQueryString({ category }) }, [setQueryString]);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [search, setSearch] = useState("");

    const [adminViewAsDropDownOpen, toggleAdminViewAsDropDown] =
        useState(false);
    const [adminViewAs, setAdminViewAs] = useState<string | null>("GP");
    const searchInputRef = useRef<any>();

    const {
        fetchCourseBanners,
        courseBanners,
        fetchAllCourses,
        fetchNotStartedCourses,
        fetchSubmittedCourses,
        fetchCompletedCourses,
        allCourses,
        notStartedCourses,
        submittedCourses,
        completedCourses,
        startCourse,
        markCourseAsSubmitted,
        courseCompletionFormQrCode,
        fetchCourseCompletionFormQrCode,
        currentEmployee,
        requestCountLoader,
        updateProfessionHeader,
        fetchCourseEventsToBeDisplayedInCoursesPage,
        courseEventsToBeDisplayedInCoursesPage,
    } = props;

    useEffect(() => {
        fetchAllCourses(search, sort, categoryFilterValue, cpdType, adminViewAs, year && Number(year));
        fetchNotStartedCourses(search, sort, categoryFilterValue, cpdType, adminViewAs, year && Number(year));
        fetchCompletedCourses(search, sort, categoryFilterValue, cpdType, adminViewAs, year && Number(year));
        fetchSubmittedCourses(search, sort, categoryFilterValue, cpdType, adminViewAs, year && Number(year));
        fetchCourseEventsToBeDisplayedInCoursesPage(search, sort);
    }, [search, sort, categoryFilterValue, adminViewAs, fetchAllCourses, fetchNotStartedCourses, fetchCompletedCourses, fetchSubmittedCourses, fetchCourseEventsToBeDisplayedInCoursesPage, cpdType, year]);

    useEffect(() => {
        updateProfessionHeader(adminViewAs);
    }, [adminViewAs, updateProfessionHeader]);

    const debouncedResults = useMemo(() => {
        return _.debounce((e: string) => {
            setSearch(e);
        }, 500);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    useEffect(() => {
        fetchCourseBanners();
    }, [fetchCourseBanners]);

    const handleTabChange = useCallback(t => setSelectedTabIndex(t.key), []);

    const resetFilters = () => {
        setSearch("");
        searchInputRef.current.value = "";
        setSort("dateAdded");
        setCategoryFilterValue(CourseCategoryFilterValue.All);
    };

    let courses = [];

    const [filtersSidebarOpen, setFiltersSidebarOpen] = useState(false);

    switch (selectedTabIndex) {
        case 0:
            courses = allCourses.slice();
            break;
        case 1:
            courses = notStartedCourses.slice();
            break;
        case 2:
            courses = submittedCourses.slice();
            break;
        case 3:
            courses = completedCourses.slice();
            break;
    }

    let isAdminUser =
        props.currentEmployee?.userRoles?.role &&
        isAdmin(props.currentEmployee.userRoles.role);

    const adminFudgeDropdown = <Dropdown
        className="sort-by-dropdown"
        isOpen={adminViewAsDropDownOpen}
        toggle={() =>
            toggleAdminViewAsDropDown(
                !adminViewAsDropDownOpen
            )
        }
        direction="down"
    >
        <DropdownToggle
            style={{ paddingLeft: "0" }}
            className="text-nowrap"
        >
            <span className="text-gray">View As: </span>
            <span className="me-2">{adminViewAs}</span>
        </DropdownToggle>
        <DropdownMenu>
            {["GP", "Registrar", "Nurse"].map(
                (professionType) => {
                    return (
                        <DropdownItem
                            key={professionType}
                            onClick={() =>
                                setAdminViewAs(
                                    professionType
                                )
                            }
                        >
                            {professionType}
                        </DropdownItem>
                    );
                }
            )}
        </DropdownMenu>
    </Dropdown>;

    const renderSearch = () => {
        return (
            <SearchBar
                type="text"
                placeholder="What are you looking to learn about?"
                onChange={(e) => debouncedResults(e.target.value)}
                ref={searchInputRef}
                className="w-full border border-gray-200 bg-white text-sm rounded-md py-2 pr-4 pl-10"
            />
        );
    };

    const renderFilters = () => {
        return (
            <>
                <div className="hidden sm:flex justify-between gap-3">
                    <FilterDropDown
                        options={[undefined, ...Object.keys(CpdType)]}
                        onSelected={setCpdType}
                        selectedValue={cpdType}
                        labelSelector={getCpdTypeTitle}
                    />

                    <FilterDropDown
                        options={Object.keys(CourseCategoryFilterValue)}
                        onSelected={(key) => {
                            setCategoryFilterValue(
                                CourseCategoryFilterValue[key]
                            );
                        }}
                        selectedValue={categoryFilterValue}
                        labelSelector={(key) =>
                            getCategoryName(CourseCategoryFilterValue[key])
                        }
                    />

                    <FilterDropDown
                        options={["dateAdded", "title"]}
                        onSelected={setSort}
                        selectedValue={sort || "dateAdded"}
                        labelSelector={getSortTitle}
                        dropdownHeader="Order by"
                    />
                </div>
                <div className="block flex-1 sm:hidden">
                    <Button
                        variant="secondary"
                        className="w-full"
                        onClick={() => setFiltersSidebarOpen(true)}
                    >
                        Filter courses
                    </Button>
                    <SidePanel
                        open={filtersSidebarOpen}
                        setOpen={setFiltersSidebarOpen}
                        direction="left"
                        hideClosePanel
                    >
                        <div className="pt-4 h-full flex flex-col overflow-hidden">
                            <h2 className="mb-1 px-3 w-full flex flex-row items-center">
                                <span className="flex-1">
                                    Filter courses
                                </span>
                                <button
                                    type="button"
                                    className="ml-auto p-2 relative rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => setFiltersSidebarOpen(false)}
                                >
                                    <IconClose />
                                </button>
                            </h2>
                            <div className="flex px-3 flex-col gap-y-6 py-6 flex-1 overflow-auto">
                                <fieldset>
                                    <legend className="text-sm text-black font-semibold mb-2">Sort by</legend>
                                    <div className="px-3">
                                        {["dateAdded", "title"].map(sortBy =>
                                            <Radio
                                                label={getSortTitle(sortBy)}
                                                name="learning-materials-sort-by"
                                                checked={(sort || "dateAdded") === sortBy}
                                                onChange={() => setSort(sortBy as SortOptions)}
                                            />
                                        )}
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend className="text-sm text-black font-semibold mb-2">CPD Types</legend>
                                    <div className="px-3">
                                        {[undefined, ...Object.keys(CpdType)].map(cpd =>
                                            <Radio
                                                label={getCpdTypeTitle(cpd as CpdType)}
                                                name="learning-materials-cpd-type"
                                                checked={cpdType === cpd}
                                                onChange={() => setCpdType(cpd as CpdType)}
                                            />
                                        )}
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend className="text-sm text-black font-semibold mb-2">Activity Types</legend>
                                    <div className="px-3">
                                        {Object.keys(CourseCategoryFilterValue).map(key =>
                                            <Radio
                                                label={getCategoryName(CourseCategoryFilterValue[key])}
                                                name="learning-materials-category-filter"
                                                checked={categoryFilterValue === key}
                                                onChange={() => setCategoryFilterValue(
                                                    CourseCategoryFilterValue[key]
                                                )}
                                            />
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </SidePanel>
                </div>
            </>
        );
    };

    return (
        <div className="flex flex-col flex-grow-1 main-card overflow-auto">
            <LearningMaterialCarousel
                courseBanners={courseBanners}
                className="hidden sm:block"
            />
            <div className="pt-4 sm:pt-8 sm:pb-5">
                <Container >
                    <div className="mb-4 sm:mb-5 flex flex-row">
                        <h2 className="flex-1">
                            {isAdmin ? `${year ?? ""} Courses` : "Explore Courses"}
                        </h2>
                        {isAdminUser && <div>
                            {adminFudgeDropdown}
                        </div>}
                    </div>
                    <div className="sm:mt-5 mb-3">
                        {renderSearch()}
                    </div>
                    <div className="flex flex-row-reverse flex-wrap items-center justify-between gap-4 mt-3">
                        {renderFilters()}
                        {!isAdminUser &&
                            <div className="mr-auto hidden sm:block">
                                <Tabs
                                    tabs={tabs}
                                    selected={tabs[selectedTabIndex]}
                                    labelSelector={labelSelector}
                                    keySelector={keySelector}
                                    onChange={handleTabChange}
                                    disableSmDropdown
                                />
                            </div>
                        }
                    </div>
                </Container>
                <div className="sm:hidden flex-shrink-0 overflow-x-auto mt-3">
                    <Container className="pb-1">
                        <Tabs
                            tabs={tabs}
                            selected={tabs[selectedTabIndex]}
                            labelSelector={labelSelector}
                            keySelector={keySelector}
                            onChange={handleTabChange}
                            disableSmDropdown
                        />
                    </Container>
                </div>
            </div>
            <hr className="my-0" />
            <LearningMaterialsTabContent
                tabId={selectedTabIndex}
                handleSearchChange={(e) => debouncedResults(e.target.value)}
                handleSortChange={setSort}
                handleCategoryFilterChange={setCategoryFilterValue}
                handleCpdTypeChange={setCpdType}
                cpdType={cpdType}
                categoryFilterValue={categoryFilterValue}
                getCategoryName={getCategoryName}
                sort={sort}
                courses={courses}
                courseEvents={courseEventsToBeDisplayedInCoursesPage}
                startCourse={startCourse}
                employee={currentEmployee}
                markCourseAsSubmitted={markCourseAsSubmitted}
                courseCompletionFormQrCode={courseCompletionFormQrCode}
                fetchCourseCompletionFormQrCode={
                    fetchCourseCompletionFormQrCode
                }
                resetFilters={resetFilters}
                isAdmin={isAdminUser}
                searchInputRef={searchInputRef}
                requestCountLoader={requestCountLoader}
            />
        </div>
    );
};

const getSortTitle = (sort: string) => {
    switch (sort) {
        case "dateAdded":
            return "Date Added";
        case "title":
            return "Title";
        default:
            return "Unknown";
    }
};

const getCpdTypeTitle = (sort: CpdType | "") => {
    switch (sort) {
        case CpdType.MeasuringOutcomes:
            return "Measuring Outcomes";
        case CpdType.EducationalActivities:
            return "Educational Activities";
        case CpdType.ReviewingPerformance:
            return "Reviewing Performance";
        default:
            return "All CPD Types";
    }
}

const getCategoryName = (categoryFilterValue: CourseCategoryFilterValue) => {
    switch (categoryFilterValue) {
        case CourseCategoryFilterValue.ClinicalAudits:
            return "Clinical Audits";
        case CourseCategoryFilterValue.Webinars:
            return "Webinars";
        case CourseCategoryFilterValue.MeetingSeries:
            return "Meeting Series";
        case CourseCategoryFilterValue.Meetings:
            return "Meetings";
        case CourseCategoryFilterValue.Conferences:
            return "Conferences";
        case CourseCategoryFilterValue.Workshops:
            return "Workshops";
        case CourseCategoryFilterValue.RecordedWebinars:
            return "Recorded Webinars";
        default:
            return "All Categories";
    }
};

interface LearningMaterialsTabContentProps {
    tabId: number;
    handleSearchChange: (e: any) => void;
    handleSortChange: (sort: SortOptions) => void;
    handleCategoryFilterChange: (
        courseCategoryFilterValue: CourseCategoryFilterValue
    ) => void;
    handleCpdTypeChange: (
        cpdValue: CpdType
    ) => void;
    categoryFilterValue: CourseCategoryFilterValue;
    cpdType?: CpdType;
    getCategoryName: (categoryFilterValue: CourseCategoryFilterValue) => string;
    sort: string;
    courses: Course[];
    courseEvents: CourseEvent[];
    startCourse: (courseId: number) => void;
    markCourseAsSubmitted: (courseId: number) => void;
    courseCompletionFormQrCode: string | null;
    fetchCourseCompletionFormQrCode: (courseId: number) => void;
    employee: Employee;
    resetFilters: () => void;
    isAdmin?: boolean;
    searchInputRef: any;
    requestCountLoader: number;
}

const LearningMaterialsTabContent = (
    props: LearningMaterialsTabContentProps
) => {
    const {
        tabId,
        courses,
        courseEvents,
        startCourse,
        markCourseAsSubmitted,
        courseCompletionFormQrCode,
        fetchCourseCompletionFormQrCode,
        employee,
        resetFilters,
        requestCountLoader,
    } = props;

    return (
        <React.Fragment>
            <TabContent activeTab={tabId}>
                <TabPane tabId={tabId} className="py-10">
                    <Container>
                        <div className="flex flex-wrap justify-start grid gap-y-6 gap-x-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                            {courseEvents.map((item, index) => (
                                <LearningMaterialCard
                                    key={item.id}
                                    expandImage={true}
                                    type={CourseOrEvent.Event}
                                    event={item}
                                    startCourse={() => { }}
                                    employee={null}
                                    markCourseAsSubmitted={() => { }}
                                    courseCompletionQrCode={""}
                                    fetchCourseCompletionFormQrCode={() => { }}
                                    hideCompletitionDate={true}
                                    isAdminUser={props.isAdmin}
                                    className="col-span-1"
                                />
                            ))}
                            {courses.map((item, index) => (
                                <LearningMaterialCard
                                    key={item.id}
                                    type={CourseOrEvent.Course}
                                    course={item}
                                    startCourse={startCourse}
                                    employee={employee}
                                    markCourseAsSubmitted={
                                        markCourseAsSubmitted
                                    }
                                    courseCompletionQrCode={
                                        courseCompletionFormQrCode
                                    }
                                    fetchCourseCompletionFormQrCode={
                                        fetchCourseCompletionFormQrCode
                                    }
                                    hideCompletitionDate={props.isAdmin}
                                    isAdminUser={props.isAdmin}
                                    className="col-span-1"
                                />
                            ))}
                            {courses.length === 0 &&
                                courseEvents.length === 0 &&
                                requestCountLoader === 0 && (
                                    <div className="w-full text-center mt-5">
                                        No conferences to show.{" "}
                                        <ResetFiltersButton
                                            onClick={resetFilters}
                                        >
                                            Reset filter
                                        </ResetFiltersButton>
                                    </div>
                                )}
                        </div>
                    </Container>
                </TabPane>
            </TabContent>
        </React.Fragment>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(LearningMaterials as any);

import "./styles/FaceToFaceEvents.scss";
import * as dashboard from "../../store/dashboardStore";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { RouteComponentProps, useHistory } from "react-router";
import { FaceToFaceEventsBackLink } from "./styles/FaceToFaceEvents";
import { ReactComponent as BackIcon } from "../../images/icon-back-ios.svg";

interface OwnRouteProps {
    id?: string;
}

type Props = RouteComponentProps<OwnRouteProps> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const FaceToFaceEventBooking: React.FC<Props> = (props: Props) => {
    const history = useHistory();
    const courseEventId = parseInt(props.match.params.id);

    const { fetchCourseEvent, selectedCourseEvent } = props;

    useEffect(() => {
        fetchCourseEvent(courseEventId);
    }, [courseEventId, fetchCourseEvent]);

    return (
        <div className="w-full h-full flex flex-col flex-grow-1">
            <div className="w-full course-title-container py-3 flex align-items-center align-content-center">
                <div className="flex w-full h-100">
                    <FaceToFaceEventsBackLink
                        role="button"
                        className="flex align-items-center w-full"
                        onClick={() =>
                            history.push(
                                `/face-to-face-events/${courseEventId}`
                            )
                        }
                    >
                        <BackIcon />
                        {selectedCourseEvent != null && (
                            <h1 className="h-100 course-title ms-1">
                                {selectedCourseEvent.title}
                            </h1>
                        )}
                    </FaceToFaceEventsBackLink>
                </div>
            </div>
            <div className="w-full flex-grow-1">
                {selectedCourseEvent != null && (
                    <iframe
                        className="w-full h-100"
                        src={`${selectedCourseEvent.bookingUrl}&embed`}
                        title="TryBooking page"
                    />
                )}
            </div>
        </div>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(FaceToFaceEventBooking as any);

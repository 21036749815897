import "./styles/SignupProgressItem.scss";
import * as React from "react";
import { Col, Row } from "reactstrap";

interface ISignupProgressItemProp {
  title: string;
  subTitle: string;
  isActive: boolean;
  isReady: boolean;
  first?: boolean;
  last?: boolean;
}

export const SignupProgressItem = (props: React.PropsWithChildren<ISignupProgressItemProp>) => {
  return (
    <Row className={`progress-item ${props.isActive ? 'active' : (props.isReady ? 'ready' : 'inactive')}`}>
      <Col className="flex justify-content-center" lg={2}>
        <div className={`align-self-center marker ${props.first ? 'first' : ''} ${props.last ? 'last' : ''}`}></div>
      </Col>
      <Col lg={10}>
        <Row>
          <Col>
            <span className="title">{props.title}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="sub-title">{props.subTitle}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

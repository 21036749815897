import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const EventHeaderSection = styled.section`
    background: linear-gradient(139deg, #fff 0%, #fbf3e9 100%);
    padding: 160px 0px 120px 0px;
    position: relative;
    @media ${device.xs} {
        padding: 112px 16px 48px 16px;
    }
`;

export const EventTopImageOverflow = styled.div`
    overflow: hidden;
`;

export const EventTopImageContainer = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
`;

export const EventHeaderSectionContainer = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 64px;
    flex: 1 0 0;
    @media ${device.xs} {
        gap: 48px;
    }
`;

export const EventInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 826px;
    gap: 24px;
    @media ${device.xs} {
        width: 100%;
        gap: 16px;
    }
`;

export const EventDateContainer = styled.div`
    color: var(--black);
    font-weight: 600;
    line-height: 30px;
    flex-direction: column;
    display: flex;
    font-size: 20px;
    @media ${device.xs} {
        font-size: 18px;
        line-height: 28px;
    }
`;

export const EventTitleContainer = styled.h1`
    color: var(--primary-700);
    font-size: 36px;

    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.72px;
    @media ${device.xs} {
        font-size: 30px;
        line-height: 38px;
    }
`;

export const EventParagraphContainer = styled.div``;

export const Paragraph = styled.p`
    color: var(--gray-700);
    font-size: 16px;

    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;

export const RegisterButtonContainer = styled.div`
    display: flex;
    @media ${device.xs} {
        justify-content: center;
        align-items: center;
        gap: 32px;
        align-self: stretch;
    }
`;

export const RegisterButton = styled.a`
    color: var(--white) !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 28px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid var(--primary-orange);
    background: var(--primary-orange);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--base-white, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    @media ${device.xs} {
        font-size: 16px;
        line-height: 24px;
        gap: 8px;
        padding: 12px 20px;
        width: 100%;
    }
`;

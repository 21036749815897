import styled from "styled-components";

export const UsersContainer = styled.div`
    background-color: var(--white);
    height: 100%;
    padding: 0;
    margin: 0;
    width: 100%;
    cursor: default;
`;

export const NavbarContainer = styled.div`
    background-color: var(--white);
    height: 49px;
    padding: 0;
    margin: 0;
    width: 50%;
`;

export const UserSearchContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const UserSearchDefinitionLabel = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black);
    margin-top: 15px;
    margin-bottom: 5px;
    color: var(--darkGrey);
`;

export const TableHeaderLabel = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--black);
`;

export interface TableNameLabelProps {
    color?: string;
}

export const TableNameLabel = styled.div`

    font-size: calc(9px + 0.416vw);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(props) => props.color || "var(--black)"}};
    var(--white)-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UserTable = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const UserSearchTitleLabel = styled.div`
    font-size: 28px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgb(33, 37, 41);
`;

export const TopBarContainer = styled.div`
    border-bottom: solid 1px var(--lightGrey);
    height: 50px;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const TopRightSideContainer = styled.div`
    margin-left: auto;
    margin-top: 5px;
    align-items: center;
    display: flex;
`;

export const UsersBottomContainer = styled.div`
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    flex-direction: row;
    width: 100%;
`;

export interface UsersListContainerProps {
    width: string;
}

export const UsersListContainer = styled.div<UsersListContainerProps>`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    height: 100%;
`;

import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import MobileInput from "./MobileInput";
import { PhoneChangeCodeVerificationState, PhoneChangeVerificationState } from "store/dashboardStore";
import Button from "components/shared/Button";

type Props = {
    show: boolean,
    setShow(show: boolean): unknown,
    employeeId: number,

    onChange?(newNumber: string)

    validatePhoneNumberChange(phoneNumber: string, employeeId: number, code: string): unknown,
    requestPhoneNumberChangeValidation(phoneNumber: string): unknown,

    phoneChangeVerificationState: PhoneChangeVerificationState,
    setPhoneChangeVerificationState(state: PhoneChangeVerificationState): unknown,

    phoneChangeCodeVerificationState: PhoneChangeCodeVerificationState,
    setPhoneChangeCodeVerificationState(state: PhoneChangeCodeVerificationState): unknown,
}

export const ChangeMobileModals: React.FC<Props> = ({
    requestPhoneNumberChangeValidation,
    show: showMobileChangeModal,
    employeeId,
    setShow: setShowMobileChangeModal,
    validatePhoneNumberChange,
    phoneChangeVerificationState,
    setPhoneChangeCodeVerificationState,
    setPhoneChangeVerificationState,
    phoneChangeCodeVerificationState,
    onChange
}) => {
    const [showCodeValidationModal, setShowCodeValidationModal] =
        useState(false);
    const [showValidationResultModal, setShowValidationResultModal] =
        useState(false);
    const [mobileNumberChanged, setMobileNumberChanged] = useState("");
    const [isValidationSuccess, setIsValidationSuccess] = useState(false);

    useEffect(() => {
        if (
            phoneChangeVerificationState ===
            PhoneChangeVerificationState.CODE_RECEIVED
        ) {
            setShowMobileChangeModal(false);
            setShowCodeValidationModal(true);
            setPhoneChangeVerificationState(PhoneChangeVerificationState.NONE);
        } else if (
            phoneChangeVerificationState ===
            PhoneChangeVerificationState.REQUEST_FAILED
        ) {
            alert("An unknown error occured. Please try again!");
            setPhoneChangeVerificationState(PhoneChangeVerificationState.NONE);
        }
    }, [phoneChangeVerificationState, setPhoneChangeVerificationState, setShowMobileChangeModal]);

    useEffect(() => {
        if (
            phoneChangeCodeVerificationState ===
            PhoneChangeCodeVerificationState.REQUEST_SUCCESS
        ) {
            setShowMobileChangeModal(false);
            setShowCodeValidationModal(false);
            setPhoneChangeCodeVerificationState(
                PhoneChangeCodeVerificationState.NONE
            );
            onChange(mobileNumberChanged);

            setIsValidationSuccess(true);
            setShowValidationResultModal(true);
        } else if (
            phoneChangeCodeVerificationState ===
            PhoneChangeCodeVerificationState.REQUEST_FAILED
        ) {
            setIsValidationSuccess(false);
            setShowValidationResultModal(true);
            setPhoneChangeCodeVerificationState(
                PhoneChangeCodeVerificationState.NONE
            );
        }
    }, [mobileNumberChanged, onChange, phoneChangeCodeVerificationState, setPhoneChangeCodeVerificationState, setShowMobileChangeModal]);

    return <>
        <Modal isOpen={showMobileChangeModal}>
            <form
                onSubmit={(e) => {
                    e.bubbles = false;
                    e.preventDefault();
                    requestPhoneNumberChangeValidation(
                        mobileNumberChanged
                    );
                }}
                className="p-3"
            >
                <h6>
                    Enter your mobile number
                </h6>
                <p>
                    <small>
                        You will receive a text message with a verification
                        code.
                        <br />
                        Your mobile number will be used for login and
                        verification.
                    </small>
                </p>
                <FormGroup>
                    <Label for="mobile-change-modal-mobile">
                        Mobile
                    </Label>
                    <Input
                        id="mobile-change-modal-mobile"
                        onChange={(e) =>
                            setMobileNumberChanged(e.target.value)
                        }
                        value={mobileNumberChanged}
                    />
                </FormGroup>
                <Row className="mt-3">
                    <Col xs={6}>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={() => setShowMobileChangeModal(false)}
                            className="w-full"
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button
                            type="submit"
                            variant="primary"
                            className="w-full"
                        >
                            Send code
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
        <Modal isOpen={showCodeValidationModal}>
            <div className="p-3">
                <h6>
                    Verify your number
                </h6>
                <p>
                    <small>
                        Please enter the 4-digit code that was sent to your number.
                        <br />
                        The code will expire after 5 minutes.
                    </small>
                </p>
                <div className="mt-2 text-center">
                    <MobileInput
                        onCompleteCode={(value) => {
                            validatePhoneNumberChange(
                                mobileNumberChanged,
                                employeeId,
                                value
                            );
                        }}
                        size="60px"
                        margin="20px"
                        fontSize="28px"
                        isValidCode
                    />
                </div>
                <Row className="mt-3">
                    <Col xs={6}>
                        <Button
                            className="w-full"
                            type="button"
                            variant="secondary"
                            onClick={() => {
                                setShowMobileChangeModal(true);
                                setShowCodeValidationModal(false);
                            }}
                        >
                            Back
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button
                            type="submit"
                            variant="primary"
                            className="w-full"
                            onClick={() => {
                                requestPhoneNumberChangeValidation(
                                    mobileNumberChanged
                                );
                            }}
                        >
                            Resend code
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
        <Modal isOpen={showValidationResultModal}>
            <div className="p-3">
                <h6>
                    Change mobile number
                </h6>
                <p className="text-center">
                    <small>
                        {isValidationSuccess
                            ? "Phone number successfully changed!"
                            : "An unknown error occured. Please try again!"}
                    </small>
                </p>

                <Button
                    className="mx-auto mt-3 w-full"
                    onClick={() => {
                        setShowValidationResultModal(false);
                    }}
                >
                    Ok
                </Button>
            </div>
        </Modal>
    </>
}
import styled from "styled-components";
import { device } from "../../../styles/sizes/device";

export const EventLocationSection = styled.section`
    padding: 96px 0px;
    background: var(--orange-25);
    @media ${device.xs} {
        padding: 48px 0px;
    }
`;

export const EventLocationSectionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    @media ${device.xs} {
        flex-direction: column;
        gap: 16px;
    }
`;

export const EventLocationAndMapContainer = styled.div`
    display: flex;
    padding: 0px 32px;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    @media ${device.xs} {
        padding: 0px 8px;
        justify-content: center;
        flex-direction: column;
    }
`;

export const EventLocationAndMapInnerContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    @media ${device.xs} {
        justify-content: center;
    }
`;

export const EventLocationHeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    @media ${device.xs} {
    }
`;

export const EventLocationContactTextContainer = styled.div`
    display: flex;
    width: 384px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    @media ${device.xs} {
        width: 100%;
    }
`;

export const EventLocationAddressContainer = styled.div`
    display: flex;
    align-items: center;
    color: var(--primary-700);
    gap: 8px;
`;

export const EventLocationPinContainer = styled.svg`
    width: 24px;
    height: 24px;
    display: block;
`;

export const EventLocationAddressButton = styled.a`
    cursor: pointer;
    display: block;
    color: var(--primary-700) !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 0px;
`;

export const EventLocationAdditionalAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 8px;
    align-self: stretch;
`;

export const EventLocationAdditionalAddressText = styled.p`
    color: var(--gray-600);
    font-size: 16px;

    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const EventLocationMapContainer = styled.div`
    height: 300px;
    width: 800px;
    border-radius: 12px;
    @media ${device.xs} {
        width: 350px;
        height: 300px;
    }
`;

export const EventLocationImageContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    overflow: hidden;
    @media ${device.xs} {
        display: none;
    }
`;

export const EventLocationImage = styled.img`
    height: 600px;
    width: 400px;
    object-fit: cover;
    border-radius: 12px;
`;

import React, { useEffect, useRef, useState } from "react";
import { InputEntry } from "./styles/MobileInput";

export interface MobileInputProps {
    onCompleteCode: (string) => void;
    isValidCode: boolean;
    size?: string;
    fontSize?: string;
    padding?: string;
    margin?: string;
    onContinue?: () => void;
}

const MobileInput = (props: MobileInputProps) => {
    const { isValidCode, onCompleteCode, size, fontSize, padding, onContinue } =
        props;
    const [inputText, setInputText] = useState(["", "", "", ""]);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const entryLength = 4;
    const inputsRef = useRef([]);

    useEffect(() => {
        if (currentIndex < entryLength && currentIndex > -1) {
            inputsRef.current[currentIndex]?.focus();
        }
        if (currentIndex === entryLength) {
            onCompleteCode(
                inputText[0] + inputText[1] + inputText[2] + inputText[3]
            );
        }
    }, [currentIndex]);

    return (
        <React.Fragment>
            {[...Array(4)].map((_, i) => (
                <InputEntry
                    type="number"
                    key={i}
                    isValid={isValidCode}
                    isFilled={!!inputText[i]}
                    value={inputText[i]}
                    fontSize={fontSize}
                    padding={padding}
                    id={`input-${i}`}
                    inputSize={size || "4.067vw"}
                    placeholder="0"
                    margin={`0.417vw 0vw 0 ${i === 0 ? "0vw" : props.margin || "2.283vw"
                        }`}
                    ref={(el) => (inputsRef.current[i] = el)}
                    onFocus={() => {
                        currentIndex !== i && setCurrentIndex(i);
                    }}
                    onChange={(t) => {
                        if (t.target.value.length > 0) {
                            setCurrentIndex(i + 1);
                            setInputText({
                                ...inputText,
                                [i]: t.target.value,
                            });
                        }
                    }}
                    onKeyUp={(e) => {
                        var key = e.keyCode || e.charCode;
                        if (key == 8 || key == 46) {
                            if (inputsRef.current[i].value.length > 0) {
                                setInputText({
                                    ...inputText,
                                    [i]: "",
                                });
                            } else {
                                setInputText({
                                    ...inputText,
                                    [i - 1]: "",
                                });
                                setCurrentIndex(i - 1);
                            }
                        } else if (key == 13) {
                            if (onContinue) {
                                onContinue();
                            }
                        }
                    }}
                />
            ))}
        </React.Fragment>
    );
};

export default MobileInput;

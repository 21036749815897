import React, { useEffect } from 'react'
import './styles/LandingPage.scss'
import EventGrid from '../../components/landing-page/EventGrid'
import WelcomeAndAbout from '../../components/landing-page/WelcomeAndAbout'
import * as dashboard from '../../store/dashboardStore'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { IApplicationState } from '../../store'
import { LandingPageContainer } from '../../components/landing-page/styles/LandingPage'
import Register from '../../components/landing-page/Register'

type Props = RouteComponentProps<{}> &
  dashboard.DashboardState & {} & typeof dashboard.actionCreators

const NewLandingPage: React.FC<Props> = (props: Props) => {
  const { fetchAllLandingPageCourses, landingPageCourses } = props
  const { allUpcomingCourseEvents } = props

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
    fetchAllLandingPageCourses()
  }, [])

  return (
    <React.Fragment>
      <LandingPageContainer>
        <WelcomeAndAbout />
        <EventGrid events={allUpcomingCourseEvents} />
        <Register courses={landingPageCourses} />
      </LandingPageContainer>
    </React.Fragment>
  )
}

export default connect(
  (state: IApplicationState) => ({
    ...state.dashboard,
  }),
  { ...dashboard.actionCreators },
)(NewLandingPage as any)

import React, { forwardRef } from 'react';
import { InputDecorator, InputDecoratorProps } from './InputDecorator';
import { Input } from 'reactstrap';

export type SelectProps = Omit<React.InputHTMLAttributes<HTMLInputElement>,
    "type"
> & InputDecoratorProps

export const Select = forwardRef<HTMLInputElement, SelectProps>(({ className, hint, label, error, id, fieldError, placeholder, children, ...selectProps }: SelectProps, ref) => {
    return <InputDecorator
        id={id}
        className={className}
        error={error}
        label={label}
        hint={hint}
        fieldError={fieldError}
    >
        <Input {...selectProps} id={id} invalid={error || !!fieldError} innerRef={ref} type="select">
            {placeholder &&
                <option value="" disabled>
                    {placeholder}
                </option>
            }
            {children}
        </Input>
    </InputDecorator>
})
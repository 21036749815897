import { ServiceBase } from "../core/ServiceBase";
import Result from "../core/Result";
import { ApiCall } from "../models/enums/ApiCall";
import { PayGrade } from "../models/PayGrade";

class PaygradeServiceClass extends ServiceBase {
  private static _paygradeService: PaygradeServiceClass;

  private constructor(controllerName: string) {
    super(controllerName);
  }

  public static get Instance(): PaygradeServiceClass {
    return (
      this._paygradeService || (this._paygradeService = new this("paygrades"))
    );
  }

  public async FetchPaygrades(): Promise<Result<PayGrade[]>> {
    const result = await super.requestJson<PayGrade[]>({
      method: "GET",
      url: ``,
      callType: ApiCall.GetPayGrades,
      data: {},
    });

    return result;
  }
}

export const PaygradeService = PaygradeServiceClass.Instance;

import { TextInput } from 'components/form';
import { FileInput } from 'components/form/FileInput';
import Button from 'components/shared/Button';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { required } from 'validate';

export type ContactFormValues = {
    subject: string;
    body: string;
    attachments?: File[];
}

type Props = {
    handleSubmit(values: ContactFormValues);
    initialValues?: Partial<ContactFormValues>,
    handleCancel?()
}

export const ContactForm = ({
    handleSubmit,
    initialValues,
    handleCancel
}: Props) => {
    const {
        register,
        control,
        handleSubmit: innerSubmit,
        formState: { errors, isSubmitting },
    } = useForm<ContactFormValues>({ defaultValues: initialValues });

    const validateFileSize = useCallback((value?: File[]) => {
        const bytes = value?.reduce((total, file) => total + file.size, 0) ?? 0;
        if (bytes > 5242880) {
            return "Max file size is 5MB";
        }
        return undefined;
    }, [])

    return <form onSubmit={innerSubmit(handleSubmit)}>
        <Row>
            <Col>
                <TextInput
                    label="Subject"
                    id="subject"
                    placeholder='Let us know what the issue is'
                    {...register("subject", { validate: required() })}
                    fieldError={errors.subject}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <TextInput
                    label="Full Description"
                    type='textarea'
                    id="body"
                    placeholder='Please enter as much details as you can.'
                    {...register("body", { validate: required() })}
                    fieldError={errors.body}
                />
            </Col>
        </Row>

        <Row>
            <Col>
                <FileInput
                    label="Attachments"
                    hint={<>Drag and drop your file here or <span className='text-primary'>browse</span> to choose a file.</>}
                    multiple
                    name="attachments"
                    control={control}
                    fieldError={errors.attachments}
                    rules={{
                        validate: validateFileSize
                    }}
                />
            </Col>
        </Row>

        <Row className='mt-3'>
            <Col>
                <Button
                    type='submit'
                    disabled={isSubmitting}
                >Submit</Button>
            </Col>
        </Row>
    </form>
}
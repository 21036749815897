import "./styles/FaceToFaceEvents.scss";
import * as dashboard from "../../store/dashboardStore";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import { RouteComponentProps } from "react-router";
import { LearningMaterialCarouselImage } from "./styles/FaceToFaceEvents";
import _ from "lodash";
import Carousel from "react-bootstrap/Carousel";
import { FaceToFaceEventsTab } from "../../models/enums/FaceToFaceEventsTab";
import { FaceToFaceEventsTabContent } from "./FaceToFaceEventsTabContent";
import { isAdmin } from "../../models/admin/UserRoles";

type Props = RouteComponentProps<{}> &
    dashboard.DashboardState & {} & typeof dashboard.actionCreators;

const FaceToFaceEvents: React.FC<Props> = (props: Props) => {
    const [selectedTab, setSelectedTab] = useState(
        FaceToFaceEventsTab.Upcoming
    );
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("dateAdded");
    const searchInputRef = useRef<any>();

    const {
        fetchCourseBanners,
        courseBanners,
        fetchUpcomingCourseEvents,
        fetchPastCourseEvents,
        upcomingCourseEvents,
        pastCourseEvents,
        requestCountLoader,
        currentEmployee,
    } = props;

    useEffect(() => {
        fetchUpcomingCourseEvents(search, sort);
        fetchPastCourseEvents(search, sort);
    }, [search, sort]);

    const debouncedResults = useMemo(() => {
        return _.debounce((e: string) => {
            setSearch(e);
        }, 500);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    useEffect(() => {
        fetchCourseBanners();
    }, []);

    const handleTabChange = (tab: FaceToFaceEventsTab) => {
        setSelectedTab(tab);
    };

    const resetFilters = () => {
        setSearch("");
        searchInputRef.current.value = "";
        setSort("dateAdded");
    };

    let events = [];

    switch (selectedTab) {
        case FaceToFaceEventsTab.Upcoming:
            events = upcomingCourseEvents.slice();
            break;
        case FaceToFaceEventsTab.Past:
            events = pastCourseEvents.slice();
            break;
    }

    const isAdminUser =
        currentEmployee?.userRoles?.role &&
        isAdmin(currentEmployee.userRoles.role);

    return (
        <Card className="flex flex-grow-1 main-card overflow-auto">
            <div className="learning-materials-carousel hidden sm:block">
                <Carousel slide={false} indicators={true} controls={false}>
                    {courseBanners.map((b) => {
                        return (
                            <Carousel.Item key={b.image}>
                                <div className="flex w-full">
                                    <a
                                        className="w-full"
                                        href={b.url || "/"}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LearningMaterialCarouselImage
                                            src={b.image}
                                        />
                                    </a>
                                </div>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
            <div className="p-3">
                <div className="fs-3">Conferences</div>
                <div className="text-gray">
                    See all our face to face{" "}
                    {FaceToFaceEventsTab[selectedTab].toLowerCase()} events
                </div>
            </div>
            <Nav tabs className="p-0 p-sm-3 learning-materials-nav">
                <NavItem
                    className={
                        selectedTab === FaceToFaceEventsTab.Upcoming
                            ? "category-nav-tab active"
                            : "category-nav-tab"
                    }
                >
                    <NavLink
                        role="button"
                        className={
                            selectedTab === FaceToFaceEventsTab.Upcoming
                                ? "active"
                                : ""
                        }
                        onClick={() =>
                            handleTabChange(FaceToFaceEventsTab.Upcoming)
                        }
                    >
                        Upcoming
                    </NavLink>
                </NavItem>
                <NavItem
                    className={
                        selectedTab === FaceToFaceEventsTab.Past
                            ? "category-nav-tab active"
                            : "category-nav-tab"
                    }
                >
                    <NavLink
                        role="button"
                        className={
                            selectedTab === FaceToFaceEventsTab.Past
                                ? "active"
                                : ""
                        }
                        onClick={() =>
                            handleTabChange(FaceToFaceEventsTab.Past)
                        }
                    >
                        Past
                    </NavLink>
                </NavItem>
            </Nav>

            <FaceToFaceEventsTabContent
                tab={selectedTab}
                handleSearchChange={(e) => debouncedResults(e.target.value)}
                handleSortChange={setSort}
                sort={sort}
                events={events}
                resetFilters={resetFilters}
                searchInputRef={searchInputRef}
                requestCountLoader={requestCountLoader}
                isAdminUser={isAdminUser}
            />
        </Card>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(FaceToFaceEvents as any);

import { PageQuery } from 'common/PageQuery';
import { PageResult } from 'common/PageResult';
import Result from 'core/Result';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Table } from 'reactstrap';
import classNames from 'classnames';

import rightEnabled from "images/pagination-arrow-right-enabled.svg";
import rightDisabled from "images/pagination-arrow-right-disabled.svg";
import leftEnabled from "images/pagination-arrow-left-enabled.svg";
import leftDisabled from "images/pagination-arrow-left-disabled.svg";
import { SortIcon } from 'components/dashboard/styles/Common';
import { SortDirection } from 'common/SortDirection';

export type PagedTableColumn<T> = {
    key?: keyof T;
    label?: string;
    className?: string;
    sortable?: boolean;
}

interface PagedTableProps<T> {
    request(query: PageQuery<T>): Promise<Result<PageResult<T>>>;
    pageSize: number;
    columns?: PagedTableColumn<T>[];
    renderRow?(item: T): React.ReactNode;
    THead?: React.ReactNode;
    defaultSort?: [keyof T, SortDirection]
}

export const PagedTable = <T,>({ request, pageSize, columns, renderRow, THead, defaultSort: [defaultSortBy, defaultSortDirection] = [undefined, undefined] }: PagedTableProps<T>) => {

    const [items, setItems] = useState<T[]>();
    const [itemCount, setItemCount] = useState<number>();
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>();
    const [sortBy, setSortBy] = useState<keyof T>(() => defaultSortBy ?? columns?.find(c => c.key !== undefined)?.key);
    const [sortDirection, setSortDirection] = useState<SortDirection>(defaultSortDirection ?? SortDirection.Asc);

    const changeSort = useCallback((key?: keyof T) => {
        if (sortBy === key) {
            if (sortDirection === SortDirection.Desc) {
                setSortBy(undefined);
            }
            setSortDirection(SortDirection.Desc);
        } else {
            setSortBy(key);
            setSortDirection(SortDirection.Asc);
        }
        setPage(0);
    }, [sortBy, sortDirection]);

    useEffect(() => {
        getPaged();
        // TODO: return cancel promise

        async function getPaged() {
            setIsLoading(true);
            try {
                // eslint-disable-next-line eqeqeq
                const response = await request({
                    currentPage: page,
                    pageSize: pageSize,
                    by: sortBy,
                    direction: sortDirection,
                });

                setItems(response.value.items);
                setItemCount(response.value.totalCount);
            } finally {
                setIsLoading(false);
            }
        }
    }, [page, pageSize, request, sortBy, sortDirection]);

    const RenderRow = useCallback((item: T) => {
        if (renderRow) {
            return renderRow(item);
        }
        return columns.map(c => item[c.key]);
    }, [columns, renderRow]);

    if (!items) {
        return <></>;
    }

    return <>
        <Table className={classNames({ 'opacity-25 pe-none': isLoading })}>
            <thead>
                {THead ||
                    <tr>
                        {columns.map(({ className, key, label, sortable }) => <th
                            key={String(key)}
                            className={classNames(className)}
                            onClick={sortable && key && (() => changeSort(key)
                            )}
                            role={key && sortable && "button"}
                        >
                            {label}
                            <SortIcon
                                isAscending={
                                    sortDirection === SortDirection.Asc
                                }
                                isHidden={
                                    !sortable ||
                                    sortBy !==
                                    key
                                }
                                src={
                                    require("images/icon-filter-down.svg")
                                        .default
                                }
                                alt="sort-icon"
                            />
                        </th>)}
                    </tr>}
            </thead>
            <tbody>
                {items.map(RenderRow)}
            </tbody>
        </Table >

        {
            // eslint-disable-next-line eqeqeq
            itemCount != undefined && <ReactPaginate
                forcePage={page}
                className="react-paginate"
                breakLabel="..."
                nextLabel={
                    <div className="pagination-buttons d-flex align-items-center align-content-center">
                        Next
                        <img
                            alt=""
                            src={
                                page !==
                                    Math.ceil(
                                        itemCount /
                                        pageSize
                                    ) -
                                    1
                                    ? rightEnabled
                                    : rightDisabled
                            }
                        />
                    </div>
                }
                onPageChange={(page) => {
                    setPage(page.selected);
                }}
                pageRangeDisplayed={3}
                pageCount={Math.ceil(
                    itemCount / pageSize
                )}
                previousLabel={
                    <div className="pagination-buttons d-flex align-items-center align-content-center">
                        <img
                            alt=""
                            src={
                                page !== 0
                                    ? leftEnabled
                                    : leftDisabled
                            }
                        />
                        Previous
                    </div>
                }
                renderOnZeroPageCount={null}
            />
        }
    </>
}
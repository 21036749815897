import React, { useState } from "react";
import _ from "lodash";

import Button from "components/shared/Button";
import { ContextMenu } from "components/shared/ContextMenu";
import { UserCourseSubmissionStatus } from "models/enums/UserCourseSubmissionStatus";
import { UserCourseSubmissionDismissReason } from "models/enums/UserCourseSubmissionDismissReason";
import { CourseUserResponse } from "models/CourseUserResponse";
import { UpdateUserCourseAnswerPayload } from "models/UpdateUserCourseAnswerPayload";

import {
    ResponseInnerCardTitle,
    PdfImage,
    PdfLink,
    ResponseCard,
    ResponseDataItemTitle,
    ResponseDataItemValue,
    ResponseItemDataGroup,
    ResponseItemBlockTitle,
    QuestionText,
    AnswerText,
    UserCourseSubmissionStatusValue,
    DismissButton,
    ApproveButton,
    AlertIcon,
    MissingEvaluationFormAlertText,
} from "../styles/LearningMaterials";
import { ReactComponent as IconEdit } from "images/icon-edit.svg";
import "./styles/LearningMaterialDetails.scss";

interface LearningMaterialResponsesProps {
    responseItem: CourseUserResponse;
    isAdminUser: boolean;
    downloadCourseResponsesCsvExport?: () => void;
    downloadEvaluationFormSubmissionDocument?: () => void;
    approveEvaluationForm?: (isReapprove?: boolean) => void;
    setIdToDismiss?: (id: number) => void;
    updateUserCourseAnswer?: (
        courseQuestionId: string,
        payload: UpdateUserCourseAnswerPayload
    ) => void;
}

const getSubmissionStatusText = (
    status: UserCourseSubmissionStatus
): string => {
    switch (status) {
        case UserCourseSubmissionStatus.PendingApproval:
            return "Pending Approval";
        default:
            return UserCourseSubmissionStatus[status].toString();
    }
};

const getSubmissionDismissReasonText = (
    reason: UserCourseSubmissionDismissReason
): string => {
    switch (reason) {
        case UserCourseSubmissionDismissReason.InsufficientResponses:
            return "Insufficient Responses";
        case UserCourseSubmissionDismissReason.InsufficientSubmissionForm:
            return "Insufficient Submission Form";
        default:
            return UserCourseSubmissionDismissReason[reason].toString();
    }
};

const LearningMaterialResponses: React.FC<LearningMaterialResponsesProps> = (
    props: LearningMaterialResponsesProps
) => {
    const {
        responseItem,
        isAdminUser,
        downloadCourseResponsesCsvExport,
        downloadEvaluationFormSubmissionDocument,
        approveEvaluationForm,
        setIdToDismiss,
        updateUserCourseAnswer,
    } = props;

    const [editAnswerQuestionId, setEditAnswerQuestionId] =
        useState<string>("");
    const [currentAnswerText, setCurrentAnswerText] = useState<string>("");

    return (
        <React.Fragment>
            {isAdminUser && (
                <ResponseCard className="card p-3">
                    <div className="flex justify-between align-items-center mb-3">
                        <ResponseInnerCardTitle>
                            {responseItem.employeeFullName}
                        </ResponseInnerCardTitle>
                        <div className="flex align-items-center">
                            <UserCourseSubmissionStatusValue
                                className="me-3"
                                isLarge={true}
                                status={
                                    UserCourseSubmissionStatus[
                                    responseItem.submissionStatus.toString() as keyof typeof UserCourseSubmissionStatus
                                    ]
                                }
                            >
                                {getSubmissionStatusText(
                                    UserCourseSubmissionStatus[
                                    responseItem.submissionStatus.toString() as keyof typeof UserCourseSubmissionStatus
                                    ]
                                )}
                            </UserCourseSubmissionStatusValue>
                            <ContextMenu
                                rightAligned={true}
                                menuitems={[
                                    {
                                        id: 0,
                                        label: "Export CSV",
                                        onAction: () =>
                                            downloadCourseResponsesCsvExport(),
                                    },
                                ]}
                                icon={
                                    require("images/icon-more-vertical.svg")
                                        .default
                                }
                            />
                        </div>
                    </div>
                    <div className="flex justify-between align-items-center">
                        <ResponseItemDataGroup>
                            <ResponseDataItemTitle>
                                Racgp id
                            </ResponseDataItemTitle>
                            <ResponseDataItemValue>
                                {responseItem.racgpNumber}
                            </ResponseDataItemValue>
                        </ResponseItemDataGroup>
                        <ResponseItemDataGroup>
                            <ResponseDataItemTitle color="var(--black)">
                                Profession type
                            </ResponseDataItemTitle>
                            <ResponseDataItemValue>
                                {responseItem.professionType}
                            </ResponseDataItemValue>
                        </ResponseItemDataGroup>
                        <ResponseItemDataGroup>
                            <ResponseDataItemTitle>
                                Submission form
                            </ResponseDataItemTitle>
                            <ResponseDataItemValue>
                                {responseItem.evaluationFormId != null && (
                                    <PdfLink
                                        href="/"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            downloadEvaluationFormSubmissionDocument();
                                        }}
                                        title="evaluation-form"
                                        className="evaluation-form-link flex align-items-center"
                                    >
                                        <PdfImage
                                            src={
                                                require("images/icon-pdf.svg")
                                                    .default
                                            }
                                            alt="pdf-icon"
                                        />
                                        <span>Form.pdf</span>
                                    </PdfLink>
                                )}
                                {responseItem.evaluationFormId == null && (
                                    <span>
                                        <AlertIcon
                                            className="me-1"
                                            src={
                                                require("images/alert-circle.svg")
                                                    .default
                                            }
                                            alt="alert-icon"
                                        />
                                        <MissingEvaluationFormAlertText>
                                            Evaluation form not yet submitted
                                        </MissingEvaluationFormAlertText>
                                    </span>
                                )}
                            </ResponseDataItemValue>
                        </ResponseItemDataGroup>
                        {(UserCourseSubmissionStatus[
                            responseItem.submissionStatus
                        ] as any) === UserCourseSubmissionStatus.Dismissed && (
                                <ResponseItemDataGroup>
                                    <ResponseDataItemTitle>
                                        REASON FOR DISMISSAL
                                    </ResponseDataItemTitle>
                                    <ResponseDataItemValue>
                                        {getSubmissionDismissReasonText(
                                            UserCourseSubmissionDismissReason[
                                            responseItem.dismissReason.toString() as keyof typeof UserCourseSubmissionDismissReason
                                            ]
                                        )}
                                        {responseItem.dismissReason != null &&
                                            responseItem.dismissComment &&
                                            ", "}
                                        {responseItem.dismissComment || ""}
                                    </ResponseDataItemValue>
                                </ResponseItemDataGroup>
                            )}
                        {(UserCourseSubmissionStatus[
                            responseItem.submissionStatus
                        ] as any) !== UserCourseSubmissionStatus.Dismissed && (
                                <ResponseItemDataGroup isHidden={true}>
                                    <ResponseDataItemTitle></ResponseDataItemTitle>
                                    <ResponseDataItemValue></ResponseDataItemValue>
                                </ResponseItemDataGroup>
                            )}
                        <ResponseItemDataGroup isHidden={true}>
                            <ResponseDataItemTitle></ResponseDataItemTitle>
                            <ResponseDataItemValue></ResponseDataItemValue>
                        </ResponseItemDataGroup>
                    </div>
                </ResponseCard>
            )}
            <ResponseCard className="card mt-3 p-3 flex-grow-1 h-100 overflow-auto">
                <div className="mb-3">
                    <ResponseInnerCardTitle>Responses</ResponseInnerCardTitle>
                </div>
                {!isAdminUser && (
                    <div className="mb-3">
                        <p>Review your responses for this course here.</p>
                        <p>
                            You will be able to edit them until your submission
                            has been approved by our CPD Admin Team.
                        </p>
                    </div>
                )}
                <div>
                    {responseItem.blocks?.map((block, blockIndex) => {
                        return (
                            <React.Fragment key={blockIndex}>
                                <ResponseItemBlockTitle
                                    className="p-3 mb-2"
                                    id={`response-block-${block.blockTitle}`}
                                >
                                    {block.blockTitle}
                                </ResponseItemBlockTitle>
                                {block.questionsAndAnswers?.map(
                                    (question, questionIndex) => {
                                        const splitQuestion =
                                            question.questionText.split("\n");
                                        const splitAnswer =
                                            question.answer.split("\n");
                                        return (
                                            <div
                                                key={questionIndex}
                                                className="p-3"
                                            >
                                                <QuestionText className="mb-3">
                                                    {splitQuestion?.map(
                                                        (
                                                            questionLine,
                                                            questionLineIndex
                                                        ) => {
                                                            return (
                                                                <React.Fragment
                                                                    key={
                                                                        questionLineIndex
                                                                    }
                                                                >
                                                                    {
                                                                        questionLine
                                                                    }
                                                                    {questionLineIndex <
                                                                        splitQuestion.length -
                                                                        1 && (
                                                                            <br />
                                                                        )}
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    )}
                                                </QuestionText>
                                                <AnswerText>
                                                    {editAnswerQuestionId !==
                                                        question.questionId &&
                                                        splitAnswer?.map(
                                                            (
                                                                answerLine,
                                                                answerLineIndex
                                                            ) => {
                                                                return (
                                                                    <React.Fragment
                                                                        key={
                                                                            answerLineIndex
                                                                        }
                                                                    >
                                                                        {
                                                                            answerLine
                                                                        }
                                                                        {answerLineIndex <
                                                                            splitAnswer.length -
                                                                            1 && (
                                                                                <br />
                                                                            )}
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    {editAnswerQuestionId !==
                                                        question.questionId && (
                                                            <div className="mt-4 me-2 flex justify-content-end align-items-center">
                                                                <Button
                                                                    disabled={
                                                                        editAnswerQuestionId !==
                                                                        "" ||
                                                                        responseItem.submissionStatus?.toString() ===
                                                                        UserCourseSubmissionStatus[
                                                                        UserCourseSubmissionStatus
                                                                            .Approved
                                                                        ]
                                                                    }
                                                                    className="me-2 edit-response-button"
                                                                    variant="secondary"
                                                                    onClick={() => {
                                                                        setEditAnswerQuestionId(
                                                                            question.questionId
                                                                        );
                                                                        setCurrentAnswerText(
                                                                            question.answer
                                                                        );
                                                                    }}
                                                                >
                                                                    <IconEdit />
                                                                    <span className="ms-2">
                                                                        Edit
                                                                        response
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        )}
                                                    {editAnswerQuestionId ===
                                                        question.questionId && (
                                                            <React.Fragment>
                                                                <textarea
                                                                    className="w-full form-control"
                                                                    value={
                                                                        currentAnswerText
                                                                    }
                                                                    onChange={(e) =>
                                                                        setCurrentAnswerText(
                                                                            e.target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                                <div>
                                                                    {
                                                                        currentAnswerText.length
                                                                    }
                                                                    {" characters"}
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    {editAnswerQuestionId ===
                                                        question.questionId && (
                                                            <div className="mt-4 me-2 flex justify-content-end align-items-center">
                                                                <Button
                                                                    className="me-2"
                                                                    variant="secondary"
                                                                    onClick={() => {
                                                                        setEditAnswerQuestionId(
                                                                            ""
                                                                        );
                                                                        setCurrentAnswerText(
                                                                            ""
                                                                        );
                                                                    }}
                                                                >
                                                                    Discard changes
                                                                </Button>
                                                                <Button
                                                                    variant="primary"
                                                                    onClick={() => {
                                                                        updateUserCourseAnswer(
                                                                            question.questionId,
                                                                            {
                                                                                text: currentAnswerText,
                                                                            }
                                                                        );

                                                                        setEditAnswerQuestionId(
                                                                            ""
                                                                        );
                                                                        setCurrentAnswerText(
                                                                            ""
                                                                        );
                                                                    }}
                                                                >
                                                                    Save response
                                                                </Button>
                                                            </div>
                                                        )}
                                                </AnswerText>
                                                <hr />
                                            </div>
                                        );
                                    }
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            </ResponseCard>
            {isAdminUser && (
                <div className="flex justify-content-end mt-3">
                    <DismissButton
                        disabled={
                            responseItem.evaluationFormId == null ||
                            responseItem.submissionStatus.toString() !==
                            UserCourseSubmissionStatus[
                            UserCourseSubmissionStatus.PendingApproval
                            ]
                        }
                        className="btn btn-danger outline px-5 flex align-items-center ms-3"
                        role="button"
                        onClick={() =>
                            setIdToDismiss(responseItem.evaluationFormId)
                        }
                    >
                        Dismiss
                    </DismissButton>
                    {UserCourseSubmissionStatus[
                        responseItem.submissionStatus.toString()
                    ] !== UserCourseSubmissionStatus.Dismissed && (
                            <ApproveButton
                                disabled={
                                    responseItem.evaluationFormId == null ||
                                    responseItem.submissionStatus.toString() !==
                                    UserCourseSubmissionStatus[
                                    UserCourseSubmissionStatus
                                        .PendingApproval
                                    ]
                                }
                                className="btn btn-danger outline px-5 flex align-items-center ms-3"
                                role="button"
                                onClick={() => approveEvaluationForm()}
                            >
                                Approve
                            </ApproveButton>
                        )}
                    {responseItem.evaluationFormId != null &&
                        UserCourseSubmissionStatus[
                        responseItem.submissionStatus.toString()
                        ] === UserCourseSubmissionStatus.Dismissed && (
                            <ApproveButton
                                className="btn btn-danger outline px-5 flex align-items-center ms-3"
                                role="button"
                                onClick={() => approveEvaluationForm(true)}
                            >
                                Reapprove
                            </ApproveButton>
                        )}
                </div>
            )}
        </React.Fragment>
    );
};

export default LearningMaterialResponses;

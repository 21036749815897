import {
    Employee,
    SuperAnnuationType,
    WorkRights,
} from "../../models/Employee";
import {UsersPageListItem} from "../../models/UsersPageListItem";
import { ProfessionType } from "../../models/enums/ProfessionType";

export const getRoleString = (usersPageListItem: UsersPageListItem) => {
    switch (usersPageListItem.userRole) {
        case "SuperUser":
            return "Super Admin";
        case "Admin":
            return "Admin";
        case "Client":
            return "Client";
        case "User":
            return "User";
        default:
            return usersPageListItem.userRole || "";
    }
}

export const validateEmail = (email: string) => {
    var validEmailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return email.match(validEmailRegex);
};

export const fullEmployeeValidation = (employee: Employee) => {
    if (!validateEmployee(employee)) {
        return false;
    }
    if (employee.address === null || employee.address.length === 0) {
        return false;
    }
    if (
        employee.bankAccountName === null ||
        employee.bankAccountName.length === 0
    ) {
        return false;
    }
    if (
        employee.workRight === WorkRights.VISA &&
        employee.inactiveDate === null
    ) {
        return false;
    }
    if (employee.payGrade === null) {
        return false;
    }
    if (employee.bsb === null || employee.accountNumber === null) {
        return false;
    }
    if (
        employee.superType === SuperAnnuationType.SELF_MANAGED &&
        (employee.usiNumber === null ||
            employee.fundName === null ||
            employee.membershipNumber === null)
    ) {
        return false;
    }
    if (
        employee.superType === SuperAnnuationType.SELF_MANAGED &&
        employee.usiNumber !== null &&
        (employee.usiNumber.length > 16 || employee.usiNumber.length < 4)
    ) {
        return false;
    }
    if (
        employee.superType === SuperAnnuationType.SELF_MANAGED &&
        employee.membershipNumber &&
        employee.membershipNumber.length === 0
    ) {
        return false;
    }
    if (
        employee.accountNumber != null &&
        (employee.accountNumber.length > 16 ||
            employee.accountNumber.length < 4 ||
            !/^[0-9]+$/.test(employee.accountNumber))
    ) {
        return false;
    }
    if (employee.bsb.includes("-") && employee.bsb.length !== 7) {
        return false;
    }
    if (!employee.bsb.includes("-") && employee.bsb.length !== 6) {
        return false;
    }
    return true;
};

export const validateEmployee = (employee: Employee) => {
    if (employee.firstName.length === 0) {
        return false;
    }
    if (employee.lastName.length === 0) {
        return false;
    }
    if (!validateEmail(employee.email)) {
        return false;
    }
    if (!validateMobile(employee.mobile)) {
        return false;
    }
    if (employee.professionType.length === 0) {
        return false;
    }
    if (employee.racgpNumber.length === 0) {
        return false;
    }
    if (employee.ahpraNumber.length === 0) {
        return false;
    }
    if (employee.professionType === ProfessionType.GeneralPractitioner && (employee.fellowshipCompletionYear < 1900 || employee.fellowshipCompletionYear > 2999)) {
        return false;
    }
    if (employee.professionType === ProfessionType.Registrar) {
        if (employee.registrarClassification == null) {
            return false;
        }
        if (employee.registrarCommencementYear == null || employee.registrarCommencementYear < 1900 || employee.registrarCommencementYear > 2999) {
            return false;
        }
        if (employee.registrarCommencementMonth == null) {
            return false;
        }
    }
    if (employee.primaryPracticeLocationId === -1 && employee.otherPracticeLocation.length === 0) {
        return false;
    }
    if (employee.primaryPracticeLocationId === -1 && employee.otherPracticeLocationState.length === 0) {
        return false;
    }
    return true;
};

export const validateMobile = (mobile: string) => {
    if (mobile === null || mobile.length === 0) {
        return false;
    }
    return true;
};

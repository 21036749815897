import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import {
    ResendLabel,
    ResendContainer,
    ResendButtonContainer,
    TitleLabel,
    ErrorLabel,
    Title,
    Modal,
    ModalCard,
    ModalTitle,
    ModalLabel,
} from "./styles/VerifyMobile";
import "react-toastify/dist/ReactToastify.css";
import MobileInput from "../../components/dashboard/MobileInput";

import leftArrowIcon from "../../images/icons/arrow-left.svg";
import Button from "components/shared/Button";

interface IVerifyMobileBoxProps {
    isValidCode: boolean;
    onNext: (code: string) => Promise<void>;
    onBack: () => void;
    onResend: () => Promise<void>;
    showAlert: boolean;
    onCloseAlertModel: () => void;
}

export const VerifyMobileBox: React.FC<IVerifyMobileBoxProps> = (
    props: IVerifyMobileBoxProps
) => {
    const resendTime = 5;
    const [currentTimeLeft, setCurrentTimeLeft] = useState(resendTime);
    const [code, setCode] = useState("");

    useEffect(() => {
        const myInterval = setInterval(() => {
            if (currentTimeLeft > 0) {
                setCurrentTimeLeft(currentTimeLeft - 1);
            }
            if (currentTimeLeft === 0) {
                clearInterval(myInterval);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <React.Fragment>
            <Modal show={props.showAlert}>
                <ModalCard>
                    <ModalTitle>Too many attempts</ModalTitle>
                    <ModalLabel>
                        You have requested your code too many times.{"\n"}{" "}
                        Please try again later.
                    </ModalLabel>
                    <div className="mt-4">
                        <Button
                            onClick={props.onCloseAlertModel}
                            type="button"
                        >
                            Ok
                        </Button>
                    </div>
                </ModalCard>
            </Modal>

            <div className="m-3">
                <Row className="text-center">
                    <Title>Enter your code</Title>
                    <TitleLabel>
                        We sent a verification code to your number.<br />
                        The code will expire after 5 minutes.
                    </TitleLabel>
                </Row>
                <Row
                    className="mb-3"
                >
                    <div className="text-center">
                        <MobileInput
                            size="60px"
                            margin="20px"
                            fontSize="28px"
                            isValidCode={props.isValidCode}
                            onCompleteCode={(value) => {
                                setCode(value);
                            }}
                            onContinue={() =>
                                code != null &&
                                code.length === 4 &&
                                props.onNext(code)
                            }
                        />
                    </div>
                </Row>
                <Row className="justify-content-left">
                    {!props.isValidCode && (
                        <ErrorLabel className="px-4">
                            Verification code is incorrect
                        </ErrorLabel>
                    )}
                </Row>
                <Row className="d-grid gap-2">
                    <Button
                        className="m-auto"
                        onClick={async () => {
                            props.onNext(code);
                        }}
                    >
                        Verify
                    </Button>
                </Row>
            </div>
            <div className="pt-4">
                {currentTimeLeft > 0 && (
                    <ResendContainer>
                        <ResendLabel>
                            Resend code in {currentTimeLeft} second(s)
                        </ResendLabel>
                    </ResendContainer>
                )}

                {currentTimeLeft === 0 && (
                    <React.Fragment>
                        <ResendContainer>
                            <ResendLabel>
                                Didn’t receive the code?&nbsp;
                            </ResendLabel>
                            <ResendButtonContainer
                                onClick={async () => {
                                    await props.onResend();
                                    setCurrentTimeLeft(resendTime);
                                }}
                            >
                                Click to resend
                            </ResendButtonContainer>
                        </ResendContainer>
                    </React.Fragment>
                )}
            </div>
            <div className="text-center">
                <span className="back-button" role="button" onClick={props.onBack}>
                    <img src={leftArrowIcon} alt="" className="inline" />&nbsp;
                    Back
                </span>
            </div>
        </React.Fragment>
    );
};

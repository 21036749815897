import React from "react";
import { connect } from "react-redux";
import * as dashboard from "../../store/dashboardStore";
import { IApplicationState } from "../../store";
import { Employee } from "../../models/Employee";

const DateFormat = new Intl.DateTimeFormat("en-AU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
});

interface DoctorCertificatesProps {
    employee: Employee;
}

type Props = DoctorCertificatesProps &
    dashboard.DashboardState &
    typeof dashboard.actionCreators;

const DoctorCertificates: React.FC<Props> = (props: Props) => {
    const { employee, downloadUserCourseCertificateExportAdmin } = props;

    const completedCourses = employee.courses.filter(
        (c) => c.approvedAt != null
    );

    return (
        <React.Fragment>
            <div className="p-3 flex justify-between">
                <h5 className="p-0 m-0 align-self-center">Certificates</h5>
            </div>
            <hr className="m-0" />
            <div className="flex-grow-1 overflow-auto">
                <table className="table table-hover doctors-table w-full mb-0">
                    <thead>
                        <tr className="p-2">
                            <th className="table-cell">Course Name</th>
                            <th className="table-cell">Date</th>
                            <th className="table-cell"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {completedCourses.map((course) => {
                            return (
                                <tr key={course.id} className="p-2">
                                    <td className="approval-table-cell">
                                        {course.title}
                                    </td>
                                    <td className="approval-table-cell">
                                        {DateFormat.format(course.approvedAt)}
                                    </td>
                                    <td className="approval-table-cell">
                                        <a
                                            href="#"
                                            title="user-certificate"
                                            className="evaluation-form-link flex align-items-center align-content-center"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                downloadUserCourseCertificateExportAdmin(
                                                    course.id
                                                );
                                            }}
                                        >
                                            <span>View</span>
                                        </a>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.dashboard,
    }),
    { ...dashboard.actionCreators }
)(DoctorCertificates as any) as React.FC<DoctorCertificatesProps>;

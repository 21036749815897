import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../services/AuthService";
import SessionManager from "../../core/session";
import * as auth from "../../store/authStore";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { IApplicationState } from "../../store";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router-dom";
import { VerifyMobileBox } from "./VerifyMobileBox";
import { HelpText } from "../../components/shared/HelpText";
import { LoginLogo, MobileLoginLogo } from "./styles/SignUp";
import academyLogoImage from "../../images/academy-logo.svg";
import { showDefault, showErrors } from "../../utils";

enum VerifyStatus {
    Ready,
    Failed,
    Successful,
}

enum ResendStatus {
    Failed,
    Successful,
}

type Props = RouteComponentProps<{}> &
    auth.AuthState & {} & typeof auth.actionCreators;

const VerifyMobile: React.FC<Props> = (props: Props) => {
    const { user } = props;
    const [isLoading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [verifyStatus, setVerifyStatus] = useState(VerifyStatus.Ready);
    const [isValidCode, setIsValidCode] = useState(true);
    const history = useHistory();

    if (verifyStatus === VerifyStatus.Successful) {
        return (
            <Redirect
                to={
                    (props.location?.state as any)?.from != null
                        ? (props.location.state as any).from
                        : "/"
                }
            />
        );
    }

    const validateCode = async (code: string) => {
        setLoading(true);
        const result = await AuthService.VerifyMobile(
            user.email,
            user.lastName,
            code
        );
        setLoading(false);
        const newStatus = result.hasErrors
            ? VerifyStatus.Failed
            : VerifyStatus.Successful;
        if (newStatus == VerifyStatus.Successful && result.value.token) {
            SessionManager.setLogin(result.value.token, true);
            setIsValidCode(true);
            setVerifyStatus(VerifyStatus.Successful);
        } else {
            setIsValidCode(false);
            setVerifyStatus(VerifyStatus.Failed);
        }
    };

    const resendValidationCode = async () => {
        const result = await AuthService.ResendCode(user.email, user.lastName);
        const newStatus = result.hasErrors
            ? ResendStatus.Failed
            : ResendStatus.Successful;
        if (newStatus === ResendStatus.Failed) {
            if (
                result.errors
                    .toLocaleString()
                    .toString()
                    .toLowerCase()
                    .includes("retry")
            ) {
                setShowAlert(true);
            } else {
                showErrors(...result.errors);
            }
        } else {
            showDefault("A new code has been sent to your number!");
        }
    };

    return (
        <div className="signin">
            <div className="block md:hidden">
                <MobileLoginLogo src={academyLogoImage} />
            </div>
            <div className="hidden lg:block">
                <LoginLogo src={academyLogoImage} />
            </div>
            <Container className="vh-100">
                <Row
                    style={{ height: "calc(100vh - 40px)", padding: "0" }}
                    className="justify-content-center m-0"
                >
                    <Col
                        xs={12}
                        md={6}
                        className="align-self-center justify-self-center"
                        style={{ maxWidth: "375px" }}
                    >
                        <VerifyMobileBox
                            onBack={() => {
                                history.push("/");
                            }}
                            onResend={resendValidationCode}
                            onNext={validateCode}
                            isValidCode={isValidCode}
                            showAlert={showAlert}
                            onCloseAlertModel={() => setShowAlert(false)}
                        />
                    </Col>
                </Row>
                <Row className="m-0">
                    <Col>
                        <HelpText />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default connect(
    (state: IApplicationState) => ({
        ...state.auth,
    }),
    { ...auth.actionCreators }
)(VerifyMobile as any);
